import React, {useState} from 'react';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Business as BusinessIcon, Check as CheckIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import './UserItem.css'

interface UserItemProps {
    idx: number;
    token: string;
    user: any; // Replace 'any' with a proper user type
    users: any[]; // Replace 'any' with a proper user type
    setUsers: (users: any[]) => void; // Replace 'any' with a proper user type
    setSendList: (sendList: number[]) => void;
    sendList: number[];
  }
  
  const UserAddItem: React.FC<UserItemProps> = ({ idx, token, user, users, setUsers, setSendList, sendList }) => {
    const itemStyle = 'rgb(30, 35, 40)';
    const [active, setActive] = useState(false);

    return (
      <ListItem>
  <Grid container alignItems="center">
    <Grid item xs={2} onClick={() => {}}>
      <ListItemAvatar>
        <Avatar src={`https://outvirt.com/assets/imgmap/${user.profilepic}`} />
      </ListItemAvatar>
    </Grid>
    <Grid item xs={8} onClick={() => {}}>
      <ListItemText primary={user.name} secondary={<><span style={{color:"#999"}}>@{user.username}&nbsp;</span></>} />
    </Grid>
    <Grid item xs={2}>
      <ListItemSecondaryAction>
      <IconButton
        className={!user.select ? "outlined" : "filled"}
        color={user.select ? "primary" : "default"}
        onClick={async () => {
            if (!user.select) {
                setSendList([...sendList, user.id]);
                user.select = true;
                const newUser = [...users.slice(0,idx), user, ...users.slice(idx+1)] // TODO: there might be a bug here
                setUsers(newUser);
            // ...
            } else {
                setSendList(sendList.filter((id) => id !== user.id));
                user.select = false;
                const newUser = [...users.slice(0,idx), user, ...users.slice(idx+1)] // TODO: there might be a bug here
                setUsers(newUser);
            // ...
            }
        }}
        >
        {user.select && <CheckIcon fontSize="small"/> }
        </IconButton>

      </ListItemSecondaryAction>
    </Grid>
  </Grid>
</ListItem>

    );
  };
  
  export default UserAddItem;