import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import { useTranslation } from 'react-i18next';
const Uploader = () => {
    const { t, i18n } = useTranslation();

  const [files, setFiles] = useState([]);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [url, setUrl] = useState('');
  const [caption, setCaption] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);  // Add this state for the success message
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleRemove = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const handleUpload = async () => {
    // Check if files is empty
    if (files.length === 0) {
        setErrorMessage(t('uploader.addfiles'));
        return;  // Exit the function
    } else if (email === ''){
        setErrorMessage(t('uploader.enteremailbefore'));
        return;  // Exit the function
    } else if (username === ''){
        setErrorMessage(t('uploader.enteruserbefore'));
        return;  // Exit the function
    }

    let uploadedFilenames = [];

    // Upload each media file one by one
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        const filename = `${username}_${Math.random().toString(36).substring(2, 15)}_${i}.${file.name.split('.').pop()}`;
        formData.append('file', file, filename);
        try {
        // Note: Replace 'YOUR_NODEJS_ENDPOINT' with your actual endpoint for file uploads
        const response = await fetch('https://outvirt.com:8080/uploadWebMedia', {
            method: 'POST',
            body: formData,
            onUploadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
            }
        });
        if (!response.ok) {
            throw new Error(t('uploader.failupload'));
        }
        uploadedFilenames.push(filename);
        } catch (err) {
        console.error(t('uploader.errorfiles'), err);
        setErrorMessage(t('uploader.failupload'));
        return;
        }
    }

    // After all media are uploaded, send the caption with filenames
    try {
        // Note: Replace 'YOUR_NODEJS_ENDPOINT' with your actual endpoint for data uploads
        const response = await fetch('https://outvirt.com:8080/uploadWebData', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username,
            email,
            caption,
            location,
            url,
            filenames: uploadedFilenames
        })
        });
        if (!response.ok) {
        throw new Error(t('uploader.faildata'));
        }
        setFiles([]);
        setCaption('');
        setLocation('')
        setUrl('')
        setErrorMessage('')
        setUploadSuccess(true);
        setTimeout(() => {
        setUploadSuccess(false);
        }, 5000);
    } catch (err) {
        console.error(t('uploader.faildata'), err);
        setErrorMessage(t('uploader.faildata'));
    }
  };

  // Clear the error message if new files are added or files are removed
    useEffect(() => {
        if (files.length > 0) {
        setErrorMessage('');
        }
    }, [files]);
    const spinnerStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the spinner
        border: "6px solid rgba(0, 0, 0, 0.1)", 
        borderRadius: "50%",
        borderTop: "6px solid #3498db", 
        width: "50px",
        height: "50px",
        animation: "spin 1s linear infinite",
        zIndex: 10
      };
      
      const progressTextStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the text
        fontSize: '1em',
        fontWeight: 'bold'
      };
      
      // Add keyframes for spinning animation:
      const spinKeyframes = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      `;
      
      
  const thumbContainerStyle = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
  };

  const imgStyle = {
    display: 'block',
    margin: 'auto',
    width: 'auto',
    maxHeight: '100%',
    maxWidth: '100%'
  };

  const videoStyle = {
    ...imgStyle,
    width: '100%',
    height: '100%'
  };

  const dropzoneStyle = {
    border: '1px dashed gray',
    padding: '20px',
    textAlign: 'center',
    position: 'relative'
  };
  
  const closeButtonStyle = {
    position: 'absolute',
    right: '5px',  
    top: '5px',
    width: '20px',
    height: '20px',
    backgroundColor: 'rgba(176, 176, 176, 0.8)',  // Semi-transparent grey
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '18px',  // Adjusted for vertical alignment
    fontSize: '16px',   // Adjusted to fit within the circle
    cursor: 'pointer',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
    padding: 0,        // Added to remove button default padding
    outline: 'none',    // To remove focus outline
    zIndex: 10  // Add this property

  };
  
  // CSS for fade-in and fade-out animations
const fadeInKeyframes = `
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`;

const fadeOutKeyframes = `
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
`;

// Inject the keyframes into the document (typically done in global styles)
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(fadeInKeyframes, styleSheet.cssRules.length);
styleSheet.insertRule(fadeOutKeyframes, styleSheet.cssRules.length);
styleSheet.insertRule(spinKeyframes, styleSheet.cssRules.length);
const successMessageStyle = {
  display: 'inline-block',
  padding: '10px 20px',
  color: '#FFFFFF',
  backgroundColor: '#4CAF50',
  borderRadius: '5px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  fontWeight: 'bold',
  fontSize: '1.1em',
  border: '1px solid #388E3C',
  animationDuration: '0.5s', // half second fade
};


// In your JSX
<div style={{ marginTop: '10px', textAlign: 'center' }}>
    {uploadSuccess && <p style={successMessageStyle}>{t('uploader.contentssuccess')}</p>}
</div>

// const ffmpeg = createFFmpeg({ log: true, corePath: '/ffmpeg-core.js' });

const onDrop = useCallback(async acceptedFiles => {
    setIsUploading(true);

    const processedFiles = await Promise.all(
      acceptedFiles.map(async (file, idx) => {
        console.log('type', file.type)
        if (file.type === 'image/heif' || file.type === 'image/heic') {
          const blob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8
          });
            return new File([blob], file.name, { type: 'image/jpeg' });
        }
          
        return file;
      })

      
    );
  
    setFiles(prevFiles => [...prevFiles, ...processedFiles.map(file => {
      if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      }
      return null;
    }).filter(Boolean)]);
    setIsUploading(false);

  }, []);
  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/heif, video/*, video/quicktime'
  });

  const thumbs = files.map(file => (
    <div key={file.name} style={thumbContainerStyle}>
        <button 
            onClick={() => handleRemove(file.name)} 
            style={closeButtonStyle}
        >
            &#10005;  {/* Unicode close symbol */}
        </button>


      {file.type.startsWith('image/') ? (
        <img src={file.preview} alt={file.name} style={imgStyle} />
      ) : (
        <video src={file.preview} autoPlay={true}  style={videoStyle}></video>
      )}
    </div>
  ));

  // Make sure to revoke the data URIs to avoid memory leaks
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div style={{marginLeft:"10px", marginRight: "10px"}}>
        <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <img 
                src={require('../assets/images/logo.svg').default} 
                alt="Icon" 
                style={{ width: '40px', height: '40px', marginRight: '10px' }} // Adjust width and height as needed
            />
            {t('uploader.outvirtuploader')}
        </h1>
        <div style={{ marginBottom: '10px' }}>
            <input
                type="email"
                placeholder={t('uploader.enteremail')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%' }}
            />
        </div>
        <div style={{ marginBottom: '10px' }}>
        <input
            type="text"
            placeholder={t('uploader.enteruser')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: '100%' }}
        />
        </div>
        <div style={{ marginBottom: '10px' }}>
        <input
            type="text"
            placeholder={t('uploader.location')}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            style={{ width: '100%' }}
        />
        </div>
        <div style={{ marginBottom: '20px' }}>
        <input
            type="text"
            placeholder={t('uploader.url')}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            style={{ width: '100%' }}
        />
        </div>
        <section>
  <div {...getRootProps()} style={dropzoneStyle}>
    <input {...getInputProps()} />
    {isUploading && (
      <div style={spinnerStyle}>
      </div>
    )}
    <p>{t('uploader.dragndrop')}</p>
  </div>
  <aside style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
    {thumbs}
  </aside>
</section>

        <div style={{ marginTop: '20px' }}>
            <textarea 
                placeholder={t('uploader.entercaption')}
                style={{ width: '100%', height: '100px' }}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
            ></textarea>
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button onClick={handleUpload}>
          {t('uploader.upload')}
        </button>
        {errorMessage && <p style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</p>}
        <div style={{ marginTop: '10px' }}>
            {uploadProgress > 0 && uploadProgress < 100 && (
            <progress value={uploadProgress} max="100"></progress>
            )}
        </div>
      </div>
      
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
            {uploadSuccess ? (
                <p style={{...successMessageStyle, animationName: 'fadeIn'}}>
                    {t('uploader.contentssuccess')}
                </p>
            ) : (
                <p style={{...successMessageStyle, animationName: 'fadeOut', opacity: 0}}>
                    {t('uploader.contentssuccess')}
                </p>
            )}
        </div>
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
            <button 
                onClick={() => i18n.changeLanguage('en')}
                style={{
                    marginRight: '20px',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    background: '#181818',
                    border: '1px solid #ddd',
                    cursor: 'pointer',
                    transition: 'background 0.2s'
                }}
                onMouseOver={(e) => e.currentTarget.style.background = '#252525'}
                onMouseOut={(e) => e.currentTarget.style.background = '#181818'}
            >
                EN
            </button>
            <button 
                onClick={() => i18n.changeLanguage('cn')}
                style={{
                    padding: '10px 20px',
                    borderRadius: '4px',
                    background: '#181818',
                    border: '1px solid #ddd',
                    cursor: 'pointer',
                    transition: 'background 0.2s'
                }}
                onMouseOver={(e) => e.currentTarget.style.background = '#252525'}
                onMouseOut={(e) => e.currentTarget.style.background = '#181818'}
            >
                中文
            </button>
        </div>


    </div>
  );
};

export default Uploader;
