import { useState, useEffect } from "react";
import "./AreaName.css";
import React from 'react';
import { getHighestZIndex } from '../utils/GetHighestZIndex';

const AreaName: React.FC<{ showAreaName: boolean; areaName: string; onTimeoutEnd: () => void; zoom: number; handleAreaNameClick: (zoom: number) => void}> = ({
    showAreaName,
    areaName,
    onTimeoutEnd,
    zoom,
    handleAreaNameClick
  }) => {
    const [visible, setVisible] = useState(true);
    const [isAreaNameClicked, setIsAreaNameClicked] = useState<boolean>(false);
  
    useEffect(() => {
      if (showAreaName) {
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
          setTimeout(() => {onTimeoutEnd();}, 1000);
          
        }, 1500);
      } else {
        setVisible(false);
      }
    }, [showAreaName]);
  

    

  return (
    <>
    <div className='area-name-container  show'  onClick={() => handleAreaNameClick(zoom)}>
            {areaName}
        </div>
      {/* {showAreaName && (
        // <div className={`area-name-container ${visible ? 'show' : ''}`} style={{zIndex: getHighestZIndex()+1}}>
        //     {areaName}
        // </div>
        <div className='area-name-container  show' style={{zIndex: getHighestZIndex()+1}}>
            {areaName}
        </div>
      )} */}
    </>
  );
}

export default AreaName;
