import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';

function VerticalSlider({ zoom, onZoomSliderChange, map }) {
  const [value, setValue] = useState(zoom);

  useEffect(() => {
    setValue(zoom);
  }, [zoom]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onZoomSliderChange(newValue);
  };

  return (
    <Slider
      orientation="vertical"
      value={value}
      onChange={handleChange}
      min={3}
      max={18}
      step={1}
    //   valueLabelDisplay="auto"
      marks
      sx={{
        height: 200,
        width: 20,
        color: 'gray',
        '& .MuiSlider-thumb': {
          width: 20,
          height: 20,
        },
        '& .MuiSlider-track': {
          width: '2px',
        },
        '& .MuiSlider-rail': {
          width: '2px',
        },
      }}
    />
  );
}

export default VerticalSlider;
