import React, { useState, useMemo } from 'react';
import maplibregl from 'maplibre-gl';
import L, { LatLng } from 'leaflet';
// import { Marker } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { Avatar } from '@material-ui/core';
import PublicIcon from '@mui/icons-material/Public';
import { MdFavorite, MdFavoriteBorder, MdChatBubble, MdChatBubbleOutline } from 'react-icons/md'; // Assuming you are using 'react-icons' for icons
import ParseDate from '../date/DateT';
import './map.css'
import { toast } from 'react-toastify';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import zIndex from '@mui/material/styles/zIndex';
import MlgMarker from './MlgMarker';
import { createRoot } from 'react-dom/client';


interface RegionCoverInfo {
  created_at: string;
  profilepic: string;
  n_likes: number;
  n_comments: number;
  body: string;
  account_type: number;
}

type RegionMarkerProps = {
  rs: any;
  state: any;
  map: any;
  opt: any;
  zoom: number;
  isHovered: boolean;
  hoverIdx: number;
  setIsHovered: (hovered: boolean) => void;
  setHoverIdx: (idx: number) => void;
  setRegionCoverInfo: (info: any) => void;
  setRegionInfo?: (info: any) => void;
  setFollowingPics: (pics: string[]) => void;
  // fetchRegionPosts: (id: number, zoom: number, userId: number, opt: any) => Promise<any>;
  fetchRegionPosts: (id: number, userId: number, topic_id: number, zoom: number, area_id: number, opt: any) => Promise<any>;
  fetchUserPostsInRegion?: (userId: number, topic_id: number, zoom: number, area_id: number) => Promise<any>;
  fetchSinglePost: (id: number) => Promise<any>;
  fetchRegionCoverInfo: (id: number, zoom: number, user_id: number, opt: any) => Promise<any>;
  fetchPostCoverInfo: (id: number, zoom: number, user_id: number, area_id: number, opt: any) => Promise<any>;
  setFetchBuffer: (buffer: any[]) => void;
  setRegionPosts: (posts: any[]) => void;
  setRegionAreas: (areas: any[]) => void;
  setZoomList: (zoomList: any[]) => void;
  setCurrentIdx: (idx: number) => void;
  setIsPrev: (isPrev: boolean) => void;
  setShowContent: (showContent: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setShowTutorials: (showTutorial: boolean) => void;
  regionCoverInfo: RegionCoverInfo;
  followingPics: string[];
  t: any;
  setCurrentSearchId: (idx: number) => void;
  type: string;
  prevIds: number[];
};


function deepEqual(obj1: any, obj2: any) {
  // Here we assume that obj1 and obj2 are the 'rs' prop passed to MLGRegionMarker component
  // If 'rs' prop structure is different, adjust this function accordingly
  // console.log('deepEqual', obj1.prevIds.includes(obj2.rs.id), (obj2.rs.id !== obj2.hoverIdx), !(obj2.hoverIdx == -1 && obj1.hoverIdx == obj2.rs.id), obj2.prevIds.includes(obj2.rs.id))
  // console.log(obj2.rs.id, obj2.hoverIdx, obj2.prevIds, obj2.isHovered)
  // console.log(obj1.id, obj1, obj2)
  // console.log('deepEqual', (obj1.id === obj2.id) , 
  // (obj1.rs.id === obj2.rs.id) , 
  // (obj1.isHovered === obj2.isHovered) , 
  // (obj1.hoverIdx !== obj1.rs.id) , 
  // (obj2.hoverIdx !== obj2.rs.id) ,
  //  (!(obj2.hoverIdx === obj2.rs.id) || (obj1.regionCoverInfo === obj2.regionCoverInfo)))
  // check if obj2.rs.id is within obj1.prevIds
  // console.log(obj1.prevIds, obj2.rs.id, obj1.rs.id , obj2.hoverIdx)
  return obj1.prevIds.includes(obj2.rs.id) && (obj2.rs.id !== obj2.hoverIdx) && !(obj2.hoverIdx == -1 && obj1.hoverIdx == obj2.rs.id) && obj2.prevIds.includes(obj2.rs.id)
  // return (obj1.id === obj2.id) && (obj1.rs.id === obj2.rs.id) && (obj1.isHovered === obj2.isHovered) && (obj1.hoverIdx !== obj1.rs.id) && (obj2.hoverIdx !== obj2.rs.id) && (!(obj2.hoverIdx === obj2.rs.id) || (obj1.regionCoverInfo === obj2.regionCoverInfo));
}

const MLGRegionMarker = React.memo<RegionMarkerProps>(({
  rs,
  state,
  map,
  opt,
  zoom,
  isHovered,
  hoverIdx,
  setIsHovered,
  setHoverIdx,
  setRegionCoverInfo,
  setRegionInfo,
  setFollowingPics,
  fetchRegionPosts,
  fetchUserPostsInRegion,
  fetchSinglePost,
  fetchRegionCoverInfo,
  fetchPostCoverInfo,
  setFetchBuffer,
  setRegionPosts,
  setRegionAreas,
  setZoomList,
  setCurrentIdx,
  setIsPrev,
  setShowContent,
  setIsLoading,
  setShowTutorials,
  regionCoverInfo,
  followingPics,
  t,
  setCurrentSearchId,
  type
}) => {
  function truncate(str: string, n: number) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
  };
  const handleMouseOver = () => {
    console.log('enter', isHovered);
    if (!isHovered) {
      console.log('HOVEREDID', rs.id)
      setIsHovered(true);
      setHoverIdx(rs.id);
      fetchPostCoverInfo(rs.id, zoom, state.id, rs.area_id, opt);
      // if (type == "summary")
      //   fetchRegionCoverInfo(rs.id, map!.getZoom(), state.id, opt);
      // else if (type == "single" || type == "search")
      //   fetchPostCoverInfo(rs.id, map!.getZoom(), state.id, opt);
    }
  }

  const handleMouseOut = () => {
    console.log('leave', isHovered);
    if (isHovered) {
      setIsHovered(false);
      setHoverIdx(-1);
      setRegionCoverInfo({ created_at: '', body: '', n_likes: 0, n_comments: 0, profilepic: '', account_type: 0 });
    }
  };
  // const handleMouseOver = React.useCallback(() => {
  //   console.log('enter', isHovered);
  //   if (!isHovered) {
  //     console.log('HOVEREDID', rs.id)
  //     setIsHovered(true);
  //     setHoverIdx(rs.id);
  //     fetchPostCoverInfo(rs.id, zoom, state.id, rs.area_id, opt);
  //     // if (type == "summary")
  //     //   fetchRegionCoverInfo(rs.id, map!.getZoom(), state.id, opt);
  //     // else if (type == "single" || type == "search")
  //     //   fetchPostCoverInfo(rs.id, map!.getZoom(), state.id, opt);
  //   }
  // }, [rs, isHovered]);

  // const handleMouseOut = React.useCallback(() => {
  //   console.log('leave', isHovered);
  //   if (isHovered) {
  //     setIsHovered(false);
  //     setHoverIdx(-1);
  //     setRegionCoverInfo({ created_at: '', body: '', n_likes: 0, n_comments: 0, profilepic: '', account_type: 0 });
  //   }
  // }, [isHovered]);

  const handleClick = React.useCallback(async () => {
    if (type == "summary") {
      // console.log('CLICK region summary', rs.id);
      // toast('CLICK region summary: ' + rs.id)
      setIsHovered(false);
      setIsLoading(true);
      setShowTutorials(true)
      try {
        // const res = await fetchRegionPosts(rs.id, map!.getZoom(), state.id, opt);
        const res = await fetchRegionPosts(rs.id, state.id, rs.topic_id, zoom, rs.area_id, opt);

        const { posts, areas, zoom_list } = res;

        const bufferStart = posts.length > 5 ? 5 : posts.length;

        setFetchBuffer(posts.splice(bufferStart, posts.length - bufferStart));

        for (let i = 0; i < posts.length; i++) {
          posts[posts.length - i - 1].idx = i;
        }

        setRegionPosts(posts.reverse());
        setRegionInfo && setRegionInfo({ id: rs.area_id, zoom: zoom })
        // setRegionAreas(areas);
        // setZoomList(zoom_list);

        setCurrentIdx(bufferStart - 1);
        setIsPrev(false);
        setShowContent(true);

        // map!.dragging.disable();
        // map!.touchZoom.disable();
        // map!.doubleClickZoom.disable();
        // map!.scrollWheelZoom.disable();
        // map!.boxZoom.disable();
        // map!.keyboard.disable();
        // if (map!.tap) map!.tap.disable();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else if (type == "user") {
      // console.log('CLICK region summary', rs.id);
      // toast('CLICK region summary: ' + rs.id)
      setIsHovered(false);
      setIsLoading(true);
      setShowTutorials(true)
      try {
        const res = await fetchUserPostsInRegion!(state.userPostsOnMapId, rs.topic_id, zoom, rs.area_id);

        const { posts, areas, zoom_list } = res;

        const bufferStart = posts.length > 5 ? 5 : posts.length;

        setFetchBuffer(posts.splice(bufferStart, posts.length - bufferStart));

        for (let i = 0; i < posts.length; i++) {
          posts[posts.length - i - 1].idx = i;
        }

        setRegionPosts(posts.reverse());
        setRegionInfo && setRegionInfo({ id: rs.area_id, zoom: zoom })
        // setRegionAreas(areas);
        // setZoomList(zoom_list);

        setCurrentIdx(bufferStart - 1);
        setIsPrev(false);
        setShowContent(true);

        // map!.dragging.disable();
        // map!.touchZoom.disable();
        // map!.doubleClickZoom.disable();
        // map!.scrollWheelZoom.disable();
        // map!.boxZoom.disable();
        // map!.keyboard.disable();
        // if (map!.tap) map!.tap.disable();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else if (type == "single") {
      setShowTutorials(true)
      setIsHovered(false)
      setIsLoading(true)
      fetchSinglePost(rs.id)
      // map!.dragging.disable();
      // map!.touchZoom.disable();
      // map!.doubleClickZoom.disable();
      // map!.scrollWheelZoom.disable();
      // map!.boxZoom.disable();
      // map!.keyboard.disable();
      // if (map!.tap) map!.tap.disable();
      setIsLoading(false)
    } else if (type == "search") {
      setIsLoading(true)
      if (rs.search_id == undefined) {
        setCurrentSearchId(0)
      } else {
        setCurrentSearchId(rs.search_id)
      }
      const response = await fetch('https://outvirt.com:8080/fetchsearchclusterposts', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: state.id, search_id: rs.search_id, start_id: -1 })
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(async (res) => {
          const bufferStart = res.posts.length > 5 ? 5 : res.posts.length;
          setFetchBuffer(res.posts.splice(bufferStart, res.posts.length - bufferStart))
          for (var i = 0; i < res.posts.length; i++) {
            res.posts[res.posts.length - i - 1].idx = i
          }
          setRegionPosts(res.posts.reverse())
          setCurrentIdx(bufferStart - 1)
          setIsPrev(false)
          setShowContent(true)
          // map!.dragging.disable();
          // map!.touchZoom.disable();
          // map!.doubleClickZoom.disable();
          // map!.scrollWheelZoom.disable();
          // map!.boxZoom.disable();
          // map!.keyboard.disable();
          // if (map!.tap) map!.tap.disable();
        })
      }

      setIsLoading(false)
    }
  }, [rs, map, state.id, opt]);


  const MarkerIcon = useMemo(() => {
    const markerContainer = document.createElement('div');
    markerContainer.className = (isHovered && hoverIdx === rs.id) ? 'map-div-icon-nav to-front' : 'map-div-icon-nav';

    // Create the inner content of the marker
    const markerContent = document.createElement('div');
    markerContent.className = 'marker-object';
    markerContent.style.display = 'flex';
    markerContent.style.flexDirection = 'column';
    markerContent.style.alignItems = 'center';
    markerContent.style.justifyContent = 'center';

    // Create the main icon region
    const mainIcon = document.createElement('div');
    mainIcon.className = (type=="navigate" ? 'main-map-icon-nav' : 'main-map-icon-region');
    if (type != "navigate" && rs.seen) mainIcon.style.setProperty("--border-color", "#999999");
    // mainIcon.className = 'main-map-icon-region';


    if (rs.image.slice(-4) === '.mp4') {
      const videoContainer = document.createElement('div');
      videoContainer.className = 'map-high-level-nav-vid';

      const videoInnerContainer = document.createElement('div');
      videoInnerContainer.style.position = "relative";
      videoInnerContainer.style.width = "100%";
      videoInnerContainer.style.height = "100%";

      const video = document.createElement('video');
      video.loop = true;
      video.muted = true;
      video.autoplay = true;
      video.playsInline = true;
      video.preload = 'auto';
      video.src = `https://outvirt.com/assets/img${isHovered && hoverIdx === rs.id ? '' : 'map'}/${rs.image}`;

      // videoContainer.appendChild(video);
      videoInnerContainer.appendChild(video);
      videoContainer.appendChild(videoInnerContainer);
      mainIcon.appendChild(videoContainer);
    } else {
      const imageContainer = document.createElement('div');
      imageContainer.className = 'map-high-level-nav';
      imageContainer.style.backgroundImage = `url(https://outvirt.com/assets/img${isHovered && hoverIdx === rs.id ? '' : 'map'}/${rs.image})`;

      mainIcon.appendChild(imageContainer);
    }
    if (isHovered && hoverIdx === rs.id && regionCoverInfo.account_type) {
      const publicIconString = ReactDOMServer.renderToString(<PublicIcon style={{ fontSize: '8px', color: "rgba(255,255,255,0.5)", position: "absolute", right: "3px", bottom: "3px" }} />);
      const publicIconElement = document.createElement('div');
      publicIconElement.innerHTML = publicIconString;
      const firstChild = publicIconElement.firstChild;
      if (firstChild !== null) {
        mainIcon.appendChild(firstChild);
      }
      // publicIcon.style.fontSize = '8px';
      // publicIcon.style.color = "rgba(255,255,255,0.5)";
      // publicIcon.style.position = "absolute";
      // publicIcon.style.right = "3px";
      // publicIcon.style.bottom = "3px";
      // publicIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" class="bi bi-globe2" viewBox="0 0 16 16">
      // <path d="M3.105 10.736c.665-.344 1.485-.564 2.394-.646l.608-1.965c1.259-.151 2.173-.8 2.173-1.574 0-.774-.914-1.423-2.173-1.574L5.5 2.91A6.98 6.98 0 0 1 8 2c1.754 0 3.333.655 4.5 1.727L11.78 5.067C12.76 5.263 13.5 6.032 13.5 6.951c0 .92-.74 1.688-1.72 1.884l-.658 2.125c-.31.056-.62.08-.915.081h-.344c-.296-.001-.605-.025-.915-.081l-.658-2.125C3.74 8.639 3 7.872 3 6.951c0-.92.74-1.688 1.72-1.884L6.5 3.27a6.98 6.98 0 0 1 2.5-.36V.5h1V2.91c1.259.15 2.173.8 2.173 1.574 0 .773-.914 1.422-2.173 1.573l-.608 1.965c.909.082 1.729.302 2.394.646a7.01 7.01 0 0 1 2.155 2.153l1.498-.862a.5.5 0 0 1 .723.476v2.992a.5.5 0 0 1-.723.475l-1.498-.861a6.98 6.98 0 0 1-2.155 2.153zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
      // </svg>`;
    }
    markerContent.appendChild(mainIcon);
    if (isHovered && hoverIdx === rs.id) {
      const hoveredContentContainer = document.createElement('div');
      hoveredContentContainer.className = "hovered-content-container";

      const contentBackground = document.createElement('div');
      contentBackground.className = "content-background";

      const profileAndLikesContainer = document.createElement('div');

      const profilepicWrapper = document.createElement('div');
      profilepicWrapper.className = "profilepic-wrapper";

      const profilepicContainer = document.createElement('div');
      profilepicContainer.className = "profilepic-container";
      profilepicContainer.style.backgroundImage = `url(https://outvirt.com/assets/imgmap/${regionCoverInfo.profilepic})`;

      profilepicWrapper.appendChild(profilepicContainer);
      profileAndLikesContainer.appendChild(profilepicWrapper);

      const likesComments = document.createElement('div');
      likesComments.className = "likes-comments";

      const likesDiv = document.createElement('div');
      const commentsDiv = document.createElement('div');

      const likesIconContainer = document.createElement('div');
      const commentsIconContainer = document.createElement('div');

      createRoot(likesIconContainer).render(<MdFavorite color="#ff7799" />);
      createRoot(commentsIconContainer).render(<MdChatBubble color="#77b7ff" />);

      likesDiv.appendChild(likesIconContainer);
      commentsDiv.appendChild(commentsIconContainer);

      const likesCountSpan = document.createElement('span');
      likesCountSpan.classList.add('likes-comments-text');
      const likesCount = document.createTextNode(` ${regionCoverInfo.n_likes}`);
      likesCountSpan.appendChild(likesCount);
      likesDiv.appendChild(likesCountSpan);

      const commentsCountSpan = document.createElement('span');
      commentsCountSpan.classList.add('likes-comments-text');
      const commentsCount = document.createTextNode(` ${regionCoverInfo.n_comments}`);
      commentsCountSpan.appendChild(commentsCount);
      commentsDiv.appendChild(commentsCountSpan);


      likesDiv.appendChild(likesCount);
      commentsDiv.appendChild(commentsCount);

      likesComments.appendChild(likesDiv);
      likesComments.appendChild(commentsDiv);


      // const likesDiv = document.createElement('div');
      // const commentsDiv = document.createElement('div');

      // const favoriteIcon = (
      //   <div className="icon-wrapper">
      //     <MdFavorite color="#ff7799" />
      //   </div>
      // );

      // const chatBubbleIcon = (
      //   <div className="icon-wrapper">
      //     <MdChatBubble color="#77b7ff" />
      //   </div>
      // );

      // createRoot(likesDiv).render(favoriteIcon);
      // createRoot(commentsDiv).render(chatBubbleIcon);

      // likesDiv.innerHTML += ` ${regionCoverInfo.n_likes}`;
      // commentsDiv.innerHTML += ` ${regionCoverInfo.n_comments}`;


      // likesDiv.appendChild(document.createTextNode(` ${regionCoverInfo.n_likes}`));
      // // likesComments.appendChild(favoriteIcon);
      // commentsDiv.appendChild(document.createTextNode(` ${regionCoverInfo.n_comments}`));
      // // likesComments.appendChild(chatBubbleIcon);

      const followingPicsRow = document.createElement('div');
      followingPicsRow.className = "followingpics-row";

      followingPics.forEach((followingPic, fIdx) => {
        const followingpicWrapper = document.createElement('div');
        followingpicWrapper.className = "followingpic-wrapper";

        const followingpicContainer = document.createElement('div');
        followingpicContainer.className = "followingpic-container";
        followingpicContainer.style.backgroundImage = `url(https://outvirt.com/assets/imgmap/${followingPic})`;

        followingpicWrapper.appendChild(followingpicContainer);
        followingPicsRow.appendChild(followingpicWrapper);
      });

      likesComments.appendChild(likesDiv);
      likesComments.appendChild(commentsDiv);
      likesComments.appendChild(followingPicsRow);

      profileAndLikesContainer.appendChild(likesComments);

      const postBody = document.createElement('div');
      postBody.className = "post-body";

      const postDate = document.createElement('p');
      postDate.className = "post-date";
      postDate.innerText = ParseDate(regionCoverInfo.created_at, t);

      const postText = document.createElement('p');
      postText.className = "post-text";
      postText.innerHTML = regionCoverInfo.body ? truncate(regionCoverInfo.body, 60) : '';

      postBody.appendChild(postDate);
      postBody.appendChild(postText);

      contentBackground.appendChild(profileAndLikesContainer);
      contentBackground.appendChild(postBody);
      // contentBackground.appendChild(profilepicWrapper);
      // contentBackground.appendChild(likesComments);
      // contentBackground.appendChild(postBody);

      hoveredContentContainer.appendChild(contentBackground);

      markerContent.appendChild(hoveredContentContainer);
    }

    markerContainer.appendChild(markerContent);

    //   const icon = new maplibregl.Marker(markerContainer, { offset: [0, 0] });

    return markerContainer;
  }, [rs, regionCoverInfo]);


  // const Marker = new maplibregl.Marker(MarkerIcon, { offset: [0, 0] })
  // .setLngLat([rs.loc[1], rs.loc[0]]);

  // Marker.getElement().addEventListener('mouseover', handleMouseOver);
  // Marker.getElement().addEventListener('mouseout', handleMouseOut);
  // Marker.getElement().addEventListener('click', handleClick);

  return (
    <MlgMarker
      mapRef={map}
      MarkerIcon={MarkerIcon}
      offset={[0, 0]}
      location={new LatLng(rs.loc[0], rs.loc[1])}
      handleMouseOver={handleMouseOver}
      handleMouseOut={handleMouseOut}
      handleClick={handleClick}
    />
  );
}, deepEqual);



export default MLGRegionMarker;