import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import 'swiper/swiper.min.css';
import PeopleIcon from '@mui/icons-material/People';
import '../map/map.css'
import './Conversation.css'
import { Swiper as SwiperType } from 'swiper';
import UserList from './UserList';
import { getHighestZIndex } from '../utils/GetHighestZIndex';

interface ContainerProps {
    showConnections: boolean
    setShowConnections: React.Dispatch<React.SetStateAction<boolean>>
    user_id: number;
}


interface User {
    id: number;
    account_type: number;
    name: string;
    username: string;
    profilepic: string;
    follow: boolean;
  }
  

SwiperCore.use([Navigation, Pagination]);


const useStyles = makeStyles({
    searchbar: {
      paddingBottom: 0,
    },
    indicator: {
        backgroundColor: '#3f50b4', // Your primary color
      },
      tab: {
        "&:hover": {
          backgroundColor: "#3f50b4", // Same color as the selected indicator
          opacity: 1, // Adjust the opacity to your liking
          color: "#fff",
        },
      },
  });

const Connections: React.FC<ContainerProps> = (props) => {
//   const { t } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);
  
  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', showError: false });
  const [searchText, setSearchText] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [following, setFollowing] = useState<User[]>([])
  const [followers, setFollowers] = useState<User[]>([])
  const [connZIndex, setConnZIndex] = useState(0);

  const handleSegmentChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // update list
    if (newValue) fetchFollowers()
    else fetchFollowing()

    setValue(newValue);
    swiperRef?.slideTo(newValue);
  };
  
  
const [pageNumber, setPageNumber] = useState(1);
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  // set card's height
  useEffect(() => {
    setConnZIndex(getHighestZIndex())
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    fetchFollowing()

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchFollowing = async() => {
    try {
        const response = await fetch('https://outvirt.com:8080/fetchfollowingbyid', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.user_id})
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        } else{
          response.json().then(async(standard_res) => {
            setFollowing(standard_res);
          })
        }
      } catch (err) {
        console.log(err);
      }
  }
  const fetchFollowers = async() => {
    try {
        const response = await fetch('https://outvirt.com:8080/fetchfollowersbyid', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.user_id})
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        } else{
          response.json().then(async(standard_res) => {
            setFollowers(standard_res);
          })
        }
      } catch (err) {
        console.log(err);
      }
  }



  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};




  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };


  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    const tolerance = 5;
  if (scrollHeight - scrollTop <= clientHeight + tolerance) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
    }
  };

//   const useStyles = makeStyles((theme) => ({
//     bookmarkFriends: {
//       display: "flex",
//       overflowX: "scroll",
//       msOverflowStyle: "none",
//       scrollbarWidth: "none",
//       marginBottom: "12px"
//     },
//     noScrollbar: {
//       "&::-webkit-scrollbar": {
//         display: "none",
//       },
//     },
//   }));
//   const classes = useStyles();



  return (
    <div>
    <Dialog  
      className="dark-dialog"
      open={props.showConnections}
      onClose={() => props.setShowConnections(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: connZIndex + 1 }}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <PeopleIcon/>
            &nbsp;&nbsp;Connections
        </div>
        <IconButton color="inherit" onClick={() => props.setShowConnections(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content" onScroll={handleScroll}>
      <header>
        <Tabs value={value} onChange={handleSegmentChange} centered variant="fullWidth" classes={{ indicator: classes.indicator }}>
            <Tab label="Following" className={classes.tab} />
            <Tab label="Followers" className={classes.tab} />
        </Tabs>
      </header>
      <main>
        <Snackbar
          open={error.showError}
          onClose={() => setError({ message: '', showError: false })}
          message={error.message}
          autoHideDuration={100}
        />
        <Swiper
          className="mySwiper"
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          onSlideChange={(swiper) => setValue(swiper.activeIndex)}
        >
          <SwiperSlide>
            <UserList users={following} setUsers={setFollowing}/>
          </SwiperSlide>
          <SwiperSlide>
            <UserList users={followers} setUsers={setFollowers}/>
          </SwiperSlide>
        </Swiper>
      </main>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default Connections;
