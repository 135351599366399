import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as ReactDOMClient from 'react-dom/client';
import {AppContextProvider} from './state';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
import i18n from './i18n'; // Import i18n
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';

// import ReactGA from 'react-ga';


//import './App.css';
import './assets/scss/style.scss';



const history = createBrowserHistory();

// Initialize Google Analytics
// ReactGA.initialize('G-S8712KFCXC');
Modal.setAppElement('#root');


ReactDOMClient.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId="780614210124-r6pqavbeu7t1kda2emjon16ohbdonucs.apps.googleusercontent.com">
  <AppContextProvider>
  <Router history={history}>
  {/* <Suspense fallback={<div>Loading...</div>}> */}
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
  {/* </Suspense> */}
  </Router>
  </AppContextProvider>
  </GoogleOAuthProvider>
);


// ReactDOM.render(
//   <Router history={history}>
//     <App />
//   </Router>,
//   document.getElementById('root')
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
