import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import 'swiper/swiper.min.css';

import '../map/map.css'
import './Conversation.css'
import { Swiper as SwiperType } from 'swiper';
import SearchPeople from './SearchPeople';
import PeopleNearby from './PeopleNearby';

interface ContainerProps {
    showDiscover: boolean
    setShowDiscover: React.Dispatch<React.SetStateAction<boolean>>
}

SwiperCore.use([Navigation, Pagination]);


const useStyles = makeStyles({
    searchbar: {
      paddingBottom: 0,
    },
    indicator: {
        backgroundColor: '#3f50b4', // Your primary color
      },
  });

const Discover: React.FC<ContainerProps> = (props) => {
  const { t } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);
  
  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', showError: false });
  const [searchText, setSearchText] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);

  const handleSegmentChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    swiperRef?.slideTo(newValue);
  };
  
  
const [pageNumber, setPageNumber] = useState(1);
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);





  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};




  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };


  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    const tolerance = 5;
  if (scrollHeight - scrollTop <= clientHeight + tolerance) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
    }
  };

//   const useStyles = makeStyles((theme) => ({
//     bookmarkFriends: {
//       display: "flex",
//       overflowX: "scroll",
//       msOverflowStyle: "none",
//       scrollbarWidth: "none",
//       marginBottom: "12px"
//     },
//     noScrollbar: {
//       "&::-webkit-scrollbar": {
//         display: "none",
//       },
//     },
//   }));
//   const classes = useStyles();



  return (
    <div>
    <Dialog  
      className="profile-dialog dark-dialog"
      open={props.showDiscover}
      onClose={() => props.setShowDiscover(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <PersonAddIcon/>
            &nbsp;&nbsp;{t('social.discpeople')}
        </div>
        <IconButton color="inherit" onClick={() => props.setShowDiscover(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content" onScroll={handleScroll}>
      <header>
        <Tabs value={value} onChange={handleSegmentChange} centered variant="fullWidth" classes={{ indicator: classes.indicator }}>
          <Tab label={t('social.search')} />
          <Tab label={t('social.here')} />
        </Tabs>
      </header>
      <main>
        <Snackbar
          open={error.showError}
          onClose={() => setError({ message: '', showError: false })}
          message={error.message}
          autoHideDuration={100}
        />
        <Swiper
          className="mySwiper"
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => setSwiperRef(swiper)}
          onSlideChange={(swiper) => setValue(swiper.activeIndex)}
        >
          <SwiperSlide>
            <SearchPeople/>
          </SwiperSlide>
          <SwiperSlide>
            {
              value === 1 && 
              <PeopleNearby/>
            }
          </SwiperSlide>
        </Swiper>
      </main>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default Discover;
