import React, { useCallback, Component, useState, useEffect, useContext, useRef, createContext, ChangeEvent, FormEvent } from 'react';
import { useMapEvents, Circle, useMap, MapContainer, Marker, TileLayer } from 'react-leaflet'
import * as ReactDOMServer from 'react-dom/server';
import { Avatar, Fab } from '@mui/material';
import L, { LatLngExpression } from "leaflet";
import './map.css'
import Header from '../layout/Header';
import classNames from 'classnames';
import Logo from '../layout/partials/Logo';
import Image from '../elements/Image';
import Agar from '../agar/Agar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaGoogle } from "react-icons/fa";
// import {AppContext, wsContext} from '../../state'
import { AppContext } from '../../state'
import jwt_decode from "jwt-decode";
import { normalize } from '../agar/util'
// import {BlobData} from "../agar/util";
import ReactGA from 'react-ga';
import ContentCard from '../post/ContentCard'
import PostContent from '../post/PostContent'
import { LatLng } from 'leaflet';
// import LoginComponent from '../user/Login';
import ProfileComponent from '../user/Profile';
import PostButtons from '../tutorial/PostButtons';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
// import PreventSwipeToRefresh from './PreventSwipeToRefresh';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MdFavorite, MdFavoriteBorder, MdChatBubble, MdChatBubbleOutline } from 'react-icons/md'; // Assuming you are using 'react-icons' for icons
import ParseDate from '../date/DateT';
import StandardProfile from '../user/StandardProfile';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@material-ui/core';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import { generateRandomString } from '../utils/GenerateRandomString';
import PublicIcon from '@mui/icons-material/Public';
import Group from '../post/Group'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import 'leaflet-rotatedmarker';
import useFractionalZoom from './useFractionalZoom';
import { throttle, debounce, Dictionary } from 'lodash';
import RegionMarker from './RegionMarker';
import AreaName from './AreaName';
import { Search } from '@material-ui/icons';
import MlgTileLayer from './MlgTileLayer';
import { useMap as useMapGL } from 'react-map-gl';
import ReactMapGL, { Marker as MarkerGL, Popup } from 'react-map-gl';
import { LngLatBoundsLike } from 'maplibre-gl';
import maplibregl from 'maplibre-gl';
import MlgMarker from './MlgMarker';
import User3DMarker from './User3DMarker';
import MLGRegionMarker from './MLGRegionMarker'
import VerticalSlider from '../elements/VerticalSlider';

interface PostProps {
  tablename: string
  post_id: number
  is_object?: boolean
}



const ball_x = 0; // the array "balls" contains five attributes, the first one is position x
const ball_y = 1; // position y
const ball_id = 2; // id of the ball
const ball_r = 3; // radius of the ball
const ball_alive = 4; // the condition of living
const ball_pic = 5; // profile pic

// var e_data; // make the recive msg be global varible
var my_id = 0; // this client id
var my_id_saved = 0; // make sure to get the correct id, kind of "flag", only 1 or 0
var current_ball_number = 0; //numbers of the balls

const color = [ //define the color of balls
  "LightCoral",
  "PaleGreen",
  "Wheat",
  "RosyBrown",
  "Pink",
  "MediumAquamarine",
  "LightSalmon",
  "ightYellow",
  "PaleTurquoise",
  "Lavender",
  "Moccasin"
];


interface RegionSummary {
  id: number;
  loc: number[];
  image: string;
  seen: boolean
  type: boolean
  length: number
  tablename?: string
  search_id?: number
  topic_id?: number
  area_id?: number
  area_name?: string
  // child_areas?: string[]
}

interface GoogleCredential {
  email: string;
  name: string;
  picture: string;
  sub: string;
}


interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number
  url: string
  user_url: string
  zoom: number;
}

interface ScoreBoard {
  nycu: number;
  nthu: number;
}

interface AgarUserInfo {
  team: number;
  name: string;
  score: number;
}

interface RegionCoverInfo {
  created_at: string;
  profilepic: string;
  n_likes: number;
  n_comments: number;
  body: string;
  account_type: number;
}

interface Location0 {
  ID: number;
  country: string;
  lon: number;
  lat: number;
}
interface Location1 {
  ID: number;
  country: string;
  lon: number;
  lat: number;
  name: string;
  nl_name: string;
}

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };


// type QueryParams = {
//   id: string | null;
//   tab: string | null;
// };

// const useQueryParams = (): QueryParams => {
//   const [queryParams, setQueryParams] = useState<QueryParams>({
//     id: null,
//     tab: null,
//   });

//   useEffect(() => {
//     const parsedUrl = new URL(window.location.href);
//     const searchParams = new URLSearchParams(parsedUrl.search);
//     const id = searchParams.get('post');
//     const tab = searchParams.get('tab');
//     console.log('test', id, tab)
//     setQueryParams({ id, tab });
//   }, []);

//   return queryParams;
// };



const Map = () => {
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   const onPopState = (event: PopStateEvent) => {
  //     toast('🦄 Wow so easy!')
  //     event.preventDefault();
  //   };

  //   window.addEventListener('popstate', onPopState);

  //   const onBeforeUnload = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', onBeforeUnload);


  //   return () => {
  //     window.removeEventListener('popstate', onPopState);
  //     window.removeEventListener('beforeunload', onBeforeUnload);

  //   };
  // }, []);


  interface PlaceSuggestions {
    lat: number;
    lon: number;
    ne_lat: number;
    ne_lon: number;
    sw_lat: number;
    sw_lon: number;
    name: string;
    vicinity: string;
    open_now: boolean;
    price_level: number;
    rating: number;
    user_ratings_total: number;
    place_id: string;
  }


  const { state, dispatch } = useContext(AppContext);
  const [wsMsg, setWsMsg] = useState();
  const [userScore, setUserScore] = useState(0);
  const [leaderScore, setLeaderScore] = useState<ScoreBoard>({ nycu: 0, nthu: 0 });
  const nycu_final = 2960
  const nthu_final = 1311
  const [leaderBoard, setLeaderBoard] = useState<AgarUserInfo[]>([]);
  const [play, setPlay] = useState<boolean>(false);
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showTutorials, setShowTutorials] = useState(false);
  const [map, setMap] = useState<L.Map>();
  const GLmap = useRef<maplibregl.Map | null>(null);
  const [regionCoverInfo, setRegionCoverInfo] = useState<RegionCoverInfo>({
    created_at: '',
    profilepic: '',
    n_likes: 0,
    n_comments: 0,
    body: '',
    account_type: 0,
  })
  const [followingPics, setFollowingPics] = useState<string[]>([]);

  const [searchText, setSearchText] = useState<string>('')
  const [isFocusOnSearch, setIsFocusOnSearch] = useState<boolean>(false);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [placeSuggestions, setPlaceSuggestions] = useState<PlaceSuggestions[]>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [marker, setMarker] = useState<LatLng>(new LatLng(24.790601503977307, 120.99565885890476));
  const [lat, setLat] = useState<number>();
  const [lon, setLon] = useState<number>();
  const [cardZIndex, setCardZIndex] = useState<number>(0);
  const [cardName, setCardName] = useState<string>('');
  const [showContent, setShowContent] = useState<boolean>(false);
  const [isPrev, setIsPrev] = useState<boolean>(false);
  const [currentIdx, setCurrentIdx] = useState<number>(4);
  const [currentSearchId, setCurrentSearchId] = useState<number>(0);
  const [fetchPost, setFetchPost] = useState<boolean>(false);
  const arrowClickRef = useRef(false)
  const fetchLockRef = useRef<any>(false);
  const [showStandardProfile, setShowStandardProfile] = useState(false);
  const [standardProfileId, setStandardProfileId] = useState<number>(0)
  const [showGroup, setShowGroup] = useState(false);
  const [groupId, setGroupId] = useState<number>(0)
  const [areaName, setAreaName] = useState<string>('');
  const [showAreaName, setShowAreaName] = useState<boolean>(false);
  const [locations0, setLocations0] = useState<Location0[]>([]);
  const [locations1, setLocations1] = useState<Location1[]>([]);
  const [markers0, setMarkers0] = useState<maplibregl.Marker[]>([]);
  const [markers1, setMarkers1] = useState<maplibregl.Marker[]>([]);
  // const [islarger_five, setislarger_five] = useState<boolean>(true);

  useEffect(() => {
    const fetchLocations0FromBackend = async () => {
      try {
          const response = await fetch('https://outvirt.com:8080/locations0');
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setLocations0(data);
          console.log('success fetching locations from backend')
      } catch (error) {
          console.error("Error fetching locations from backend:", error);
      }
  };

    fetchLocations0FromBackend();
  }, []);

  const showLocationsOnMap0 = () => {
    markers0.forEach(marker => marker.remove());
    setMarkers0([]);
    const newMarkers: maplibregl.Marker[] = [];
    const bounds = GLmap.current!.getBounds();
    console.log('locations0:', locations0.length);
    locations0.forEach(location => {
      if (bounds.contains([location.lon, location.lat])) {
        const markerEl = document.createElement('div');
        markerEl.textContent = location.country;
        markerEl.className = 'marker';  // 您可以為其添加樣式

        const marker = new maplibregl.Marker(markerEl)
            .setLngLat([location.lon, location.lat])
            .addTo(GLmap.current!);
        // marker.remove()

        newMarkers.push(marker);
      }
    });
    // newMarkers.forEach(marker => marker.remove());

    setMarkers0(newMarkers);
  };

  const hideLocationsOnMap0 = () => {
    markers0.forEach(marker => marker.remove());
    setMarkers0([]);
  };
  useEffect(() => {
    console.log('Updated markers0:', markers0);
  }, [markers0]);

//   useEffect(() => {
//     if (GLmap.current && locations0.length > 0) {
//         locations0.forEach(location => {
//             const markerEl = document.createElement('div');
//             markerEl.className = 'marker';
//             markerEl.innerText = location.country;  // Assuming the name is stored in the COUNTRY field

//             new maplibregl.Marker(markerEl)
//                 .setLngLat([location.lon, location.lat])
//                 .addTo(GLmap.current!);
//         });
//     }
// }, [GLmap, locations0]);

  useEffect(() => {
    const fetchLocations1FromBackend = async () => {
      try {
          const response = await fetch('https://outvirt.com:8080/locations1');
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setLocations1(data);
          console.log('success fetching locations from backend')
      } catch (error) {
          console.error("Error fetching locations from backend:", error);
      }
  };

    fetchLocations1FromBackend();
  }, []);

  const showLocationsOnMap1 = () => {
    markers1.forEach(marker => marker.remove());
    setMarkers1([]);
    const newMarkers: maplibregl.Marker[] = [];
    const bounds = GLmap.current!.getBounds();
    console.log('locations1:', locations1.length);
    locations1.forEach(location => {
      if (bounds.contains([location.lon, location.lat])) {
        const markerEl = document.createElement('div');
        markerEl.textContent = location.name;
        markerEl.className = 'marker';  // 您可以為其添加樣式

        const marker = new maplibregl.Marker(markerEl)
            .setLngLat([location.lon, location.lat])
            .addTo(GLmap.current!);

        newMarkers.push(marker);
      }
    });

    setMarkers1(newMarkers);
  };

  const hideLocationsOnMap1 = () => {
    markers1.forEach(marker => marker.remove());
    setMarkers1([]);
  };
// useEffect(() => {
//   if (GLmap.current && locations1.length > 0) {
//       locations1.forEach(location => {
//           const markerEl = document.createElement('div');
//           markerEl.className = 'marker';
//           markerEl.innerText = location.name;  // Assuming the name is stored in the COUNTRY field

//           new maplibregl.Marker(markerEl)
//               .setLngLat([location.lon, location.lat])
//               .addTo(GLmap.current!);
//       });
//   }
// }, [GLmap, locations1]);

// useEffect(() => {
//   if (GLmap.current && locations0.length > 0 && locations1.length > 0) {
//       const handleZoom = () => {
//           const currentZoom = GLmap.current!.getZoom();
//           // console.log('zoommmmmmmmm:', currentZoom)
//           console.log('markers0.length:', markers0.length)

//           if (currentZoom <= 5 && markers0.length == 0) {
//               console.log('show!!!!!!!!!!!!!!')
//               showLocationsOnMap0();
//               // setislarger_five(false);
//               hideLocationsOnMap1();
//           } else if(currentZoom > 5 && markers0.length != 0 && markers1.length == 0){
//               console.log('hide!!!!!!!!!!!!!!')
//               hideLocationsOnMap0();
//               showLocationsOnMap1();
//               // setislarger_five(true);
//           }
          
//       };

//       // handleZoom();
//       // 添加事件處理函數
//       GLmap.current.on('zoom', handleZoom);

//       return () => {
//           // 清理事件處理函數
//           if (GLmap.current) {
//               GLmap.current.off('zoom', handleZoom);
//           }
//       };
//   }
// }, [GLmap, locations0, locations1, markers0, markers1]);


  const team = useRef(false)
  const handleUserScore = (newValue: number) => {
    setUserScore(newValue);
  }
  const handleLeaderScore = (newValue: ScoreBoard) => {
    setLeaderScore(newValue);
  }
  const handleLeaderBoard = (newValue: AgarUserInfo[]) => {
    setLeaderBoard(newValue);
  }
  const handlePlay = (newValue: boolean) => {
    setPlay(newValue);
  }


  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (isLoading) {
      // Set a timeout to automatically set isLoading to false after a certain duration
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 5000); // Timeout duration, e.g., 5000ms (5 seconds)
    }

    // Cleanup function to clear the timeout when isLoading changes or the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoading]);

  // initialize zIndex when content shown
  useEffect(() => {
    if (showContent) {
      setCardZIndex(getHighestZIndex());
      setCardName(generateRandomString(5))
    }
  }, [showContent])

  const registerRedirect = async(id: number, tablename: string, url: string) => {
    const response = await fetch('https://outvirt.com:8080/registerRedirect', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, tablename, url })
      });
  }

  const fetchSinglePostRef = useRef<any>(false);
  useEffect(() => {
    if (location.pathname == '/map') {

      console.log('Referrer:', document.referrer);

      const parsedUrl = new URL(window.location.href);
      const searchParams = new URLSearchParams(parsedUrl.search);
      const post_id = searchParams.get('post');
      const user_id = searchParams.get('user');
      const group_id = searchParams.get('group');
      if (post_id != null) {
        fetchSinglePostRef.current = true;
        // Check if the post_id format contains tablename
        if (post_id.includes("_")) {
          const parts = post_id.split('_');
          const id = Number(parts.pop()); // Extract the last part after '_'
          const tableName = parts.join('_'); // Construct the tablename
          fetchSinglePost(id, tableName); 
          registerRedirect(id, tableName, document.referrer)
        } else {
          fetchSinglePost(Number(post_id));
          registerRedirect(Number(post_id), '', document.referrer)
        }
        // fetchSinglePost(Number(post_id))
      } else if (user_id != null) {
        setStandardProfileId(Number(user_id))
        setShowStandardProfile(true)
      } else if (group_id != null) {
        setGroupId(Number(group_id))
        setShowGroup(true)
      }

    } else {
      console.log('loc', location.pathname)
    }
  }, [location.pathname, map, state.id])

  useEffect(() => {
    if (state.showSinglePost) {
      fetchSinglePost(state.singlePostId)
      // .then(() => {
      //   dispatch({
      //     type: "setShowSinglePost",
      //     showSinglePost: false,
      //     singlePostId: 0,
      //   });
      // })
    }
  }, [state.showSinglePost])



  const fetchUserPostsOnMap = async (zoom: number, nw: LatLng, se: LatLng) => {
    const cZoom = Math.floor(zoom)
    try {
      console.log('TESTTT')
      // const response = await fetch('https://outvirt.com:8080/fetchuserpostsonmap', {
      const response = await fetch('https://outvirt.com:8080/fetchusersummary', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ zoom: cZoom, nw: nw, se: se, user_id: userPostsOnMapIdRef.current, opt: 0 })
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(res_data => {
          setZoom(cZoom)
          console.log('userpost', res_data)
          setUserPostsOnMap(res_data)
          // enable map
          // map && map.dragging.enable();
          // map && map.touchZoom.enable();
          // map && map.doubleClickZoom.enable();
          // map && map.scrollWheelZoom.enable();
          // map && map.boxZoom.enable();
          // map && map.keyboard.enable();
          // if (map) {
          //   if (map!.tap) map!.tap.enable();
          // }
        }
        )
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchPostSearch = async (text: string) => {
    try {

      const bounds = GLmap.current!.getBounds();
      const nw = new LatLng(bounds.getNorth(), bounds.getWest());
      const se = new LatLng(bounds.getSouth(), bounds.getEast());
      const response = await fetch('https://outvirt.com:8080/fetchPostSearch', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text,
          nw: nw,
          se: se
        })
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(res_data => {
          console.log('searhres', res_data)
          if (res_data.zoom != undefined) {
            // dismiss close button
            setShowSearchResult(false);
            // fly to location
            GLmap.current && GLmap.current.flyTo({
              center: [res_data.loc[1], res_data.loc[0]],
              zoom: res_data.zoom,
              speed: 1,
            });
            // map && map.flyTo([res_data.loc[0], res_data.loc[1]], res_data.zoom, { duration: 0.7 });
            // // enable map
            // map && map.dragging.enable();
            // map && map.touchZoom.enable();
            // map && map.doubleClickZoom.enable();
            // map && map.scrollWheelZoom.enable();
            // map && map.boxZoom.enable();
            // map && map.keyboard.enable();
            // if (map) {
            //   if (map!.tap) map!.tap.enable();
            // }
            return;
          }
          setSearchPosts(res_data)
          // enable map
          // map && map.dragging.enable();
          // map && map.touchZoom.enable();
          // map && map.doubleClickZoom.enable();
          // map && map.scrollWheelZoom.enable();
          // map && map.boxZoom.enable();
          // map && map.keyboard.enable();
          // if (map) {
          //   if (map!.tap) map!.tap.enable();
          // }
        }
        )
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Map panning
  //   useEffect(() => {
  //     const x = 0; // initial the value of mouse position
  //     const y = 0;
  //     const sx = window.screen.width/2; // initial the value of the center of the screen
  //     const sy = window.screen.height/2;
  // // sx = document.documentElement.clientWidth/2;
  // // sy = document.documentElement.clientHeight/2;
  //     document.addEventListener('mousemove', function(e) {
  //       // console.log(e.screenX,e.screenY)
  //       if(e.screenX != null){ //update the mouse position
  //         x=e.screenX;
  //       }
  //       if(e.screenY != null){ 
  //         y=e.screenY;
  //       }

  //     }, false);

  //   }, [])


  // Fetch more post on server every 9 posts; store in buffer
  const fetchMorePost = async () => {
    fetchLockRef.current = true
    // const fetchIdx = fetchBuffer.length>0 ? fetchBuffer[fetchBuffer.length-1].id : regionPosts[regionPosts.length-1].id
    const fetchCreatedAt = fetchBuffer.length > 0 ? fetchBuffer[fetchBuffer.length - 1].created_at : regionPosts[0].created_at
    const fetchPostId = fetchBuffer.length > 0 ? fetchBuffer[fetchBuffer.length - 1].id : regionPosts[0].id
    try {
      if (showSearchResult) {
        const response = await fetch('https://outvirt.com:8080/fetchsearchclusterposts', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ user_id: state.id, search_id: currentSearchId, start_id: fetchPostId })
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        response.json().then(res_data => {
          console.log('response:', res_data.posts)
          console.log('fetchBuffer:', fetchBuffer)
          setFetchBuffer([...fetchBuffer, ...res_data.posts])
          // setRegionAreas(res_data.areas)
          // setZoomList(res_data.zoom_list)
          console.log([...fetchBuffer, ...res_data.posts], res_data.areas)
        })
      } else {
        const response = await fetch('https://outvirt.com:8080/fetchmoreregionpostsv2', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          // body: JSON.stringify({ created_at: fetchCreatedAt, user_id: state.id, areas: regionAreas, zoom: map!.getZoom(), zoom_list: zoomList, opt: opt })
          body: JSON.stringify({ post_id: fetchPostId, user_id: state.id, opt: opt, area_id: regionInfo.id, zoom: regionInfo.zoom })
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        response.json().then(res_data => {
          console.log('response:', res_data.posts)
          console.log('fetchBuffer:', fetchBuffer)
          setFetchBuffer([...fetchBuffer, ...res_data.posts])
          setRegionAreas(res_data.areas)
          setZoomList(res_data.zoom_list)
          console.log([...fetchBuffer, ...res_data.posts], res_data.areas)
        })
      }
    } catch (err) {
      console.log(err);
    }
    fetchLockRef.current = false

  }

  useEffect(() => {
    console.log('..................')
    console.log('whyenter123', regionPosts.map(post => post.idx))
    console.log('fetchBuffer123:', fetchBuffer)
    console.log('current_idx', currentIdx)
    console.log('regionPosts length', regionPosts.length)
    console.log('state.fetch_post:', fetchPost)
    // console.log('regionAreas.length:', regionAreas.length)
    console.log('..................')
    // if (regionPosts.length>0 && state.fetch_post && regionAreas.length>0){
    // if (regionPosts.length > 0 && fetchPost && regionAreas.length >= 0) {
    if (regionPosts.length > 0 && fetchPost) {
      // fetch more post from server every 9 post
      if (currentIdx == 1 && fetchBuffer.length < 9) {
        console.log('fetch more post')
        fetchMorePost()
      }
      console.log('prepre', regionPosts.length, fetchBuffer.length)
      if (regionPosts.length == 9) {
        // funnel contents from buffer to display and pop last element of display
        let idx = (currentIdx + 4) % 9 // store previous 3 posts
        let data = fetchBuffer.shift();

        console.log('PREFETCH', data)
        regionPosts.pop()
        if (data) { // false if end of post reached
          console.log('FIFO', currentIdx, idx, data)
          data!.idx = idx
          setRegionPosts(oldCards =>
            [data!, ...oldCards]

          )
        }
      } else {
        console.log('nofifo', fetchBuffer.length)
        console.log('fetchBuffer:', fetchBuffer)
        // insert directly from buffer to display
        let data = fetchBuffer.shift();
        if (data) { // false if end of post reached
          data!.idx = (regionPosts[0].idx == 0) ? 8 : regionPosts[0].idx - 1
          setRegionPosts(oldCards =>
            [data!, ...oldCards]
          )
        } else if (!regionPosts.map(post => post.idx).includes(currentIdx)) {
          // dismiss content cards if no more post (for scroll)
          setTimeout(() => {
            setShowContent(false)
            map && map.dragging.enable();
            map && map.touchZoom.enable();
            map && map.doubleClickZoom.enable();
            map && map.scrollWheelZoom.enable();
            map && map.boxZoom.enable();
            map && map.keyboard.enable();
            if (map) {
              if (map!.tap) map!.tap.enable();
            }
            setRegionPosts([])
            setFetchBuffer([])
            setRegionAreas([])
          }, 500)

        }
      }

    } else if (!regionPosts.map(post => post.idx).includes(currentIdx)) {
      if (isPrev) {
        // overshoot first post
        console.log('overshoot first post')
        setCurrentIdx(currentIdx > 0 ? currentIdx - 1 : 8)
        setIsPrev(true)
        // toast('This is the first post')
        return
      }
      // console.log('whyenter', fetchBuffer.length, regionPosts.length, fetchPost, regionAreas.length, regionPosts, currentIdx)
      console.log('whyenter', fetchBuffer.length, regionPosts.length, fetchPost, regionPosts, currentIdx)

      if (fetchSinglePostRef.current) fetchSinglePostRef.current = false
      else {
        // dismiss content cards if no more post
        setTimeout(() => {
          setShowContent(false)
          map && map.dragging.enable();
          map && map.touchZoom.enable();
          map && map.doubleClickZoom.enable();
          map && map.scrollWheelZoom.enable();
          map && map.boxZoom.enable();
          map && map.keyboard.enable();
          if (map) {
            if (map!.tap) map!.tap.enable();
          }
          setRegionPosts([])
          setFetchBuffer([])
          setRegionAreas([])
        }, 500)
      }


    }
  }, [currentIdx, fetchPost]);

  // websockets
  // const WS_URL = 'wss://140.113.225.160:3015/';
  const WS_URL = 'wss://outvirt.com:3015/';
  const ws = useRef(null);

  const socketRef = useRef<WebSocket>();
  // const [myBlob, setMyBlob] = useState({
  //   x: 0,
  //   y: 0,
  //   id: Math.floor(Math.random() * 100),
  //   // id: state.id,
  //   r: 20,
  //   alive: true,
  //   profilepic: '',
  //   color: color[Math.floor(Math.random()*11)]
  // })
  const [foodBlobs, setFoodBlobs] = useState([]);

  const myBlobRef = useRef()
  const foodBlobsRef = useRef()
  const ref = React.useRef<HTMLInputElement>(null)


  const Updatemap = () => {
    var x = 0;
    var y = 0;
    const sx = window.screen.width / 2; // initial the value of the center of the screen
    const sy = window.screen.height / 2;
    // const sx = document.documentElement.clientWidth/2;
    // const sy = document.documentElement.clientHeight/2;
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;

    const updateMap = () => {
      const moveAmount = 20;
      const normalized = normalize(width / 2, height / 2)
      const xvalue = (x > sx ? 1 : -1) * 3 * normalized.x;
      const yvalue = (y > sy ? 1 : -1) * 3 * normalized.y;
      // const xvalue = (x > sx ? 1 : -1) * moveAmount; 
      // const yvalue = (y > sy ? 1 : -1) * moveAmount;
      // const xvalue = (x > sx ? 1 : -1) * moveAmount; 
      // const yvalue = (y > sy ? 1 : -1) * moveAmount;
      map && map.panBy(new L.Point(xvalue, yvalue));
    };

    // updateMap(); // Call the function once initially

    setInterval(() => {
      if (play) updateMap(); // Call the function every 100ms
    }, 100);

    // document.addEventListener('mousemove', function(e) {
    //   if(e.screenX != null){ 
    //     x=e.screenX;
    //   }
    //   if(e.screenY != null){ 
    //     y=e.screenY;
    //   }
    // }, false);
    // document.addEventListener('touchmove', function(e) {
    //   const touch = e.touches[0]; // Get the first touch point
    //   if (touch) {
    //       x = touch.clientX;
    //       y = touch.clientY;
    //   }
    // }, false);

    return <></>
  }




  var intervalIdRef = useRef();
  // useEffect(() => {
  //   const socket = new WebSocket(WS_URL);
  //   socketRef.current = socket
  //   const tmp_id = Math.floor(Math.random() * 10000)
  //   // setWsMsg({ws: socketRef, id: tmp_id, profilepic: state.profilepic})
  //   // const init_x = Math.floor(Math.random() * 100)
  //   // const init_y = Math.floor(Math.random() * 100)
  //   const init_x = Math.floor(Math.random() * 1000) - 500
  //   const init_y = Math.floor(Math.random() * 1000) - 500
  //   setWsMsg({ws: socketRef, id: tmp_id, name: 'eugene lee', init_x: init_x, init_y: init_y})
  //   // setWsMsg({ws: socketRef, id: state.id, name: state.name})
  //   socket.onopen = () => {

  //     console.log("Connection opened");
  //     // socket.send('Hello');


  //     // remove later
  //     // intervalIdRef.current = setInterval(() => {
  //     //   // Call your function here
  //     //   console.log('Function called every 20ms');
  //     //   sendBlob();

  //     // }, 20);
  //     socketRef.current.send(JSON.stringify({
  //       x: init_x,
  //       y: init_y,
  //       id: tmp_id,
  //       r: 20,
  //       alive: true,
  //       name: 'eugene lee',
  //       color: color[Math.floor(Math.random()*11)],
  //       team: team.current
  //     }))
  //     // socketRef.current.send(JSON.stringify(myBlob))

  //   };

  // },[])

  // const startGame = () => {
  //   const socket = new WebSocket(WS_URL);
  //   socketRef.current = socket
  //   const tmp_id = Math.floor(Math.random() * 10000)
  //   const init_x = Math.floor(Math.random() * 1000) - 500
  //   const init_y = Math.floor(Math.random() * 1000) - 500
  //   setWsMsg({ws: socketRef, id: state.id, name: state.username, init_x: init_x, init_y: init_y})
  //   socket.onopen = () => {

  //     console.log("Connection opened");
  //     console.log('selectedteam', selectedOption, selectedOption=='nycu')
  //     socketRef.current.send(JSON.stringify({
  //       x: init_x,
  //       y: init_y,
  //       id: state.id,
  //       r: 20,
  //       alive: true,
  //       name: state.username,
  //       color: color[Math.floor(Math.random()*11)],
  //       team: selectedOption=='nycu'// team.current
  //     }))
  //   };
  // }




  // const [state, setState] = useState();
  const [regionSummary, setRegionSummary] = useState<RegionSummary[]>();
  const [userPostsOnMap, setUserPostsOnMap] = useState<RegionSummary[]>([]);
  const [regionObjects, setRegionObjects] = useState<RegionSummary[]>();
  const [searchPosts, setSearchPosts] = useState<RegionSummary[]>([]);
  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [regionPosts, setRegionPosts] = useState<PostCard[]>([]);
  const [regionAreas, setRegionAreas] = useState<string[]>([])
  const [zoomList, setZoomList] = useState<string[]>([])
  const [opt, setOpt] = useState<number>(0)
  const [postProps, setPostProps] = useState<PostProps>({ post_id: 0, tablename: '' });
  const [showPostModal, setShowPostModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoverIdx, setHoverIdx] = useState<number>(-1);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [regionInfo, setRegionInfo] = useState<{ id: number, zoom: number }>({ id: 0, zoom: 0 });
  const [signUp, setSignUp] = useState(false);
  const [profilepic, setProfilepic] = useState('');
  const [gid, setGid] = useState('')

  const [showInput, setShowInput] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(true);
  //   const [userLocation, setUserLocation] = useState<LatLng | null>(null);
  // const [userDirection, setUserDirection] = useState<number | null>(null);
  //   id: 0,
  //   loc: [0,0],
  //   image: '',
  //   seen: false,
  //   type: false,
  //   length: 0,
  // }]);
  // const [latLon, setLatLon] = useState([24.7889856365559, 120.99991809152368]);

  const query = new URLSearchParams(window.location.search);
  const loc = query.get('loc')
  const qzoom = query.get('zoom')
  // setLatLon(loc.split(','))
  var cLoc: number[]
  var cZoom
  if (loc == null)
    cLoc = [24.790601503977307, 120.99565885890476]// [24.7889856365559, 120.99991809152368]
  else
    cLoc = loc.split(',').map(Number)
  if (qzoom == null)
    cZoom = 17
  else
    cZoom = Number(qzoom)
  const [latLon, setLatLon] = useState<number[]>(cLoc);
  const [zoom, setZoom] = useState<number>(cZoom);
  const [showMarker, setShowMarker] = useState(false);



  // useEffect(() => {
  //   // your logic here
  //   // e.g. API call, setting up a subscription, etc.
  //   toast('📣 Zoom-in to connect');
  // }, []);


  // useEffect(() => {
  //   // your logic here
  //   // e.g. API call, setting up a subscription, etc.
  //   if (map){
  //     if(map.getZoom()==18 && signUp==false)
  //     toast('📣 登入進入比賽！');
  //   }
  // }, [map]);

  // Google analytics
  // useEffect(() => {
  //   console.log( location.pathname)
  //   if (location.pathname == '/map'){
  //     trackPage(location.pathname);
  //   }
  // }, [location]);


  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // show areaname when navigate to new area
  useEffect(() => {
    if (map && areaName != '') {
      setShowAreaName(true)
    }
  }, [areaName])

  // fly to navigated point of interest
  useEffect(() => {
    if (state.navigate && map) {
      // dismiss card if open
      setShowContent(false)
      if (ref.current) ref.current.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
      if (ref.current) ref.current.style.transform = 'scale(0)';
      if (ref.current) ref.current.style.opacity = '0';
      // enable map movement
      if (map != null) {
        map.dragging.enable();
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }
      // fly to point of interest
      map.flyTo([state.navigate_lat, state.navigate_lon], 17, { duration: 0.7 });
      console.log(state.navigate_lat, state.navigate_lon, state.navigate_img)
    }
  }, [state.navigate]);


  // fetch user posts to display on map
  const showUserPostsOnMapRef = useRef<boolean>(false)
  useEffect(() => {
    showUserPostsOnMapRef.current = state.showUserPostsOnMap
    if (state.showUserPostsOnMap) {
      const bounds = GLmap.current!.getBounds();
      const nw = new LatLng(bounds.getNorth(), bounds.getWest());
      const se = new LatLng(bounds.getSouth(), bounds.getEast());
      fetchUserPostsOnMap(zoom, nw, se)

      // dismiss card if open
      setShowContent(false)
      if (ref.current) ref.current.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
      if (ref.current) ref.current.style.transform = 'scale(0)';
      if (ref.current) ref.current.style.opacity = '0';
      // enable map movement
      // if (map != null) {
      //   map.dragging.enable();
      //   map.touchZoom.enable();
      //   map.doubleClickZoom.enable();
      //   map.scrollWheelZoom.enable();
      //   map.boxZoom.enable();
      //   map.keyboard.enable();
      //   if (map.tap) map.tap.enable();
      // }
    }
  }, [state.showUserPostsOnMap])

  const userPostsOnMapIdRef = useRef<number>(0)
  useEffect(() => {
    userPostsOnMapIdRef.current = state.userPostsOnMapId
  }, [state.userPostsOnMapId])

  const handleAreaNameClick = (zoom: number) => {
    console.log("AreaName被点击了！");
    GLmap.current?.zoomIn();
  };



  const idRef = useRef<number>(0)
  useEffect(() => {
    idRef.current = state.id
  }, [state.id])

  const fetchRegionSummary = async (zoom: number, nw: LatLng, se: LatLng, opt_in = -1) => {
    const cZoom = Math.floor(zoom)
    console.log("fetchRegionSummary", idRef.current);
    // fetch region contents
    try {
      const response = await fetch('https://outvirt.com:8080/fetchregioncontentv3', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ zoom: cZoom, nw: nw, se: se, user_id: idRef.current, opt: 0 })
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(res_data => {
          console.log('fetch', res_data, cZoom)
          setZoom(cZoom)
          // if (res_data.length > 0 && res_data[0].area_name != areaName && res_data[0].area_name && res_data[0].area_name != '') {
          //   console.log('checkareaname:', res_data.length, res_data[0].area_name);
          //   setAreaName(res_data[0].area_name)
          // }
          
          setRegionSummary(res_data.regionSummary)
          // setRegionSummary(res_data)
          // setRegionSummary(res_data.posts)
          // setRegionObjects(res_data.objects)
          // setRegionUsers(res_data.users)
        }
        )
      }
    } catch (err) {
      console.log(err);
    }
  }

  const useLeadingEdgeThrottle = <T extends (...args: any[]) => any>(func: T, wait: number) => {
    const [isWaiting, setIsWaiting] = useState(false);

    return useCallback((...args: Parameters<T>) => {
      if (!isWaiting) {
        func(...args);
        setIsWaiting(true);
        setTimeout(() => setIsWaiting(false), wait);
      }
    }, [func, wait, isWaiting]);
  };


  const throttledFetchRegionSummary = useLeadingEdgeThrottle(fetchRegionSummary, 1500);


  // const throttledFetchRegionSummary = useCallback(
  //   // debounce(fetchRegionSummary, 1500),
  //   throttle(fetchRegionSummary, 1500),
  //   []  // dependencies: none in this case, adjust as needed
  // );
  // const GetZoomLevel = () => {

  //   const mapEvents = useMapEvents({
  //     click: () => {
  //       setShowMarker(false)
  //     },
  //     zoomend: async () => {
  //       console.log('zoom:', mapEvents.getZoom())
  //       console.log('boundNorthWest:', mapEvents.getBounds().getNorthWest(), typeof mapEvents.getBounds().getNorthWest());
  //       state.showUserPostsOnMap ? fetchUserPostsOnMap() :
  //         fetchRegionSummary(mapEvents.getZoom(), mapEvents.getBounds().getNorthWest(), mapEvents.getBounds().getSouthEast())
  //       // console.log('zoom:', mapEvents.getZoom(), 'nw:', mapEvents.getBounds().getNorthWest(), 'se:', mapEvents.getBounds().getSouthEast())
  //       // throttledFetchRegionSummary(mapEvents.getZoom(), mapEvents.getBounds().getNorthWest(), mapEvents.getBounds().getSouthEast())
  //     },
  //     moveend: async () => {
  //       if (!play) {
  //         state.showUserPostsOnMap ? fetchUserPostsOnMap() :
  //           // throttledFetchRegionSummary(mapEvents.getZoom(), mapEvents.getBounds().getNorthWest(), mapEvents.getBounds().getSouthEast())
  //           fetchRegionSummary(mapEvents.getZoom(), mapEvents.getBounds().getNorthWest(), mapEvents.getBounds().getSouthEast())
  //       }

  //     }
  //   });

  //   return null
  // }
  const GetZoomLevelGL = (action: string, zoom: number, bounds: [LatLng, LatLng]) => {
    console.log('GetZoomLevelGL')
    if (action == 'click') {
      console.log('click')

      setShowMarker(false);
    } else if (action == 'zoomend') {
      console.log('Mzoom:', zoom);
      console.log('Mbounds:', bounds[0], bounds[1]);
      (async () => {
        if (showUserPostsOnMapRef.current) {
          fetchUserPostsOnMap(zoom, bounds[0], bounds[1]);
        } else {
          fetchRegionSummary(zoom, bounds[0], bounds[1]);
        }
      })();
    } else if (action == 'moveend') {
      console.log('test!', GLmap.current!.getZoom());
      console.log('Mzoom:', zoom);
      console.log('Mbounds:', bounds);
      console.log('showuserpost', showUserPostsOnMapRef.current);
      (async () => {
        if (!play) {
          if (showUserPostsOnMapRef.current) {
            await fetchUserPostsOnMap(zoom, bounds[0], bounds[1]);
          } else {
            await fetchRegionSummary(zoom, bounds[0], bounds[1]);
          }
        }
      })();
    }

    return null
  }
  useEffect(() => {
    if (play) {
      map && map.dragging.disable();
      map && map.touchZoom.disable();
      map && map.doubleClickZoom.disable();
      map && map.scrollWheelZoom.disable();
      map && map.boxZoom.disable();
      map && map.keyboard.disable();
      if (map && map.tap) map.tap.disable();
    } else {
      map && map.dragging.enable();
      map && map.touchZoom.enable();
      map && map.doubleClickZoom.enable();
      map && map.scrollWheelZoom.enable();
      map && map.boxZoom.enable();
      map && map.keyboard.enable();
      if (map && map.tap) map.tap.enable();
    }
  }, [play])


  // const locationRequestedRef = useRef<boolean>(false)
  // const watchIdRef = useRef<number | null>(null);

  const [userLocationGL, setUserLocationGL] = useState<LatLng | null>(null);
  const [userLocation, setUserLocation] = useState<LatLng | null>(null);
  const [userDirection, setUserDirection] = useState<number | null>(null);
  function useUserLocation(map: L.Map | null) {
    const [watchId, setWatchId] = useState<number | null>(null);

    useEffect(() => {
      if (!map || !navigator.geolocation || watchId !== null) return;

      const onLocationFound = (position: GeolocationPosition) => {
        const latlng = new L.LatLng(position.coords.latitude, position.coords.longitude);
        setUserLocation(latlng);
        setUserDirection(position.coords.heading);
      };

      const onError = (error: GeolocationPositionError) => {
        console.error("Error watching position:", error);
      };

      const newWatchId = navigator.geolocation.watchPosition(onLocationFound, onError, {
        enableHighAccuracy: true,
      });
      setWatchId(newWatchId);

      return () => {
        if (watchId !== null) {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    }, [map, watchId]);

    // return [location, direction];
  }

  const UserLocationMarker = () => {
    const map = useMap();
    useUserLocation(map);

    const arrowIcon = L.icon({
      iconUrl: 'assets/arrow.svg',
      iconSize: [24, 24],
      iconAnchor: [12, 12]
    });
    if (!userLocation) {
      return null;
    }
    return (
      <Marker
        position={userLocation}
        icon={arrowIcon}
        rotationAngle={userDirection !== null ? userDirection : 0}
        rotationOrigin='center center'
      />
    )
  }
  const hasFlownToLocation = useRef(false);
  function useUserLocationGL(map: maplibregl.Map | null) {
    const [watchId, setWatchId] = useState<number | null>(null);

    useEffect(() => {
      if (!map || !navigator.geolocation || watchId !== null) return;

      const onLocationFound = (position: GeolocationPosition) => {
        const latlng: LatLng = new LatLng(position.coords.latitude, position.coords.longitude);
        setUserLocationGL(latlng);
        setUserDirection(position.coords.heading);

        // Fly to the location as soon as it is found
        if (!hasFlownToLocation.current) {
          setTimeout(() => {
            map.flyTo({
              center: [latlng.lng, latlng.lat],
              zoom: 14,
              speed: 1,
            });
            hasFlownToLocation.current = true;
          }, 1000);
        }

      };

      const onError = (error: GeolocationPositionError) => {
        console.error("Error watching position:", error);
      };

      const newWatchId = navigator.geolocation.watchPosition(onLocationFound, onError, {
        enableHighAccuracy: true,
      });
      setWatchId(newWatchId);

      return () => {
        if (watchId !== null) {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    }, [map, watchId]); // Don't forget to add hasFlownToLocation to the dependency array

    // return [location, direction];
  }
  const UserLocationMarkerGL = () => {
    useUserLocationGL(GLmap.current);

    const createMarkerElement = () => {
      const element = document.createElement('div');
      element.style.backgroundImage = 'url(assets/arrow.svg)';
      element.style.width = '24px';
      element.style.height = '24px';
      element.style.backgroundSize = 'cover';
      element.style.backgroundPosition = 'center';
      return element;
    };

    const markerOptions: maplibregl.MarkerOptions = {
      element: createMarkerElement(),
      anchor: 'center',
    };
    if (!userLocationGL || !GLmap.current) {
      return null;
    }
    return (
      <MlgMarker
        mapRef={GLmap}
        location={userLocationGL}
        MarkerIcon={markerOptions}
        offset={[0, 0]}
      />
      // <User3DMarker
      //   mapRef={GLmap}
      //   location={userLocationGL}
      // />
    );

  }
  // function UserLocationMarker(): null {
  //   const map = useMap();
  //   const [location, direction] = useUserLocation(map);
  //   const userMarkerRef = useRef<L.Marker | null>(null);

  //   useEffect(() => {
  //     if (!location) return;

  //     if (!userMarkerRef.current) {
  //       toast('onlyonce')
  //       const arrowIcon = L.icon({
  //         iconUrl: 'assets/arrow.svg',
  //         iconSize: [24, 24],
  //         iconAnchor: [12, 12]
  //       });

  //       userMarkerRef.current = L.marker(location, {
  //         icon: arrowIcon,
  //         rotationAngle: direction !== null ? direction : 0,
  //         rotationOrigin: 'center center'
  //       }).addTo(map);
  //     } else {
  //       userMarkerRef.current.setLatLng(location);
  //       if (direction !== null) {
  //         userMarkerRef.current.setRotationAngle(direction);
  //       }
  //       // Check if the marker is on the map, if not, add it
  //       if (!map.hasLayer(userMarkerRef.current)) {

  //         toast('twice')
  //         userMarkerRef.current.addTo(map);
  //       }
  //     }
  //   }, [location, direction, map]);

  //   // Separate useEffect hook for handling the marker removal when the component is unmounted
  //   useEffect(() => {
  //     return () => {
  //       if (userMarkerRef.current) {
  //         map.removeLayer(userMarkerRef.current);
  //       }
  //     };
  //   }, [map]);

  //   return null;
  // }


  // const watchIdRef = useRef<number | null>(null);
  // function useUserLocation(map: L.Map | null): [LatLng | null, number | null] {
  //   const [location, setLocation] = useState<LatLng | null>(null);
  //   const [direction, setDirection] = useState<number | null>(null);

  //   useEffect(() => {
  //     if (!map || !navigator.geolocation) return;

  //     if (watchIdRef.current === null) {

  //     const onLocationFound = (position: GeolocationPosition) => {
  //       const latlng = new L.LatLng(position.coords.latitude, position.coords.longitude);
  //       setLocation(latlng);
  //       setDirection(position.coords.heading);
  //     };

  //     const onError = (error: GeolocationPositionError) => {
  //       console.error("Error watching position:", error);
  //     };
  //     toast(`test ${watchIdRef.current}`)
  //       watchIdRef.current = navigator.geolocation.watchPosition(onLocationFound, onError, {
  //         enableHighAccuracy: true,
  //       });
  //     }

  //     return () => {
  //       if (watchIdRef.current !== null) {
  //         navigator.geolocation.clearWatch(watchIdRef.current);
  //       }
  //     };
  //   }, [map]);

  //   return [location, direction];
  // }


  // function UserLocationMarker(): null {
  //   const map = useMap();
  //   const [location, direction] = useUserLocation(map);

  //   useEffect(() => {
  //     if (!location) return;

  //     const arrowIcon = L.icon({
  //       iconUrl: 'assets/arrow.svg',
  //       iconSize: [24, 24],
  //       iconAnchor: [12, 12]
  //     });

  //     const userMarker = L.marker(location, {
  //       icon: arrowIcon,
  //       rotationAngle: direction !== null ? direction : 0,
  //       rotationOrigin: 'center center'
  //     }).addTo(map);

  //     return () => {
  //       map.removeLayer(userMarker);
  //     };
  //   }, [location, direction, map]);

  //   return null;
  // }

  // function useUserLocation(map: L.Map | null): [LatLng | null, number | null] {
  //   const [location, setLocation] = useState<LatLng | null>(null);
  //   const [direction, setDirection] = useState<number | null>(null);

  //   useEffect(() => {
  //     if (!map) return;


  //     const onLocationFound = (e: L.LocationEvent) => {
  //       setLocation(e.latlng);
  //       setDirection(e.heading);
  //     };

  //     map.on('locationfound', onLocationFound);
  //     if (!locationRequestedRef.current) {
  //       map.locate({ watch: true, enableHighAccuracy: true });
  //       locationRequestedRef.current = true;
  //     }

  //     return () => {
  //       map.off('locationfound', onLocationFound);
  //       map.stopLocate();
  //     };
  //   }, [map]);

  //   return [location, direction];
  // }





  const MapController = () => {
    const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);

    const mapc = useMap();

    // useFractionalZoom(mapc);
    // useEffect(() => {
    //   useFractionalZoom(mapc);
    // }, [mapc]);

    setMap(mapc)
    if (regionSummary == undefined) {
      setRegionSummary([])
      console.log('didenter', mapc.getZoom(), mapc.getBounds().getNorthWest(), mapc.getBounds().getSouthEast())

      fetchRegionSummary(mapc.getZoom(), mapc.getBounds().getNorthWest(), mapc.getBounds().getSouthEast())
    }
    // fetchRegionSummary(14, mapc.getBounds().getNorthWest(), mapc.getBounds().getSouthEast())
    // do something with map, in a useEffect hook, for example.
    // useEffect(() => {
    //   if (map == undefined){
    //     // initApp()
    //     // setupUser();
    //     // gestureInit()
    //   }
    //   if (state.fly){
    //     dispatch({ type: "setShowContent",
    //       show_content: false
    //     });
    //     mapc.dragging.enable();
    //     mapc.touchZoom.enable();
    //     mapc.doubleClickZoom.enable();
    //     mapc.scrollWheelZoom.enable();
    //     mapc.boxZoom.enable();
    //     mapc.keyboard.enable();
    //     if (mapc.tap) mapc.tap.enable();
    //     mapc.flyTo([state.lat, state.lon], 18, {duration: 0.7});
    //     setMarkerLatLon(new LatLng(state.lat, state.lon))
    //     setShowMarker(true)
    //     dispatch({ type: "setNavigate",
    //       fly: false,
    //       marker_img: state.marker_img,
    //       precise: state.precise,
    //       place_id: state.place_id
    //     });
    //   }

    // }, [state.fly]);
    return <></>;
  };
  const leftScore = 70;
  const rightScore = 30;
  const gradientCenter = `calc(${leftScore}% - ${rightScore}%)`;

  const gradientStyle = {
    background: `linear-gradient(to right, #e1a1f9 ${leaderScore.nycu}%, #83d3ce ${leaderScore.nthu}%) 0 0/100% 100% no-repeat`,
    // background: `linear-gradient(to right, #FF4136 ${leftScore}%, #0074D9 ${rightScore}%) center/200% 100% no-repeat`,
    // background: `linear-gradient(to right, #FF4136 ${leaderScore.nycu}%, #0074D9 ${leaderScore.nthu}%) center/200% 100% no-repeat`,
    backgroundPositionX: `calc(${leaderScore.nycu}% - ${leaderScore.nthu}%)`,
    // backgroundPositionX: gradientCenter,
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    if (selectedOption == 'nycu') {
      team.current = true
    } else if (selectedOption == 'nthu') {
      team.current = false
    }
    console.log(event.target.value)
  };


  const fetchRegionCoverInfo = async (id: number, zoom: number, user_id: number, opt: number) => {
    const response = await fetch('https://outvirt.com:8080/fetchregioncoverinfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: id, zoom: zoom, user_id: user_id, opt: opt })
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      response.json().then(async (res) => {
        setFollowingPics(res.following_pics)
        setRegionCoverInfo(res.post)
      })
    }
  }

  const fetchPostCoverInfo = async (id: number, zoom: number, user_id: number, area_id: number, opt: number) => {
    const response = await fetch('https://outvirt.com:8080/fetchpostcoverinfo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: id, zoom: zoom, user_id: user_id, area_id: area_id, opt: opt })
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      response.json().then(async (res) => {
        setRegionCoverInfo(res.post)
        setFollowingPics(res.following_pics)
      })
    }
  }


  // fetch a single post and display as card
  const fetchSinglePost = async (id: number, tableName?: string) => {
    try {
      // Decide the API endpoint based on tableName presence
      const endpoint = tableName 
        ? 'https://outvirt.com:8080/fetchfollowingpostsbyidandtable'
        : 'https://outvirt.com:8080/fetchfollowingpostsbyid';
      
      // Modify the body payload based on tableName presence
      const bodyPayload = tableName 
        ? { id: [id], user_id: state.id, tablenames: [tableName] }
        : { id: [id], user_id: state.id };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyPayload)
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        console.log(res_data);
        const bufferStart = res_data.length > 5 ? 5 : res_data.length;

        setFetchBuffer(res_data.splice(bufferStart, res_data.length - bufferStart))

        for (var j = 0; j < res_data.length; j++) {
          res_data[res_data.length - j - 1].idx = j
        }
        setRegionPosts(res_data.reverse())

        setCurrentIdx(bufferStart - 1)
        setIsPrev(false)
        setShowContent(true)

        // dispatch({
        //   type: "setCurrentIdx",
        //   current_idx: bufferStart - 1, // initial val
        //   is_prev: false
        // });
        // dispatch({
        //   type: "setShowContent",
        //   show_content: true
        // });
        map!.dragging.disable();
        map!.touchZoom.disable();
        map!.doubleClickZoom.disable();
        map!.scrollWheelZoom.disable();
        map!.boxZoom.disable();
        map!.keyboard.disable();
        if (map!.tap) map!.tap.disable();
      })
    } catch (err) {
      console.log(err);
    }
  }


  // const fetchRegionPosts = async (id: number, zoom: number, userId: number, opt: number) => {
  //   const response = await fetch(`https://outvirt.com:8080/fetchregionpostsv2`, {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ id, zoom, user_id: userId, opt }),
  //   });
  //   if (!response.ok) {
  //     throw new Error(response.statusText);
  //   } else {
  //     const result = await response.json();
  //     // You may want to process the result here before returning it
  //     return result;
  //   }
  // };

  const fetchRegionPosts = async (id: number, userId: number, topic_id: number, zoom: number, area_id: number, opt: any) => {
    const response = await fetch(`https://outvirt.com:8080/fetchregionpostsv2`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id, zoom: zoom, user_id: userId, topic_id: topic_id, area_id: area_id, opt }),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      const result = await response.json();
      return result;
    }
  };

  const fetchUserPostsInRegion = async (userId: number, topic_id: number, zoom: number, area_id: number) => {
    const response = await fetch(`https://outvirt.com:8080/fetchuserpostsinregion`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ zoom: zoom, user_id: state.id, targeted_user_id: userId, area_id: area_id, topic_id: topic_id }),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      const result = await response.json();
      return result;
    }
  };

  const MarkerMemo = React.memo(Marker);


  // const MarkerMemo = React.memo(Marker, (prevProps, nextProps) => {
  //   // Helper function to compare LatLngExpressions
  //   const comparePositions = (pos1: LatLngExpression, pos2: LatLngExpression) => {
  //     if (Array.isArray(pos1) && Array.isArray(pos2)) {
  //       // Both are arrays
  //       return pos1[0] === pos2[0] && pos1[1] === pos2[1];
  //     } else if (!Array.isArray(pos1) && !Array.isArray(pos2)) {
  //       // Both are LatLng objects
  //       return pos1.lat === pos2.lat && pos1.lng === pos2.lng;
  //     } else {
  //       // One is an array and the other is a LatLng object, so they're not equal
  //       return false;
  //     }
  //   };

  //   // Only re-render if the props you care about have changed
  //   return comparePositions(prevProps.position, nextProps.position)
  //   //  &&
  //   //        prevProps.isHovered === nextProps.isHovered &&
  //   //        prevProps.hoverIdx === nextProps.hoverIdx;
  // });

  const onZoomSliderChange = (value: number) => {
    setZoom(value);
    GLmap.current && GLmap.current.flyTo({
      center: GLmap.current.getCenter(),
      zoom: value,
      speed: 3,
    });
    // map!.flyTo([map?.getCenter().lat!, map?.getCenter().lng!], value, {duration: 0.7});
    // map?.dragging.enable()
  };


  return (
    <>
      <header
        className={classNames(
          'site-header-map',
          // 'has-bottom-divider',
        )}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
            )}>
            <Logo />
          </div>
        </div>
      </header>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <MlgTileLayer
        styleUrl="https://vmap.outvirt.com/styles/customized/style.json"
        center={[120.999881, 24.788566]}
        zoom={4}
        onAction={GetZoomLevelGL}
        mapRef={GLmap}
      />

      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        position: "fixed",
        bottom: "100px",
        right: "0px",
        zIndex: '400'
      }}>
        <VerticalSlider
          zoom={zoom}
          onZoomSliderChange={onZoomSliderChange}
          map={map}
        />
      </div>
      {/* <MapContainer
        // ref={mapRef}
        center={[latLon[0], latLon[1]]}
        zoom={map ? map.getZoom() : zoom}
        scrollWheelZoom={true}
        attributionControl={false}
        minZoom={3}
        zoomControl={false}
        zoomAnimation={true}
      // fractionalZoom={true}
      // maxBounds={L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180))}
      // contextmenu={true}
      > */}

      {/* standard profile */}
      {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={standardProfileId} map={map} />}
      {/* group */}
      {showGroup && <Group showGroup={showGroup} setShowGroup={setShowGroup} id={groupId} map={map} />}


      {/* search: post and map navigate */}
      <Fab
        style={{
          zIndex: '3000',
          position: 'absolute',
          top: '15px',
          right: '15px',
          border: '1px solid #333',
          backgroundColor: '#333',
          color: '#fff',
          display: showSearchButton ? 'flex' : 'none',
          animation: showSearchButton ? 'slideIn 0.5s ease' : 'slideOut 0.9s ease',
        }}
        color="default"
        onClick={() => {
          setShowInput(true);
          setShowSearchButton(false);
        }}
      >
        <Search />
      </Fab>
      {
        showInput && (
          <input
            // Post search bar
            type="text"
            // placeholder={isFocusOnSearch ? "" : "Post search"}
            placeholder={"Search your journey"}
            className="map-searchbar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            // onClick={() => setIsFocusOnSearch(true)}
            onBlur={() => {
              setShowInput(false);
              setShowSearchButton(true);
              // setIsFocusOnSearch(false)
            }}
            onKeyPress={async (e) => {
              if (e.key === 'Enter') {
                setIsLoading(true);
                setShowInput(false);
                setShowSearchButton(true);
                console.log('Search: ' + searchText);
                setShowSearchResult(true);
                await fetchPostSearch(searchText);
                setIsLoading(false);
              }
            }}
            style={{
              zIndex: '3000',
              position: 'absolute',
              top: '15px',
              right: '15px',
              padding: '5px',
              borderRadius: '4px',
              border: '1px solid #fff',
              // border: isFocusOnSearch ? '1px solid #fff' : '1px solid #333',
              backgroundColor: '#333',
              color: '#fff',
              width: '300px',
              display: showInput ? 'block' : 'none',
              opacity: showInput ? 1 : 0,
              animation: showInput ? 'slideIn 0.5s ease' : 'slideOut 0.5s ease',
            }}
            autoFocus
          />
        )
      }

      {/* <PreventSwipeToRefresh/> */}
      {isLoading && <div className="spinner-container" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>}

      {/* pan to current position */}
      <Fab
        className="navigate-icon"
        // style={{ zIndex: '3000', position: 'absolute', bottom: '70px', right: '15px' }}
        // color="secondary"
        onClick={async () => {
          // Your logic here
          // if (navigator.geolocation) {
          if (userLocationGL) {
            setIsLoading(true);
            GLmap.current && GLmap.current.flyTo({
              center: userLocationGL,
              zoom: 17,
              speed: 3,
            });
            setIsLoading(false);
            // const geolocationPromise = new Promise<GeolocationPosition>((resolve, reject) => {
            //   navigator.geolocation.getCurrentPosition(resolve, reject);
            // });

            // const timeoutPromise = new Promise<GeolocationPosition>((_, reject) => {
            //   setTimeout(() => {
            //     reject(new Error('Geolocation request timed out'));
            //   }, 5000); // Timeout duration, e.g., 5000ms (5 seconds)
            // });

            // Promise.race([geolocationPromise, timeoutPromise])
            //   .then((position: GeolocationPosition) => {
            //     const { latitude, longitude } = position.coords;
            //     // map!.flyTo([latitude, longitude], 17, { duration: 0.7 });
            //     setIsLoading(false);
            //   })
            //   .catch((error) => {
            //     setIsLoading(false);
            //     console.error('Error fetching location:', error);
            //   });
          } else {
            console.error('Geolocation is not supported by this browser.');
          }


        }}

      >
        <LocationSearchingIcon />
      </Fab>

      {/* a button to dismiss user posts on map */}
      {
        state.showUserPostsOnMap &&
        <Button
          variant="contained"
          className="userpostmap-icon"
          onClick={async () => {
            dispatch({
              type: 'setShowUserPostsOnMap',
              showUserPostsOnMap: false,
              userPostsOnMapId: 0,
            })
          }}
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      }

      {/* a button to dismiss searched posts on map */}
      {
        showSearchResult &&
        <Button
          variant="contained"
          className="userpostmap-icon"
          onClick={async () => {
            setShowSearchResult(false);
            setCurrentSearchId(0);
          }}
          startIcon={<CloseIcon />}
        >
          Close
        </Button>
      }


      {
        (showContent) && (
          <div ref={ref}
            style={{
              height: `${viewportHeight}px`,
              // zIndex: getHighestZIndex() + 1
              zIndex: cardZIndex + 1
            }}
            className="cards-stack-container">
            {
              regionPosts.map((post, idx) => {
                return <ContentCard key={post.id} {...{ ...post, card_name: cardName, map: map, cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdx, setCurrentIdx: setCurrentIdx, isPrev: isPrev, setIsPrev: setIsPrev, setShowContent: setShowContent, setFetchPost: setFetchPost, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef }} />
                // return <ContentCard key={post.id} {...{ ...post, card_name: "video", map: map, cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal }} />
              }
              )
            }
          </div>
        )
      }

      <PostContent {...{ showPostModal: showPostModal, setShowPostModal: setShowPostModal, post_id: postProps?.post_id, tablename: postProps?.tablename, is_object: postProps?.is_object, map: map }} key={postProps?.post_id} />
      {/* <Updatemap/> */}
      {/* <UserLocationMarker /> */}
      {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://outvirt.com/osm/{z}/{x}/{y}.png"
          updateWhenIdle={true}
          updateWhenZooming={false}
          keepBuffer={5}
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
      {/* <MlgTileLayer
          styleUrl="https://vmap.outvirt.com/styles/customized/style.json"
          center={[120.999881, 24.788566]}
          zoom={4}
          onAction={GetZoomLevelGL}
          mapRef = {GLmap}

        /> */}
      {GLmap.current && <UserLocationMarkerGL />}
      {/* <GetZoomLevel /> */}

      <ProfileComponent map={map} />

      <PostButtons showTutorials={showTutorials} onTimeoutEnd={() => setShowTutorials(false)} />
      <AreaName showAreaName={showAreaName} areaName={areaName} onTimeoutEnd={() => setShowAreaName(false)} zoom={zoom} handleAreaNameClick={() => handleAreaNameClick(zoom)} />

      {

        state.navigate && !showSearchResult && !state.showUserPostsOnMap && map &&
        <div>
          <Marker position={[state.navigate_lat, state.navigate_lon]} icon=
            {
              L.divIcon({
                // className: (isHovered && hoverIdx == rs.id) ? "map-div-icon-nav to-front" : "map-div-icon-nav",
                className: "map-div-icon-nav",
                iconAnchor: [0, 0],
                html: ReactDOMServer.renderToStaticMarkup(
                  <div className="marker-object" style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                    <Avatar
                      className={(isHovered) ? "main-map-icon-region to-front" : "main-map-icon-region"}
                      // className="main-map-icon-region"
                      onClick={() => {
                      }}
                    >
                      {
                        (state.navigate_img.slice(-4) == '.mp4') ? (
                          <div
                            className="map-high-level-nav-vid"
                          >
                            <video
                              // loop={true} muted={true} autoPlay={true} playsInline={true} src={`https://outvirt.com/assets/imgmap/${state.navigate_img}`} />
                              loop={true} muted={true} autoPlay={true} playsInline={true}
                              preload="auto" src={`https://outvirt.com/assets/img${(isHovered) ? '' : 'map'}/${state.navigate_img}`} />
                          </div>
                        ) : (
                          <div
                            className="map-high-level-nav"
                            style={{ "backgroundImage": `url(https://outvirt.com/assets/img${(isHovered) ? '' : 'map'}/${state.navigate_img})` }}
                          // style={{ "backgroundImage": `url(https://outvirt.com/assets/imgmap/${state.navigate_img})` }}
                          />
                        )
                      }

                    </Avatar>
                    <div className="navigate-content-container">
                      <div className="navigate-content-background" data-action="navigate">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <GoogleIcon />
                          <p className="post-text">&nbsp; Navigate using Google Maps </p>
                        </div>
                      </div>
                      <div className="navigate-content-background" data-action="close">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CloseIcon />
                          <p className="post-text">&nbsp; Close </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            eventHandlers={
              {
                click: (event) => {
                  const targetElement = event?.originalEvent?.target as HTMLElement;
                  const action = targetElement?.closest("[data-action]")?.getAttribute("data-action");

                  if (action === "navigate") {
                    console.log("navigate element clicked");
                    if (state.navigate_place_id == '') {
                      window.open(`https://www.google.com/maps/search/?api=1&query=${state.navigate_lat},${state.navigate_lon}`, '_blank');
                    } else {
                      window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${state.navigate_place_id}`, '_blank');
                    }
                    // close navigation
                    dispatch({
                      type: "setNavigate",
                      navigate: false,
                      navigate_img: '',
                      navigate_lat: 0,
                      navigate_lon: 0,
                      navigate_place_id: ''
                    });
                    // Your custom code to handle click event for navigate
                  } else if (action === "close") {
                    // close navigation
                    dispatch({
                      type: "setNavigate",
                      navigate: false,
                      navigate_img: '',
                      navigate_lat: 0,
                      navigate_lon: 0,
                      navigate_place_id: ''
                    });
                  }
                },
                mouseover: async () => {
                  console.log('enter')
                  if (!isHovered) {
                    setIsHovered(true)
                  }
                },
                mouseout: () => {
                  console.log('leave')
                  setIsHovered(false);
                },
              }
            }
          >

          </Marker>

        </div>

      }

      {!state.navigate && !state.showUserPostsOnMap && showSearchResult && searchPosts.map((rs, idx) => {
        return (
          <MLGRegionMarker key={idx} rs={rs} state={state} map={GLmap} opt={opt} zoom={zoom}
            isHovered={isHovered} hoverIdx={hoverIdx} setIsHovered={setIsHovered} setHoverIdx={setHoverIdx}
            setRegionCoverInfo={setRegionCoverInfo} setFollowingPics={setFollowingPics}
            fetchRegionPosts={fetchRegionPosts} fetchPostCoverInfo={fetchPostCoverInfo} fetchSinglePost={fetchSinglePost} fetchRegionCoverInfo={fetchRegionCoverInfo}
            setFetchBuffer={setFetchBuffer} setRegionPosts={setRegionPosts} setRegionAreas={setRegionAreas}
            setZoomList={setZoomList} setCurrentIdx={setCurrentIdx} setIsPrev={setIsPrev}
            setShowContent={setShowContent} setIsLoading={setIsLoading} setShowTutorials={setShowTutorials}
            regionCoverInfo={regionCoverInfo} followingPics={followingPics} t={t} setCurrentSearchId={setCurrentSearchId}
            type={"search"} prevIds={searchPosts.map((sub => sub.id))}/>
        )
      })
      }


      {

        state.showUserPostsOnMap && !showSearchResult && userPostsOnMap.map((rs, idx) => {
          return (
            <MLGRegionMarker key={idx} rs={rs} state={state} map={GLmap} opt={opt} zoom={zoom}
              isHovered={isHovered} hoverIdx={hoverIdx} setIsHovered={setIsHovered} setHoverIdx={setHoverIdx}
              setRegionCoverInfo={setRegionCoverInfo} setFollowingPics={setFollowingPics}
              fetchRegionPosts={fetchRegionPosts} fetchPostCoverInfo={fetchPostCoverInfo} fetchSinglePost={fetchSinglePost} fetchRegionCoverInfo={fetchRegionCoverInfo}
              setFetchBuffer={setFetchBuffer} setRegionPosts={setRegionPosts} setRegionAreas={setRegionAreas}
              setZoomList={setZoomList} setCurrentIdx={setCurrentIdx} setIsPrev={setIsPrev}
              setShowContent={setShowContent} setIsLoading={setIsLoading} setShowTutorials={setShowTutorials}
              regionCoverInfo={regionCoverInfo} followingPics={followingPics} t={t} setCurrentSearchId={setCurrentSearchId}
              type={"user"} fetchUserPostsInRegion={fetchUserPostsInRegion} setRegionInfo={setRegionInfo} prevIds={userPostsOnMap.map((sub => sub.id))} />
          )
        })
      }

      {/* {

          state.showUserPostsOnMap && !showSearchResult && map && userPostsOnMap.map((rs, idx) => {
            return (
              <RegionMarker key={idx} rs={rs} state={state} map={map} opt={opt}
                isHovered={isHovered} hoverIdx={hoverIdx} setIsHovered={setIsHovered} setHoverIdx={setHoverIdx}
                setRegionCoverInfo={setRegionCoverInfo} setFollowingPics={setFollowingPics}
                fetchRegionPosts={fetchRegionPosts} fetchPostCoverInfo={fetchPostCoverInfo} fetchSinglePost={fetchSinglePost} fetchRegionCoverInfo={fetchRegionCoverInfo}
                setFetchBuffer={setFetchBuffer} setRegionPosts={setRegionPosts} setRegionAreas={setRegionAreas}
                setZoomList={setZoomList} setCurrentIdx={setCurrentIdx} setIsPrev={setIsPrev}
                setShowContent={setShowContent} setIsLoading={setIsLoading} setShowTutorials={setShowTutorials}
                regionCoverInfo={regionCoverInfo} followingPics={followingPics} t={t} setCurrentSearchId={setCurrentSearchId}
                type={"single"} />
            )
          })
        } */}


      {

        !state.navigate && !state.showUserPostsOnMap && !showSearchResult && regionSummary && regionSummary.map((rs, idx) => {
          return (
            <MLGRegionMarker key={idx} rs={rs} state={state} map={GLmap} opt={opt} zoom={zoom}
              isHovered={isHovered} hoverIdx={hoverIdx} setIsHovered={setIsHovered} setHoverIdx={setHoverIdx}
              setRegionCoverInfo={setRegionCoverInfo} setFollowingPics={setFollowingPics}
              fetchRegionPosts={fetchRegionPosts} fetchPostCoverInfo={fetchPostCoverInfo} fetchSinglePost={fetchSinglePost} fetchRegionCoverInfo={fetchRegionCoverInfo}
              setFetchBuffer={setFetchBuffer} setRegionPosts={setRegionPosts} setRegionAreas={setRegionAreas}
              setZoomList={setZoomList} setCurrentIdx={setCurrentIdx} setIsPrev={setIsPrev}
              setShowContent={setShowContent} setIsLoading={setIsLoading} setShowTutorials={setShowTutorials}
              regionCoverInfo={regionCoverInfo} followingPics={followingPics} t={t} setCurrentSearchId={setCurrentSearchId}
              type={"summary"} setRegionInfo={setRegionInfo}  prevIds={regionSummary.map((sub => sub.id))}/>
            // null
            // <RegionMarker key={idx} rs={rs} state={state} map={map} opt={opt}
            //   isHovered={isHovered} hoverIdx={hoverIdx} setIsHovered={setIsHovered} setHoverIdx={setHoverIdx}
            //   setRegionCoverInfo={setRegionCoverInfo} setFollowingPics={setFollowingPics}
            //   fetchRegionPosts={fetchRegionPosts} fetchPostCoverInfo={fetchPostCoverInfo} fetchSinglePost={fetchSinglePost} fetchRegionCoverInfo={fetchRegionCoverInfo}
            //   setFetchBuffer={setFetchBuffer} setRegionPosts={setRegionPosts} setRegionAreas={setRegionAreas}
            //   setZoomList={setZoomList} setCurrentIdx={setCurrentIdx} setIsPrev={setIsPrev}
            //   setShowContent={setShowContent} setIsLoading={setIsLoading} setShowTutorials={setShowTutorials}
            //   regionCoverInfo={regionCoverInfo} followingPics={followingPics} t={t} setCurrentSearchId={setCurrentSearchId}
            //   type={"summary"} setRegionInfo={setRegionInfo} />
          )
        })
      }
      {/* {
          !state.navigate && !state.showUserPostsOnMap && !showSearchResult && regionObjects && regionObjects.map((rs, idx) => {
            return (
              <div key={idx}>
                <Marker position={[rs.loc[0], rs.loc[1]]} icon=
                  {
                    L.divIcon({
                      className: "map-div-icon-nav",
                      // iconAnchor: [0, 0],
                      iconAnchor: [12, 41],
                      html: ReactDOMServer.renderToStaticMarkup(
                        // <div className="main-map-icon-region" style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
                        <div className="main-map-object"
                        // style={{
                        //   // width: '100%',
                        //   // height: '100%',
                        //           width:map!.getZoom()>14?'50px':`${(map!.getZoom()-2)*6}px`,
                        //           height:map!.getZoom()>14?'50px':`${(map!.getZoom()-2)*6}px`,
                        //   // "border": (rs.seen) ? "2px solid #666":"2px solid purple",
                        //   "opacity": (rs.seen) ? "0.5" : "1"
                        // }}
                        >
                          {
                            // rs.type? (
                            (rs.image.slice(-4) == '.mp4') ? (
                              <div className="map-high-level-obj-vid">
                                <video
                                  loop={true} muted={true} autoPlay={true} playsInline={true} src={`https://outvirt.com/assets/imgmap/${rs.image}`} />

                              </div>
                            ) : (
                              // <div  className="map-high-level-obj">
                              //   <img
                              // //   style={{
                              // // width:map!.getZoom()>14?`${Math.pow(1.7, map!.getZoom()/2) }px`:`${(map!.getZoom()-2)*3}px`}}
                              // src={`https://outvirt.com/assets/img/${rs.image}`} 
                              // ></img>
                              // </div>
                              // <div className="map-high-level-obj" style={{ backgroundImage: `url(https://outvirt.com/assets/img/${rs.image})`, backgroundPosition: 'center' }} />
                              <div className="map-high-level-obj" style={{ backgroundImage: `url(https://outvirt.com/assets/img/${rs.image})`, backgroundPosition: 'center', backgroundSize: `${rs.id === 645 ? '180px' : '80px'}` }} />

                            )
                          }

                        </div>
                      )
                    })
                  }
                  eventHandlers={
                    {
                      click: async () => {

                        setIsLoading(true)
                        const response = await fetch('https://outvirt.com:8080/fetchregionposts', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({ id: rs.id, zoom: map!.getZoom(), user_id: state.id, opt: opt })
                        });
                        if (!response.ok) {
                          throw new Error(response.statusText);
                        } else {
                          response.json().then(async (res) => {
                            const bufferStart = res.posts.length > 5 ? 5 : res.posts.length;
                            console.log('before setfetch buffer, post_length:', res.posts.length, 'bufferStart', bufferStart)
                            // console.log('splice:',(res.posts.splice(bufferStart, res.posts.length - bufferStart)))
                            setFetchBuffer(res.posts.splice(bufferStart, res.posts.length - bufferStart))
                            console.log('click', fetchBuffer)
                            console.log('bs', bufferStart, res.posts.length)
                            for (var i = 0; i < res.posts.length; i++) {
                              res.posts[res.posts.length - i - 1].idx = i
                            }
                            setRegionPosts(res.posts.reverse())
                            setRegionAreas(res.areas)
                            setZoomList(res.zoom_list)

                            setCurrentIdx(bufferStart - 1)
                            setIsPrev(false)
                            setShowContent(true)
                            map!.dragging.disable();
                            map!.touchZoom.disable();
                            map!.doubleClickZoom.disable();
                            map!.scrollWheelZoom.disable();
                            map!.boxZoom.disable();
                            map!.keyboard.disable();
                            if (map!.tap) map!.tap.disable();
                          })
                        }

                        setIsLoading(false)
                        // toast('📣 Download the app to view contents!');
                      },
                    }
                  }
                >

                </Marker>

              </div>

            )
          })
        } */}
      {/* <MapController /> */}


      {/* </MapContainer > */}

    </>
  );
};

export default Map;