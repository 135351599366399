import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/DownloadSectionHeader';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AppDownload = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
    const { t } = useTranslation();

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: t('mainpage.appdownload.title'),
    paragraph: t('mainpage.appdownload.paragraph')
    // paragraph: 'Tired of spending hours on socia media and still feeling empty? Outvirt is here supercharge your social media experience.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      style={{
        backgroundImage: `url(https://outvirt.com/assets/img/website_background.png)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="container" >
        <div className={innerClasses}>
            <div className="mt-0  reveal-from-bottom" data-reveal-delay="200">
                <SectionHeader data={sectionHeader} className="center-content" />
            </div>
            
            <div className="reveal-from-bottom" data-reveal-delay="600">
                
                <ButtonGroup>
                <Button tag="a" color="primary" href="https://apps.apple.com/app/outvirt/id6443451816?l=en">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16" >
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                    </svg>   
                    &nbsp;{t('appstore')}
                    </Button>
                  <Button tag="a" color="dark" href="https://play.google.com/store/apps/details?id=com.outvirt.app">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-google-play" viewBox="0 0 16 16">
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z"/>
                    </svg>
                    &nbsp;{t('googleplay')}
                    </Button>
                  <Button tag="a" color="dark" href="./map">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM1.5 8a6.5 6.5 0 0 1 12.49-2.013 14.937 14.937 0 0 0-1.234 2.221c-.226.347-.491.686-.79 1.004A17.926 17.926 0 0 0 8 10.424a17.89 17.89 0 0 0-2.65-1.212 14.94 14.94 0 0 0-.828-1.99A6.492 6.492 0 0 1 1.5 8zm-.489 3a6.5 6.5 0 0 1 10.142-4.09c.548.885.98 1.86 1.287 2.903a17.6 17.6 0 0 0-2.548 1.523A21.507 21.507 0 0 1 8 12.925c-1.045 0-2.06-.16-3.031-.461a17.69 17.69 0 0 0-2.086-1.212A6.518 6.518 0 0 1 1.011 11zm6.004.201c.572-.573 1.356-1.004 2.23-1.23a15.767 15.767 0 0 1 2.735-.27 13.682 13.682 0 0 1 2.4.44c.491.14.972.31 1.445.503A6.506 6.506 0 0 1 8.5 14.98 6.508 6.508 0 0 1 7.015 11.2z"/>
</svg>

                    &nbsp;{t('web')}
                    </Button>
                </ButtonGroup>
                
                </div>
        </div>
      </div>
    </section>
  );
}

AppDownload.propTypes = propTypes;
AppDownload.defaultProps = defaultProps;

export default AppDownload;
