import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Avatar, ListItemAvatar, ListItemText, IconButton, TextField, Typography, Grid as GridCore } from '@material-ui/core';
import { Switch, FormControlLabel } from '@mui/material';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import Modal from 'react-modal';
import { Grid, Row, Col } from 'react-flexbox-grid';
import CollectionsIcon from '@mui/icons-material/Collections';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import { CloseCircleOutline } from "@material-ui/icons";
import ContentCard from '../post/ContentCard'
import PostContent from '../post/PostContent'
import '../map/map.css'
import { createPortal } from 'react-dom';
import { LeveltoExperience } from '../../utils/LeveltoExperience';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import FootstepsIcon from '@mui/icons-material/DirectionsWalk';
import {MapOutlined} from "@material-ui/icons";
import Connections from './Connections';
import './Profile.css'
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import { generateRandomString } from '../utils/GenerateRandomString';
import { dismissElementsAboveZIndex } from '../utils/DismissElementsAboveZIndex';
import L from "leaflet";
import PublicIcon from '@mui/icons-material/Public';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import Cropper from 'react-easy-crop'
import { generateCrop } from './components/GenerateCrop';

interface ContainerProps {
    showUserProfile: boolean
    setShowUserProfile: React.Dispatch<React.SetStateAction<boolean>>
    id: number
    map: L.Map | undefined;
    // webSocket?: WebSocket
}
type CropArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};
interface UserPhoto {
    srcwebviewPath: string;
    filepath: string;
}

interface Tag {
  id: number;
  username: string;
}


interface PostProps {
  tablename: string
  post_id: number
  is_object?: boolean
}



interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
  }
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
  }
  interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
  }


interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number;
  url: string;
  user_url: string
  zoom: number;
  }


  interface UserInfo {
    // basic
    id: number;
    username: string;
    name: string;
    profilepic: string;
    account_type: number;
    follow: boolean;
    token: string;
    coins: number;
    // remaining
    email: string;
    followers: number[];
    following: number[];
    posts: number[];
    shares: number[];
    comments: number[];
    
    level: number;
    experience: number;
    experienceRequired: number;
    url: string;
  }
  

  const WS_URL = 'wss://outvirt.com:8080';


const UserProfile: React.FC<ContainerProps> = (props) => {
  // ... useState, useRef, and other custom hooks
  const { t } = useTranslation();

  const [showReplyTo, setShowReplyTo] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const [tagList, setTagList] = useState<Tag[]>([])
  const [parentId, setParentId] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>([]);
  // const listRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<any>(null);
  // const contentRef = useRef<HTMLDivElement | null>(null);
  const [postCards, setPostCards] = useState<PostCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.useRef<HTMLInputElement>(null)
  const [showPostModal, setShowPostModal] = useState(false);
  const [showConnections, setShowConnections] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>(-1);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
  const [userInfo, setUserInfo] = useState<UserInfo>({
    id: 0,
    email: 'fetching...',
    username: 'fetching...',
    name: 'fetching...',
    profilepic: '1185f8c893242a4df10f02f25bbab5bf',
    token: '',
    coins: 0,
    account_type: 1,
    followers: [],
    following: [],
    follow: false,
    posts: [],
    shares: [],
    comments: [],
    level: 1,
    experience: 0,
    experienceRequired: 999,
    url: ''
})

const [posts, setPosts] = useState<Post[]>([]);
const [postProps, setPostProps] = useState<PostProps>({post_id: 0, tablename: ''});

const [cardZIndex, setCardZIndex] = useState<number>(0);
const [profileZIndex, setProfileZIndex] = useState<number>(0);
const [cardName, setCardName] = useState<string>('');
const [showContent, setShowContent] = useState<boolean>(false);
const [isPrev, setIsPrev] = useState<boolean>(false);
const [currentIdx, setCurrentIdx] = useState<number>(4);
const [fetchPost, setFetchPost] = useState<boolean>(false);
const arrowClickRef = useRef(false)
const fetchLockRef = useRef<any>(false);
const [showUrlDialog, setShowUrlDialog] = useState<boolean>(false);
const [inputValueUrl, setInputValueUrl] = useState('');

  const ws = useRef<WebSocket>();

  // initialize zIndex when content shown
  useEffect(() => {
    if (showContent) {
      // console.log('test zindex: ', getHighestZIndex(), viewportHeight)
      setCardZIndex(getHighestZIndex());
      setCardName(generateRandomString(5))
    } 
  }, [showContent])
  useEffect(() => {
    if (props.showUserProfile) {
      setProfileZIndex(getHighestZIndex());
    } 
  }, [props.showUserProfile])


  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  
  const setProfile = async() => {
    try {
      const response = await fetch('https://outvirt.com:8080/fetchprofile', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id})
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(async(userRemainingData) => {
        setUserInfo({...state, ...userRemainingData, experienceRequired: LeveltoExperience(userRemainingData.level)})
        setInputValueUrl(userRemainingData.url)
        // fetch posts, shares, comments
        try {
          // const response = await fetch('https://outvirt.com:8080/fetchpostssharescomments', {
          const response = await fetch('https://outvirt.com:8080/fetchuserposts', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: state.token, posts: userRemainingData.posts})
            // body: JSON.stringify({token: state.token, posts: userRemainingData.posts, shares: userRemainingData.shares, comments: userRemainingData.comments})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          response.json().then(res_data => {
            setPosts(res_data.posts)
            // setShares(res_data.shares)
            // setComments(res_data.comments)
            pushInitialDataPosts(res_data.posts)
          })
        } catch (err) {
          console.log(err);
        }
      })
    } catch (err) {
      console.log(err);
    }
  
  }

// Fetch more post on server every 9 posts; store in buffer
const fetchPostById = async() => {
  fetchLockRef.current = true
  const fetchIdx = selectIdx + fetchBuffer.length + postCards.length
  const deltaId = (infPosts.length - fetchIdx > 9) ? 9 : infPosts.length - fetchIdx
  try {
    const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: infPosts.slice(fetchIdx, fetchIdx+deltaId).map(post => post.id), user_id: props.id})
  });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    response.json().then(res_data => {
      setFetchBuffer([...fetchBuffer, ...res_data])
    })
  } catch (err) {
    console.log(err);
  }
  setSelectIdx(fetchIdx)
  fetchLockRef.current = false
}



  useEffect(() => {
    // if (state.profile_current_idx == -1){
    if (currentIdx == -1){
      setProfile()
    }
    if (postCards.length>0 && fetchPost){
      // if (postCards.length>0 && state.profile_fetch_post){
      // fetch more post from server every 9 post
      if (currentIdx == 1 && fetchBuffer.length < 9){
        // if (state.profile_current_idx == 1 && fetchBuffer.length < 9){
        console.log('fetch more post')
        fetchPostById()
      }
      if (postCards.length == 9){
        // funnel contents from buffer to display and pop last element of display
        let idx = (currentIdx + 4) % 9 // store previous 3 posts
        // let idx = (state.profile_current_idx + 4) % 9 // store previous 3 posts
        console.log('NOW', currentIdx, idx)
        let data = fetchBuffer.shift();
        
        postCards.pop()
        if (data){ // false if end of post reached
          data!.idx = idx
          setPostCards(oldCards =>
            [data!, ...oldCards]
          )
        }
      } else {
        // insert directly from buffer to display
        let data = fetchBuffer.shift();
        if (data){ // false if end of post reached
          data!.idx = (postCards[0].idx == 0) ? 8 : postCards[0].idx - 1
          setPostCards(oldCards =>
            [data!, ...oldCards]
          )
        } else if (!postCards.map(post => post.idx).includes(currentIdx) ){
            // dismiss content cards if no more post (for scroll)
            console.log('???????')
            setTimeout(() => {
              setShowContent(false)
              
              props.map && props.map.dragging.enable();
              props.map && props.map.touchZoom.enable();
              props.map && props.map.doubleClickZoom.enable();
              props.map && props.map.scrollWheelZoom.enable();
              props.map && props.map.boxZoom.enable();
              props.map && props.map.keyboard.enable();
              if (props.map) {
                if (props.map!.tap) props.map!.tap.enable();
              }
              setPostCards([])
              setFetchBuffer([])
            }, 500);
          }
      }
  
    } else if (!postCards.map(post => post.idx).includes(currentIdx) ){
    // } else if (!postCards.map(post => post.idx).includes(state.profile_current_idx) ){
      console.log('shouldenter')
      setTimeout(() => {
        setShowContent(false)
        
        props.map && props.map.dragging.enable();
        props.map && props.map.touchZoom.enable();
        props.map && props.map.doubleClickZoom.enable();
        props.map && props.map.scrollWheelZoom.enable();
        props.map && props.map.boxZoom.enable();
        props.map && props.map.keyboard.enable();
        if (props.map) {
          if (props.map!.tap) props.map!.tap.enable();
        }
        setPostCards([])
        setFetchBuffer([])
      }, 500);
    }
  }, [currentIdx, fetchPost]);
// }, [state.profile_current_idx, state.profile_fetch_post]);


  useEffect(() => {
    if (props.showUserProfile){
      setProfile()
      // if (state.show_content){
      //   // pause video on other pages if played
      //   const myVideo = document.getElementById("video"+state.current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.follow_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("follow"+state.follow_current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.bookmark_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("bookmark"+state.bookmark_current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.profile_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("profile"+state.profile_current_idx) as HTMLVideoElement; 
      //   console.log(myVideo)
      //   myVideo && myVideo.play();    
      // }
    }
}, [props.showUserProfile]);


  // posts
  const [infPosts, setInfPosts] = useState<Post[]>([]);
  const pushDataPosts = (curData: Post[]) => {
    const max = infPosts.length + 12;
    const min = max - 12;
    const newData : Post[] = [];
    for (let i = min; i < max && i < curData.length; i++) {
      newData.push(curData[i]);
    }
    setInfPosts([
      ...infPosts,
      ...newData
    ]);
  }
  
  const pushInitialDataPosts = (initialData: Post[]) => {
    const max = 12;
    const min = max - 12;
    const newData : Post[] = [];
    for (let i = min; i < max && i < initialData.length; i++) {
      newData.push(initialData[i]);
    }
    console.log(newData);
    
    setInfPosts([
      ...newData
    ]);
  }

  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};

const [pageNumber, setPageNumber] = useState(1);
  // const loader = useRef(null);
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  useEffect(() => {
    if (!isHeightFilled && props.showUserProfile) {
      fetchMorePosts();
    }
  }, [isHeightFilled, props.showUserProfile]);

  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };

  useEffect(() => {
    if (infPosts.length > 0) {
      checkIfHeightFilled();
    }
  }, [infPosts]);
  

  // Function to load more posts
  const fetchMorePosts = async () => {
    console.log('fetching more posts')
    setTimeout(async() => {
      if (infPosts.length + 12 > posts.length) {
        try {
          const response = await fetch('https://outvirt.com:8080/fetchmoreuserposts', {
              method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, lastId: (posts.length==0 ? -1 : posts[posts.length - 1].id)})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        response.json().then(res_data => {
            console.log(res_data.posts)
            if (res_data.posts.length!=0){
              setPosts([...posts, ...res_data.posts])
              pushDataPosts([...posts, ...res_data.posts]);
            }
          })
        } catch (err) {
          console.log(err);
        }
      } else {
        pushDataPosts(posts);
      }
    }, 500);
  };

  // Fetch more posts when pageNumber changes
  useEffect(() => {
    if (pageNumber>1 && props.showUserProfile) fetchMorePosts();
  }, [pageNumber, props.showUserProfile]);

  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    const tolerance = 5;
    if (scrollHeight - scrollTop <= clientHeight + tolerance) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (showContent){
        // if (state.profile_show_content){
          // close content card
          // ref.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
          // ref.current!.style.transform = 'scale(0)';
          // ref.current!.style.opacity = '0';
          setShowContent(false)
            // setTimeout(function(){
            //     dispatch({ type: "setProfileShowContent",
            //       profile_show_content: false
            //     });
            // }, 200);
      } else{
        props.setShowUserProfile(false)
      }
    }
  };

  // update profile pic
  const updatePP = async() => {
    const croppedImg = await generateCrop(url!, croppedArea!);
    let formData = new FormData();
    let imgBlob = await fetch(croppedImg!).then(res => res.blob());
    const parts = url!.split("/");
    formData.append('photo', imgBlob  , parts[parts.length-1]);
    formData.append('token', state.token);
    
    try {
        const response = await fetch('https://outvirt.com:8080/updatepp', {
        method: 'POST',
        body: formData,
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    } else {
        response.json().then(async(res)=>{
          dispatch({ type: "setUser",
              id: state.id,
              email: state.email,
              username: state.username,
              name: state.name,
              token: state.token,
              profilepic: res.profilepic,
              coins: state.coins,
            });  
            localStorage.setItem('profilepic', res.profilepic);
            setShowPPModal(false);
        }

        )
    }
    console.log(response);
    } catch (err) {
    console.log(err);
    }
  }

  // camera stuff
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isRearCamera, setIsRearCamera] = useState(true);
  const [cameraZIndex, setCameraZIndex] = useState(0);
  const videoElement = useRef<HTMLVideoElement>(null);
  const canvasElement = useRef<HTMLCanvasElement>(null);
  const [blob, setBlob] = useState<Blob>()
  const [url, setUrl] = useState<string>()
  const [showPPModal, setShowPPModal] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState<CropArea>();

  useEffect(() => {
    if (isCameraVisible){
      setCameraZIndex(getHighestZIndex() + 1)
    } else {
      setCameraZIndex(0)
    }
  }, [isCameraVisible]);

  
  useEffect(() => {
    if (isCameraVisible) {
      startCamera(isRearCamera);
    } else {
      const video = videoElement.current;
      if (video && video.srcObject) {
        const stream = video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        video.srcObject = null;
      }
    }
  }, [isCameraVisible]);

  const onCropComplete = useCallback((croppedArea: CropArea, croppedAreaPixels: CropArea) => {
    console.log(croppedArea, croppedAreaPixels)
    setCroppedArea(croppedAreaPixels);
  }, [])
  const handleFileInputChange = async(e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const blob = new Blob([file], { type: file.type });

      if (blob){
        const createdUrl = URL.createObjectURL(blob);
        setBlob(blob)
        setUrl(createdUrl)
        setIsCameraVisible(!isCameraVisible);
        setShowPPModal(true)
      }
    }
  };

    
const handleCapture = async () => {
  const canvas = canvasElement.current;
  const video = videoElement.current;
  if (canvas && video) {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d')?.drawImage(video, 0, 0);
    canvas.toBlob(async (blob: Blob | null) => {
      if (blob) {
        const createdUrl = URL.createObjectURL(blob);
        setBlob(blob)
        setUrl(createdUrl)
      }
    });
  }
    
  setShowPPModal(true)
  setIsCameraVisible(!isCameraVisible);
};
  const startCamera = async (isRearCamera: boolean) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Stop any active streams
        if (videoElement.current && videoElement.current.srcObject) {
          const stream = videoElement.current.srcObject as MediaStream;
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        // Set the back camera as the default
        const constraints = {
          video: {
            facingMode: { exact: isRearCamera ? 'environment' : 'user' },
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            frameRate: { ideal: 30 },
          },
          audio: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoElement.current) {
          videoElement.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing the camera:', err);
        // toast('Error accessing the camera:');
        // setIsCameraVisible(false)
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
      }
    } else {
      console.error('Your browser does not support camera access.');
    //   toast('Your browser does not support camera access.');
      // setIsCameraVisible(false)
      if (fileInputRef.current) {
        fileInputRef.current.click();
    }
    }
  };

  return (
    <div>
      {
        (showContent && cardZIndex>0) && (
          // (state.profile_show_content) && (
          
            <div ref={ref}
              style={{
                height: `${viewportHeight}px`,
                zIndex: cardZIndex + 2,
                // position: "absolute",
              }} 
            className="cards-stack-container">
              {
                postCards.map((post, idx) =>{
                  return <ContentCard key={post.id} {...{ ...post, card_name: cardName, cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdx, setCurrentIdx: setCurrentIdx, isPrev: isPrev, setIsPrev: setIsPrev, setShowContent: setShowContent, setFetchPost: setFetchPost, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef, map: props.map }} />
                  // return <ContentCard  key={post.id} {...{...post, card_name: "profile", cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                }
                )
              }
            </div>
        )
      }
      <PostContent {...{map: props.map, showPostModal: showPostModal, setShowPostModal: setShowPostModal, post_id: postProps?.post_id, tablename: postProps?.tablename}} key={postProps?.post_id}/>

      {showConnections && <Connections showConnections={showConnections} setShowConnections={setShowConnections} user_id={state.id} />}
      {isLoading && <div className="spinner-container" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>}
      


    <Dialog  
      className="dark-dialog"
      open={props.showUserProfile}
      onClose={() => props.setShowUserProfile(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      disableEnforceFocus 
      style={{ zIndex: profileZIndex + 1 }}
      // style={{ zIndex: getHighestZIndex() + 1 }}
    >

      

{/* profile pic cropper */}
  <Dialog style={{ zIndex: profileZIndex + 2 }} open={showPPModal} onClose={() => setShowPPModal(false)}>
    <DialogTitle className="dark-dialog-title">Update Profile Picture</DialogTitle>
    <DialogContent className={`dark-dialog-inputbox-content`}>
      <div  className="crop-container">
        <Cropper
          image={url}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape='round'
          />

      </div>
    </DialogContent>
    <DialogActions className={`dark-dialog-inputbox-content`}>
      <Button onClick={() => setShowPPModal(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={updatePP} color="primary">
        Update
      </Button>
    </DialogActions>
  </Dialog>

      {/* personal website input */}
      <Dialog style={{ zIndex: profileZIndex + 2 }} open={showUrlDialog} onClose={() => setShowUrlDialog(false)}>
        <DialogTitle className="dark-dialog-title">Personal website</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <GridCore container alignItems="center" spacing={2}>
            <GridCore item xs>
              <TextField
                autoFocus
                margin="dense"
                label="A website for your story"
                type="text"
                fullWidth
                value={inputValueUrl}
                onChange={(e) => setInputValueUrl(e.target.value)}
                InputProps={{ style: { color: 'white' } }}
                placeholder="https://www.outvirt.com"
                // helperText={inputError}
                // error={inputError !== ''}
              />
            </GridCore>
          </GridCore>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setShowUrlDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={async() => {
            // handle overly short or long input
            // if (inputValueCat.length === 0 || inputValueCat.length > 16) {
            //   setInputError('Must be between 1 and 16 characters.');
            //   return;
            // } else {
            //   setInputError('');
            // }

            try {
              const response = await fetch('https://outvirt.com:8080/updateuserurl', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: inputValueUrl, id: state.id })
              });
        
              if (!response.ok) throw new Error(response.statusText);
              else {
                setUserInfo({ ...userInfo, url: inputValueUrl })
                setShowUrlDialog(false);
              }
            } catch (err) {
              console.log(err);
            }
          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
{/* video components */}
<input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          accept="image/*,video/*"
      />
      {
        isCameraVisible &&
          <div className={`camera-container ${!isRearCamera ? 'flip-horizontal' : ''}`} style={{zIndex: `${cameraZIndex + 1}`}} >
              <video
                  ref={videoElement}
                  className={`camera-stream ${isCameraVisible ? 'visible' : ''}`}
                  autoPlay
                  playsInline
                  muted
              ></video>
          </div>

      }
      <canvas ref={canvasElement} className="camera-canvas"></canvas>

      {/* Add the round capture button and the gallery button */}
      <div className={`camera-controls ${isCameraVisible ? 'visible' : ''}`} style={{zIndex: `${cameraZIndex+2}`}}>
      <div
          className="gallery-button"
          onClick={() => {
              if (fileInputRef.current) {
                  fileInputRef.current.click();
              }
          }}
          style={{zIndex: `${cameraZIndex+2}`}}
          >
        <CollectionsIcon/>
      </div>
      <div
        className="toggle-camera-button"
        onClick={() => {
          const newIsRearCamera = !isRearCamera;
          setIsRearCamera(newIsRearCamera);
          startCamera(newIsRearCamera);
        }}
        style={{zIndex: `${cameraZIndex+2}`}}
      >
        <CameraswitchIcon/>
      </div>

      <div
          className={`capture-button`}
          onClick={handleCapture}
      >
        <svg className="countdown-svg" viewBox="0 0 80 80">
          <circle className="countdown-circle-bg-placeholder" cx="40" cy="40" r="35" />
          <circle className="countdown-circle-placeholder" cx="40" cy="40" r="35" />
        </svg>
      </div>
    </div>

      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`https://outvirt.com/assets/imgmap/${state.profilepic}`} // Replace this URL with the actual profile picture URL
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            marginRight: '10px',
          }}
          onClick={()=>{setIsCameraVisible(true)}}
        />
        <div>
          <div>{state.name}</div> {/* Replace 'Username' with the actual username */}
          <div
            style={{
              color: 'gray',
              fontSize: '14px',
            }}
          >
            @{state.username} {/* Replace 'Username' with the actual username */}
          </div>

        </div>
      </div>
        {/* <span>Profile</span> */}
        <IconButton color="inherit" onClick={() => props.setShowUserProfile(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent ref={listRef}  style={{padding: '10px'}} className="dark-dialog-content" onScroll={handleScroll}>




      <GridCore container>
      <GridCore item xs={12} container 
      className="profile-stats"
      >
        <GridCore item xs={4} 
        className="profile-stat"
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.posts.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("social.posts")}</Typography>
        </GridCore>
        <GridCore item xs={4} 
          className="profile-stat"
          onClick={() => setShowConnections(true)}
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.following.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("following")}</Typography>
        </GridCore>
        <GridCore item xs={4} 
          className="profile-stat"
          onClick={() => setShowConnections(true)}
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.followers.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("followers")}</Typography>
        </GridCore>
      </GridCore>
    </GridCore>

    <GridCore item xs={12} container 
        className="profile-stats"
        justifyContent="space-between"
      >
        
        <GridCore
          item
          xs={4}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <div style={{ width: "90%" }}>
            <Typography variant="h6" style={{ display: 'flex', justifyContent: 'left', fontSize: "16px" }}>
              {t('level')} {userInfo.level}
            </Typography>
            <Typography component="div" color="primary">
              <LinearProgress
                variant="determinate"
                value={(userInfo.experience / userInfo.experienceRequired) * 100}
              />
            </Typography>

            <div className="no-padding" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                color="textSecondary"
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '12px'
                }}
              >
                <IconButton size="small" disableRipple={true} disableFocusRipple={true}>
                  <FootstepsIcon fontSize="small" style={{ fontSize: "12px", color: "#999" }} />
                </IconButton>
                {/* &nbsp; {t('experience')} */}
              </Typography>
              <Typography
                color="textSecondary"
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '12px',
                  color: "#999"
                }}
              >
                {userInfo.experience} / {userInfo.experienceRequired}
              </Typography>
            </div>
          </div>

        </GridCore>
        
        <GridCore
          item
          xs={2}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Switch
              style={{ transform: 'scale(0.7)' }}
                checked={state.visible}
                onChange={async (ev) => {
                  console.log('check', ev.target.checked);
                  dispatch({
                    type: 'setVisible',
                    visible: ev.target.checked,
                  });
                  try {
                    // const response = await fetch('https://outvirt.com:8080/fetchpostssharescomments', {
                    const response = await fetch('https://outvirt.com:8080/updatevisible', {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        token: state.token,
                        visible: ev.target.checked,
                        lat: state.loc[0],
                        lon: state.loc[1],
                      }),
                    });
                    if (!response.ok) {
                      throw new Error(response.statusText);
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
              <Typography variant="body2" style={{ marginTop: 0, fontSize: '10px' }}>
                Visible
              </Typography>
            </div>
          </div>
        </GridCore>
        <GridCore
          item
          xs={3}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            className="mail-button"
            style={{color: userInfo.url=='' ?  "#999" : "#7a3fe4", borderColor:  userInfo.url=='' ?  "#999" : "#7a3fe4"}}
            variant="outlined"
            size="small"
            onClick={() => {
              setShowUrlDialog(true)              
            }}
          >
            <PublicIcon   style={{ fontSize: "24px" }}/>
          </Button>
        </GridCore>
        <GridCore
          item
          xs={3}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            className="mail-button"
            style={{color: "#7a3fe4", borderColor: "#7a3fe4"}}
            variant="outlined"
            size="small"
            onClick={() => {
              dispatch({
                type: 'setShowUserPostsOnMap',
                showUserPostsOnMap: true,
                userPostsOnMapId: state.id,
              })
              dismissElementsAboveZIndex(2000)
              // props.setShowUserProfile(false)
            }}
          >
            <MapOutlined   style={{ fontSize: "24px" }}/>
          </Button>
        </GridCore>


    </GridCore>

    


      <Grid fluid>
  <Row>
    {infPosts &&
      infPosts.map((infPost, idx) => {
        return (
          <Col
            key={idx}
            className="div-crop-profile"
            xs={4}
            onClick={async () => {
              setIsLoading(true)
              setSelectIdx(idx)
              const deltaId = (infPosts.length - (idx) > 9) ? 9 : infPosts.length - (idx)
              try {
                const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: infPosts.slice(idx, idx+deltaId).map(post => post.id), user_id: props.id})
              });
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                response.json().then(res_data => {
                  console.log(res_data);
                  const bufferStart = res_data.length > 5 ? 5 : res_data.length;

                  setFetchBuffer(res_data.splice(bufferStart, res_data.length - bufferStart))
                  
                  for (var j=0; j<res_data.length; j++){
                    res_data[res_data.length-j-1].idx = j
                  }
                  console.log('post', res_data);
                  setPostCards(res_data.reverse())
                  setCurrentIdx(bufferStart-1)
                  setIsPrev(false)
                  setShowContent(true)
                  
                  // props.map!.dragging.disable();
                  // props.map!.touchZoom.disable();
                  // props.map!.doubleClickZoom.disable();
                  // props.map!.scrollWheelZoom.disable();
                  // props.map!.boxZoom.disable();
                  // props.map!.keyboard.disable();
                  // if (props.map!.tap) props.map!.tap.disable();
                })
              } catch (err) {
                console.log(err);
              }
              setIsLoading(false)
            }}
          >
            {infPosts[idx].photos[0].slice(-4) === ".mp4" ? (
              <div className="vid-container-bookmark">
              {infPosts[idx].photos.length > 1 && <div className="albums-icon"><CollectionsIcon /></div> }
                <div className="video-play"><PlayArrowIcon /></div>
                <video
                  preload="metadata"
                  src={`https://outvirt.com/assets/img/${infPosts[idx].photos[0]}#t=0.1`}
                ></video>
              </div>
            ) : (
              <div
                className="img-container-bookmark"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/img/${infPosts[idx].photos[0]})`,
                }}
              >
                {infPosts[idx].photos.length > 1 && <div className="albums-icon"><CollectionsIcon /></div> }
              </div>
            )}
          </Col>
        );
      })}
  </Row>
</Grid>
      <div/>
      <Modal
        isOpen={modalInfo.show}
        onRequestClose={() => setModalInfo({ slides: [], show: false, index: 0 })}
      >
        {modalInfo.slides.length > 0 ? (
          <Swiper
            className="testSwiper"
            initialSlide={modalInfo.index}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {modalInfo.slides.map((image, index) => (
              <SwiperSlide key={index}>
                <div>
                  <img
                    src={'https://outvirt.com/assets/img/' + image}
                    width="15"
                    onClick={() => {
                      setModalInfo({ ...modalInfo, show: false });
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </Modal>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default UserProfile;
