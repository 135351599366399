export const LeveltoCoupons = (level: number) => {
    return Math.floor(5*Math.log(level**2 + level + 2))
}

export const LeveltoCoins = (level: number) => {
    return Math.floor(50*Math.log(level*2 + level + 3))*10
}

export const LeveltoExperience = (level: number) => {
    return 100*level + 200
}