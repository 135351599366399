import React, { useRef, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { MdFavorite, MdFavoriteBorder, MdChatBubble, MdChatBubbleOutline, MdPlace } from 'react-icons/md'; // Assuming you are using 'react-icons' for icons
// import { urlRegex, ParseDate } from './helpers'; // Assuming urlRegex and ParseDate are helper functions in your project
// import { showToast } from './utils'; // Assuming showToast is a utility function in your project
import { toast, ToastContainer } from 'react-toastify';
import ParseDate from '../date/Date';
import {AppContext} from '../../state'
import './PostContent.css'
import PublicIcon from '@mui/icons-material/Public';
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
    url: string;
}
interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
}

interface PostItemProps {
    idx: number
    post : Post
    setPost: React.Dispatch<React.SetStateAction<Post>>
    setModalInfo: React.Dispatch<React.SetStateAction<ModalInfo>>
    setShowPostModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowStandardProfile: React.Dispatch<React.SetStateAction<boolean>>
    setStandardProfileId: React.Dispatch<React.SetStateAction<number>>
    top: boolean
}

const PostItem: React.FC<PostItemProps> = (props) => {
    const ref = React.useRef<HTMLInputElement>(null)
    // const history = useHistory();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const { state, dispatch } = useContext(AppContext);

    return (
            // <div ref={ref}>
                <div ref={ref} className='post-item'>
                        <div className="float-left">
                            <div className="title-padding-converse" style={{ display: 'flex', alignItems: 'center' }} onClick={()=>{
                                // props.setStandardProfileId(props.post.user_id)
                                // props.setShowStandardProfile(true)
                            }}>
                                <div
                                    className="profile-icon"
                                    style={{
                                        backgroundImage: `url(https://outvirt.com/assets/img/${props.post.profilepic})`,
                                    }}
                                     onClick={()=>{
                                        props.setStandardProfileId(props.post.user_id)
                                        props.setShowStandardProfile(true)
                                     }}
                                />
                                <span onClick={async () => {
                                    props.setStandardProfileId(props.post.user_id)
                                    props.setShowStandardProfile(true)
                                }}>
                                    {props.post.name}
                                </span>
                                {
                                    (props.post.account_type === 1) ? <PublicIcon onClick={()=>{
                                        const url = props.post.url;
                                        const hasProtocol = /^https?:\/\//i.test(url);
                                        const absoluteUrl = hasProtocol ? url : "http://" + url;
                                        window.open(absoluteUrl, "_blank");
                                    }} style={{fontSize:'16px', color:"#999", margin: "5px"}} /> : null
                                }
                                <span style={{ color: 'gray' }}>
                                    &nbsp;&nbsp;· {ParseDate(props.post.created_at)}&nbsp;&nbsp;
                                </span>
                            </div>
                        </div>
                        <div style={{ paddingBottom: '0' }}>
                            {/*...*/}
                            <div>
                                {
                                    (props.post.body) ? (
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {props.post.body.split(urlRegex).map((part, i) => {
                                                if (part.match(urlRegex)) {
                                                    return (
                                                        <a href={part} key={i} target="_blank" rel="noopener noreferrer">
                                                            {part}
                                                        </a>
                                                    );
                                                } else {
                                                    return <span key={i}>{part}</span>;
                                                }
                                            })
                                            }
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div className="like-comment">
                            <div
                                style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: '1',
                                }}
                                onClick={async () => {
                                    if (state.id == 0) return;
                                    if (!props.post.liked){
                                        try {
                                            console.log('liketest', props.post.tablename)
                                            const response = await fetch('https://outvirt.com:8080/likepost', {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({id: props.post.id, token: state.token, tablename: props.post.tablename, post: ((props.post.post_id == 0) ? true : false)})
                                            });
                                            if (!response.ok) {
                                                throw new Error(response.statusText);
                                            }
                                            props.setPost({...props.post, liked: true, n_likes: props.post.n_likes+1})
                                            
                                        } catch (err) {
                                        console.log(err);
                                        }
                                    }
                                else {
                                    console.log('unlike')
                                    try {
                                        const response = await fetch('https://outvirt.com:8080/unlikepost', {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({id: props.post.id, token: state.token, tablename: props.post.tablename, post: ((props.post.post_id == 0) ? true : false)})
                                        });
                                        if (!response.ok) {
                                            throw new Error(response.statusText);
                                        }
                                        props.setPost({...props.post, liked: false, n_likes: props.post.n_likes-1})
                                    } catch (err) {
                                    console.log(err);
                                    }
                                }
                                }}
                            >
                                <span className="icon-like">
                                {props.post.liked ? <MdFavorite color="#ff7799" /> : <MdFavoriteBorder color="#92949c" />}
                                </span>
                                <span className="text">
                                {' '}
                                <span style={{ color: props.post.liked ? '#ff7799' : '#92949c' }}>&nbsp;{props.post.n_likes}</span>{' '}
                                </span>
                            </div>
                            <div
                                style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: '1',
                                }}
                            >
                                <span className="icon-chat">
                                {props.post.n_comments ? (
                                    <MdChatBubble color="#77b7ff" />
                                ) : (
                                    <MdChatBubbleOutline color="#92949c" />
                                )}
                                </span>
                                <span className="text">
                                {' '}
                                <span style={{ color: props.post.n_comments ? '#77b7ff' : '#92949c' }}>&nbsp;{props.post.n_comments}</span>{' '}
                                </span>
                            </div>
                            </div>
                        </div>
                </div>
            // </div>
    );
};

export default PostItem;
