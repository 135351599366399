import React, {useContext, useState} from "react";
import {
  Grid,
  Avatar,
  Typography,
  Chip,
} from "@material-ui/core";
import ParseDate from '../date/DateT';
import {AppContext} from '../../state'
import './Conversation.css'
import './Profile.css'
import { useTranslation } from 'react-i18next';
import { useDraggable } from "./components/Drag";
import { Bookmarks } from "@material-ui/icons";

interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    selected: boolean;
    user_id: number;
  }

  
interface ChatUser {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }
  
interface PostProps {
    tablename: string
    post_id: number
  }

interface PostCard {
    idx: number;
    id: number;
    tablename: string;
    photos: string[]
    profilepic: string
    user_id: number
    name: string
    type: boolean
    body: string
    created_at: string
    n_likes: number
    n_comments: number
    bookmarked: boolean
    liked: boolean
    precise: boolean
    topic_name: string[]
    loc_name: string
    lat?: number
    lon?: number
    account_type: number;
    url: string;
    user_url: string
    zoom: number;
  }

interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

interface ContainerProps {
    // i: number
    idx: number
    setBookmarkSelectIdx: React.Dispatch<React.SetStateAction<number>>;
    setContentScroll: React.Dispatch<React.SetStateAction<boolean>>;
    setBookmarkFetchBuffer: React.Dispatch<React.SetStateAction<PostCard[]>>;
    setBookmarkPostCards: React.Dispatch<React.SetStateAction<PostCard[]>>;
    setBookmarks: React.Dispatch<React.SetStateAction<Bookmark[]>>;
    bookmarks: Bookmark[]
    bookmarkCats: BookmarkCatProp[]
    setBookmarkCats: React.Dispatch<React.SetStateAction<BookmarkCatProp[]>>;
    setPostProps?: React.Dispatch<React.SetStateAction<PostProps>>;
    setShowPostModal?: React.Dispatch<React.SetStateAction<boolean>>;
    setChatUser?: React.Dispatch<React.SetStateAction<ChatUser>>;
    setShowChatModal?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowStandardProfile?: React.Dispatch<React.SetStateAction<boolean>>;
    setDisableScroll: React.Dispatch<React.SetStateAction<boolean>>;
    handleDrop: (droppedId: string | null, event: MouseEvent | TouchEvent) => void;
    setCurrentIdxBookmark: React.Dispatch<React.SetStateAction<number>>;
    setIsPrevBookmark: React.Dispatch<React.SetStateAction<boolean>>;
    setShowContentBookmark: React.Dispatch<React.SetStateAction<boolean>>;
    map: L.Map | undefined;

    // catNames: string[]
}

const ConversationItem: React.FC<ContainerProps> = (props) => {
  // Your existing logic and functions
  const { state, dispatch } = useContext(AppContext);
  // const ref = React.useRef<HTMLInputElement>(null)
  const { t } = useTranslation();
  // const draggableRef = useDraggable({ id: props.idx.toString() });
  const [isDragging, setIsDragging] = useState(false);
  const {ref} = useDraggable({ id: props.idx.toString(), isDragging: isDragging, setIsDragging: setIsDragging,
    onDragStart: () => props.setDisableScroll(true),
    onDragEnd: () => props.setDisableScroll(false),
    handleDrop: props.handleDrop, bookmarks: props.bookmarks, bookmarkCats: props.bookmarkCats });


  return (
    <Grid container style={{marginBottom: "16px"}}>
      <Grid item xs={2}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
        <div ref={props.bookmarks[props.idx].tablename == 'chat' ? null : ref} className="div-crop-conversation" onClick={async () => {
          if (!isDragging){
            if (props.bookmarks[props.idx].tablename == 'chat'){
                props.setChatUser && props.setChatUser({
                    name: props.bookmarks[props.idx].name,
                    username: props.bookmarks[props.idx].username || props.bookmarks[props.idx].name,
                    profilepic: props.bookmarks[props.idx].photos[0],
                    id: props.bookmarks[props.idx].user_id,
                })
                props.setShowStandardProfile && props.setShowStandardProfile(true)
                // dispatch({ type: "setShowStandardProfile",
                //     showStandardProfile: true,
                //     standardProfileId: props.bookmarks[props.idx].user_id
                //   });
                return;
                // history.push({
                //     pathname: `/standardprofile/${props.bookmarks[props.idx].user_id}`,
                // })
                // return;
            }
            // if (clickToggle){
            //     clickToggle = false;
            //     return;
            // }
            props.setBookmarkSelectIdx(props.idx)
            const deltaId = (props.bookmarks.length - (props.idx) > 9) ? 9 : props.bookmarks.length - (props.idx)
            try {
                const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: [props.bookmarks[props.idx].id], user_id: state.id})
                // body: JSON.stringify({id: props.bookmarks.slice(props.idx, props.idx+deltaId).map(post => post.id), user_id: state.id})
            });
                if (!response.ok) {
                throw new Error(response.statusText);
                }
                response.json().then(res_data => {
                const bufferStart = res_data.length > 5 ? 5 : res_data.length;
    
                props.setBookmarkFetchBuffer(res_data.splice(bufferStart, res_data.length - bufferStart))
                
                for (var j=0; j<res_data.length; j++){
                    res_data[res_data.length-j-1].idx = j
                }
                props.setBookmarkPostCards(res_data.reverse())
                props.setCurrentIdxBookmark(bufferStart-1)
                props.setIsPrevBookmark(false)
                props.setShowContentBookmark(true)
                
                props.map && props.map.dragging.enable();
                props.map && props.map.touchZoom.enable();
                props.map && props.map.doubleClickZoom.enable();
                props.map && props.map.scrollWheelZoom.enable();
                props.map && props.map.boxZoom.enable();
                props.map && props.map.keyboard.enable();
                if (props.map) {
                  if (props.map!.tap) props.map!.tap.enable();
                }
                // dispatch({ type: "setBookmarkCurrentIdx",
                //     bookmark_current_idx: bufferStart-1, // initial val
                //     bookmark_is_prev: false
                // });
                // dispatch({ type: "setBookmarkShowContent",
                //     bookmark_show_content: true
                //     });
                })
            } catch (err) {
                console.log(err);
            }

          } else {
            setIsDragging(false)
          }
      }}>
          {props.bookmarks[props.idx].tablename === "chat" ? (
            <div
              className="chat-icon-wrapper"
            //   style={{
            //     border:
            //       props.bookmarks[props.idx].n_comments > 0
            //         ? "2px solid #6f3490"
            //         : "1px solid #666",
            //   }}
            >
              <div
                className="img-container-bookmark"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/imgmap/${props.bookmarks[props.idx].photos[0]})`,
                  border:
                    props.bookmarks[props.idx].n_comments > 0
                      ? "2px solid #6f3490"
                      : "1px solid #666",
                }}
              />
            </div>
          ) : (
            props.bookmarks[props.idx].photos[0].slice(-4) === ".mp4" ? (
              <div className="post-icon-wrapper">
                <div className="vid-container-bookmark"  style={{ border: "1px solid #666" }}>
                  <video preload="metadata" src={`https://outvirt.com/assets/imgmap/${props.bookmarks[props.idx].photos[0]}#t=0.1`}></video>
                </div>
              </div>
            ) : (
              <div className="post-icon-wrapper">
                <div
                  className="img-container-bookmark"
                  style={{
                    backgroundImage: `url(https://outvirt.com/assets/imgmap/${props.bookmarks[props.idx].photos[0]})`,
                    border: "1px solid #666"
                  }}
                />
              </div>
            )
          )}
        </div>
      </Grid>
      <Grid item xs={10} onClick={() => {
        if (props.bookmarks[props.idx].tablename == 'chat'){
            // set chatUser for chat room modal
            props.setChatUser && props.setChatUser({
                name: props.bookmarks[props.idx].name,
                username: props.bookmarks[props.idx].username || props.bookmarks[props.idx].name,
                profilepic: props.bookmarks[props.idx].photos[0],
                id: props.bookmarks[props.idx].user_id,
            })
            // launch chat room
            props.setShowChatModal && props.setShowChatModal(true)
            // reset n_comments
            const newBookmarks = [...props.bookmarks]
            newBookmarks[props.idx].n_comments = 0
            props.setBookmarks && props.setBookmarks(newBookmarks)
        } else {
            props.setPostProps && props.setPostProps({post_id: props.bookmarks[props.idx].id, tablename: props.bookmarks[props.idx].tablename})
            props.setShowPostModal && props.setShowPostModal(true)
        }
      }}>
        <Grid container style={{color: props.bookmarks[props.idx].last_id == state.id || props.bookmarks[props.idx].n_comments == 0 || props.bookmarks[props.idx].tablename != 'chat' ? "#9E9E9E" : "#FFFFFF" }}>
          <Grid item xs={12}>
            <Typography className="limit-text">
              {props.bookmarks[props.idx].name}
              {props.bookmarks[props.idx].n_comments > 0 &&
                props.bookmarks[props.idx].last_id !== state.id && (
                  <Chip
                    label={props.bookmarks[props.idx].n_comments}
                    // style={props.bookmarks[props.idx].tablename === "chat" ? {color: "#666"}: {color: "#fff"}}
                    // color={
                    //   props.bookmarks[props.idx].tablename === "chat"
                    //     ? "info"
                    //     : "info"
                    // }
                    style={{ height: "14px", marginLeft:"6px", padding: "1px", fontSize: "12px", color: props.bookmarks[props.idx].n_comments > 0 && props.bookmarks[props.idx].tablename == "chat" ?  "#fff" : "#999", backgroundColor: props.bookmarks[props.idx].tablename === "chat" ? "#50239d" : "rgba(30,30,30,0.7)" }}
                  />
                )}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center"}}>
            <Typography
              className="limit-text"
              style={{ maxWidth: "60vw" }}
            >
              {props.bookmarks[props.idx].last_id === state.id && "You: "}
              {props.bookmarks[props.idx].body}
            </Typography>
            <Typography style={{ alignItems: "center", marginLeft: "10px", fontSize: "12px" }}>
              {ParseDate(props.bookmarks[props.idx].updated_at, t)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}


export default ConversationItem;
