import React, {useState, useContext, useEffect, useRef} from 'react';
// import { useMap } from 'react-leaflet'
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  TextField,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Business as BusinessIcon, Check as CheckIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import UserAddItem from './components/UserAddItem';
import { toast } from 'react-toastify';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

const useStyles = makeStyles((theme) => ({
    // Add your custom styles here
    input: {
      color: 'white',
    },
    inputFocused: {
      color: 'white',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
    rotate: {
        animation: `$rotate 1s linear infinite`,
      },
      "@keyframes rotate": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "25%": {
          transform: "rotate(-20deg)",
        },
        "50%": {
          transform: "rotate(0deg)",
        },
        "75%": {
          transform: "rotate(20deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
      centerBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '50vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        // height: '100vh',
      },
      button: {
        marginTop: '10px',
      },
  }));



interface User {
    id: number;
    account_type: number;
    name: string;
    username: string;
    profilepic: string;
    follow: boolean;
  }
  


const PeopleNearby: React.FC = () => {
  // Your component logic and state management here
  const [searchText, setSearchText] = useState('');

  const classes = useStyles();
  const [foundUser, setFoundUser] = useState<User[]>([])
  const { state, dispatch } = useContext(AppContext);
  // const map = useMap();
  // useEffect(() => {
    const inProgress = useRef(false);
   const throttle = (func: (...args: any[]) => void, delay: number) => {
  return (...args: any[]) => {
    if (!inProgress.current) {
      func(...args);
      inProgress.current = true;
      setTimeout(() => {
        inProgress.current = false;
      }, delay);
    }
  };
};

  const detectShake = () => {
    console.log('Shake detected!');
    toast('Shake detected!')
    // Here, you can call your backend fetch function
    // fetchFromBackend();
};


const throttledDetectShake = throttle(detectShake, 1000);



// The threshold value for determining when a shake has occurred
const shakeThreshold = 25;
  
// Keep track of the device's last known acceleration
const [lastAcc, setLastAcc] = useState<{ x: number | null, y: number | null, z: number | null }>({ x: null, y: null, z: null });

useEffect(() => {
  // This function will be called whenever new accelerometer data is available
  const handleMotionEvent = (event: DeviceMotionEvent) => {
    const acc = event.accelerationIncludingGravity;
    if (acc && acc.x !== null && acc.y !== null && acc.z !== null) {
        if (!lastAcc.x || !lastAcc.y || !lastAcc.z) {
        // This is the first motion event, so just save the acceleration data and return
        setLastAcc({ x: acc.x, y: acc.y, z: acc.z });
        return;
        }
        // Calculate the change in acceleration
        const deltaX = Math.abs(acc.x - lastAcc.x);
        const deltaY = Math.abs(acc.y - lastAcc.y);
        const deltaZ = Math.abs(acc.z - lastAcc.z);
        
        // If the change in acceleration is greater than the threshold, a shake has occurred
        if (deltaX + deltaY + deltaZ > shakeThreshold) {
            throttledDetectShake()
          // Here, you can call your backend fetch function
          // fetchFromBackend();
        }
        
        // Save the current acceleration for the next motion event
        setLastAcc({ x: acc.x, y: acc.y, z: acc.z });
    }
    
  };

  // Request permission to access motion and orientation data
  if (typeof (DeviceMotionEvent as any).requestPermission === 'function') {
    (DeviceMotionEvent as any).requestPermission()
      .then((permissionState: 'granted' | 'denied') => {
        if (permissionState === 'granted') {
          window.addEventListener('devicemotion', handleMotionEvent);
        }
      })
      .catch(console.error);
  } else {
    // If DeviceMotionEvent.requestPermission is not a function, this is not iOS 13+,
    // so we add the event listener directly.
    window.addEventListener('devicemotion', handleMotionEvent);
  }
  
  // Add an event listener for the device motion event
//   window.addEventListener('devicemotion', handleMotionEvent);
  
  // Remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('devicemotion', handleMotionEvent);
  };
}, [lastAcc]);


  const userSearch = async() => {
    if (searchText != ""){
        try {
        const response = await fetch('https://outvirt.com:8080/searchuser', {
            method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: state.token, text: searchText})
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        response.json().then(res_data => {
            console.log(res_data);
            if (res_data.length != 0){
                setFoundUser(res_data)
            }
            //  else{
            //     present('No matching users.', 2000)
            // }
        })
        } catch (err) {
        console.log(err);
        }
    }
  }

  return (
    <Box>
      {/* <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: <SearchIcon />,
          className: classes.input,
        }}
        onKeyPress={async (e) => {
          if (e.key === 'Enter') {
            await userSearch();
            setSearchText('');
          }
        }}
        variant="outlined"
        className={classes.inputFocused}
        style={{ marginTop: '16px', marginBottom: '16px', paddingLeft: '4px', paddingRight: '4px', width: '90%' }} // Adjust the padding here
        /> */}
        {
            foundUser.length == 0 ?
            <div className={classes.centerBox}>
                <div>
                    <ScreenRotationIcon className={classes.rotate} style={{ margin: 'auto', display: 'block', fontSize: "96px" }}/>
                    <Button onClick={detectShake} className={classes.button} variant="contained" color="primary" >Shake</Button>
                </div>
            </div>
            :
            <List>
                {foundUser.map((user, idx) => (
                <UserAddItem key={idx} idx={idx} token={state.token} user_id={state.id} user={user} users={foundUser} setUsers={setFoundUser} map={undefined} />
                ))}
            </List>
        }
    </Box>
  );
};


export default PeopleNearby;