import React, {useContext, useEffect, useState} from 'react';
import './Profile.css';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import NavigationIcon from '@mui/icons-material/Navigation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import HelpIcon from '@mui/icons-material/Help';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SellIcon from '@mui/icons-material/Sell';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Swiper, SwiperSlide } from 'swiper/react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import 'swiper/swiper-bundle.min.css';
import { Photo } from '@material-ui/icons';
import MapLocate from './MapLocate';
import Modal from 'react-modal';
import {TagUserItem} from './TagUserItem'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WebIcon from '@mui/icons-material/Web';
import BookmarkItemCompose from './components/BookmarkItemCompose';
interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

interface ContainerProps {
    id: number;
    onComplete: (
      upload: boolean,
      text?: string,
      locName?: string,
      websiteText?: string,
      labels?: string[],
      tagList?: Tag[],
      subbody?: string[],
      post_ids?: number[],
      post_tablenames?: string[],
      blob?: Blob,
      url?: string
      ) => void;
}


interface Friend {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }

interface Tag {
  id: number;
  name: string;
  username: string;
  profilepic: string;
}
  

const CompilePost: React.FC<ContainerProps> = (props) => {
    // Add your state and other logic here
    const { state, dispatch } = useContext(AppContext);
    // const [url, setUrl] = useState<string>('');
    const [text, setText] = useState<string>('');
    // const [isVideo, setIsVideo] = useState<boolean>(false);
    // const [isVideos, setIsVideos] = useState<boolean[]>([]);
    const [friendsList, setFriendsList] = useState<Friend[]>([]);
    const [locValue, setLocValue] = useState<string>('yl');
    const [locName, setLocName] = useState<string>("");

  const [placeId, setPlaceId] = useState<string>("");
    const [labelWarning, setLabelWarning] = useState<string>("");
    const [labels, setLabels] = useState<string[]>([])
    const [mapLocate, setMapLocate] = useState<boolean>(false);
    const [precise, setPrecise] = useState(true);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    // const [urls, setUrls] = useState<string[]>([]);
    // const [blobs, setBlobs] = useState<Blob[]>([]);
    // const urlsRef = React.useRef(urls);
    // const blobsRef = React.useRef(blobs);
    // const isVideosRef = React.useRef(isVideos);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [lat, setLat] = useState<number>(0);
    const [lon, setLon] = useState<number>(0);
    const [startIndex, setStartIndex] = useState<number>(0);

    const [tagList, setTagList] = useState<Tag[]>([])
    const [subbody, setSubbody] = useState<string[]>([])
    const [post_ids, setPost_ids] = useState<number[]>([])
    const [post_tablenames, setPost_tablenames] = useState<string[]>([])

    const [labelText, setLabelText] = useState<string>("");
    const [websiteText, setWebsiteText] = useState<string>("");
    const { t } = useTranslation();
    const [modalTagIsOpen, setModalTagIsOpen] = useState<boolean>(false);
    const [bookmarks, setBookmarks] = useState<Bookmark[]>([])
    const [bookmarkLoading, setBookmarkLoading] = useState<boolean>(false)
    const [blob, setBlob] = useState<Blob>();
    const [url, setUrl] = useState<string>();
    const useStyles = makeStyles((theme) => ({
      selectableChip: {
          // borderColor: theme.palette.grey[500],
          borderWidth: '0px',
          borderStyle: 'solid',
          backgroundColor: "rgba(128, 128, 128, 0.1)",
          color: theme.palette.grey[500],
          fontWeight: 'normal',
          fontSize: '8px',
          margin: '2px',
          borderRadius: '20px',
        },
        customChip: {
            // borderColor: theme.palette.grey[500],
            borderWidth: '0px',
            borderStyle: 'solid',
            backgroundColor: "rgba(128, 128, 128, 0.3)",
            color: theme.palette.common.white,
            fontWeight: 'normal',
            fontSize: '8px',
            margin: '2px',
            borderRadius: '20px',
          },
      customChipLabel: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    }));
    const classes = useStyles();



    const fetchAllBookmarks = async() => {
        setBookmarkLoading(true)
        // fetch bookmarked posts
        try {
          const response = await fetch('https://outvirt.com:8080/fetchallbookmarks', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: state.id, cat_id: props.id})
        });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          response.json().then(res_data => {
                setBookmarks(res_data)
                setSubbody(new Array(res_data.length).fill(''));
                const ids = res_data.map((item: any) => item.id);
                const tablenames = res_data.map((item: any) => item.tablename);
                setPost_ids(ids);
                setPost_tablenames(tablenames)

                
                const photos = res_data.map((item: any) => "https://outvirt.com/assets/img/" + item.photos[0]);
                // composeImages(photos)
                // composeImages(photos.slice(0,6))
                composeImages(photos.slice(0,6), 10)
                .then(blob => {
                    // Use blob here
                    console.log(blob.size, blob.type); // Add this line
                    const createdUrl = URL.createObjectURL(blob);
                    setBlob(blob);
                    setUrl(createdUrl)

                    // console.log(blob);
                })
                .catch(err => {
                  // Handle error
                  console.error(err);
                });
          })
        } catch (err) {
          console.log(err);
        }
        setBookmarkLoading(false)
      }

      function loadImage(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.src = url;
          img.onload = () => {
            console.log(img); // Add this line
            resolve(img);
          };
        //   img.onload = () => resolve(img);
          img.onerror = reject;
        });
      }

    //   function composeImages(imageUrls: string[], text: string): Promise<Blob> {
    //     return new Promise(async (resolve, reject) => {
    //       try {
    //         const images: HTMLImageElement[] = await Promise.all(imageUrls.map(loadImage));
      
    //         const canvas = document.createElement("canvas");
    //         const ctx = canvas.getContext("2d");
      
    //         if (!ctx) {
    //           reject(new Error('Failed to get canvas context'));
    //           return;
    //         }
      
    //         // Set canvas dimensions to be the maximum height among all images and the width of the first image
    //         const maxHeight = Math.max(...images.map(img => img.naturalHeight));
    //         canvas.width = images[0].naturalWidth;
    //         canvas.height = maxHeight;
      
    //         const stream = canvas.captureStream(1); // 1 fps for simplicity
    //         const recorder = new MediaRecorder(stream, {mimeType: 'video/webm'});
      
    //         let chunks: Blob[] = [];
    //         recorder.ondataavailable = e => chunks.push(e.data);
      
    //         recorder.onstop = () => {
    //           const blobv = new Blob(chunks, {type: 'video/webm'});
    //           resolve(blobv);
    //         };
      
    //         recorder.start();
      
    //         for (let i = 0; i < images.length; i++) {
    //           const img = images[i];
      
    //           // Draw the image centered vertically
    //           const yOffset = (maxHeight - img.naturalHeight) / 2;
    //           ctx.fillStyle = 'black';
    //           ctx.fillRect(0, 0, canvas.width, canvas.height);
    //           ctx.drawImage(
    //             img,
    //             0,
    //             yOffset,
    //             img.naturalWidth,
    //             img.naturalHeight
    //           );
      
    //           // Add semi-transparent black rectangle
    //           const textHeight = 30; // adjust this as needed
    //           const textY = (canvas.height / 2) - (textHeight / 2);
    //           ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    //           ctx.fillRect(0, textY, canvas.width, textHeight);
      
    //           // Add text
    //           ctx.fillStyle = 'white';
    //           ctx.textAlign = 'center';
    //           ctx.textBaseline = 'middle'; 
    //           const lines = splitTextIntoLines(text, ctx, canvas.width); // assuming this function exists
    //           const lineHeight = textHeight / lines.length;
    //           for (let j = 0; j < lines.length; j++) {
    //             ctx.fillText(lines[j], canvas.width / 2, textY + (j * lineHeight) + (lineHeight / 2));
    //           }
      
    //           await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before drawing the next frame
    //         }
      
    //         recorder.stop();
      
    //       } catch (err) {
    //         reject(err);
    //       }
    //     });
    //   }
      
    //   // A function to split a long string into multiple lines so it fits within the specified width.
    //   // This function assumes that the context's font has been set before calling this function.
    //   function splitTextIntoLines(text: string, ctx: CanvasRenderingContext2D, maxWidth: number): string[] {
    //     const words = text.split(' ');
    //     const lines = [];
    //     let currentLine = words[0];
      
    //     for (let i = 1; i < words.length; i++) {
    //       const word = words[i];
    //       const width = ctx.measureText(currentLine + " " + word).width;
    //       if (width < maxWidth) {
    //         currentLine += " " + word;
    //       } else {
    //         lines.push(currentLine);
    //         currentLine = word;
    //       }
    //     }
    //     lines.push(currentLine);
      
    //     return lines;
    //   }
      
      
      
    //   function composeImages(imageUrls: string[]): Promise<Blob> {
    //     return new Promise(async (resolve, reject) => {
    //       try {
    //         const images: HTMLImageElement[] = await Promise.all(imageUrls.map(loadImage));
      
    //         const minDimension = Math.min(
    //           ...images.map((img) => Math.min(img.naturalWidth, img.naturalHeight))
    //         );
      
    //         const canvas = document.createElement("canvas");
    //         const ctx = canvas.getContext("2d");
      
    //         if (!ctx) {
    //           reject(new Error('Failed to get canvas context'));
    //           return;
    //         }
      
    //         const cols = images.length === 3 ? 2 : Math.min(2, images.length);
    //         const rows = Math.ceil(images.length / cols);
      
    //         canvas.width = cols * minDimension;
    //         canvas.height = rows * minDimension;
      
    //         for (let i = 0; i < images.length; i++) {
    //           const img = images[i];
    //           const dx = (i % cols) * minDimension;
    //           const dy = Math.floor(i / cols) * minDimension;
      
    //           const offsetX = (img.naturalWidth - minDimension) / 2;
    //           const offsetY = (img.naturalHeight - minDimension) / 2;
      
    //           ctx.drawImage(
    //             img,
    //             offsetX,
    //             offsetY,
    //             minDimension,
    //             minDimension,
    //             dx,
    //             dy,
    //             minDimension,
    //             minDimension
    //           );
    //         }
      
    //         canvas.toBlob(blob => {
    //             if (blob) {
    //               resolve(blob);
    //             } else {
    //               reject(new Error('Canvas toBlob method failed'));
    //             }
    //           });
    //       } catch (err) {
    //         reject(err);
    //       }
    //     });
    //   }

    function composeImages(imageUrls: string[], gap: number): Promise<Blob> {
        return new Promise(async (resolve, reject) => {
          try {
            const images: HTMLImageElement[] = await Promise.all(imageUrls.map(loadImage));
      
            const minDimension = Math.min(
              ...images.map((img) => Math.min(img.naturalWidth, img.naturalHeight))
            );
      
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
      
            if (!ctx) {
              reject(new Error('Failed to get canvas context'));
              return;
            }
      
            const cols = images.length === 3 ? 2 : Math.min(2, images.length);
            const rows = Math.ceil(images.length / cols);
      
            canvas.width = cols * minDimension + (cols - 1) * gap;
            canvas.height = rows * minDimension + (rows - 1) * gap;
      
            // Set the background color of the canvas
            ctx.fillStyle = '#6f3591'; // your desired color
            ctx.fillRect(0, 0, canvas.width, canvas.height);
      
            for (let i = 0; i < images.length; i++) {
              const img = images[i];
              const dx = (i % cols) * (minDimension + gap);
              const dy = Math.floor(i / cols) * (minDimension + gap);
      
              const offsetX = (img.naturalWidth - minDimension) / 2;
              const offsetY = (img.naturalHeight - minDimension) / 2;
      
              ctx.drawImage(
                img,
                offsetX,
                offsetY,
                minDimension,
                minDimension,
                dx,
                dy,
                minDimension,
                minDimension
              );
            }
      
            canvas.toBlob(blob => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('Canvas toBlob method failed'));
                }
              });
          } catch (err) {
            reject(err);
          }
        });
      }
      
      
      
      

      
    // Modify content display to fit the screen
    useEffect(() => {
        // display initial pagination of photos/videos
        setSliding(true)
        setTimeout(() => {
            setSliding(false)
        }, 1000);

        // initialize bookmarks
        fetchAllBookmarks()

        // update viewport height
        const updateHeight = () => {
        setViewportHeight(window.innerHeight);
        };

        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, []);



    const [sliding, setSliding] = useState(false);


  return (
    <div 
    className="post-container"
    style={{
        height: `${viewportHeight}px`,
        // backgroundColor: "blue",
      }}>
  <Modal
    isOpen={modalTagIsOpen}
    onRequestClose={() => setModalTagIsOpen(false)}
    className="your-modal-class"
    overlayClassName="your-overlay-class"
  >
    <div>
      {
        tagList.map((tag, idx) => (
          <TagUserItem key={idx} idx={idx} user={tag} users={tagList} setUsers={setTagList} />
        ))
      }
    </div>
    <button onClick={()=>setModalTagIsOpen(false)}>Close</button>
  </Modal>
        {
          mapLocate ? <MapLocate onSelectLoc={(clat, clon, locName, placeId)=>{
            setMapLocate(false)
            setLat(clat)
            setLon(clon)
            setLocName(locName) // i'd like this to be the value returned from the child component
            setPlaceId(placeId) // i'd like this to be the value returned from the child component
            // setPrecise(true)
            console.log(clat, clon, locName, placeId)
            
        }}/>  :
    <div className="create-post" 
    style={{
        fontSize: "14px"
        // height: `${viewportHeight}px`,
        // backgroundColor: "blue",
      }}>
  <div className="container">
    <div className="row content" style={{width: "100%", paddingTop: '10px', marginLeft:"0px"}}>
    <div className="row " style={{width: "100%"}}>
        
        <div className="col">
        <div className="item transparent-background">
            <div className="col-2">
                <div className="compile-avatar">
                <img src={"https://outvirt.com/assets/imgmap/" + state.profilepic} width="35" alt="Profile"></img>
                </div>
            </div>
            <div className="col-8">
                <textarea className="textarea2" rows={1} placeholder={t('createpost.title')!} value={locName} 
                    onChange={async(e) => {
                        setLocName(e.target.value!);
                    }}/>
                <textarea className="textarea2" rows={2} placeholder={t('createpost.writeacap')!} value={text} 
                    onChange={async(e) => {
                    setText(e.target.value!);
                    if (e.target.value!.slice(-2,-1) == '@' && friendsList.length==0 ){
                        setStartIndex(e.target.value!.length-1)
                        try {
                        const response = await fetch('https://outvirt.com:8080/fetchfriends', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({id: state.id})
                        });
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }
                        response.json().then(res_data => {
                            setFriendsList(res_data)
                        })
                        } catch (err) {
                        console.log(err);
                        }
                    } else if (e.target.value!.slice(-1) == ' ' || text?.slice(startIndex-1,startIndex)!='@'){
                        setFriendsList([])
                    }
                    }}/>
            </div>
            <div className="col-2">
                <div style={{paddingLeft: '10px', display: 'flex'}}>
                    {
                        url ? 
                        <img src={url} alt={`cover photo`} width="100%" height="auto" /> 
                        // <video
                        //         src={url}
                        //         loop={true}
                        //         muted={true}
                        //         autoPlay={true}
                        //         playsInline={true}
                        //         width="100%"
                        //         height="auto"
                        //         controls
                        //         >
                        //         Your browser does not support the video tag.
                        //         </video>
                        :
                        <div className="spinner-container-child"><img src="assets/icon.svg" alt="loading" className="spinner" /></div>
                        // <img src={"https://outvirt.com/assets/imgmap/picinit"} alt={`cover photo`} width="100%" height="auto" />
                    }
                </div>
            </div>

        </div>
        </div>
    </div>
      {
  friendsList.length > 0 ? (
    <div className="tag-list">
      {friendsList.map((friend, idx) => {
        if (
          friend.username
            .toLowerCase()
            .slice(0, text!.slice(startIndex).length) ===
            text!.slice(startIndex) ||
          friend.name.toLowerCase().slice(0, text!.slice(startIndex).length) ===
            text!.slice(startIndex)
        ) {
          return (
            <div
              className="tag-item"
              key={idx}
              onClick={() => {
                setText(text!.slice(0, startIndex) + friend.username);
                setFriendsList([]);
                setTagList(
                  tagList.concat({
                    id: friend.id,
                    username: friend.username,
                    profilepic: friend.profilepic,
                    name: friend.name,
                  })
                );
              }}
            >
              <div className="profile-container">
                <img
                  className="profile-icon"
                  src={"https://outvirt.com/assets/img/" + friend.profilepic}
                  width="15"
                  alt="Profile"
                />
              </div>
              <div className="friend-info">
                <div>
                  <span>{friend.name}</span>
                </div>
                <div>
                  <span className="medium">@{friend.username}</span>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  ) : null
}
    </div>
    <div>
      <div className="row">
        <div className="col">

            
          <div className="item transparent-background label-color" onClick={async () => { }}>
            <AlternateEmailIcon className="icon"/>
            <span>{t('createpost.tagpeople')}</span>
            {
              tagList.length > 0 ? (
                tagList.map((tag, idx) => {
                  return (
                    idx < 2 ? (
                      <div key={idx} className="tag-profile">
                        <div className="avatar">
                          <img src={"https://outvirt.com/assets/img/" + tag.profilepic} width="15" alt={`Tagged user ${tag.username}`} />
                        </div>
                      </div>
                    ) : null
                  );
                })
              ) : (
                <span className="medium input-end">{t('createpost.tagwith')}</span>
              )
            }
            {
              tagList.length > 2 ? (
                <div className="tag-profile">
                <button className="tag-button">
                  +{tagList.length - 2}
                </button>
                </div>
              ) : null
            }
          </div>
          <div className="item-label-text transparent-background label-color">
            <SellIcon className="icon"/>
            {/* <i className="icon">{pricetagOutline}</i> */}
            <span>{t('createpost.addlabel')}</span>
            <input
              value={labelText}
              className="location-name input-end"
              placeholder={t('createpost.entertext')!}
              onChange={(e) => setLabelText(e.target.value)}
              // Add your event handlers here
            />
            
          </div>
          <div className="item-label transparent-background">
            <Chip label={`#${t('food')}`} size="small"
              className={classes.selectableChip}
              classes={{ label: classes.customChipLabel }}
              onClick={() => { setLabels([...labels, `${t('food')}`]) }} />
              <Chip label={`#${t('outdoor')}`} size="small"
                className={classes.selectableChip}
                classes={{ label: classes.customChipLabel }}
                onClick={() => { setLabels([...labels, `${t('outdoor')}`]) }} />
                <Chip label={`#${t('events')}`} size="small"
                  className={classes.selectableChip}
                  classes={{ label: classes.customChipLabel }}
                  onClick={() => { setLabels([...labels, `${t('events')}`]) }} />
                  <Chip label={`#${t('shopping')}`} size="small"
                    className={classes.selectableChip}
                    classes={{ label: classes.customChipLabel }}
                    onClick={() => { setLabels([...labels, `${t('shopping')}`]) }} />
                    <Chip label={`#${t('education')}`} size="small"
                      className={classes.selectableChip}
                      classes={{ label: classes.customChipLabel }}
                      onClick={() => { setLabels([...labels, `${t('education')}`]) }} />
                      <Chip label={`#${t('health')}`} size="small"
                        className={classes.selectableChip}
                        classes={{ label: classes.customChipLabel }}
                        onClick={() => { setLabels([...labels, `${t('health')}`]) }} />
                        
          </div>
          <div className="item transparent-background">
            <div style={{ "width": "70%" }}>
              {labels.map((label, idx) =>
              <Chip key={idx} label={`#${label}`} size="small"
              className={classes.customChip}
              classes={{ label: classes.customChipLabel }}
                        onClick={() => { setLabels([...labels.slice(0,idx),...labels.slice(idx+1)]) }} />
                // <span key={idx} className="label-chip" onClick={() => { }}>{label}</span>
              )}
            </div>
            <button className="icon-end add-label-button input-end" style={{backgroundColor: (labelText=="" || labelText==undefined) ? "#999":"#007bff"}} onClick={async () => {
              if (labelText.length>0){
                if(labelText.match(/\s/) != null){
                  setLabelWarning("White space not allowed")
                } else if (labelText.length >24){
                  setLabelWarning("Within 24 chars")
                } else {
                  setLabelWarning("")
                  setLabels([...labels, labelText])
                  setLabelText("")
                }
              }
            }}>
            <AddIcon className="icon-button"/>
            </button>
          </div>
        </div>
      </div>
      <div style={{height: "30vh", overflow: "auto"}} className='nice-scrollbar'>
      {
              bookmarkLoading ?
              <div className="spinner-container-child"><img src="assets/icon.svg" alt="loading" className="spinner" /></div>
              :
                bookmarks && bookmarks.map((bookmark, idx) => {
                    return (
                      <BookmarkItemCompose  key={idx} idx={idx} bookmarks={bookmarks} subbody={subbody} setSubbody={setSubbody}/>
                      )
                      })
            }
        </div>


  <div className="row">
    <div className="col col-6 button-center">
        <button className="post-upload-button button-danger" onClick={async () => {
            props.onComplete(false);
        }}>
        <DeleteIcon className="icon"/>
        <span>&nbsp;&nbsp;{t('createpost.discard')}</span>
      </button>
    </div>
    <div className="col col-6 button-center">
      <button className="post-upload-button" onClick={async () => {
        
            props.onComplete(
              true,
              text,
              locName,
              websiteText,
              labels,
              tagList,
              subbody,
              post_ids,
              post_tablenames,
              blob,
              url
            );

      }}>
        <CloudUploadIcon className="icon"/>
        <span>&nbsp;&nbsp;{t('createpost.post')}</span>
      </button>
    </div>
  </div>
</div>
  </div>
</div>

        }

    </div>
  );
}

export default CompilePost;
