import React, {useContext, useEffect, useState} from 'react';
import './Profile.css';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import NavigationIcon from '@mui/icons-material/Navigation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import HelpIcon from '@mui/icons-material/Help';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SellIcon from '@mui/icons-material/Sell';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Swiper, SwiperSlide } from 'swiper/react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import 'swiper/swiper-bundle.min.css';
import { Check, Photo } from '@material-ui/icons';
import MapLocate from './MapLocate';
import Modal from 'react-modal';
import {TagUserItem} from './TagUserItem'
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SwiperCore, { Navigation } from 'swiper';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import WebIcon from '@mui/icons-material/Web';

SwiperCore.use([Navigation]);

interface ContainerProps {
    blobs: Blob[];
    urls: string[];
    isVideos: boolean[];
    isNew: boolean[];
    setBlobs: (blobs: Blob[]) => void;
    setUrls: (urls: string[]) => void;
    setIsVideos: (isVideos: boolean[]) => void;
    setIsNew: (isNew: boolean[]) => void;
    onComplete: (
        save: boolean,
        text?: string,
        lat?: number,
        lon?: number,
        precise?: boolean,
        locName?: string,
        websiteText?: string,
        labels?: string[],
        placeId?: string,
        tagList?: Tag[],
        oriTable?: string,
    ) => void;
    onBlobFeed: () => void;
    onDeleteBlob: (index: number) => void;
}


interface Friend {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }

interface Tag {
  id: number;
  name: string;
  username: string;
  profilepic: string;
}
  

const EditPost: React.FC<ContainerProps> = (props) => {
    // Add your state and other logic here
    const { state, dispatch } = useContext(AppContext);
    // const [url, setUrl] = useState<string>('');
    const [text, setText] = useState<string>('');
    // const [isVideo, setIsVideo] = useState<boolean>(false);
    // const [isVideos, setIsVideos] = useState<boolean[]>([]);
    const [friendsList, setFriendsList] = useState<Friend[]>([]);
    const [locValue, setLocValue] = useState<string>('yl');
    const [locName, setLocName] = useState<string>("");
    const [oriTable, setOriTable] = useState<string>('');
  const [placeId, setPlaceId] = useState<string>("");
    const [labelWarning, setLabelWarning] = useState<string>("");
    const [labels, setLabels] = useState<string[]>([])
    const [mapLocate, setMapLocate] = useState<boolean>(false);
    const [precise, setPrecise] = useState(true);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    // const [urls, setUrls] = useState<string[]>([]);
    // const [blobs, setBlobs] = useState<Blob[]>([]);
    // const urlsRef = React.useRef(urls);
    // const blobsRef = React.useRef(blobs);
    // const isVideosRef = React.useRef(isVideos);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [lat, setLat] = useState<number>(0);
    const [lon, setLon] = useState<number>(0);
    const [startIndex, setStartIndex] = useState<number>(0);

    const [tagList, setTagList] = useState<Tag[]>([])

    const [labelText, setLabelText] = useState<string>("");
    const { t } = useTranslation();
    const [modalTagIsOpen, setModalTagIsOpen] = useState<boolean>(false);
    const [websiteText, setWebsiteText] = useState<string>("");

    const useStyles = makeStyles((theme) => ({
      selectableChip: {
        // borderColor: theme.palette.grey[500],
        borderWidth: '0px',
        borderStyle: 'solid',
        backgroundColor: "rgba(128, 128, 128, 0.1)",
        color: theme.palette.grey[500],
        fontWeight: 'normal',
        fontSize: '8px',
        margin: '2px',
        borderRadius: '20px',
      },
      customChip: {
          // borderColor: theme.palette.grey[500],
          borderWidth: '0px',
          borderStyle: 'solid',
          backgroundColor: "rgba(128, 128, 128, 0.3)",
          color: theme.palette.common.white,
          fontWeight: 'normal',
          fontSize: '8px',
          margin: '2px',
          borderRadius: '20px',
        },
      customChipLabel: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    }));
    const classes = useStyles();

      
    // Modify content display to fit the screen
    useEffect(() => {
        // display initial pagination of photos/videos
        setSliding(true)
        setTimeout(() => {
            setSliding(false)
        }, 1000);

        // initialize post location
        // initPostLocation()

        // update viewport height
        const updateHeight = () => {
        setViewportHeight(window.innerHeight);
        };

        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, []);


    useEffect(()=>{
      console.log('editpost', state.editPost)
        if (state.editPost){
            setupContent()
        }
    },[state.editPost])

    const setupContent = async() => {
        try {
            const response = await fetch('https://outvirt.com:8080/fetchpostedit', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: state.editPostId})});
            
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            response.json().then(res => {
                setLat(res.lat)
                setLon(res.lon)
                setText(res.body)
                setLocValue('lm')
                setLocName(res.loc_name)
                setWebsiteText(res.url)
                setLabelText('')
                setLabels(res.topic_name)
                setPrecise(res.precise)
                setTagList(res.tag_list)
                setOriTable(res.tablename)
                setPlaceId(res.place_id)
                // var objContainer : ImgObject[] = []
                var fetchUrls = []
                var fetchBlobs = []
                var fetchIsVideos = []
                var fetchIsNew = []
                for (var i=0; i<res.photos.length; i++){
                    fetchUrls.push(res.photos[i])
                    fetchBlobs.push(new Blob())
                    fetchIsVideos.push(res.photos[i].slice(-4)=='.mp4')
                    fetchIsNew.push(false)
                }
                props.setUrls([...props.urls, ...fetchUrls])
                props.setBlobs([...props.blobs, ...fetchBlobs])
                props.setIsVideos([...props.isVideos, ...fetchIsVideos])
                props.setIsNew([...props.isNew, ...fetchIsNew])
            })
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }


    const initPostLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLat(position.coords.latitude)
                setLon(position.coords.longitude)
                console.log(position.coords.latitude, position.coords.longitude)
                // setPrecise(true)
            }, (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setMapLocate(true)
                }
            });
        } else {
            setMapLocate(true)
        }
    }


    const [sliding, setSliding] = useState(false);


  return (
    <div 
    className="post-container"
    style={{
        height: `${viewportHeight}px`,
        zIndex: getHighestZIndex() + 1,
        // backgroundColor: "blue",
      }}>
  <Modal
    isOpen={modalTagIsOpen}
    onRequestClose={() => setModalTagIsOpen(false)}
    className="post-modal-class"
    overlayClassName="post-overlay-class"
    style={{
      overlay: {
        zIndex: getHighestZIndex() + 5,
        // add other styles for the overlay here
      },
      content: {
        zIndex: getHighestZIndex() + 5,
        // add styles for the content here
      },
    }}
  >
    <div>
      {
        tagList.map((tag, idx) => (
          <TagUserItem key={idx} idx={idx} user={tag} users={tagList} setUsers={setTagList} />
        ))
      }
    </div>
    <button onClick={()=>setModalTagIsOpen(false)}>Close</button>
  </Modal>
        {
          mapLocate ? <MapLocate onSelectLoc={(clat, clon, locName, placeId)=>{
            setMapLocate(false)
            setLat(clat)
            setLon(clon)
            setLocName(locName) // i'd like this to be the value returned from the child component
            setPlaceId(placeId) // i'd like this to be the value returned from the child component
            // setPrecise(true)
            console.log(clat, clon, locName, placeId)
            
        }}/>  :
    <div className="create-post" 
    style={{
        // height: `${viewportHeight}px`,
        // backgroundColor: "blue",
      }}>
  <div className="container">
    <div className="row content bottom-pad">
      <div className="col-8">
    <div className="row ">
      <div className="col-12">
        <div className="create-avatar">
          <img src={"https://outvirt.com/assets/img/" + state.profilepic} width="35" alt="Profile"></img>
          <span>{state.name}</span>
        </div>
      </div>
    </div>
        <textarea className="textarea" rows={10} placeholder={t('createpost.writeacap')!} value={text} 
          onChange={async(e) => {
          setText(e.target.value!);
          if (e.target.value!.slice(-2,-1) == '@' && friendsList.length==0 ){
            setStartIndex(e.target.value!.length-1)
            try {
              const response = await fetch('https://outvirt.com:8080/fetchfriends', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({id: state.id})
            });
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              response.json().then(res_data => {
                setFriendsList(res_data)
              })
            } catch (err) {
              console.log(err);
            }
          } else if (e.target.value!.slice(-1) == ' ' || text?.slice(startIndex-1,startIndex)!='@'){
            setFriendsList([])
          }
        }}/>
      </div>
      <div className="col-4">
        <div>
            <div className="swiper-container">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    onSlideChange={(swiper) => {
                        console.log('slide change');
                        setCurrentIndex(swiper.activeIndex);
                        setSliding(true)
                        setTimeout(() => {
                            setSliding(false)
                        }, 1000);

                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                    pagination={{ clickable: true }}
                    navigation 
                    // className="media-wrapper"
                    
                    // onSlideChangeStart={() => setSliding(true)}
                    // onSlideChangeEnd={() => setSliding(false)}
                    >
                    {props.urls.map((url, index) => (
                        <SwiperSlide key={index}>
                            {/* <div className="media-wrapper"> */}
                            {props.isVideos[index] ? (
                                <video
                                src={props.isNew[index] ? url : `https://outvirt.com/assets/img/${url}`}
                                loop={true}
                                muted={true}
                                autoPlay={true}
                                playsInline={true}
                                width="100%"
                                height="auto"
                                controls
                                >
                                Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img src={props.isNew[index] ? url : `https://outvirt.com/assets/img/${url}`} alt={`My Blob ${index}`} width="100%" height="auto" />
                            )}
                          {/* </div> */}
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`indicator ${sliding ? 'visible' : ''}`}>
                    {currentIndex + 1} / {props.urls.length}
                </div>
            </div>
            <div className="button-container">
                <button className="round-button button-danger" onClick={() => {
                    if (props.urls.length > 1) {
                        props.onDeleteBlob(currentIndex)
                    }
                }}>
                    <DeleteIcon className="icon-button"/>
                </button>
                <button className="round-button button-capture" onClick={() => { 
                    props.onBlobFeed()
                 }}>
                    <PhotoCameraIcon className="icon-button"/>
                </button>
            </div>
        </div>
      </div>
      {
  friendsList.length > 0 ? (
    <div className="tag-list">
      {friendsList.map((friend, idx) => {
        if (
          friend.username
            .toLowerCase()
            .slice(0, text!.slice(startIndex).length) ===
            text!.slice(startIndex) ||
          friend.name.toLowerCase().slice(0, text!.slice(startIndex).length) ===
            text!.slice(startIndex)
        ) {
          return (
            <div
              className="tag-item"
              key={idx}
              onClick={() => {
                setText(text!.slice(0, startIndex) + friend.username);
                setFriendsList([]);
                setTagList(
                  tagList.concat({
                    id: friend.id,
                    username: friend.username,
                    profilepic: friend.profilepic,
                    name: friend.name,
                  })
                );
              }}
            >
              <div className="profile-container">
                <img
                  className="profile-icon"
                  src={"https://outvirt.com/assets/img/" + friend.profilepic}
                  width="15"
                  alt="Profile"
                />
              </div>
              <div className="friend-info">
                <div>
                  <span>{friend.name}</span>
                </div>
                <div>
                  <span className="medium">@{friend.username}</span>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  ) : null
}
    </div>
    <div>
      <div className="row">
        <div className="col">
            <div className="item transparent-background">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <NavigationIcon  className="icon" />
                <span>{t('createpost.locatepost')}</span>
                </div>
                <select
                className="input-end locate-select"
                value={locValue}

                // onMouseDown={(e) => {
                //   const target = e.target as HTMLSelectElement;
                //   target.dataset.clicked = "true";
                // }}
                // onClick={(e) => {
                //   const target = e.target as HTMLSelectElement;
                //   if (target.dataset.clicked === "true") {
                //     // Set clicked to false, so that it won't trigger the same action again unless clicked again
                //     target.dataset.clicked = "false";
              
                //     const selectedValue = target.value;
                //     setLocValue(selectedValue);
                //     console.log(selectedValue)
                //     if (selectedValue === "lm") {
                //       setMapLocate(true);
                //     }
                //   }
                // }}
                onChange={(e) => {
                    setLocValue(e.target.value);
                    if (e.target.value === 'lm') {
                    setMapLocate(true);
                    }
                }}

                // onBlur={(e) => {
                //   const target = e.target as HTMLSelectElement;
                //   console.log(target.value)
                // }}
                // onSelect={(e) => {
                //   const target = e.target as HTMLSelectElement;
                //   console.log(target.value)
                // }}
                >
                <option value="yl">{t('createpost.yourloc')}</option>
                <option value="lm">{t('createpost.locateonmap')}</option>
                </select>
            </div>

            <div className="item transparent-background">
                {/* <span>{t('createpost.nameyourloc')}</span> */}
                <input
                value={locName}
                className="location-name input-end"
                placeholder={t('createpost.nameyourloc')!}
                onChange={(e) => setLocName(e.target.value)}
                // Add your event handlers here
                />
            </div>
            {/* <div className="item transparent-background toggle-wrapper">
                <LocationOnIcon  className="icon"/>
                <span>{t('createpost.discoverable')}</span>
                <div className="custom-checkbox input-end">
                  <button className="whatsdisc" data-tooltip="Post's precise location is revealed">
                  <HelpOutlineIcon
                      className="help-circle icon"
                  />
                  </button>

                    <input
                        type="checkbox"
                        name="darkMode"
                        className="dark-mode-checkbox"
                        checked={precise}
                        onChange={(ev) => {
                        setPrecise(ev.target.checked);
                        }}
                    />
                </div>
            </div> */}
          <div className="item transparent-background" onClick={async () => { }}>
            <AlternateEmailIcon className="icon"/>
            <span>{t('createpost.tagpeople')}</span>
            {
              tagList.length > 0 ? (
                tagList.map((tag, idx) => {
                  return (
                    idx < 2 ? (
                      <div key={idx} className="tag-profile">
                        <div className="avatar">
                          <img src={"https://outvirt.com/assets/img/" + tag.profilepic} width="15" alt={`Tagged user ${tag.username}`} />
                        </div>
                      </div>
                    ) : null
                  );
                })
              ) : (
                <span className="medium input-end">{t('createpost.tagwith')}</span>
              )
            }
            {
              tagList.length > 2 ? (
                <div className="tag-profile">
                <button className="tag-button">
                  +{tagList.length - 2}
                </button>
                </div>
              ) : null
            }
          </div>
          
          <div className="item transparent-background">
            <WebIcon className="icon"/>
            {/* <i className="icon">{pricetagOutline}</i> */}
            <span>{t('createpost.website')}</span>
            <input
              value={websiteText}
              className="location-name input-end"
              placeholder={t('createpost.https')!}
              onChange={(e) => setWebsiteText(e.target.value)}
              // Add your event handlers here
            />
          </div>
          <div className="item-label-text transparent-background">
            <SellIcon className="icon"/>
            {/* <i className="icon">{pricetagOutline}</i> */}
            <span>{t('createpost.addlabel')}</span>
            <input
              value={labelText}
              className="location-name input-end"
              placeholder={t('createpost.entertext')!}
              onChange={(e) => setLabelText(e.target.value)}
              // Add your event handlers here
            />
          </div>
          <div className="item-label transparent-background">
            <Chip label={`#${t('food')}`} size="small"
              className={classes.selectableChip}
              classes={{ label: classes.customChipLabel }}
              onClick={() => { setLabels([...labels, `${t('food')}`]) }} />
              <Chip label={`#${t('outdoor')}`} size="small"
                className={classes.selectableChip}
                classes={{ label: classes.customChipLabel }}
                onClick={() => { setLabels([...labels, `${t('outdoor')}`]) }} />
                <Chip label={`#${t('events')}`} size="small"
                  className={classes.selectableChip}
                  classes={{ label: classes.customChipLabel }}
                  onClick={() => { setLabels([...labels, `${t('events')}`]) }} />
                  <Chip label={`#${t('shopping')}`} size="small"
                    className={classes.selectableChip}
                    classes={{ label: classes.customChipLabel }}
                    onClick={() => { setLabels([...labels, `${t('shopping')}`]) }} />
                    <Chip label={`#${t('education')}`} size="small"
                      className={classes.selectableChip}
                      classes={{ label: classes.customChipLabel }}
                      onClick={() => { setLabels([...labels, `${t('education')}`]) }} />
                      <Chip label={`#${t('health')}`} size="small"
                        className={classes.selectableChip}
                        classes={{ label: classes.customChipLabel }}
                        onClick={() => { setLabels([...labels, `${t('health')}`]) }} />
                        
          </div>
          <div className="item transparent-background">
            <div style={{ "width": "70%" }}>
              {labels.map((label, idx) =>
              <Chip key={idx} label={`#${label}`} size="small"
              className={classes.customChip}
              classes={{ label: classes.customChipLabel }}
                        onClick={() => { setLabels([...labels.slice(0,idx),...labels.slice(idx+1)]) }} />
                // <span key={idx} className="label-chip" onClick={() => { }}>{label}</span>
              )}
            </div>
            <button className="icon-end add-label-button input-end" style={{backgroundColor: (labelText=="" || labelText==undefined) ? "#999":"#007bff"}} onClick={async () => {
              if (labelText.length>0){
                if(labelText.match(/\s/) != null){
                  setLabelWarning("White space not allowed")
                } else if (labelText.length >24){
                  setLabelWarning("Within 24 chars")
                } else {
                  setLabelWarning("")
                  setLabels([...labels, labelText])
                  setLabelText("")
                }
              }
            }}>
            <AddIcon className="icon-button"/>
            </button>
          </div>
        </div>
      </div>
  <div className="row">
    <div className="col col-6 button-center">
        <button className="post-upload-button button-danger" onClick={async () => {
            props.onComplete(false); //close post
        }}>
        <CloseIcon className="icon"/>
        <span>&nbsp;&nbsp;{t('createpost.close')}</span>
      </button>
    </div>
    <div className="col col-6 button-center">
      <button className="post-upload-button" onClick={async () => {
        // save post
        props.onComplete(
            true,
            text,
            lat,
            lon,
            precise,
            locName,
            websiteText,
            labels,
            placeId,
            tagList,
            oriTable,
        );
      }}>
        <CheckIcon className="icon"/>
        <span>&nbsp;&nbsp;{t('createpost.save')}</span>
      </button>
    </div>
  </div>
</div>
  </div>
</div>

        }

    </div>
  );
}

export default EditPost;
