
import { useTranslation } from 'react-i18next';


const ParseDate = (tdate: string) => {
  const { t, i18n } = useTranslation();
    var system_date = new Date(Date.parse(tdate));
    var user_date = new Date();
    if (K.ie) {
      system_date = new Date(Date.parse(tdate.replace(/( \+)/, ' UTC$1')));
    }
    var diff = Math.floor((user_date.getTime() - system_date.getTime()) / 1000);
    if (diff <= 1) {return t('time.justnow');}
    if (diff < 20) {return diff + t('time.secondsago');}
    if (diff < 40) {return t('time.halfaminuteago');}
    if (diff < 60) {return t('time.lessthanaminuteago');}
    if (diff <= 90) {return t('time.oneminuteago');}
    if (diff <= 3540) {return Math.round(diff / 60) + t('time.minutesago');}
    if (diff <= 5400) {return t('time.1hourago');}
    if (diff <= 86400) {return Math.round(diff / 3600) + t('time.hoursago');}
    if (diff <= 129600) {return t('time.1dayago');}
    if (diff < 604800) {return Math.round(diff / 86400) + t('time.daysago');}
    if (diff <= 777600) {return t('time.1weekago');}
    return "on " + system_date.toLocaleString('default', {month: 'short', day: '2-digit'});
  }
  
  // from http://widgets.twimg.com/j/1/widget.js
  var K = function () {
    var a = navigator.userAgent;
    return {
        ie: a.match(/MSIE\s([^;]*)/)
    }
  }();

  export default ParseDate;