import React, { useRef, useEffect } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Banner from '../components/layout/partials/Banner';
import Hero from '../components/sections/AboutHero';
import AboutDetails from '../components/sections/AboutDetails';
import ScrollReveal from '../utils/ScrollReveal';
import { useLocation, Routes, Route  } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);
// ReactGA.initialize([
//   {
//     trackingId: process.env.REACT_APP_GA_CODE,
//   },
// ]);
const trackPage = page => {
  ReactGA.send({ hitType: "pageview", page: page });

  // ReactGA.set({ page });
  // ReactGA.pageview(page);
};
const AboutPage = () => {
    
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // console.log(location.pathname)
    const page = location.pathname;
    trackPage(page);
    if (page == '/about'){
      document.body.classList.add('is-loaded')
      childRef.current.init();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
      <ScrollReveal
      ref={childRef}
      children={() => (
    
          <>
          <Header navPosition="right" className="reveal-from-bottom" />
          <main className="site-content">
              <Hero className="illustration-section-01" />
              <AboutDetails invertMobile topDivider imageFill className="illustration-section-02" />
          </main>
          <Footer />
          </>
  )} />

  )
      };

    //<Banner />
export default AboutPage;  
