import React, { useState, useRef, useEffect, ImgHTMLAttributes } from 'react';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
}

const Image: React.FC<ImageProps> = ({
  className,
  src,
  width,
  height,
  alt,
  ...props
}) => {

  const [loaded, setLoaded] = useState(false);

  const image = useRef(null);

  useEffect(() => {
    if (image.current)
      handlePlaceholder(image.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const placeholderSrc = (w: number, h: number) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
  }

  const handlePlaceholder = (img: HTMLImageElement) => {
    const placeholder = document.createElement('img');
    if (!loaded) {
      img.style.display = 'none';
      img.before(placeholder);
      placeholder.src = placeholderSrc(
        parseInt(img.getAttribute('width') || '0', 10),
        parseInt(img.getAttribute('height') || '0', 10)
      );
      placeholder.width = parseInt(img.getAttribute('width') || '0', 10);
      placeholder.height = parseInt(img.getAttribute('height') || '0', 10);

      placeholder.style.opacity = '0';
      img.className && placeholder.classList.add(img.className);
      placeholder.remove();
      img.style.display = '';      
    }
  }

  function onLoad() {
    setLoaded(true);
  }  

  return (
    <img
      {...props}
      ref={image}
      className={className}
      src={src}
      width={width}
      height={height}
      alt={alt}
      onLoad={onLoad} />
  );
}

// Image.propTypes = propTypes;
// Image.defaultProps = defaultProps;

export default Image;
