import React, { useRef, useState, useContext, useEffect, useLayoutEffect, ReactElement } from 'react';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Collapse, ClickAwayListener, MenuList, Popover , Select, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import { Settings as SettingsIcon } from '@material-ui/icons';
import 'swiper/swiper.min.css';
import TranslateIcon from '@mui/icons-material/Translate';
import '../map/map.css'
import './Conversation.css'
import { Swiper as SwiperType } from 'swiper';

interface OptionRowProps {
  logo: ReactElement;
  rowName: string;
  optionElement: ReactElement;
}

interface ContainerProps {
    showSettings: boolean
    setShowSettings: React.Dispatch<React.SetStateAction<boolean>>
}

SwiperCore.use([Navigation, Pagination]);


const useStyles = makeStyles({
    searchbar: {
      paddingBottom: 0,
    },
    indicator: {
        backgroundColor: '#3f50b4', // Your primary color
      },
  });


  const menuListStyles = makeStyles((theme) => ({
    button: {
      color: '#999',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#454545',
      },
    },
    root: {
      backgroundColor: '#222222',
      borderRadius: '4px',
      boxShadow: theme.shadows[1],
      overflow: 'hidden',
    },
    menuItem: {
      '&:hover': {
        backgroundColor: '#454545',
      },
    },
  }));
  

const Settings: React.FC<ContainerProps> = (props) => {
  const { t, i18n } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);
  const listClasses = menuListStyles();

  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', showError: false });
  const [searchText, setSearchText] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [language, setLanguage] = useState<string>('english');

  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
  };

  
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);





  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};



  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
    }
  };

//   const useStyles = makeStyles((theme) => ({
//     bookmarkFriends: {
//       display: "flex",
//       overflowX: "scroll",
//       msOverflowStyle: "none",
//       scrollbarWidth: "none",
//       marginBottom: "12px"
//     },
//     noScrollbar: {
//       "&::-webkit-scrollbar": {
//         display: "none",
//       },
//     },
//   }));
//   const classes = useStyles();
const OptionRow: React.FC<OptionRowProps> = ({ logo, rowName, optionElement }) => {
    return (
      <Grid container alignItems="center" justifyContent="space-between" spacing={2} style={{ marginBottom: '10px' }}>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {logo}
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{rowName}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
        {React.cloneElement(optionElement, { style: { zIndex: 113000 } })}
        </Grid>
      </Grid>
    );
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <div>
    <Dialog  
      className="profile-dialog dark-dialog"
      open={props.showSettings}
      onClose={() => props.setShowSettings(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <SettingsIcon/>
            &nbsp;&nbsp;{t('social.settings')}
        </div>
        <IconButton color="inherit" onClick={() => props.setShowSettings(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content">
        <OptionRow
            logo={<TranslateIcon />}
            rowName="Language"
            optionElement={
                <Grid item>
        <Button className={listClasses.button} onClick={handleClick}>{language}</Button>
        <ClickAwayListener onClickAway={handleClose}>
        <Collapse in={menuOpen}>

        <MenuList className={listClasses.root} style={{ position: 'absolute' }}>
        <MenuItem
              className={listClasses.menuItem}
              onClick={() => {
                setLanguage('English');
                i18n.changeLanguage('en');
                handleClose();
              }}
            >
              {t('language.english')}
            </MenuItem>
            <MenuItem
              className={listClasses.menuItem}
              onClick={() => {
                setLanguage('Chinese');
                i18n.changeLanguage('cn');
                handleClose();
              }}
            >
              {t('language.chinese')}
            </MenuItem>
          </MenuList>
          </Collapse>
        </ClickAwayListener>
      </Grid>
            }
        />
     
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default Settings;
