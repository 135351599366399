import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { useTranslation } from 'react-i18next';
import LazyLoadVideo from '../video/LazyLoadVideo';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );

  const sectionHeader = {
    title: t("mainpage.contenttiles.title"),
    paragraph: t("mainpage.contenttiles.paragraph")
    // `Missing out on trending activities by location?\n Dive into a decentralized world with Outvirt.`
    // paragraph: 'Tired of searching for the best place to hangout on weekends? Outvirt is a next generation solution for getting your daily social feed packed with latest web3 technology.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content mt-32" />
          <div className={tilesClasses}>
            
            <div
              className={`tiles-item reveal-from-bottom`}>
                
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                        <LazyLoadVideo
                          src={require('./../../assets/videos/post.mp4')}
                          // src={require('./../../assets/videos/sec3.mp4')}
                          alt="Features split 02"
                          height={96}
                          className="lazyload-video"
                        />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("mainpage.contenttiles.tile1.title")}</h4>
                  <p className="m-0 text-sm">{t("mainpage.contenttiles.tile1.subtitle")}</p>
                </div>
              </div>
            </div>
            
            <div
              className={`tiles-item reveal-from-bottom`} data-reveal-delay="200">
                
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                        <LazyLoadVideo
                          src={require('./../../assets/videos/trails.mp4')}
                          // src={require('./../../assets/videos/sec3.mp4')}
                          alt="Features split 02"
                          height={96}
                          className="lazyload-video"
                        />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("mainpage.contenttiles.tile2.title")}</h4>
                  <p className="m-0 text-sm">{t("mainpage.contenttiles.tile2.subtitle")}</p>
                </div>
              </div>
            </div>
            
            <div
              className={`tiles-item reveal-from-bottom`} data-reveal-delay="400">
                
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                        <LazyLoadVideo
                          src={require('./../../assets/videos/follows.mp4')}
                          // src={require('./../../assets/videos/sec3.mp4')}
                          alt="Features split 02"
                          height={96}
                          className="lazyload-video"
                        />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">{t("mainpage.contenttiles.tile3.title")}</h4>
                  <p className="m-0 text-sm">{t("mainpage.contenttiles.tile3.subtitle")}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;