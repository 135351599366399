import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const [tilesContent, setTilesContent] = useState([]);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [revealStatus, setRevealStatus] = useState([])
  const [displayedTrailIds, setDisplayedTrailIds] = useState([]);

  const outerClasses = classNames(
    // 'features-tiles section has-animations',
    'features-tiles section',
    // isDataLoaded && 'is-loaded',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );

  const sectionHeader = {
    title: t("mainpage.featuretiles.title"),
    paragraph: t("mainpage.featuretiles.paragraph")
    // `Missing out on trending activities by location?\n Dive into a decentralized world with Outvirt.`
    // paragraph: 'Tired of searching for the best place to hangout on weekends? Outvirt is a next generation solution for getting your daily social feed packed with latest web3 technology.'
  };

  useEffect(()=>{
    setupContents()
  },[])

  function getRandomNonOverlappingItems(arr, count, excludedItems=[]) {
    // Filter out the excluded items
    const filteredArr = arr.filter(item => !excludedItems.includes(item));
    let arrCopy = [...filteredArr];
    
    for(let i = arrCopy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arrCopy[i], arrCopy[j]] = [arrCopy[j], arrCopy[i]];
    }

    return arrCopy.slice(0, count);
}

  const demoTrailsTableId = ['e7769_n1838_8236',
    'e7766_n1838_8233',
    'e7746_n1790_8363',
    'e6408_n469_8247',
    'e7717_n1704_8367',
    'e7765_n1839_7763',
    'e7767_n1839_8490',
    'e7767_n1841_8253',
    'e7767_n1843_8380',
    'e7768_n1840_8245',
    'e7801_n1858_8392',
    'e7804_n1871_8372',
    'e7767_n1823_8381'
]
  const setupContents = async() => {
    const selectedDemoTrails = getRandomNonOverlappingItems(demoTrailsTableId, 3)
    try {
      const response = await fetch('https://outvirt.com:8080/fetchdemotrails', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tableids: selectedDemoTrails })
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(res => {
          const mergedData = res.map((item, index) => ({
            ...item,
            demoTrailId: selectedDemoTrails[index]
          }));
          
          setTilesContent(mergedData);
          // setTilesContent(res)
          setDataLoaded(true);
          setDisplayedTrailIds(selectedDemoTrails);  // Add this line
          console.log(res)
        }
        )
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (isDataLoaded) {
        tilesContent.forEach((_, idx) => {
            setTimeout(() => {
                // Here, update the state in a way that sets 'is-revealed' class to the specific tile
                // Let's assume you have an array in state that tracks which tiles should be revealed
                setRevealStatus(prev => {
                    const newStatus = [...prev];
                    newStatus[idx] = true;
                    return newStatus;
                });
            }, idx * 200);  // This delay is 200ms multiplied by the index, so each tile is delayed more than the previous
        });
    }
}, [isDataLoaded, tilesContent]);

const [fetchCount, setFetchCount] = useState(0);


// This useEffect will be responsible for fetching new content periodically.
useEffect(() => {
  const intervalId = setInterval(() => {
    // Increment the fetch count
    setFetchCount(prevCount => prevCount + 1);

    // If the fetch count exceeds 5, clear the interval
    if (fetchCount >= 5) {
      clearInterval(intervalId);
      return;
    }
    
    // Get a random tile index to update.
    const randomIndex = Math.floor(Math.random() * tilesContent.length);

    fetchNewTileContent(randomIndex);
  }, 3000); // every 3 seconds

  // Clear the interval when the component is unmounted.
  return () => clearInterval(intervalId);
}, [tilesContent]);

const [updatingIndex, setUpdatingIndex] = useState(null);

const fetchNewTileContent = async (indexToUpdate) => {
  try {
    setUpdatingIndex(indexToUpdate);

    setTimeout(async () => {
      const newTileId = getRandomNonOverlappingItems(demoTrailsTableId, 1, displayedTrailIds);
      const response = await fetch('https://outvirt.com:8080/fetchdemotrails', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ tableids: newTileId })
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        const newTileContent = await response.json();
        setTilesContent(prevTiles => {
          const updatedTiles = [...prevTiles];
          updatedTiles[indexToUpdate] = {...newTileContent[0], demoTrailId: newTileId[0]};
          return updatedTiles;
        });

        setDisplayedTrailIds(prevIds => {
          const newIds = [...prevIds];
          newIds[indexToUpdate] = newTileId[0];
          return newIds;
        });

        setUpdatingIndex(null);
      }
    }, 500); // 0.5s delay (equal to transition duration) before updating content

  } catch (err) {
    console.log(err);
    setUpdatingIndex(null);
  }
};

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-trails">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={tilesClasses}>
          {
  tilesContent.length === 0 ? (
    // Render placeholder tiles if the content hasn't been loaded yet
    [...Array(3)].map((_, idx) => ( 
      <div key={idx} className="tiles-item-placeholder reveal-from-bottom">
        Loading...
      </div>
    ))
  ) : (
    tilesContent.map((tc, idx) => (
      <a href={`./map?post=${tc.demoTrailId}`} key={idx}>
        <div
          className={`tiles-item reveal-from-bottom ${revealStatus[idx] ? 'is-revealed' : ''} ${updatingIndex === idx ? 'fade-out' : ''}`}
        >          
          <div className="tiles-item-inner">
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image src={`https://outvirt.com/assets/img/${tc.photos[0]}`}
                      alt="Features tile icon 03"
                      width={640}
                      height={640} />
              </div>
            </div>
            <div className="features-tiles-item-content">
              <h4 className="mt-0 mb-8">{tc.loc_name}</h4>
              <p className="m-0 text-sm">
                {tc.body.split('\n').map((line, index, array) => (
                  <React.Fragment key={index}>
                    {line}
                    {index === array.length - 1 ? null : <br />}
                  </React.Fragment>
                ))}
              </p>
            </div>
            
          </div>
        </div>
      </a>
    ))
  )
}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;




          {/* {
            tilesContent.map((tc, idx) => (
              <div key={idx} 
                // className={`tiles-item reveal-from-bottom`} 
                className={`tiles-item reveal-from-bottom ${revealStatus[idx] ? 'is-revealed' : ''}`}
                // className={`tiles-item is-loaded reveal-from-bottom ${revealStatus[idx] ? 'is-revealed' : ''}`}
                // className={`tiles-item  ${revealStatus[idx] ? 'is-revealed' : ''}`}

                // className={`tiles-item reveal-from-bottom ${revealStatus[idx] ? 'is-loaded' : ''}`}
                // className={`tiles-item ${revealStatus[idx] ? 'is-revealed' : ''}`}

                // className={`tiles-item reveal-from-bottom ${isDataLoaded ? 'is-revealed' : ''}`}
              // data-reveal-delay={idx*200}
              >
                   
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image src={`https://outvirt.com/assets/img/${tc.photos[0]}`}
                             alt="Features tile icon 03"
                             width={640}
                             height={640} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">{tc.loc_name}</h4>
                    <p className="m-0 text-sm">{tc.body}</p>
                  </div>
                </div>
              </div>
            ))
          } */}


            

            {/* <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg').default}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    {t("mainpage.featuretiles.tile1.title")}
                  </h4>
                  <p className="m-0 text-sm">
                    {t("mainpage.featuretiles.tile1.subtitle")}
                  </p>
                </div>
              </div>
            </div>

            
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg').default}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    {t("mainpage.featuretiles.tile2.title")}
                    </h4>
                  <p className="m-0 text-sm">
                    {t("mainpage.featuretiles.tile2.subtitle")}
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg').default}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    {t("mainpage.featuretiles.tile3.title")}
                    </h4>
                  <p className="m-0 text-sm">
                    {t("mainpage.featuretiles.tile3.subtitle")}
                  </p>
                </div>
              </div>
            </div> */}

export default FeaturesTiles;