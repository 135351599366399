import React, { useEffect, useRef } from 'react';

const LazyLoadVideo = ({ src, alt, height, className }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.src = src;
          observer.disconnect();
        }
      });
    });

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [src]);

  return (
    <video
      ref={videoRef}
      className={className}
      alt={alt}
      height={height}
      loop
      muted
      playsInline
      preload="auto"
      autoPlay
      poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC" // Add this line
    >
      <source data-src={src} type="video/mp4" />
      {/* Your browser does not support the video tag. */}
    </video>
  );
};

export default LazyLoadVideo;
