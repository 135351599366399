import React, { useRef, useState, useContext, useEffect, useLayoutEffect, ChangeEvent } from 'react';
import {AppContext} from '../../state'
import { Avatar, Badge, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon, DeleteOutline, Bookmark as BookmarkIcon } from '@material-ui/icons';
import { QuestionAnswer } from '@material-ui/icons';
import ContentCard from '../post/ContentCard'
import PostContent from '../post/PostContent'
import '../map/map.css'
import AddIcon from "@material-ui/icons/Add";
import ConversationItem from './ConversationItem';
import BookmarkCat from './BookmarkCat';
import './Conversation.css'
import StandardProfile from './StandardProfile';
import ChatRoom from './ChatRoom'
import { toast } from 'react-toastify';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import { generateRandomString } from '../utils/GenerateRandomString';
import Modal from 'react-modal';
import ShareIcon from '@mui/icons-material/Share';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import SearchPeopleSelect from './SearchPeopleSelect';
import L from "leaflet";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CompilePost from './CompilePost';
import { set } from 'lodash';

type CheckboxNames = 'deleteBookmark' | 'reorderBookmark' | 'addBookmark';


interface ContainerProps {
    // showBookmark: boolean
    // setShowBookmark: React.Dispatch<React.SetStateAction<boolean>>
    ws: React.MutableRefObject<WebSocket | undefined>
    showBookmark: boolean
    setShowBookmark: React.Dispatch<React.SetStateAction<boolean>>
    map: L.Map | undefined;
    // webSocket?: WebSocket
}

interface UserPhoto {
    srcwebviewPath: string;
    filepath: string;
}

interface Tag {
  id: number;
  username: string;
}


interface PostProps {
  tablename: string
  post_id: number
  is_object?: boolean
}


interface ChatUser {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }
  

interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
  }
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
  }
  interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
  }


interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number;
  url: string;
  user_url: string
  zoom: number;
  }
  


  interface FollowingPost {
    id: number;
    profilepic: string;
    tablename: string;
    seen: boolean;
  }
  interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

  interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    selected: boolean;
    user_id: number;
  }

  
interface CatModal {
  show: boolean;
  idx: number;
  user_id: number;
}


interface RenameDialog {
  show: boolean;
  id: number;
  name: string;
  image: string;
}
interface MoveDialog {
  show: boolean;
  bookmark_cats: BookmarkCatProp[];
}



  const WS_URL = 'wss://outvirt.com:8080';


const Bookmark: React.FC<ContainerProps> = (props) => {
//   const { t } = useTranslation();
const { t } = useTranslation();

  const { state, dispatch } = useContext(AppContext);
  
  const listRef = useRef<any>(null);

  const followingRef = React.useRef<HTMLInputElement>(null)
  const [showPostModal, setShowPostModal] = useState(false);
  const [followingPosts, setFollowingPosts] = useState<FollowingPost[]>([]);
  const [bookmarkCats, setBookmarkCats] = useState<BookmarkCatProp[]>([{
    id: 0,
    name: t("conversation.recent"),
    image: "recent",
    selected: true,
    user_id: 0,
  }])
  const [bookmarksContainer, setBookmarksContainer] = useState<Bookmark[]>([]);
  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>(-1);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [bookmarkSelectIdx, setBookmarkSelectIdx] = useState<number>(-1);
  const [bookmarkFetchBuffer, setBookmarkFetchBuffer] = useState<PostCard[]>([]);
  const [contentScroll, setContentScroll] = useState<boolean>(true)
  const [showChatModal, setShowChatModal] = useState(false);
  const [showStandardProfile, setShowStandardProfile] = useState(false);
  const [chatUser, setChatUser] = useState<ChatUser>({id: 0, name: '', username: '', profilepic: 'picinit'});
  const [bookmarkPostCards, setBookmarkPostCards] = useState<PostCard[]>([]);
  const [catModal, setCatModal] = useState<CatModal>({show: false, idx: 0, user_id: 0})
  const [renameDialog, setRenameDialog] = useState<RenameDialog>({show: false, id: 0, image: '', name: ''})
  const [permissionDialog, setPermissionDialog] = useState<RenameDialog>({show: false, id: 0, image: '', name: ''})
  const [duplicateDialog, setDuplicateDialog] = useState<RenameDialog>({show: false, id: 0, image: '', name: ''})
  const [deleteDialog, setDeleteDialog] = useState<RenameDialog>({show: false, id: 0, image: '', name: ''})
  const [shareDialog, setShareDialog] = useState<RenameDialog>({show: false, id: 0, image: '', name: ''})
  const [moveDialog, setMoveDialog] = useState<MoveDialog>({show: false, bookmark_cats: []})
  const [textSpinner, setTextSpinner] = useState<{show: boolean, label: string}>({show: false, label: ''});

  const [catId, setCatId] = useState<number>(0);
  const catIdRef = useRef<number>(catId);
  const [followingPostCards, setFollowingPostCards] = useState<PostCard[]>([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const bookmarkRef = React.useRef<HTMLInputElement>(null)
  
const [posts, setPosts] = useState<Post[]>([]);
const [postProps, setPostProps] = useState<PostProps>({post_id: 0, tablename: ''});
// const [infPosts, setInfPosts] = useState<Post[]>([]);

const [pageNumber, setPageNumber] = useState(1);
  // const loader = useRef(null);
  const [isHeightFilled, setIsHeightFilled] = useState(false);
  const bookmarksRef = useRef<Bookmark[]>(bookmarks);
  const [disableScroll, setDisableScroll] = useState(false);

  const [cardZIndex, setCardZIndex] = useState<number>(0);
  const [conversationZIndex, setConversationZIndex] = useState<number>(0);
  const [cardNameBookmark, setCardNameBookmark] = useState<string>('');
  const [showContentBookmark, setShowContentBookmark] = useState<boolean>(false);
  const [isPrevBookmark, setIsPrevBookmark] = useState<boolean>(false);
  const [currentIdxBookmark, setCurrentIdxBookmark] = useState<number>(4);
  const [fetchPostBookmark, setFetchPostBookmark] = useState<boolean>(false);
  const [cardNameFollowing, setCardNameFollowing] = useState<string>('');
  const [showContentFollowing, setShowContentFollowing] = useState<boolean>(false);
  const [isPrevFollowing, setIsPrevFollowing] = useState<boolean>(false);
  const [currentIdxFollowing, setCurrentIdxFollowing] = useState<number>(4);
  const [fetchPostFollowing, setFetchPostFollowing] = useState<boolean>(false);
  const arrowClickRef = useRef(false)
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const modal = useRef<Modal>(null);
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [userSelectionId, setUserSelectionId] = useState<number>(0)
  const [sendList, setSendList] = useState<number[]>([]);
  const [checkedState, setCheckedState] = useState<Record<CheckboxNames, boolean>>({
    deleteBookmark: false,
    reorderBookmark: false,
    addBookmark: false
  });
  const [bookmarkLoading, setBookmarkLoading] = useState<boolean>(false);
  const [showCompilePost, setShowCompilePost] = useState<{show: boolean, idx: number}>({show: false, idx: 0});
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.checked);
    setCheckedState({ ...checkedState, [event.target.name as keyof typeof checkedState]: event.target.checked });
  }
  
  
  // const [showModal, setShowModal] = useState<boolean>(false);



  // initialize zIndex when content shown
  useEffect(() => {
    if (showContentFollowing || showContentBookmark) {
      setCardZIndex(getHighestZIndex());
    } 
  }, [showContentFollowing, showContentBookmark])

  useEffect(() => {
    if (props.showBookmark) {
      setConversationZIndex(getHighestZIndex());
    } 
  }, [props.showBookmark])

  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);


    //   web socket setup
    // const ws = useRef<WebSocket>();
    useEffect(() => {
        // if (ws.current && !showChatModal && location.pathname == '/tabs/conversation'){
        if (props.ws.current && !showChatModal){
                console.log('shouldnt enterrrrr')
          refreshConversation()
          // Listening on ws new added messages
          props.ws.current.onmessage = (event) => {
              const data = JSON.parse(event.data);
              console.log('WebSocket message received:', data);
              console.log(catIdRef.current)
              if ((data.type == 'msg' || data.type == 'cmt') && catIdRef.current == 0){
                const index = bookmarksRef.current.findIndex((bookmark: Bookmark) => bookmark.user_id === data.sender);
                console.log(bookmarks)
                if (index > -1) {
                  // const newBookmarks = [...bookmarks.slice(0,index),
                  //   {...bookmarks[index], body: true},
                  //   ...bookmarks.slice(index+1)]
                  var newBookmarks = [...bookmarksRef.current];
                  newBookmarks[index].body = data.text;
                  if (showChatModal && chatUser.id == data.sender){
                    newBookmarks[index].n_comments = 0;
                  } else {
                    newBookmarks[index].n_comments += 1;
                  }
                  newBookmarks = [newBookmarks[index], ...newBookmarks.slice(index==0?1:0,index), ...newBookmarks.slice(index+1)]
                  console.log(index, newBookmarks)
                  setBookmarks(newBookmarks)
                } else {
                  // refresh bookmarks
                  refreshConversation()
                }
              } else {
                const catElem = document.getElementById("cat/0")  as HTMLElement;
                console.log(catElem)
                catElem.className = "bookmark-profile-icon pulse"
                setTimeout(() => {
                  catElem.className = "bookmark-profile-icon"
                }
                , 1000)
              }
          };
        }
      }, [showChatModal])

useEffect(() => {console.log('len', followingPostCards.length)},[followingPostCards])
      
const fetchLockRef = useRef<any>(false);
// FOLLOWING: Insert card to queue
useEffect(() => {
  if (followingPostCards.length>0 && fetchPostFollowing){
    // fetch more post from server every 9 post
    if (currentIdxFollowing == 1 && fetchBuffer.length < 9){
      fetchFollowingPost()
    }
    if (followingPostCards.length == 9){
      // funnel contents from buffer to display and pop last element of display
      let idx = (currentIdxFollowing + 4) % 9 // store previous 3 posts
      let data = fetchBuffer.shift();
      followingPostCards.pop()
      if (data){ // false if end of post reached
        data!.idx = idx
        setFollowingPostCards(oldCards =>
          [data!, ...oldCards]
        )
      } else if (!followingPostCards.map(post => post.idx).includes(currentIdxFollowing) ){
        setTimeout(() => {
          props.map && props.map.dragging.enable();
          props.map && props.map.touchZoom.enable();
          props.map && props.map.doubleClickZoom.enable();
          props.map && props.map.scrollWheelZoom.enable();
          props.map && props.map.boxZoom.enable();
          props.map && props.map.keyboard.enable();
          if (props.map) {
            if (props.map!.tap) props.map!.tap.enable();
          }
          setShowContentFollowing(false)
          setFollowingPostCards([])
          setFetchBuffer([])
        }, 500);
      }
    } else {
      // insert directly from buffer to display
      let data = fetchBuffer.shift();
      if (data){ // false if end of post reached
        data!.idx = (followingPostCards[0].idx == 0) ? 8 : followingPostCards[0].idx - 1
        setFollowingPostCards(oldCards =>
          [data!, ...oldCards]
        )
      }
    }

  } else if (!followingPostCards.map(post => post.idx).includes(currentIdxFollowing) ){
    if (isPrevFollowing) {
      // overshoot first post
      setCurrentIdxFollowing(currentIdxFollowing > 0 ? currentIdxFollowing - 1 : 8)
      setIsPrevFollowing(true)
      toast('This is the first post')
      // toast('已經是第一篇囉')
      return
    }
    setTimeout(() => {
      props.map && props.map.dragging.enable();
      props.map && props.map.touchZoom.enable();
      props.map && props.map.doubleClickZoom.enable();
      props.map && props.map.scrollWheelZoom.enable();
      props.map && props.map.boxZoom.enable();
      props.map && props.map.keyboard.enable();
      if (props.map) {
        if (props.map!.tap) props.map!.tap.enable();
      }
      setShowContentFollowing(false)
      setFollowingPostCards([])
      setFetchBuffer([])
    }, 500);
  }
}, [currentIdxFollowing, fetchPostFollowing]);

// Fetch more post on server every 9 posts; store in buffer
const fetchFollowingPost = async() => {
  fetchLockRef.current = true
  const fetchIdx = selectIdx + fetchBuffer.length + followingPostCards.length
  const deltaId = (followingPosts.length - fetchIdx > 9) ? 9 : followingPosts.length - fetchIdx
  try {
    const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: followingPosts.slice(fetchIdx, fetchIdx+deltaId).map(post => post.id), user_id: state.id})
  });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    response.json().then(res_data => {
      setFetchBuffer([...fetchBuffer, ...res_data])
    })
  } catch (err) {
    console.log(err);
  }
  setSelectIdx(fetchIdx)
  fetchLockRef.current = false
}

    useEffect(() => {
        // Code to execute on component mount (replaces useIonViewDidEnter)
        

        console.log("entered conv");

        // ws.current = new WebSocket(WS_URL);

        // Opening the ws connection
        // ws.current.onopen = () => {
        //     console.log("Connection opened", state.id);
        //     ws.current!.send(
        //         JSON.stringify({
        //             type: 'idle',
        //             user_id: state.id,
        //         })
        //     );
        //     // initPage(props.chatUser.id)
        //     // setConnectionOpen(true);
        // };
        console.log('check', props.ws.current)
        if (props.ws.current){

            // Listening on ws new added messages
            props.ws.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket message received:', data);
                console.log(catIdRef.current)
                if ((data.type == 'msg' || data.type == 'cmt') && catIdRef.current == 0){
                const index = (data.type == 'msg') ?
                    bookmarksRef.current.findIndex((bookmark: Bookmark) => bookmark.user_id === data.sender)
                    : bookmarksRef.current.findIndex((bookmark: Bookmark) => bookmark.id === data.post_id);
                console.log(bookmarks)
                if (index > -1) {
                    // update existing bookmark
                    var newBookmarks = [...bookmarksRef.current];
                    newBookmarks[index].body = data.text;
                    if (showChatModal && chatUser.id == data.sender){
                    newBookmarks[index].n_comments = 0;
                    } else {
                    newBookmarks[index].n_comments += 1;
                    }
                    console.log(index, index==0?1:0,index, newBookmarks.slice(index==0?1:0,index), newBookmarks.slice(0,index))
                    newBookmarks = [newBookmarks[index], ...newBookmarks.slice(0,index), ...newBookmarks.slice(index+1)]
                    setBookmarks(newBookmarks)
                } else {
                    // refresh bookmarks
                    refreshConversation()
                }
                } else {
                const catElem = document.getElementById("cat/0")  as HTMLElement;
                console.log(catElem)
                catElem.className = "bookmark-profile-icon pulse"
                setTimeout(() => {
                    catElem.className = "bookmark-profile-icon"
                }
                , 1000)
                }
            };
        }


        // Cleanup function (replaces useIonViewDidLeave)
        return () => {
            console.log("left conv");
            // if (props.ws.current !== null && props.ws.current !== undefined && props.ws.current.readyState === WebSocket.OPEN) {
            //     console.log("Cleaning up...");
            //     props.ws.current.close();
            // }
            clearMessage();

            dispatch({
                type: "setMsg",
                msg_cnt: 0,
            });
        };
    }, []); // Passing an empty array ensures the effect only runs on mount and cleanup on unmount

    const clearMessage = async() => {
        try {
          const response = await fetch('https://outvirt.com:8080/clearmessage', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: state.token})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        } catch (err) {
          console.log(err);
        }
      }



      const refreshConversation = async() => {
        // fetch bookmarked posts
        setBookmarkLoading(true)
        try {
          const response = await fetch('https://outvirt.com:8080/fetchbookmarkstab', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: state.id, cat_id: catId})
        });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          response.json().then(res_data => {
              // setBookmarks(res_data)
              for (var i=0; i<res_data.length; i++) {
                res_data[i].cat = catId
              }
              setBookmarksContainer(res_data)
              pushInitialData(res_data)
          })
        } catch (err) {
          console.log(err);
        }
        
      setBookmarkLoading(false)
      }


      useEffect(() => {
        bookmarksRef.current = bookmarks;
      }, [bookmarks]);

  const loadBookmark = async(cat_id: number) => {
    setBookmarkLoading(true)
    try {
      const response = await fetch('https://outvirt.com:8080/fetchbookmarkstab', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: state.id, cat_id: cat_id})
    });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
          // setBookmarks(res_data)
          for (var i=0; i<res_data.length; i++) {
            res_data[i].cat = cat_id
          }
          setBookmarksContainer(res_data)
          pushInitialData(res_data)
      })
    } catch (err) {
      console.log(err);
    }
    setBookmarkLoading(false)
  }

  const initPage = async() => {
    console.log('init')
    // fetch following user's new posts
    // try {
    //   const response = await fetch('https://outvirt.com:8080/fetchfollowingposts', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({id: state.id})
    // });
    //   if (!response.ok) {
    //     throw new Error(response.statusText);
    //   }
    //   response.json().then(res_data => {
    //     res_data.sort((a: FollowingPost, b: FollowingPost) => {
    //       // Find the first occurrence of a and b in the array based on profilepic
    //       const aIndex = res_data.findIndex((obj: FollowingPost) => obj.profilepic === a.profilepic);
    //       const bIndex = res_data.findIndex((obj: FollowingPost) => obj.profilepic === b.profilepic);
      
    //       // If a appears before b in the array based on profilepic, sort a before b
    //       if (aIndex < bIndex) return -1;
      
    //       // If b appears before a in the array based on profilepic, sort b before a
    //       if (aIndex > bIndex) return 1;
      
    //       // If both posts have the same profilepic, sort by seen status
    //       if (a.seen === b.seen) return 0;
      
    //       // If post a has not been seen and post b has been seen, sort a before b
    //       if (!a.seen && b.seen) return -1;
      
    //       // If post b has not been seen and post a has been seen, sort b before a
    //       return 1;
    //     });
    //     setFollowingPosts(res_data);
      
    //     // res_data.sort((a: FollowingPost, b: FollowingPost) => {
    //     //   // If a and b are equal, return 0 to preserve their order
    //     //   if (a.id === b.id) return 0;
        
    //     //   // Find the first occurrence of a and b in the array
    //     //   const aIndex = res_data.findIndex((obj: FollowingPost) => obj.profilepic === a.profilepic);
    //     //   const bIndex = res_data.findIndex((obj: FollowingPost) => obj.profilepic === b.profilepic);
        
    //     //   // If a appears before b in the array, return -1 to sort a before b
    //     //   if (aIndex < bIndex) return -1;
        
    //     //   // Otherwise, return 1 to sort b before a
    //     //   return 1;
    //     // });
    //     // setFollowingPosts(res_data)
    //   })
    // } catch (err) {
    //   console.log(err);
    // }
    // fetch bookmark cat
    
    setBookmarkLoading(true)
    try {
      const response = await fetch('https://outvirt.com:8080/fetchbookmarkcat', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: state.id})
    });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res => {
          for (var i=0; i<res.length; i++) {
            res[i].selected = false
          }
          setBookmarkCats([bookmarkCats[0], ...res]) // first item is always recent
      })
    } catch (err) {
      console.log(err);
    }
    // fetch bookmarked posts
    try {
      const response = await fetch('https://outvirt.com:8080/fetchbookmarkstab', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: state.id, cat_id: catId})
    });
    console.log(response)
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
          // setBookmarks(res_data)
          for (var i=0; i<res_data.length; i++) {
            res_data[i].cat = catId
          }
          setBookmarksContainer(res_data)
          pushInitialData(res_data)
          console.log(res_data)
      })
    } catch (err) {
      console.log(err);
    }
    
    setBookmarkLoading(false)
};


const pushInitialData = (initialData: Bookmark[]) => {
    const max = 12;
    const min = max - 12;
    const newData : Bookmark[] = [];
    for (let i = min; i < max && i < initialData.length; i++) {
      newData.push(initialData[i]);
    }
    
    setBookmarks([
      ...newData
    ]);
  }


  
  // initialize zIndex when content shown
  useEffect(() => {
    if (showContentBookmark) {
      setCardNameBookmark(generateRandomString(5))
    } 
  }, [showContentBookmark])
  useEffect(() => {
    if (showContentFollowing) {
      setCardNameFollowing(generateRandomString(5))
    } 
  }, [showContentFollowing])

  useEffect(() => {
    if (props.showBookmark){
      initPage()
    }
}, [props.showBookmark]);


useEffect(() => {
    if (!isHeightFilled && props.showBookmark) {
      console.log('height')
      fetchMorePosts();
    }
  }, [isHeightFilled, props.showBookmark]);
// }, [isHeightFilled, props.showBookmark, bookmarks, bookmarksContainer]);

  
  // Fetch more posts when pageNumber changes
  useEffect(() => {
    if (pageNumber > 1 && props.showBookmark){
      console.log('page')
      fetchMorePosts();
    } 
  }, [pageNumber, props.showBookmark]);
// }, [pageNumber, props.showBookmark, bookmarks, bookmarksContainer]);

  // useEffect(() => {
  //   if (infPosts.length > 0) {
  //     checkIfHeightFilled();
  //   }
  // }, [infPosts]);
  


  useEffect(() => {
    if (bookmarks.length > 0) {
      checkIfHeightFilled();
    }
  }, [bookmarks]);



  useEffect(() => {
    if (disableScroll) {
      const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        e.preventDefault();
        listRef.current.scrollTop = 0;
      };
      listRef.current.addEventListener('scroll', handleScroll);
      return () => listRef.current.removeEventListener('scroll', handleScroll);
    }
  }, [disableScroll]);


// BOOKMARK: Insert card to queue
useEffect(() => {
    if (bookmarkPostCards.length>0 && fetchPostBookmark){
      // fetch more post from server every 9 post
      if (currentIdxBookmark == 1 && bookmarkFetchBuffer.length < 9){
        console.log('fetch more post')
        fetchBookmarkPost()
      }
      if (bookmarkPostCards.length == 9){
        // funnel contents from buffer to display and pop last element of display
        let idx = (currentIdxBookmark + 4) % 9 // store previous 3 posts
        console.log('NOW', currentIdxBookmark, idx)
        let data = bookmarkFetchBuffer.shift();
        
        bookmarkPostCards.pop()
        if (data){ // false if end of post reached
          data!.idx = idx
          setBookmarkPostCards(oldCards =>
            [data!, ...oldCards]
          )
        }
      } else {
        // insert directly from buffer to display
        let data = bookmarkFetchBuffer.shift();
        if (data){ // false if end of post reached
          data!.idx = (bookmarkPostCards[0].idx == 0) ? 8 : bookmarkPostCards[0].idx - 1
          setBookmarkPostCards(oldCards =>
            [data!, ...oldCards]
          )
        } else{
          console.log('nodata', bookmarkPostCards.length)
        }
      }
  
    } else if (!bookmarkPostCards.map(post => post.idx).includes(currentIdxBookmark) ){
      setShowContentBookmark(false)
      // dispatch({ type: "setBookmarkShowContent",
      //   bookmark_show_content: false
      // });
      setBookmarkPostCards([])
      setBookmarkFetchBuffer([])
    }
  }, [currentIdxBookmark, fetchPostBookmark]);
  
  // Fetch more post on server every 9 posts; store in buffer
  const fetchBookmarkPost = async() => {
    const fetchIdx = bookmarkSelectIdx + bookmarkFetchBuffer.length + bookmarkPostCards.length
    const deltaId = (bookmarks.length - fetchIdx > 9) ? 9 : bookmarks.length - fetchIdx
    try {
      const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: bookmarks.slice(fetchIdx, fetchIdx+deltaId).map(post => post.id), user_id: state.id})
    });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        setBookmarkFetchBuffer([...bookmarkFetchBuffer, ...res_data])
      })
    } catch (err) {
      console.log(err);
    }
    setBookmarkSelectIdx(fetchIdx)
  }
  
  
  const updateBookmark = async(catId: number, droppedId: number) => {
    const catIds = bookmarkCats.map((bc) => bc.id)
    // const selectedId = Number(selectedElem.id.substring(selectedElem.id.lastIndexOf("/")+1))
    var image = "recent"
    // update cover photo (if not "recent")
    if (catId!=0){
      const catIdx = catIds.indexOf(catId)
      image = bookmarks[droppedId].photos[0]
      console.log(bookmarkCats, bookmarks)
      setBookmarkCats([...bookmarkCats.slice(0,catIdx),{...bookmarkCats[catIdx], image: image}, ...bookmarkCats.slice(catIdx+1)])
    }

    // update bookmark on server
    try {
        const response = await fetch('https://outvirt.com:8080/updatebookmark', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({user_id: state.id, t_cat: catId, o_cat: bookmarks[droppedId].cat, id: bookmarks[droppedId].id, image: image})
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    } catch (err) {
        console.log(err);
    }
    console.log(catId, bookmarks[droppedId].cat)


  }

  const handleDrop = (droppedId: string | null, event: MouseEvent | TouchEvent) => {
    console.log('12345 ' + droppedId)
    if (droppedId) {
      // Get the element under the draggable item
      const clientX = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX;
      const clientY = event instanceof MouseEvent ? event.clientY : event.changedTouches[0].clientY;
      // const elementUnder = document.elementFromPoint(clientX, clientY) as HTMLElement;
      var hoveredElems = document.elementsFromPoint(clientX, clientY) as HTMLElement[];
      hoveredElems.map(async(hoveredElem) => {
          if (hoveredElem && hoveredElem.id.substring(0, hoveredElem.id.lastIndexOf("/"))=='cat'){
              console.log(`Dropped item with ID: ${droppedId} on element with ID: ${hoveredElem.id}`);
              if (Number(hoveredElem.id.substring(hoveredElem.id.lastIndexOf("/")+1))!=bookmarks[Number(droppedId)].cat){
                  await updateBookmark(Number(hoveredElem.id.substring(hoveredElem.id.lastIndexOf("/")+1)), Number(droppedId))
                  // setBookmarks([])
                  console.log(bookmarks, Number(droppedId), [...bookmarks.slice(0,Number(droppedId)), ...bookmarks.slice(Number(droppedId+1))])
                  setBookmarks([...bookmarks.slice(0,Number(droppedId)), ...bookmarks.slice(Number(droppedId+1))])
                  // setTimeout(function(){
                  //     setBookmarks([])
                  //     setBookmarks([...bookmarks.slice(0,Number(droppedId)), ...bookmarks.slice(Number(droppedId+1))])
                  // }, 500)
              }
          }
      })
    }
  };
  
  // for add category
  
  const [inputOpen, setInputOpen] = useState(false);
  const [inputValueCat, setInputValueCat] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');

  const handleConfirm = async () => {
    if (inputValue.length === 0 || inputValue.length > 16) {
      setInputError('Must be between 1 and 16 characters.');
      return;
    } else {
      setInputError('');
    }
    try {
      const response = await fetch('https://outvirt.com:8080/updatebookmarkcat', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({user_id: state.id, name: inputValue})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      } else{
        response.json().then(res => {
          setBookmarkCats([...bookmarkCats, {id: res.id, image: "empty", name: inputValue, selected: false, user_id: state.id}])
          // setBookmarks([])
          // setOldBookmarks(_.cloneDeep(bookmarks))
        })
      }
      
  } catch (err) {
      console.log(err);
  }
    setInputValue('')
    setInputOpen(false);
  };
  const handlePermissionConfirm = async () => {
    if (inputValue.length === 0 || inputValue.length > 16) {
      setInputError('Must be between 1 and 16 characters.');
      return;
    } else {
      setInputError('');
    }
    try {
      const response = await fetch('https://outvirt.com:8080/updatebookmarkcatpermission', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({user_id: state.id, name: inputValue})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      } else{
        response.json().then(res => {
          setBookmarkCats([...bookmarkCats, {id: res.id, image: "empty", name: inputValue, selected: false, user_id: state.id}])
          // setBookmarks([])
          // setOldBookmarks(_.cloneDeep(bookmarks))
        })
      }
      
  } catch (err) {
      console.log(err);
  }
    setInputValue('')
    setInputOpen(false);
  };

  const compilePost = async(text: string|undefined, locName: string|undefined, websiteText: string|undefined, labels: string[]|undefined, tagList: Tag[]|undefined, subbody: string[]|undefined, post_ids: number[]|undefined, post_tablenames: string[]|undefined, blob: Blob|undefined, url: string|undefined) => {
    setTextSpinner({show: true, label: t('uploading')})
    let formData = new FormData();
    const parts = url!.split("/");
    const photo = parts[parts.length-1]
    formData.append('photo', blob!  , photo);
    var obj_type;
    if (blob!.type.startsWith("image/")) {
      console.log("This is an image");
      obj_type = false
    } else if (blob!.type.startsWith("video/")) {
      console.log("This is a video");
      obj_type = true
    }
    
    try {
        const response = await fetch('https://outvirt.com:8080/upload', {
          method: 'POST',
          body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      console.log(response);
    } catch (err) {
      console.log(err);
    }

    console.log('phpto', photo)

    try{
      const response = await fetch('https://outvirt.com:8080/compilepost', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: state.token, body: text, photo: photo, locName: locName, url: websiteText, labels: labels, tagList: tagList, subbody: subbody, post_ids: post_ids, post_tablenames: post_tablenames, type: obj_type})
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        if (response.ok){
          toast.success('Post created successfully!')
        }
    } catch (err) {
        console.log(err);
    }

    setTextSpinner({show: false, label: ''})
  }

  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};



const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};




  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };

  // posts
  const pushData = (curData: Bookmark[]) => {
    const max = bookmarks.length + 12;
    const min = max - 12;
    const newData : Bookmark[] = [];
    for (let i = min; i < max && i < curData.length; i++) {
      newData.push(curData[i]);
    }
    setBookmarks([
      ...bookmarks,
      ...newData
    ]);
  }


  // Function to load more posts
  const fetchMorePosts = async () => {
    console.log('fetching more posts')
    setTimeout(async() => {
      if (bookmarks.length + 12 > bookmarksContainer.length) {
        try {
          const response = await fetch('https://outvirt.com:8080/fetchmorebookmarkstab', {
              method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: state.id, lastId: (bookmarksContainer.length==0 ? -1 : bookmarksContainer[bookmarksContainer.length - 1].id), cat_id: catId})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        response.json().then(res_data => {
          if (res_data.length > 0){
            for (var i=0; i<res_data.length; i++) {
              res_data[i].cat = catId
            }
            console.log('testcontainer', bookmarksContainer, res_data)
            setBookmarksContainer([...bookmarksContainer, ...res_data])
            pushData([...bookmarksContainer, ...res_data]);
          }
          })
        } catch (err) {
          console.log(err);
        }
      } else {
        pushData(bookmarksContainer);
      }
      // ev.target.complete();
      // if (bookmarks.length === 1000) {
      //   setInfiniteDisabled(true);
      // }
    }, 500);
  };


  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    const tolerance = 5;
  if (scrollHeight - scrollTop <= clientHeight + tolerance) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (getHighestZIndex()==Number(dialogRef.current!.style.zIndex)){
        setTimeout(function(){
          props.setShowBookmark(false)
        }, 200);
          
      }
      // if (state.showSinglePost){
      //   // content card open in chat room
      //   return
      // } else {
      //   if (showContent){
      //     // close content card
      //       ref.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
      //       ref.current!.style.transform = 'scale(0)';
      //       ref.current!.style.opacity = '0';
      //         setTimeout(function(){
      //           setShowContent(false)
      //             // dispatch({ type: "setBookmarkShowContent",
      //             // bookmark_show_content: false
      //             // });
      //         }, 200);
      //   } else{
      //     props.setShowBookmark(false)
      //     // dispatch({ type: "setShowBookmark", showBookmark: false});
      //   }
      // }
    }
  };

  const useStyles = makeStyles((theme) => ({
    bookmarkFriends: {
      display: "flex",
      overflowX: "scroll",
      overflowY: "hidden",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      marginBottom: "12px",
      width: 'calc(100% - 70px)',
    },
    parentContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
    },
    noScrollbar: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    darkButton: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      right: '20px', // adjust this value to position the button
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      color: theme.palette.primary.contrastText,
    },
    darkButtonOrder: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      // position: 'absolute',
      // right: '20px', // adjust this value to position the button
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      color: theme.palette.primary.contrastText,
    },
    selectedItem: {
      backgroundColor: '#000000',
      borderRadius: '10px',
    }
}));


  // const useStyles = makeStyles((theme) => ({
  //   bookmarkFriends: {
  //     // maxWidth: 'calc(100% - 50px)',
  //     maxWidth: '500px',
  //     display: "flex",
  //     overflowX: "scroll",
  //     msOverflowStyle: "none",
  //     scrollbarWidth: "none",
  //     marginBottom: "12px"
  //   },
  //   parentContainer: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     alignItems: 'center',
  //     backgroundColor: "#123456",
  //     width: "100%"
  //   },
  //   noScrollbar: {
  //     "&::-webkit-scrollbar": {
  //       display: "none",
  //     },
  //   },
  //   darkButton: {
  //     width: '40px',
  //     height: '40px',
  //     borderRadius: '50%',
  //     backgroundColor: theme.palette.primary.main,
      
  //     '&:hover': {
  //       backgroundColor: theme.palette.primary.dark,
  //     },
  //     color: theme.palette.primary.contrastText,
  //   },
  //   selectedItem: {
  //     backgroundColor: theme.palette.action.selected,
  //   }
  // }));
  const classes = useStyles();

  // handles to move contents up and down on the bookmark dialog
  const handleMoveUp = () => {
    setMoveDialog((prev) => {
      const newBookmarkCats = [...prev.bookmark_cats];
      const selectedIndex = newBookmarkCats.findIndex((item) => item.selected);
  
      if (selectedIndex > 0) {
        const temp = newBookmarkCats[selectedIndex - 1];
        newBookmarkCats[selectedIndex - 1] = newBookmarkCats[selectedIndex];
        newBookmarkCats[selectedIndex] = temp;
      }
  
      return { ...prev, bookmark_cats: newBookmarkCats };
    });
  };
  
  const handleMoveDown = () => {
    setMoveDialog((prev) => {
      const newBookmarkCats = [...prev.bookmark_cats];
      const selectedIndex = newBookmarkCats.findIndex((item) => item.selected);
  
      if (selectedIndex >= 0 && selectedIndex < newBookmarkCats.length - 1) {
        const temp = newBookmarkCats[selectedIndex + 1];
        newBookmarkCats[selectedIndex + 1] = newBookmarkCats[selectedIndex];
        newBookmarkCats[selectedIndex] = temp;
      }
  
      return { ...prev, bookmark_cats: newBookmarkCats };
    });
  };
  
  // updates user's bookmark categories
  const updateUserBookmark = async(bookmarkList: number[]) => {
    try {
        const response = await fetch('https://outvirt.com:8080/updateuserbookmark', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id: state.id, bookmarks: bookmarkList})
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    } catch (err) {
        console.log(err);
    }
}


  return (
    <div>
     
      {
        (showContentFollowing) && (
          
            
          <div ref={followingRef}
            style={{
              height: `${viewportHeight}px`,
              zIndex: cardZIndex + 2
            }} 
            className="cards-stack-container">
              {
                followingPostCards.map((post, idx) =>{
                  return <ContentCard key={post.id} {...{ ...post, card_name: cardNameFollowing, cardsRef: followingRef, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdxFollowing, setCurrentIdx: setCurrentIdxFollowing, isPrev: isPrevFollowing, setIsPrev: setIsPrevFollowing, setShowContent: setShowContentFollowing, setFetchPost: setFetchPostFollowing, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef }} />
                  // return <ContentCard  key={post.id} {...{...post, card_name: "follow", cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                }
                )
              }
            </div>
        )
      }

      {
        (showContentBookmark) && (
          
            <div ref={bookmarkRef}
              style={{
                height: `${viewportHeight}px`,
                zIndex: cardZIndex + 2
              }} 
             className="cards-stack-container">
              {
                bookmarkPostCards.map((post, idx) =>{
                  return <ContentCard key={post.id} {...{ ...post, card_name: cardNameBookmark, cardsRef: bookmarkRef, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdxBookmark, setCurrentIdx: setCurrentIdxBookmark, isPrev: isPrevBookmark, setIsPrev: setIsPrevBookmark, setShowContent: setShowContentBookmark, setFetchPost: setFetchPostBookmark, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef }} />
                  // return <ContentCard  key={post.id} {...{...post, card_name: "bookmark", cardsRef: bookmarkRef, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                }
                )
              }
            </div>
        )
      }
      {/* <ChatRoom {...{chatUser: chatUser, chatModal: chatModal, showChatModal: showChatModal, setShowChatModal: setShowChatModal, webSocket: ws.current}} /> */}
      {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={chatUser.id} map={props.map}/> }
      {/* {showStandardProfile && <StandardProfile/> } */}
      <PostContent {...{map: props.map, showPostModal: showPostModal, setShowPostModal: setShowPostModal, post_id: postProps?.post_id, tablename: postProps?.tablename, webSocket: props.ws.current}} key={postProps?.post_id}/>
        {showChatModal && <ChatRoom showChatModal={showChatModal} setShowChatModal={setShowChatModal} chatUser={chatUser} webSocket={props.ws.current}/> }
      {/* <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={chatUser.id}/> */}
      {
        textSpinner.show &&
        <div className="spinner-container-post" style={{ zIndex: getHighestZIndex() + 2 }}>
          <img src="assets/icon.svg" alt="loading" className="spinner-post" />
          <span className="spinner-text">{textSpinner.label}</span>
        </div>
      }
      

    <Dialog  
      ref={dialogRef}
      className="dark-dialog"
      open={props.showBookmark}
      onClose={() => props.setShowBookmark(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: conversationZIndex + 1 }}
    >

      

     
 {/* compile post */}
 {
        showCompilePost.show &&
        <CompilePost 
            id={showCompilePost.idx}
            onComplete={async(upload: boolean, text: string|undefined, locName: string|undefined, websiteText: string|undefined, labels: string[]|undefined, tagList: Tag[]|undefined, subbody: string[]|undefined, post_ids: number[]|undefined, post_tablenames: string[]|undefined, blob: Blob|undefined, url: string|undefined) => {
              if (upload){
                compilePost(text, locName, websiteText, labels, tagList, subbody, post_ids, post_tablenames, blob, url)
                setShowCompilePost({show: false, idx: -1})
              } else {
                setShowCompilePost({show: false, idx: -1})
              }
            }}
          />
      }


        {/* user selection (for share) */}
        <div className={`usershow-container-conv ${showUserSelection ? 'show' : ''}`} style={{zIndex: conversationZIndex+2}}>
      <SearchPeopleSelect setSendList={setSendList} sendList={sendList}/>
      <Button
            variant="contained"
            className="userselection-icon"
            onClick={async () => {
              setShowUserSelection(false)
              try {
                const response = await fetch('https://outvirt.com:8080/sendpost', {
                    method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({sender: state.id, receipients: sendList, text: `/group=${userSelectionId}`})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else{
                  toast.success(t('sent'))
                }
              } catch (err) {
                console.log(err);
              }
              setSendList([])
              setUserSelectionId(0)
            }}
            // startIcon={<CloseIcon />}
          >
            Send
          </Button>
    </div>
      
      
      <Modal
        isOpen={catModal.show}
        onRequestClose={() => setCatModal({show: false, idx: 0, user_id: 0})}
        className="Modal"
        overlayClassName="modal-overlay"
        ref={modal}
        style={{
          overlay: {
            zIndex: conversationZIndex + 2,
            // add other styles for the overlay here
          },
          content: {
            zIndex: conversationZIndex + 2,
            // add styles for the content here
          },
        }}
      >
        <div className="modal-content">
          
            <div className="list-item" onClick={async() => {
              const bookmarkCat = bookmarkCats.find((cat) => cat.id === catModal.idx);
              if (bookmarkCat){
                setDeleteDialog({show: true, id: catModal.idx, name: bookmarkCat.name, image: bookmarkCat.image})
              }
              setCatModal({show: false, idx: 0, user_id: 0})

          }}>
            <div className="list-icon"> <DeleteOutline/> </div>
            <div className="list-text unselectable">
                <h5>{t("options.delete")}</h5>
                <p>{t("options.deletecat")}</p>
            </div>
          </div>


          <div className="list-item" onClick={async() => {
            setShowCompilePost({show: true, idx: catModal.idx})
            setCatModal({show: false, idx: 0, user_id: 0})
          }}>
              <div className="list-icon"> <ListAltIcon/> </div>
              <div className="list-text unselectable">
              <h5>{t("options.compile")}</h5>
              <p>{t('options.compilecat')}</p>
              </div>
          </div>
          
          <div className="list-item" onClick={async() => {
            const moveDialogBookmarkCats = bookmarkCats.map((cat) => ({
              ...cat,
              selected: cat.id === catModal.idx,
            }));
          
            setMoveDialog({
              show: true,
              bookmark_cats: moveDialogBookmarkCats.slice(1),
            });
            setCatModal({show: false, idx: 0, user_id: 0})
          }}>
              <div className="list-icon"> <SwapHorizIcon/> </div>
              <div className="list-text unselectable">
              <h5>{t("options.move")}</h5>
              <p>{t('options.movecat')}</p>
              </div>
          </div>
          
          {
            catModal.user_id === state.id &&
              <div className="list-item" onClick={async() => {
                const moveDialogBookmarkCats = bookmarkCats.map((cat) => ({
                  ...cat,
                  selected: cat.id === catModal.idx,
                }));
              
                setMoveDialog({
                  show: true,
                  bookmark_cats: moveDialogBookmarkCats.slice(1),
                });
                setCatModal({show: false, idx: 0, user_id: 0})
              }}>
                  <div className="list-icon"> <MoveDownIcon/> </div>
                  <div className="list-text unselectable">
                  <h5>{t("options.reorder")}</h5>
                  <p>{t('options.reordercat')}</p>
                  </div>
              </div>
          }
          
          {
            catModal.user_id === state.id &&
              <div className="list-item" onClick={async() => {
                  const bookmarkCat = bookmarkCats.find((cat) => cat.id === catModal.idx);
                  if (bookmarkCat){
                    setPermissionDialog({show: true, id: catModal.idx, name: bookmarkCat.name, image: bookmarkCat.image})
                  }
                  setCatModal({show: false, idx: 0, user_id: 0})
              }}>
                  <div className="list-icon"> <CheckBoxIcon/> </div>
                  <div className="list-text unselectable">
                  <h5>{t("options.permission")}</h5>
                  <p>{t('options.permissioncat')}</p>
                  </div>
              </div>
        }
          {
            catModal.user_id === state.id ? 
              <div className="list-item" onClick={async() => {
                  const bookmarkCat = bookmarkCats.find((cat) => cat.id === catModal.idx);
                  if (bookmarkCat){
                    setRenameDialog({show: true, id: catModal.idx, name: bookmarkCat.name, image: bookmarkCat.image})
                  }
                  setCatModal({show: false, idx: 0, user_id: 0})
              }}>
                  <div className="list-icon"> <DriveFileRenameOutlineIcon/> </div>
                  <div className="list-text unselectable">
                  <h5>{t("options.rename")}</h5>
                  <p>{t('options.renamecat')}</p>
                  </div>
              </div>
              :
              <div className="list-item" onClick={async() => {
                const bookmarkCat = bookmarkCats.find((cat) => cat.id === catModal.idx);
                if (bookmarkCat){
                  setDuplicateDialog({show: true, id: catModal.idx, name: bookmarkCat.name, image: bookmarkCat.image})
                }
                setCatModal({show: false, idx: 0, user_id: 0})
            }}>
                <div className="list-icon"> <ContentCopyIcon/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.duplicate")}</h5>
                <p>{t('options.duplicatecat')}</p>
                </div>
            </div>
        }
          
            

            <div className="list-item" onClick={async() => {
              const bookmarkCat = bookmarkCats.find((cat) => cat.id === catModal.idx);
              if (bookmarkCat){
                setShareDialog({show: true, id: catModal.idx, name: bookmarkCat.name, image: bookmarkCat.image})
              }
              setCatModal({show: false, idx: 0, user_id: 0})
            }}>
                <div className="list-icon"> <ShareIcon/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.share")}</h5>
                <p>{t('options.sharecat')}</p>
                </div>
            </div>
            


        </div>
        <div className="fade-in-anim">
            <button onClick={() => {
              setCatModal({show: false, idx: 0, user_id: 0})
            }}>
                <div className='close-text unselectable'>
                    <p>
                    Close
                    </p>

                    </div>
                
            </button>
        </div>

      </Modal>

      {/* Bookmark permissions */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={permissionDialog.show} onClose={() => setPermissionDialog({show: false, id: 0, name: '', image: ''})}>
        <DialogTitle className="dark-dialog-title">Other users can:</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          {/* ... existing code ... */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                src={`https://outvirt.com/assets/img/${permissionDialog.image}`}
                style={{ borderRadius: "50%" }}
              />
            </Grid>
            <Grid item xs>
              <Typography>{permissionDialog.name}</Typography>
            </Grid>
          </Grid>
          <List>
            <ListItem dense button onChange={handleCheckboxChange}>
              <input type="checkbox" checked={checkedState.deleteBookmark} name="deleteBookmark" onChange={handleCheckboxChange}/>
              <ListItemText primary={'Delete bookmark'} />
            </ListItem>
            <ListItem dense button>
              <input type="checkbox" checked={checkedState.reorderBookmark} name="reorderBookmark" onChange={handleCheckboxChange}/>
              <ListItemText primary={'Re-order bookmark'} />
            </ListItem>
            <ListItem dense button>
              <input type="checkbox" checked={checkedState.addBookmark} name="addBookmark" onChange={handleCheckboxChange}/>
              <ListItemText primary={'Add bookmark'} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setPermissionDialog({show: false, id: 0, name: '', image: ''})} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePermissionConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>


      {/* Create new bookmark category */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={inputOpen} onClose={() => setInputOpen(false)}>
        <DialogTitle className="dark-dialog-title">Create new category</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <TextField
            autoFocus
            margin="dense"
            label="Category name"
            type="text"
            fullWidth
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{ style: { color: 'white' } }}
            helperText={inputError}
            error={inputError !== ''}
          />
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setInputOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Move bookmark categories */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={moveDialog.show} onClose={() => setMoveDialog({show: false, bookmark_cats: []})}>
        <DialogTitle className="dark-dialog-title">Move</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={8}>
              {/* List with photo and name */}
              <List>
                {/* Map over your data to create list items */}
                {moveDialog.bookmark_cats.map((item) => (
                  <ListItem key={item.id} className={item.selected ? classes.selectedItem : ''}>
                    <ListItemAvatar>
                      <Avatar src={`https://outvirt.com/assets/img/${item.image}`} />
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems="center"  justifyContent="center" spacing={2}>
                <Grid item>
                  {/* Up button */}
                  <IconButton className={classes.darkButtonOrder} onClick={handleMoveUp}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  {/* Down button */}
                  <IconButton className={classes.darkButtonOrder} onClick={handleMoveDown}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setMoveDialog({show: false, bookmark_cats: []})} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{
            // update the bookmark categories on server
            updateUserBookmark(moveDialog.bookmark_cats.map((nbc)=> nbc.id))
            // update UI
            //// Reorder the bookmarkCats, excluding the one with index 0
            const reorderedCats = bookmarkCats
            .slice(1)
            .sort((a, b) => {
              const aIndex = moveDialog.bookmark_cats.findIndex(cat => cat.id === a.id);
              const bIndex = moveDialog.bookmark_cats.findIndex(cat => cat.id === b.id);
              return aIndex - bIndex;
            });
            //// Update bookmarkCats, keeping the first element (index 0) unchanged
            setBookmarkCats([bookmarkCats[0], ...reorderedCats]);

            // close dialog
            setMoveDialog({show: false, bookmark_cats: []})
          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      
{/* Rename bookmark category */}
<Dialog style={{ zIndex: conversationZIndex + 2 }} open={renameDialog.show} onClose={() => setRenameDialog({show: false, id: 0, name: '', image: ''})}>
  <DialogTitle className="dark-dialog-title">Rename</DialogTitle>
  <DialogContent className={`dark-dialog-inputbox-content`}>
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Avatar
          src={`https://outvirt.com/assets/img/${renameDialog.image}`}
          style={{ borderRadius: "50%" }}
        />
      </Grid>
      <Grid item xs>
        <Typography>{renameDialog.name}</Typography>
        <TextField
          autoFocus
          margin="dense"
          label="New category name"
          type="text"
          fullWidth
          value={inputValueCat}
          onChange={(e) => setInputValueCat(e.target.value)}
          InputProps={{ style: { color: 'white' } }}
          helperText={inputError}
          error={inputError !== ''}
        />
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions className={`dark-dialog-inputbox-content`}>
    <Button onClick={() => setRenameDialog({show: false, id: 0, name: '', image: ''})} color="primary">
      Cancel
    </Button>
    <Button onClick={async() => {
      // handle overly short or long input
      if (inputValueCat.length === 0 || inputValueCat.length > 16) {
        setInputError('Must be between 1 and 16 characters.');
        return;
      } else {
        setInputError('');
      }

      try {
        const response = await fetch('https://outvirt.com:8080/updatecatname', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: renameDialog.id, name: inputValueCat, user_id: state.id })
        });
  
        if (!response.ok) throw new Error(response.statusText);
        else {
          setBookmarkCats((prev) => {
            const newCats = [...prev];
            const catIdx = newCats.findIndex((cat) => cat.id === renameDialog.id);
            if (catIdx !== -1) {
              newCats[catIdx].name = inputValueCat;
            }
            return newCats;
          });
          setRenameDialog({show: false, id: 0, name: '', image: ''})
        }
      } catch (err) {
        console.log(err);
      }
    }} color="primary">
      Confirm
    </Button>
  </DialogActions>
</Dialog>


      
      {/* Duplicate bookmark category */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={duplicateDialog.show} onClose={() => setDuplicateDialog({show: false, id: 0, name: '', image: ''})}>
        <DialogTitle className="dark-dialog-title">Duplicate</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                src={`https://outvirt.com/assets/img/${duplicateDialog.image}`}
                style={{ borderRadius: "50%" }}
              />
            </Grid>
            <Grid item xs>
              <Typography>{duplicateDialog.name}</Typography>
              <TextField
                autoFocus
                margin="dense"
                label="New category name"
                type="text"
                fullWidth
                value={inputValueCat}
                onChange={(e) => setInputValueCat(e.target.value)}
                InputProps={{ style: { color: 'white' } }}
                helperText={inputError}
                error={inputError !== ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setDuplicateDialog({show: false, id: 0, name: '', image: ''})} color="primary">
            Cancel
          </Button>
          <Button onClick={async() => {
            // handle overly short or long input
            if (inputValueCat.length === 0 || inputValueCat.length > 16) {
              setInputError('Must be between 1 and 16 characters.');
              return;
            } else {
              setInputError('');
            }

            try {
              const response = await fetch('https://outvirt.com:8080/duplicatecat', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: duplicateDialog.id, name: inputValueCat, user_id: state.id })
              });
        
              if (!response.ok) throw new Error(response.statusText);
              else {
                setBookmarkCats((prev) => {
                  var newCats = [...prev];
                  const catIdx = newCats.findIndex((cat) => cat.id === duplicateDialog.id);
                  if (catIdx !== -1) {
                    newCats.push(newCats[catIdx])
                    newCats[newCats.length-1].name = inputValueCat;
                    // newCats[catIdx].name = inputValueCat;
                  }
                  return newCats;
                });
                setDuplicateDialog({show: false, id: 0, name: '', image: ''})
              }
            } catch (err) {
              console.log(err);
            }
          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      
      {/* Delete bookmark category */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={deleteDialog.show} onClose={() => setDeleteDialog({show: false, id: 0, name: '', image: ''})}>
        <DialogTitle className="dark-dialog-title">Delete</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                src={`https://outvirt.com/assets/img/${deleteDialog.image}`}
                style={{ borderRadius: "50%" }}
              />
            </Grid>
            <Grid item xs>
              <Typography>{deleteDialog.name}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setDeleteDialog({show: false, id: 0, name: '', image: ''})} color="primary">
            Cancel
          </Button>
          <Button onClick={async() => {
            const updatedBookmarkCats = bookmarkCats.filter(cat => cat.id !== deleteDialog.id);
            // update the bookmark categories on server
            updateUserBookmark(updatedBookmarkCats.slice(1).map((nbc)=> nbc.id))
            // update UI
            setBookmarkCats(updatedBookmarkCats);
            // close dialog
            setDeleteDialog({show: false, id: 0, name: '', image: ''})
          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Share bookmark category */}
      <Dialog style={{ zIndex: conversationZIndex + 2 }} open={shareDialog.show} onClose={() => setShareDialog({show: false, id: 0, name: '', image: ''})}>
        <DialogTitle className="dark-dialog-title">Share</DialogTitle>
        <DialogContent className={`dark-dialog-inputbox-content`}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                src={`https://outvirt.com/assets/img/${shareDialog.image}`}
                style={{ borderRadius: "50%" }}
              />
            </Grid>
            <Grid item xs>
              <Typography>{shareDialog.name}</Typography>
            </Grid>
          </Grid>
          <div>
          <IconButton color="primary" onClick={async() => {
            setShowUserSelection(true)
            setUserSelectionId(shareDialog.id)
            setShareDialog({show: false, id: 0, name: '', image: ''})
          }}>
            <div  className="share-button">
              <SendIcon/> &nbsp;Send to user
            </div>
          </IconButton>
          <IconButton color="primary"  onClick={async() => {
            try {
              if (navigator.share) {
                await navigator.share({
                  title: 'Outvirt',
                  text: shareDialog.name,
                  url: `https://outvirt.com/map?group=${shareDialog.id}`,
                });
                console.log('Content shared');
              } else {
                await navigator.clipboard.writeText(`https://outvirt.com/map?group=${shareDialog.id}`);
                toast('Copied to clipboard', {type: 'success', autoClose: 2000})
                console.log('Share not supported, copied to clipboard instead');
              }
            } catch (err) {
              console.log('Failed to share or copy: ', err);
            }
            setShareDialog({show: false, id: 0, name: '', image: ''})
          }}>
            <div  className="share-button">
            <LinkIcon/> &nbsp;Share link
            </div>
          </IconButton>

        </div>
        </DialogContent>
      </Dialog>



      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <BookmarkIcon/>
            &nbsp;&nbsp;{t('bookmark.title')}
        </div>
        <IconButton color="inherit" onClick={() => props.setShowBookmark(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent
  // style={{ padding: "10px", display: "flex", maxWidth: '100%', flexWrap: 'wrap', overflowX: 'hidden' }}
  style={{ padding: "10px", width: '100%', overflowX: 'hidden' }}

  
  // style={{ padding: "10px", display: "flex"}}
          // style={{ padding: "10px", overflowY: disableScroll ? "hidden" : "auto"}}
          ref={listRef}
          className={`dark-dialog-content`}
          onScroll={handleScroll}
      >
      <div>
        <Grid container>
          <Box className={`${classes.parentContainer}`}>
            <Box className={`${classes.bookmarkFriends} ${classes.noScrollbar}`}>
            {bookmarkCats.map((bookmarkCat, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                      setCatId(bookmarkCat.id)
                      catIdRef.current = bookmarkCat.id
                      loadBookmark(bookmarkCat.id)
                      bookmarkCats.map((bc, si)=>{
                        if (bc.selected){
                          if (si < idx){
                            setBookmarkCats([...bookmarkCats.slice(0,si),{...bc, selected: false}, ...bookmarkCats.slice(si+1,idx), {...bookmarkCats[idx], selected: true}, ...bookmarkCats.slice(idx+1)])
                          } else if (si > idx) {
                            setBookmarkCats([...bookmarkCats.slice(0,idx),{...bookmarkCats[idx], selected: true}, ...bookmarkCats.slice(idx+1,si), {...bc, selected: false}, ...bookmarkCats.slice(si+1)])
                          }
                        }
                      })
                  }}
                >
                  <BookmarkCat
                    key={idx}
                    {...{
                      id: bookmarkCat.id,
                      name: bookmarkCat.name,
                      selected: bookmarkCat.selected,
                      image: bookmarkCat.image,
                      idx: idx,
                      setCatModal: setCatModal,
                      user_id: bookmarkCat.user_id
                    }}
                  />
                </div>
              );
            })}
          </Box>
          <IconButton
            className={classes.darkButton}
            // variant="contained"
            // color="primary"
            // startIcon={<AddIcon />}
            onClick={() => {
              setInputOpen(true)
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>

          <Grid item xs={12}>
            {
              bookmarkLoading ?
              <div className="spinner-container-child"><img src="assets/icon.svg" alt="loading" className="spinner" /></div>
              // <div className="spinner-container-child" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>
              :
                bookmarks && bookmarks.map((bookmark, idx) => {
                    return (
                      <ConversationItem  key={idx} idx={idx} setBookmarkSelectIdx={setBookmarkSelectIdx}
                          setBookmarkFetchBuffer={setBookmarkFetchBuffer} setBookmarkPostCards={setBookmarkPostCards}
                          bookmarks={bookmarks} setBookmarks={setBookmarks} bookmarkCats={bookmarkCats} setBookmarkCats={setBookmarkCats}
                          setContentScroll={setContentScroll} setPostProps={bookmarks[idx].tablename=="chat"?undefined:setPostProps}
                          setShowPostModal={bookmarks[idx].tablename=="chat"?undefined:setShowPostModal}
                          setChatUser={bookmarks[idx].tablename=="chat"?setChatUser:undefined}
                          setShowChatModal={bookmarks[idx].tablename=="chat"?setShowChatModal:undefined}
                          setShowStandardProfile={bookmarks[idx].tablename=="chat"?setShowStandardProfile:undefined}
                          setDisableScroll={setDisableScroll}
                          handleDrop={handleDrop}
                          setCurrentIdxBookmark={setCurrentIdxBookmark}
                          setIsPrevBookmark={setIsPrevBookmark}
                          setShowContentBookmark={setShowContentBookmark}
                          map={props.map}
                        />
                      )
                      })
            }
          </Grid>
        </Grid>
      </div>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default Bookmark;
