export const getHighestZIndex = () => {
    const elements = document.getElementsByTagName('*');
    let highestZIndex = 0;
    for (let i = 0; i < elements.length; i++) {
      const zIndex = parseInt(window.getComputedStyle(elements[i]).getPropertyValue('z-index'), 10);
      if (!isNaN(zIndex) && zIndex > highestZIndex) {
        highestZIndex = zIndex;
      }
    }
    return highestZIndex;
  };
  