
import L from "leaflet";


export const reqNameId = (nx: number, ny: number) => {
    var nx_str;
    var ny_str;
    if (nx>=0){
      nx_str = 'e' + String(nx);
    } else {
      nx_str = 'w' + String(Math.abs(nx));
    }
    if (ny>=0){
      ny_str = 'n' + String(ny);
    } else {
      ny_str = 's' + String(Math.abs(ny));
    }
    return nx_str + '_' + ny_str;
  }
  
  
export function deg2rad(deg: number) {
    return deg * (Math.PI/180)
  }
  
export const getNumberOfHexFromDistance = (x: number, y: number) => {
    var size = 1; // 1 km
    var ny = Math.floor(y / (3 * size / 2))
    if (y % 2 == 0)
        var nx = Math.floor(x / (Math.sqrt(3) * size))
    else
        var nx = Math.floor((x - Math.sqrt(3)*size*0.5)  / (Math.sqrt(3) * size))
    return [nx, ny]
  }
  
  
export  function rad2deg(rad: number) {
    return rad * (180/Math.PI)
  }
  
export const getLatLonFromDistanceInKm = (dx: number, dy: number) => {
    var R = 6371; // Radius of the earth in km
    var lat = dy / R;
    var lon = dx / R;
    var lat = rad2deg(lat);  // deg2rad below
    var lon = rad2deg(lon); 
    return [lat, lon];
  }
  
export const  getNearestHexIdFromLatLon = (lat: number, lon: number) => {
    var size = 1; // 1 km
    const [x, y] = getDistanceFromLatLonInKm(lat, lon);
    const [n_x, n_y] = getNumberOfHexFromDistance(x, y);
    var d1_coord = hexCenterFromId(n_x, n_y);
    var d2_coord = hexCenterFromId(n_x+1, n_y);
    if (n_y % 2 == 0)
      var d3_coord = hexCenterFromId(n_x, n_y+1);
    else
      var d3_coord = hexCenterFromId(n_x+1, n_y+1);
    var dist_1 = (x- d1_coord[0])**2 + (y-d1_coord[1])**2
    var dist_2 = (x-d2_coord[0])**2 + (y-d2_coord[1])**2
    var dist_3 = (x-d3_coord[0])**2 + (y-d3_coord[1])**2
  
    if (dist_1 < dist_2 && dist_1 < dist_3){
      const [hLat, hLon] = getLatLonFromDistanceInKm(d1_coord[0], d1_coord[1])
      return [n_x, n_y, hLat, hLon];
    }
    else if (dist_2 < dist_1 && dist_2 < dist_3){
      const [hLat, hLon] = getLatLonFromDistanceInKm(d2_coord[0], d2_coord[1])
      return [n_x+1, n_y, hLat, hLon]
    }
    else if (dist_3 < dist_1 && dist_3 < dist_2){
      const [hLat, hLon] = getLatLonFromDistanceInKm(d3_coord[0], d3_coord[1])
      if (n_y % 2 == 0)
        return [n_x, n_y+1, hLat, hLon]
      else
        return [n_x+1, n_y+1, hLat, hLon]
    }
    else
        return [0, 0, 0, 0]
  }
  
  export  const hexCenterFromId = (x: number, y: number) => {
    var size = 1; // 1 km
    if (y % 2 == 0)
        x = x * (Math.sqrt(3) * size);
    else
        x = x * (Math.sqrt(3) * size) + Math.sqrt(3)*size*0.5;
    y = y * (3 * size / 2)
    return [x, y]
  }
  
  export  const getDistanceFromLatLonInKm = (lat: number, lon: number) => {
    var R = 6371; // Radius of the earth in km
    var lat_r = deg2rad(lat);  // deg2rad below
    var lon_r = deg2rad(lon); 
    var dx = R * lon_r;
    var dy = R * lat_r;
    return [dx, dy];
  }
  
export  const getNearestHexIdsFromLatLon = ([lat, lon]: [number, number]) => {
    var size = 1; // 1 km
    const [idx, idy, hLat, hLon]  = getNearestHexIdFromLatLon(lat, lon);
    const oddr_direction_differences = [
      // even rows 
      [[+1,  0], [ 0, -1], [-1, -1], 
      [-1,  0], [-1, +1], [ 0, +1]],
      // odd rows 
      [[+1,  0], [+1, -1], [ 0, -1], 
      [-1,  0], [ 0, +1], [+1, +1]],
    ]
  
    const neighbor_diff = oddr_direction_differences[idy%2];
    const [x, y] = getDistanceFromLatLonInKm(lat, lon);
  
    const dist: number[] = [];
    const hid: any[] = [];
    for (const diff of neighbor_diff){
      const cur_x = idx + diff[0];
      const cur_y = idy + diff[1];
      const [h_x, h_y] = hexCenterFromId(cur_x, cur_y);
      dist.push((x- h_x)**2 + (y-h_y)**2);
      hid.push([cur_x, cur_y]);
      console.log(cur_x, cur_y, (x- h_x)**2 + (y-h_y)**2)
    }
  
    const distIdx = dist.map((val, ind) => {return {ind, val}})
              .sort((a, b) => {return a.val > b.val ? 1 : a.val == b.val ? 0 : -1 })
              .map((obj) => obj.ind);
  
    return [[idx, idy], hid[distIdx[0]], hid[distIdx[1]]];
  }

export const getDistanceFromLatLonInKm2 = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km
    return d;
}



export const tableNametoId = (tableName: string) => {
  const xy = tableName.split('_')
  var x, y;
  if (xy[0][0] == 'w'){
    x = '-' + xy[0].substring(1)
  } else{
    x = xy[0].substring(1)
  }
  if (xy[1][0] == 's'){
    y = '-' + xy[1].substring(1)
  } else{
    y = xy[1].substring(1)
  }
  return [x, y]
}



export const pointy_hex_corner = (lat: number, lon: number) => {
  var R = 6371; // Radius of the earth in km
  var size = rad2deg(1 / R);
  console.log('size', size)
  var points:L.LatLng[][] = []
  for (var i = 0; i < 6; i++){
    var angle_deg = 60 * i - 30
    var angle_rad = Math.PI / 180 * angle_deg
    const point = new L.LatLng(lat + size * Math.sin(angle_rad), lon + size * Math.cos(angle_rad));
    points.push([point]);
    // points.push([lat + size * Math.sin(angle_rad), lon + size * Math.cos(angle_rad)])
  }
  return points;
}

// export const isMarkerInsidePolygon = (marker: L.Marker, poly: L.Polyline) => {
//   var polyPoints = poly;       
//   var x = marker.lat, y = marker.lng;
//   var inside = false;
//   for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
//       var xi = polyPoints[i][0], yi = polyPoints[i][1];
//       var xj = polyPoints[j][0], yj = polyPoints[j][1];

//       var intersect = ((yi > y) != (yj > y))
//           && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
//       if (intersect) inside = !inside;
//   }
//   console.log('check', marker, marker.lat, marker.lng)
//   console.log('inside', inside, marker, poly)
//   return inside;
// };