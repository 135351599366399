import React, { useRef, useState, useContext, useEffect, useLayoutEffect, ReactElement } from 'react';
import {AppContext} from '../../../state'
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Collapse, ClickAwayListener, MenuList, Popover , Select, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon, Bookmark as BookmarkIcon } from '@material-ui/icons';
import 'swiper/swiper.min.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Swiper as SwiperType } from 'swiper';
import UserList from '../UserList';
import { getHighestZIndex } from '../../utils/GetHighestZIndex';
import PostBookmarkItem from './PostBookmarkItem';

interface ContainerProps {
    showBookmarkList: boolean
    setShowBookmarkList: React.Dispatch<React.SetStateAction<boolean>>
    post_id: number
    tablename: string
}

  
  interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    added: boolean;
  }

  

  


const BookmarkList: React.FC<ContainerProps> = (props) => {
  const { t, i18n } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);

  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', showError: false });
  const [searchText, setSearchText] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [language, setLanguage] = useState<string>('english');
  const [bookmarkCats, setBookmarkCats] = useState<BookmarkCatProp[]>([{
    id: 0,
    name: t("conversation.recent"),
    image: "recent",
    added: false,
  }])




  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
  };

  
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    fetchUserBookmarks()

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchUserBookmarks = async () => {
    // setLoading(true);
    try {
        const response = await fetch('https://outvirt.com:8080/fetchuserbookmarks', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                post_id: props.post_id,
                user_id: state.id,
            })
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        } else {
          response.json().then((res)=>{
            setBookmarkCats(res)
          })
        }
      } catch (err) {
          console.log(err);
      }
}



  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};



  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
    }
  };

  return (
    <div>
    <Dialog  
    //   className="like-dialog dark-dialog"
      className="dark-dialog"
      open={props.showBookmarkList}
      onClose={() => props.setShowBookmarkList(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: getHighestZIndex() + 1 }}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <BookmarkIcon/>
            &nbsp;&nbsp;{t('bookmark.title')}
        </div>
        <IconButton color="inherit" onClick={() => props.setShowBookmarkList(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content">
        
        <List>
            {bookmarkCats.map((bookmarkCat, idx) => (
                <PostBookmarkItem key={idx} idx={idx} post_id={props.post_id} tablename={props.tablename} token={state.token} bookmarkCat={bookmarkCat} bookmarkCats={bookmarkCats} setBookmarkCats={setBookmarkCats}/>
            ))}
        </List>
        {/* <UserList users={users} setUsers={setUsers}/> */}
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default BookmarkList;
