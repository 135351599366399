import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface User {
    id: number;
    username: string;
    profilepic: string;
    name: string;
  }
  
  interface TagUserItemProps {
    idx: number;
    user: User;
    users: User[];
    setUsers: (users: User[]) => void;
  }
  
  export const TagUserItem: React.FC<TagUserItemProps> = ({ idx, user, users, setUsers }) => {

  return (
    // <div className={styles.followItem}>
    <div>
      <div className="grid">
        <div className="row">
          <div className="col col-2">
            <div className="title-padding-converse">
              <div className="profile-icon">
                <div className="avatar">
                  <img src={'https://outvirt.com/assets/img/' + user.profilepic} alt={`User ${user.username}`} />
                </div>
              </div>
            </div>
          </div>
          <div className="col col-8">
            <div className="row">
              <div className="col">
                <span>{user.name}</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="medium">@{user.username}&nbsp;</span>
              </div>
            </div>
          </div>
          <div className="col col-2">
            <CloseIcon
              className="medium"
              width="24"
              height="24"
              onClick={() => {
                setUsers([...users.slice(0, idx), ...users.slice(idx + 1)]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
