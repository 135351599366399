import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Logo from '../layout/partials/Logo';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);
interface MetricProps {
    created_at: string;
    id: number;
}

const Metric = () => {
    const [posts, setPosts] = useState<MetricProps[]>([]);
    const [users, setUsers] = useState<MetricProps[]>([]);
    const [realUsers, setRealUsers] = useState<MetricProps[]>([]);
    const [realPosts, setRealPosts] = useState<MetricProps[]>([]);
    const [lists, setLists] = useState<MetricProps[]>([]);

    function formatDate(dateStr: string): string {
        const options: Intl.DateTimeFormatOptions = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        };
        return new Date(dateStr).toLocaleDateString(undefined, options);
    }

    function getCumulativeData(dataList: MetricProps[]) {
        let count = 0;
        const dates: string[] = [];
        const counts: number[] = [];

        dataList.sort((a, b) => +new Date(a.created_at) - +new Date(b.created_at))
            .forEach(data => {
                const date = formatDate(data.created_at);

                if (dates[dates.length - 1] !== date) {
                    dates.push(date);
                    counts.push(count);
                }

                counts[counts.length - 1]++;
                count++;
            });

        return { dates, counts };
    }

    useEffect(() => {
        const initialize = async () => {
            try {
                const response = await fetch('https://outvirt.com:8080/fetchmetric', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    const res = await response.json();
                    // Assuming res contains both posts and users data.
                    setPosts(res.posts);
                    setUsers(res.users);
                    setRealUsers(res.users_real);
                    setRealPosts(res.posts_real);
                    setLists(res.lists);
                }
            } catch (err) {
                console.log(err);
            }
        }

        initialize();
    }, []);

    const { dates: postDates, counts: postCounts } = getCumulativeData(posts);
    const { dates: userDates, counts: userCounts } = getCumulativeData(users);
    const { dates: realUserDates, counts: realUserCounts } = getCumulativeData(realUsers);
    const { dates: realPostDates, counts: realPostCounts } = getCumulativeData(realPosts);
    const { dates: listDates, counts: listCounts } = getCumulativeData(lists);

    const postData = {
        labels: postDates,
        datasets: [
            {
                label: 'Posts',
                data: postCounts,
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
            }
        ]
    };

    const userData = {
        labels: userDates,
        datasets: [
            {
                label: 'Users',
                data: userCounts,
                borderColor: 'rgba(192,75,75,1)',
                fill: false,
            }
        ]
    };

    const realUserData = {
        labels: realUserDates,
        datasets: [
            {
                label: 'Real users',
                data: realUserCounts,
                borderColor: 'rgba(92,175,75,1)',
                fill: false,
            }
        ]
    };

    const realPostData = {
        labels: realPostDates,
        datasets: [
            {
                label: 'Real posts',
                data: realPostCounts,
                borderColor: 'rgba(192,275,25,1)',
                fill: false,
            }
        ]
    };

    const listData = {
        labels: listDates,
        datasets: [
            {
                label: 'Trails',
                data: listCounts,
                borderColor: 'rgba(2,25,125,1)',
                fill: false,
            }
        ]
    };

    return (
            <div>
            {/* <header className={classNames('site-header-map')}>
                <div className="container">
                    <div className={classNames('site-header-inner')}>
                        <Logo />
                    </div>
                </div>
            </header> */}

            {/* Total Count Grid */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>Users</h4>
            <h4>Posts</h4>
            <h4>Real Users</h4>
            <h4>Real Posts</h4>
            <h4>Trails</h4>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <p>{users.length}</p>
            <p>{posts.length}</p>
            <p>{realUsers.length}</p>
            <p>{realPosts.length}</p>
            <p>{lists.length}</p>
        </div>
                <h3>Posts Over Time</h3>
                <Line data={postData} />

<h3>Users Over Time</h3>
<Line data={userData} />

<h3>Real Users Over Time</h3>
<Line data={realUserData} />

<h3>Real Posts Over Time</h3>
<Line data={realPostData} />

<h3>Trails Over Time</h3>
<Line data={listData} />
            </div>
    );
};

export default Metric;
