import React, { useRef, useState, useContext, useEffect } from 'react';
import { Grid, Avatar, Typography, Box, Button, List, ListItem, ListItemAvatar, ListItemText, TextField, IconButton, Modal, DialogContent, DialogTitle, DialogActions, Dialog } from '@material-ui/core';
// import { Grid, Avatar, Typography, Button, List, ListItem, ListItemAvatar, ListItemText, TextField, IconButton, Modal, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { CameraOutlined, ImageOutlined, Send } from '@mui/icons-material';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import ChatItem from './ChatItem';
import { getHighestZIndex } from '../utils/GetHighestZIndex';

// import { CloseCircleOutline } from "@material-ui/icons";

// import PostItem from './PostItem';
// import CommentItem from './CommentItem';
// import SubCommentItem from './SubCommentItem';
// import './ChatRoom.css';
// import ReplyItem from './ReplyItem';



interface ChatUser {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }


interface ContainerProps {
    chatUser: ChatUser
    // chatModal: React.RefObject<HTMLIonModalElement>
    showChatModal: boolean
    setShowChatModal: React.Dispatch<React.SetStateAction<boolean>>
    webSocket?: WebSocket
}

interface Chat {
    own: boolean;
    text: string;
    photos: string[];
}

interface UserPhoto {
    srcwebviewPath: string;
    filepath: string;
}

interface Tag {
  id: number;
  username: string;
}

interface ModalInfo {
    slides: string[]|null;
    show: boolean;
    index: number;
  }

  const WS_URL = 'wss://outvirt.com:8080';


const ChatRoom: React.FC<ContainerProps> = (props) => {
  // ... useState, useRef, and other custom hooks
  
  const [showReplyTo, setShowReplyTo] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const [tagList, setTagList] = useState<Tag[]>([])
  const [parentId, setParentId] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const listRef = useRef<HTMLUListElement>(null);
  const [chats, setChats] = useState<Chat[]>([]);
  const chatsRef = useRef<Chat[]>([])
  
  const contentRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
//   const [post, setPost] = useState<Post>(
//     {
//       id: 0,
//       created_at: '1',
//       user_id: 0,
//       body: 'loading...',
//       photos: [],
//       name: '',
//       username: '',
//       profilepic: 'picinit',
//       n_comments: 0,
//       n_likes: 0,
//       n_shares: 0,
//       shared: false,
//       liked: false,
//       tablename: "",
//       account_type: 0,
//       post_id: 0,
//       precise: false,
//     }
//   );

  const ws = useRef<WebSocket>();

  const initPage = async(user_id: number) => {
    try {
    //   const ret = await Preferences.get({ key: 'user' });
    //   const user = JSON.parse(ret.value!);
    //   if (user != null){
    //     setUserInfo(user);
    //   }
      const response = await fetch('https://outvirt.com:8080/fetchchat', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user_id: user_id, token: state.token})});
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        setChats(res_data);
        chatsRef.current = res_data
        // if (!iIsPost) {
        //   setOP({...res_data[2], tablename: iPostTable})
        // }
        console.log(res_data)
        // post = res_data[0];
        // setPosts(res_data)
      })
      // response.json().then(res_data => {
      //   console.log(res_data);
      // })
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    console.log('chatsss', listRef.current?.scrollHeight)
    setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        // listRef.current!.scrollTop = listRef.current!.scrollHeight;

    //   listRef.current?.scrollTo({
    //     top: listRef.current?.scrollHeight,
    //     behavior: 'smooth',
    //   });
        // listRef.current?.scrollTo(0, listRef.current?.scrollHeight);
    }, 200)
    }, [chats]);


    useEffect(()=>{
        console.log('showchatmodal true')
        //   if (location.pathname === '/tabs/conversation') {
        //     ws.current = props.webSocket
            
        //     ws.current!.send(
        //       JSON.stringify({
        //           type: 'chat',
        //           user_id: state.id,
        //       })
        //     );
        //     initPage(props.chatUser.id)
        //   } else {
        //     ws.current = new WebSocket(WS_URL);
        //   }


            // Setup web socket from parent
            
            ws.current = props.webSocket
            
            ws.current!.send(
              JSON.stringify({
                  type: 'chat',
                  user_id: state.id,
              })
            );
            initPage(props.chatUser.id)

            // Opening the ws connection
            ws.current!.onopen = () => {
                console.log("Connection opened");
                ws.current!.send(
                    JSON.stringify({
                        type: 'chat',
                        user_id: state.id,
                    })
                );
                initPage(props.chatUser.id)
                // setConnectionOpen(true);
            };

            // Listening on ws new added messages
            ws.current!.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket message received:', data);
                console.log(chats)

                // update chat if sender is the same as the chat user
                if (data.sender == props.chatUser.id){
                  if (data.type == 'msg'){
                    setChats([
                        ...chatsRef.current,
                        {
                            own: false,
                            text: data.text,
                            photos: data.photos,
                        }
                    ])
                    chatsRef.current = [
                        ...chatsRef.current,
                        {
                            own: false,
                            text: data.text,
                            photos: data.photos,
                        }
                    ]
                  }
                  else if (data.type == 'img'){
                    // const matchingIndex = chatsRef.current.findIndex(obj => obj.photos.length>0 && obj.photos.every((val, idx) => val == data.o_photos[idx]));
                    const matchingIndex = chatsRef.current.length - 1 - chatsRef.current.slice().reverse().findIndex(obj => obj.photos.length > 0 && obj.photos.every((val, idx) => val === data.o_photos[idx]));

                    console.log('testimg', data, matchingIndex, chatsRef.current[matchingIndex])
                    if (matchingIndex != -1){
                      setChats(
                        [...chatsRef.current.slice(0, matchingIndex),
                        {
                            own: true,
                            text: chatsRef.current[matchingIndex].text,
                            photos: data.n_photos,
                          },
                        ...chatsRef.current.slice(matchingIndex + 1)
                      ]
                      )
                      chatsRef.current[matchingIndex].photos = data.n_photos
                    }
                  }
                } else {
                    console.log('wrong user')
                }
            };


            return () => {
                console.log('showchatmodal false')
                console.log('testtt', ws.current?.readyState)
                if (ws.current != null && ws.current != undefined && ws.current.readyState === WebSocket.OPEN){
                    console.log("Cleaning up...");
                    // if (location.pathname === '/tabs/conversation'){
                    //   console.log('are we in here?')
                    //     ws.current!.send(
                    //         JSON.stringify({
                    //             type: 'idle',
                    //             user_id: state.id,
                    //         })
                    //     );
                    // } else {
                    //   ws.current!.close();
                    // }
                    
                    ws.current!.send(
                        JSON.stringify({
                            type: 'idle',
                            user_id: state.id,
                        })
                    );
                    setChats([])
                    chatsRef.current = []

                }
            }

    },[])


    // useEffect(() => {
    //     // console.log('12345', listRef, listRef.current)
    //     // setTimeout(() => {
    //     //     listRef.current?.scrollTo(0, listRef.current?.scrollHeight);
    //     // }, 200)
    //     console.log('showchatmodal')
    //     if (props.showChatModal){
    //         console.log('showchatmodal true')
    //     //   if (location.pathname === '/tabs/conversation') {
    //     //     ws.current = props.webSocket
            
    //     //     ws.current!.send(
    //     //       JSON.stringify({
    //     //           type: 'chat',
    //     //           user_id: state.id,
    //     //       })
    //     //     );
    //     //     initPage(props.chatUser.id)
    //     //   } else {
    //     //     ws.current = new WebSocket(WS_URL);
    //     //   }


    //         // Setup web socket from parent
            
    //         ws.current = props.webSocket
            
    //         ws.current!.send(
    //           JSON.stringify({
    //               type: 'chat',
    //               user_id: state.id,
    //           })
    //         );
    //         initPage(props.chatUser.id)

    //         // Opening the ws connection
    //         ws.current!.onopen = () => {
    //             console.log("Connection opened");
    //             ws.current!.send(
    //                 JSON.stringify({
    //                     type: 'chat',
    //                     user_id: state.id,
    //                 })
    //             );
    //             initPage(props.chatUser.id)
    //             // setConnectionOpen(true);
    //         };

    //         // Listening on ws new added messages
    //         ws.current!.onmessage = (event) => {
    //             const data = JSON.parse(event.data);
    //             console.log('WebSocket message received:', data);
    //             console.log(chats)

    //             // update chat if sender is the same as the chat user
    //             if (data.sender == props.chatUser.id){
    //               if (data.type == 'msg'){
    //                 setChats([
    //                     ...chatsRef.current,
    //                     {
    //                         own: false,
    //                         text: data.text,
    //                         photos: data.photos,
    //                     }
    //                 ])
    //                 chatsRef.current = [
    //                     ...chatsRef.current,
    //                     {
    //                         own: false,
    //                         text: data.text,
    //                         photos: data.photos,
    //                     }
    //                 ]
    //               }
    //               else if (data.type == 'img'){
    //                 // const matchingIndex = chatsRef.current.findIndex(obj => obj.photos.length>0 && obj.photos.every((val, idx) => val == data.o_photos[idx]));
    //                 const matchingIndex = chatsRef.current.length - 1 - chatsRef.current.slice().reverse().findIndex(obj => obj.photos.length > 0 && obj.photos.every((val, idx) => val === data.o_photos[idx]));

    //                 console.log('testimg', data, matchingIndex, chatsRef.current[matchingIndex])
    //                 if (matchingIndex != -1){
    //                   setChats(
    //                     [...chatsRef.current.slice(0, matchingIndex),
    //                     {
    //                         own: true,
    //                         text: chatsRef.current[matchingIndex].text,
    //                         photos: data.n_photos,
    //                       },
    //                     ...chatsRef.current.slice(matchingIndex + 1)
    //                   ]
    //                   )
    //                   chatsRef.current[matchingIndex].photos = data.n_photos
    //                 }
    //               }
    //             } else {
    //                 console.log('wrong user')
    //             }
    //         };
        
    //     } else {
            
    //         console.log('showchatmodal false')
    //         console.log('testtt', ws.current?.readyState)
    //         if (ws.current != null && ws.current != undefined && ws.current.readyState === WebSocket.OPEN){
    //             console.log("Cleaning up...");
    //             // if (location.pathname === '/tabs/conversation'){
    //             //   console.log('are we in here?')
    //             //     ws.current!.send(
    //             //         JSON.stringify({
    //             //             type: 'idle',
    //             //             user_id: state.id,
    //             //         })
    //             //     );
    //             // } else {
    //             //   ws.current!.close();
    //             // }
                
    //             ws.current!.send(
    //                 JSON.stringify({
    //                     type: 'idle',
    //                     user_id: state.id,
    //                 })
    //             );
    //             setChats([])
    //             chatsRef.current = []

    //         }
    //     }
    // }, [props.showChatModal]);

// send message on enter
const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a newline to the TextField
      handleSubmit(); // Triggers your handleSubmit function
    }
  };


  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};



const handleSubmit = async() => {
    setLoading(true);
    if ( (text!=undefined && text!="") || photos.length!=0){
    await Promise.all( photos.map(async (photo) => {
        let formData = new FormData();
        let img = await fetch(photo.srcwebviewPath!).then(res => res.blob());
        formData.append('photo', img  , photo.filepath);
        try {
            const response = await fetch('https://outvirt.com:8080/upload', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        } catch (err) {
        console.log(err);
        }
    }));
    const photos_string: string[] = [];
    if (photos.length>0){
        photos.map((photo) => {
        photos_string.push(photo.filepath);
        })
    }
    ws.current!.send(
        JSON.stringify({
            type: 'message',
            token: state.token,
            sender: state.id,
            receipient: props.chatUser.id,
            text: text,
            photos: photos_string,
        })
    );

    setText("");
    setPhotos([]);
    setChats([
        ...chats,
        {
            own: true,
            text: text!,
            photos: photos_string,
        }
    ])
    chatsRef.current = [
        ...chats,
        {
            own: true,
            text: text!,
            photos: photos_string,
        }
    ]
    } else{
        toast('Comment must include at least text or image.')
        // present('Comment must include at least text or image.', 2000)
    }
    setLoading(false);
}


  
const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
  if (event.key === 'Escape') {
    if (state.showSinglePost){
      // content card open in chat room
      console.log('SHOULD ENTER')
      dispatch({
        type: "setShowSinglePost",
        showSinglePost: false,
        singlePostId: 0,
      });
      return
    } else {
      props.setShowChatModal(false)
    }
  }
};

    

  return (
    <Dialog  
      className="dark-dialog"
      open={props.showChatModal}
      onClose={() => props.setShowChatModal(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: getHighestZIndex() + 1 }}
    >
      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`https://outvirt.com/assets/imgmap/${props.chatUser.profilepic}`} // Replace this URL with the actual profile picture URL
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            marginRight: '10px',
          }}
        />
        <div>
          <Box display="flex" alignItems="center">
              {props.chatUser.name}&nbsp;
              {/* <Chip
                    label={userInfo.level}
                    style={{ height: "14px", marginLeft:"6px", padding: "1px", paddingTop: '2px', fontSize: "12px", color: "#fff", backgroundColor: "#7a3fe4" }}
                  /> */}
            </Box>
          <div
            style={{
              color: 'gray',
              fontSize: '14px',
            }}
          >
            @{props.chatUser.username} {/* Replace 'Username' with the actual username */}
          </div>
        </div>
      </div>
        <IconButton color="inherit" onClick={() => props.setShowChatModal(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent style={{ padding: 0, overflowX: 'hidden'}}  className="dark-dialog-content">
          <Grid container direction="column">
            <Grid item>
              <List>
                {chats.map((chat, idx) => (
                  <ChatItem
                    key={idx}
                    // idx={idx}
                    chat={chat}
                    profilepic={chat.own ? state.profilepic : props.chatUser.profilepic}
                    setModalInfo={setModalInfo}
                    setShowChatModal={props.setShowChatModal}
                  />
                ))}
                  <div ref={bottomRef} />
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      <Modal open={modalInfo.show} onClose={() => setModalInfo({ slides: [], show: false, index: 0 })}>
        <DialogContent>
          {modalInfo.slides && modalInfo.slides.length > 0 && (
            <Swiper initialSlide={modalInfo.index} centeredSlides centeredSlidesBounds>
              {modalInfo.slides && modalInfo.slides.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={`https://outvirt.com/assets/img/${image}`} width="15" onClick={() => setModalInfo({ ...modalInfo, show: false })} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </DialogContent>
      </Modal>

      {/* {
  parentId != post.id && parentId != 0 && showReplyTo ? (
    <div className="reply-to-box">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: '15px' }}>
        <span style={{ color: "#666" }}>Replying to...</span>
        <Button
          onClick={() => {
            setShowReplyTo(false);
            setParentId(post.id);
          }}
        >
          <CloseIcon style={{ color: "#666" }}/>
        </Button>
      </div>
      <ReplyItem comment={getComment(comments, parentId!)} />
    </div>
  ) : null
} */}



      {props.showChatModal && (
        <DialogActions className="dark-dialog-actions">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
              <TextField
                className="dark-text-field"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Message..."
                multiline
                rows={1}
                variant="outlined"
                onKeyDown={handleKeyDown}
              />
            </Grid>
            {/* <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleTakePhoto} color="primary">
                <PhotoCameraIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleChoosePhoto} color="primary">
                <ImageIcon />
              </IconButton>
            </Grid> */}
            <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleSubmit} style={{ color: ( (text!=undefined && text!="") || photos.length!=0) ? "#3f51b5" : "#666"} }>
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* {photos.length > 0 && (
            <Grid container spacing={1} alignItems="center">
              {photos.map((photo, index) => (
                <Grid item key={index}>
                  <IconButton onClick={() => handleRemovePhoto(index)} color="secondary">
                    <CloseIcon />
                  </IconButton>
                  <Avatar variant="square" src={photo.srcwebviewPath} />
                </Grid>
              ))}
            </Grid>
          )} */}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ChatRoom;
