import React, { useRef, useState, useContext, MouseEvent, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { IconButton, DialogTitle, DialogActions, ListItemText, Avatar, Typography, Chip, Grid, Dialog, DialogContent, Box } from '@material-ui/core';
import { FilterList, Block, Report, DeleteOutline, Edit, Navigation, PhotoCamera, Bookmark, BookmarkBorder, Favorite, FavoriteBorder, ChatBubble } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ParseDate from '../date/DateT';
import {AppContext} from '../../state'
import L from "leaflet";
import './ContentCard.css'
import { useSpring, useSprings, animated, useTrail } from "react-spring";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { toast, ToastContainer } from 'react-toastify';
import { useGestureResponder, StateType } from "react-gesture-responder";
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'react-modal';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import LinkIcon from '@mui/icons-material/Link';
import SearchPeopleSelect from '../user/SearchPeopleSelect';
import { Button } from 'reactstrap';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LikeList from '../user/components/LikeList';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import { PropaneTankSharp } from '@mui/icons-material';
import { dismissElementsAboveZIndex } from '../utils/DismissElementsAboveZIndex';
import StandardProfile from '../user/StandardProfile';
import PublicIcon from '@mui/icons-material/Public';
import WebIcon from '@mui/icons-material/Web';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { generateRandomString } from '../utils/GenerateRandomString';
import BookmarkList from '../user/components/BookmarkList';
import MapIcon from '@mui/icons-material/Map';
import GoogleIcon from '@mui/icons-material/Google';
// import debounce from 'lodash';

interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number
  url: string
  user_url: string
  zoom: number;
}

interface Location {
  place_id: string;
  lat: number;
  lon: number;
}

interface PostProps {
    tablename: string
    post_id: number
  }
interface ContainerProps {
    card_name: string;
    id: number
    tablename: string
    photos: string[]
    name: string
    body: string
    user_id: number
    profilepic: string
    idx: number
    type: boolean
    created_at: string
    n_likes: number
    n_comments: number
    bookmarked: boolean
    liked: boolean
    precise: boolean
    lat?: number
    lon?: number
    topic_name: string[]
    loc_name: string
    account_type: number
    url: string
    user_url: string
    zoom: number;
    // setShowContent: React.Dispatch<React.SetStateAction<boolean>>;
    map?: L.Map;
    cardsRef: React.RefObject<HTMLInputElement>;
    setPostProps: React.Dispatch<React.SetStateAction<PostProps>>;
    setShowPostModal: React.Dispatch<React.SetStateAction<boolean>>;
    showPostModal: boolean;
    currentIdx: number;
    setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
    isPrev: boolean;
    setIsPrev: React.Dispatch<React.SetStateAction<boolean>>;
    setShowContent: React.Dispatch<React.SetStateAction<boolean>>;
    setFetchPost: React.Dispatch<React.SetStateAction<boolean>>;
    arrowClickRef: React.MutableRefObject<boolean>;
    fetchLockRef: React.MutableRefObject<boolean>;
  }

  interface ReferencePostProps{
    id: number;
    tablename: string;
    profilepic: string;
    body: string;
    subbody: string;
    created_at: string;
    loc_name: string;
    lat: number;
    lon: number;
    place_id: string;
  }


const ContentCard: React.FC<ContainerProps> = (props) => {
    const DOUBLE_CLICK_THRESHOLD = 500;

    // Your original state, ref and function declarations here...
  const ref = React.useRef<HTMLInputElement>(null)
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  let location = useLocation();
  const [likePost, setLikePost] = useState(props.liked);
  const { state, dispatch } = useContext(AppContext);
  const [bookmarkPost, setBookmarkPost] = useState(props.bookmarked);
  const [addLike, setAddLike] = useState<number>(0)
  const [cIdx, setcIdx] = useState(0);
  const idxRef = useRef(0)
  let lastOnStart = useRef(0);
  const [hideText, setHideText]= useState<boolean>(true)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  let isSwipedY = false;
  let isSwipedX = false;
  const [vidPlay, setVidPlay] = useState<boolean>(false)
  const [seen, setSeen] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [sendList, setSendList] = useState<number[]>([]);
  const [showLikeList, setShowLikeList] = useState(false);
  const [showBookmarkList, setShowBookmarkList] = useState(false);
  const [showStandardProfile, setShowStandardProfile] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<{show: boolean, id: number, image: string}>({show: false, id: 0, image: ''})
  const [referencePosts, setReferencePosts] = useState<ReferencePostProps[]>([])
  const [cardZIndex, setCardZIndex] = useState<number>(0);
  const [showSubPostsList, setShowSubPostsList] = useState<boolean>(false);

  const useStyles = makeStyles((theme) => ({
    customChip: {
        // borderColor: theme.palette.grey[500],
        borderWidth: '0px',
        borderStyle: 'solid',
        backgroundColor: "rgba(128, 128, 128, 0.3)",
        color: theme.palette.common.white,
        fontWeight: 'normal',
        fontSize: '8px',
        margin: '2px',
        borderRadius: '20px',
      },
    customChipLabel: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  // initialize zIndex when content shown
  useEffect(() => {
      setCardZIndex(getHighestZIndex());
  }, [])

    useEffect(() => {
      // console.log('contentcard zindex', props.cardsRef.current!.style.zIndex)
      const updateHeight = () => {
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );
        setViewportHeight(vh);
      };
      
      // Set overflow hidden on the body element
      document.body.style.overflow = 'hidden';
    
      window.addEventListener("resize", updateHeight);
      updateHeight();
      return () => window.removeEventListener("resize", updateHeight);
    }, []);
    
    

    // prevent pop-up
    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    // handles sub posts if any
    const fetchSubPosts = async () => {
      const res = await fetch('https://outvirt.com:8080/fetchsubposts', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: props.id, tablename: props.tablename})
      })
      const data = await res.json();
      console.log('subposts', data)
      setReferencePosts(data)
    }
    useEffect(() => { 
      if (cIdx!=0 && props.zoom != 0 && referencePosts.length == 0){
        fetchSubPosts()
      }
    }, [cIdx])


    const scrollingRef = useRef(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const subpostsContainer = document.querySelector('.subposts-container');
    const subpostsParentContainer = document.querySelector('.subposts-parent-container');

    if (subpostsContainer && subpostsParentContainer) {

      const onScroll = () => {
        scrollingRef.current = true;
        (subpostsParentContainer as HTMLElement).style.pointerEvents = 'none';
      };

      const onScrollEnd = () => {
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => {
          scrollingRef.current = false;
          (subpostsParentContainer as HTMLElement).style.pointerEvents = 'auto';
        }, 100);
      };

      if (showSubPostsList) {
        subpostsContainer.addEventListener('scroll', onScroll);
        subpostsContainer.addEventListener('scroll', onScrollEnd);
      } else {
        subpostsContainer.removeEventListener('scroll', onScroll);
        subpostsContainer.removeEventListener('scroll', onScrollEnd);
      }

      // cleanup function
      return () => {
        subpostsContainer.removeEventListener('scroll', onScroll);
        subpostsContainer.removeEventListener('scroll', onScrollEnd);
      };
    }
  }, [showSubPostsList]); 


var myVideo:HTMLVideoElement; // = document.getElementById("video"+props.idx) as HTMLVideoElement; 
var myVideoRef = useRef<HTMLVideoElement>(); // = document.getElementById("video"+props.idx) as HTMLVideoElement; 


const startTime = useRef(0)

useEffect(() => {
      myVideo = document.getElementById(props.card_name+props.idx) as HTMLVideoElement; 
      myVideoRef.current = document.getElementById(props.card_name+props.idx) as HTMLVideoElement; 
    const card = ref.current
  //   console.log('cid', state.current_idx, 'idx', props.idx, 'seen', seen, 'isprev', state.is_prev, 'cond', state.current_idx == props.idx && seen)

    if (props.currentIdx == props.idx && props.isPrev){
      if (props.currentIdx == props.idx && seen){
        // proceed to view previously seen post
        console.log('shouldplay', props.currentIdx, props.idx )
        card!.style.transform = `translateY(0)`
        card!.style.opacity ='1'

        const nextIdx = props.idx == 0 ? 8 : props.idx-1
        const nextVideo = document.getElementById(props.card_name+nextIdx) as HTMLVideoElement; 
        if (nextVideo) nextVideo.pause();            

        if (myVideo && props.photos[cIdx].slice(-4) == '.mp4'){
            myVideo!.play();
            setVidPlay(true)
        }

        // measure and flag begin of view time
        startTime.current = performance.now();

      } else {
        // overshoot viewable posts, go back one-step
        props.setCurrentIdx(props.idx > 0 ? props.idx-1 : 8) // 0~8
        props.setIsPrev(true)
      }
      // Don't fetch post if we are navigating backwards
      props.setFetchPost(false)
    } else {
      // proceed to view next post
      if (props.currentIdx == props.idx){
        console.log('POSTID: ', props.id)
        console.log('proceed: seen', seen )
        
        // animation to next post
        const previousIdx = props.idx == 8 ? 0 : props.idx+1
        const prevCardElement = document.getElementById('c' + props.card_name+previousIdx) as HTMLDivElement; 
        if (prevCardElement){
          console.log('prev pause')
          const windowHeight = window.innerHeight
          prevCardElement.style.transition =
            '0.5s cubic-bezier(.6,.44,.83,.67)'
            prevCardElement.style.transform = `translateY(-${windowHeight * 1.2}px)`
            props.cardsRef.current!.style.opacity = '1'
            // pause video if exist
            // if (props.photos[cIdx].slice(-4) == '.mp4'){
              myVideo = document.getElementById(props.card_name+previousIdx) as HTMLVideoElement; 
              myVideoRef.current = document.getElementById(props.card_name+previousIdx) as HTMLVideoElement; 
              if (myVideo) myVideo.pause();            
              // setVidPlay(false)
            // } 
        }

        // Don't fetch post if the following post is seen
        if (seen){
          props.setFetchPost(false)
        // Fetch a single post if we are navigating to unseen post
        } else {
          props.setFetchPost(true)
        }
        myVideo = document.getElementById(props.card_name+props.currentIdx) as HTMLVideoElement;

        // play video and mark as seen
        if (myVideo && props.photos[cIdx].slice(-4) == '.mp4'){
            myVideo!.play();
            setVidPlay(true)
        }
        setSeen(true)
        
        // measure and flag begin of view time
        startTime.current = performance.now();
      } else {
        // end of post
        const previousIdx = props.currentIdx == 8 ? 0 : props.currentIdx+1
        const prevCardElement = document.getElementById('c' + props.card_name+previousIdx) as HTMLDivElement; 
        if (prevCardElement){
          const windowHeight = window.innerHeight
          prevCardElement.style.transition =
            '0.5s cubic-bezier(.6,.44,.83,.67)'
            prevCardElement.style.transform = `translateY(-${windowHeight * 1.2}px)`
            myVideo = document.getElementById(props.card_name+previousIdx) as HTMLVideoElement; 
            myVideoRef.current = document.getElementById(props.card_name+previousIdx) as HTMLVideoElement; 
            if (myVideo) myVideo.pause();            
        }
      }

    }

}, [props.currentIdx, props.isPrev, cIdx])



// close post card on outer click
const handleClickOutside = (event: MouseEvent, global_cidx: number) => {
  // if (global_cidx == props.idx && !ref.current!.contains(event.target as Node) && !props.showPostModal && !showModal){
    // setTimeout(() => {
      // console.log('TESTOUT', !props.arrowClickRef.current, global_cidx == props.idx, !ref.current!.contains(event.target as Node), getHighestZIndex()==Number(props.cardsRef.current!.style.zIndex), getHighestZIndex(), Number(props.cardsRef.current!.style.zIndex))
    if (!showModal && !deleteDialog.show && !props.arrowClickRef.current && global_cidx == props.idx && !ref.current!.contains(event.target as Node) && getHighestZIndex()==Number(props.cardsRef.current!.style.zIndex)){
        console.log('Clicked out of post card', getHighestZIndex(), Number(props.cardsRef.current!.style.zIndex));
      
        props.cardsRef.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
        props.cardsRef.current!.style.transform = 'scale(0)';
        props.cardsRef.current!.style.opacity = '0';
        if (props.map != null){
            props.map.dragging.enable();
            props.map.touchZoom.enable();
            props.map.doubleClickZoom.enable();
            props.map.scrollWheelZoom.enable();
            props.map.boxZoom.enable();
            props.map.keyboard.enable();
            if (props.map.tap) props.map.tap.enable();
          }
          props.setShowContent(false)
    } 

    // }, 300);
  // else if (showModal){
  //   setShowModal(false)
  // }
};

useEffect(() => {
  const listener = (event: Event) => handleClickOutside(event as unknown as MouseEvent, props.currentIdx);
  window.addEventListener('mousedown', listener);
  return () => {
    window.removeEventListener('mousedown', listener);
  };
}, [document, props.currentIdx, props.showPostModal, showModal]);
// }, [document, props.currentIdx, state.showStandardProfile, props.showPostModal, showModal]);






    // to handle keydown event
  useEffect(() => {
    

    const handleKeyDown = async(event: KeyboardEvent) => {
        // Animate the card back to its original position or out of the view depending on how far the user dragged the card 👇
        // if (state.current_idx == props.idx){
        if (props.currentIdx == props.idx){
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight
            // if(ref.current) ref.current.style.transition =
            // '0.5s cubic-bezier(.6,.44,.83,.67)'
            // Always set default to not fetch new post
            props.setFetchPost(false)

            // Perform actions based on pressed key
            if (event.key === ' ') {
              console.log('Space pressed');

              
              const nLikePost = !likePost;

              if (!nLikePost && props.liked) {
                  setAddLike(-1);
                  serverUnlikePost()
                  // playUnlikeButtonAnimation()
                  playLikeButtonAnimation();
              } else if ((!nLikePost && !props.liked) || (nLikePost && props.liked)) {
                  setAddLike(0);
                  // playUnlikeButtonAnimation()
                  if (props.liked) serverLikePost()
                  else serverUnlikePost()
                  playLikeButtonAnimation();
              } else if (nLikePost && !props.liked) {
                  setAddLike(1);
                  playLikeButtonAnimation();
                  playCenterLikeAnimation();
                  // startLikeAnimation()
                  serverLikePost()
              }
              await sleep(likePost ? 500 : 1000);
              setLikePost(nLikePost);
              
            } 
            else if (event.key === 'ArrowDown') {
                console.log('ArrowDown key pressed', props.fetchLockRef.current);
                if(props.fetchLockRef.current) return // previous fetch card still loading, prevent fetching
                props.setCurrentIdx(props.idx > 0 ? props.idx-1 : 8)
                props.setIsPrev(false)
                registerPostView()
            } 
            else if (event.key === 'ArrowUp') {
                console.log('ArrowUp key pressed');
                if (props.photos[cIdx].slice(-4) == '.mp4'){
                    myVideo.pause();            
                    setVidPlay(false)
                  } 
                  props.setCurrentIdx((props.idx < 8) ?  props.idx+1 : 0)
                  props.setIsPrev(true)
                  registerPostView()
            } 
            else if (event.key === 'Escape') {
              console.log('ESCAPE key pressed', props.body);
              if (getHighestZIndex()==Number(props.cardsRef.current!.style.zIndex)){
                props.cardsRef.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
                props.cardsRef.current!.style.transform = 'scale(0)';
                props.cardsRef.current!.style.opacity = '0';
                if (props.map != null){
                    props.map.dragging.enable();
                    props.map.touchZoom.enable();
                    props.map.doubleClickZoom.enable();
                    props.map.scrollWheelZoom.enable();
                    props.map.boxZoom.enable();
                    props.map.keyboard.enable();
                    if (props.map.tap) props.map.tap.enable();
                  }
                  setTimeout(function(){
                    props.setShowContent(false)
                  }, 200);
                  registerPostView()

              }

            }
            else if (event.key === 'ArrowLeft') {
                if (props.photos.length === 1) return;
                if (cIdx - 1 >= 0) {
                    setcIdx(cIdx - 1);
                    idxRef.current = cIdx - 1;
                    lastOnStart.current = 0;
                    longPressActive.current = false;
                }
            }
            else if (event.key === 'ArrowRight') {
                if (props.photos.length === 1) return;
                if (cIdx + 1 < props.photos.length) {
                  console.log('right key pressed')
                    setcIdx(cIdx + 1);
                    idxRef.current = cIdx + 1;
                    lastOnStart.current = 0;
                    longPressActive.current = false;
                }
            }
            else if (event.key === 'c') {
                console.log('c key pressed');
                // toast('Download app to view')
                props.setPostProps({post_id: props.id, tablename: props.tablename});
                props.setShowPostModal(true);
            }
        }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.currentIdx, cIdx, likePost]);
  
// const debouncedOnMove = debounce(
//   (state: StateType, delta: [number, number]) => {
//     // handle move gesture using dx and dy
//     if (Math.abs(delta[0])>5 || Math.abs(delta[1])>5){
//       longPressActive.current = false;
//     }
//     const windowWidth = window.innerWidth
//     const windowHeight = window.innerHeight
//   // console.log('checkfire', props.idx, state.current_idx, detail.deltaY)
//   // CARD DECK DISMISS: fade all cards away
//   if (Math.abs(delta[0]) > windowWidth / 4 && delta[1]>-100){
//     props.cardsRef.current!.style.transition =
//     '0.5s cubic-bezier(.6,.44,.83,.67)'
//       props.cardsRef.current!.style.transform = `translateX(${Math.sign(delta[0]) *windowWidth * 1.2}px)`
//     isSwipedX = true
//   }
//   else if (Math.abs(delta[0]) > windowWidth / 5 && delta[1]>-100 && !isSwipedX){
//     props.cardsRef.current!.style.opacity = `${Math.pow(2, -Math.abs(delta[0])/100)}`
//     props.cardsRef.current!.style.transform = `translateX(${delta[0]}px)`
//   }
  
//   // smoothly dismiss card if over threshold
//   else if (delta[1] < -windowHeight / 6) {
//     if(props.fetchLockRef.current) return // previous fetch card still loading, prevent fetching
//     if (props.photos[cIdx].slice(-4) == '.mp4'){
//       myVideoRef.current && myVideoRef.current.pause()
//       // myVideo.pause();            
//       setVidPlay(false)
//     } 
//     if(ref.current) ref.current.style.transition =
//     '0.5s cubic-bezier(.6,.44,.83,.67)'
//     if(ref.current) ref.current.style.transform = `translateY(-${windowHeight * 1.2}px)`
//     isSwipedY = true
//   }
//   // SINGLE CARD DISMISS: fade current card away slowly
//   else if (delta[1] < 0 && !isSwipedY){
//     console.log('noin', isSwipedY)
//     if(ref.current) ref.current.style.transform = `translateY(${delta[1]}px)`
//     if(ref.current) ref.current.style.opacity = `${Math.pow(2, -Math.abs(delta[1])/100)}`
//   }
//   }, 10);

// scroll to move cards up and down
const handleScroll = (event: WheelEvent) => {
  if (props.currentIdx === props.idx && getHighestZIndex()==Number(props.cardsRef.current!.style.zIndex)) {
    const deltaY = event.deltaY;
    const windowHeight = window.innerHeight;
    console.log('scroolll', deltaY)
    if (deltaY < -70) {
      // The user scrolled up (previous post)
      if (props.photos[cIdx].slice(-4) === ".mp4") {
        myVideoRef.current && myVideoRef.current.pause();
        setVidPlay(false);
      }
      props.setCurrentIdx((props.idx < 8) ? props.idx + 1 : 0);
      props.setIsPrev(true);
      registerPostView();
    } else if (deltaY > 70) {
      // The user scrolled down (next post)
      if (props.fetchLockRef.current) return; // previous fetch card still loading, prevent fetching
      props.setCurrentIdx(props.idx > 0 ? props.idx - 1 : 8);
      props.setIsPrev(false);
      registerPostView();
    }
  }
  //  else {
  //   toast('not top')
  // }
};

const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
};

const debouncedHandleScroll = debounce(handleScroll, 200);

useEffect(() => {
  // Add the event listener when the component mounts
  window.addEventListener("wheel", debouncedHandleScroll);

  // Remove the event listener when the component unmounts
  return () => {
    window.removeEventListener("wheel", debouncedHandleScroll);
  };
}, [props.currentIdx, cIdx]);



const windowWidthRef = useRef(window.innerWidth);
const windowHeightRef = useRef(window.innerHeight);
const windowWidthQuarterRef = useRef(window.innerWidth / 4);
const windowWidthFifthRef = useRef(window.innerWidth / 5);
const windowHeightSixthRef = useRef(window.innerHeight / 6);

useEffect(() => {
  const handleResize = () => {
    windowWidthRef.current = window.innerWidth;
    windowHeightRef.current = window.innerHeight;
    windowWidthQuarterRef.current = window.innerWidth / 4;
    windowWidthFifthRef.current = window.innerWidth / 5;
    windowHeightSixthRef.current = window.innerHeight / 6;
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

const lerp = (start: number, end: number, t: number) => {
  return start + (end - start) * t;
};


const triggerModelOpenRef = useRef(false);
const showBookmarkListRef = useRef(false);
useEffect(() => {
  showBookmarkListRef.current = showBookmarkList;
}, [showBookmarkList]);
  const { bind } = useGestureResponder({
    
    onStartShouldSet: () => true,
    onGrant: async(event) => {
      if (scrollingRef.current) return;
      if(getHighestZIndex()!=Number(props.cardsRef.current!.style.zIndex)) return
      // handle start gesture
      console.log('grant', event)
      const now = Date.now();
          console.log('lock', !longPressActiveLock.current)
          if (!longPressActiveLock.current){
            longPressActiveLock.current = true;
            longPressActive.current = true;
          }
            setTimeout(() => {
              console.log('timeout', longPressActive.current)
                if (!isClickedRef.current && longPressActive.current && !showBookmarkListRef.current) {
                  longPressActive.current = false;
                  window.navigator.vibrate(50);
                  // Vibration.vibrate(50)
                  triggerModelOpenRef.current = true
                  // setTimeout(() => {
                  //   setShowModal(true)
                  // }, 500);
                  // toast('why enter')
                    
                  console.log('yoooo unlock')
                }
                longPressActiveLock.current = false;
            }, 1500);

          // double click for like
          if (Math.abs(now - lastOnStart.current) <= DOUBLE_CLICK_THRESHOLD) {
            // setTimeout(function(){
            //     pauseLock.current = false
            // }, 200)
            
            if (state.id == 0){
            // if (false){
                
                toast.error(t('acc_req'))
            //   present(t('acc_req'), 2000)
            } else {
              playLikeButtonAnimation()
              playCenterLikeAnimation()
              await sleep(1000);
              setLikePost(true)
              if (!props.liked){
                if (addLike == 0){
                  serverLikePost()
                  setAddLike(1)
                }
              }
            }

            lastOnStart.current = 0;
          } else {
            lastOnStart.current = now;
          }
    },
    // onMove: (state: StateType) => {
    //   debouncedOnMove(state, state.delta as [number, number]);
    // },
    onMove: ({ delta }) => {
      if (scrollingRef.current) return;
      if(getHighestZIndex()!=Number(props.cardsRef.current!.style.zIndex)) return
      // handle move gesture using dx and dy
      const absDeltaX = Math.abs(delta[0]);
      const absDeltaY = Math.abs(delta[1]);
    
      if (absDeltaX > 5 || absDeltaY > 5) {
        longPressActive.current = false;
      }
    
      const windowWidth = windowWidthRef.current;
      const windowHeight = windowHeightRef.current;
    
      const deltaXGreaterThanQuarter = absDeltaX > windowWidthQuarterRef.current;
      const deltaYLessThanSixth = delta[1] < -windowHeightSixthRef.current;
    
      // swipe left or right
      if (deltaXGreaterThanQuarter && delta[1] > -100) {
        if (!isSwipedX) {
          // props.cardsRef.current!.style.transition = "0.5s cubic-bezier(.6,.44,.83,.67)";
          // props.cardsRef.current!.style.transform = `translateX(${Math.sign(delta[0]) * windowWidth * 1.2}px)`;
          isSwipedX = true;
        }
      } else if (absDeltaX > windowWidthFifthRef.current && delta[1] > -100 && !isSwipedX) {
        // props.cardsRef.current!.style.opacity = `${lerp(1, 0, absDeltaX / (windowWidth * 2))}`;
        // props.cardsRef.current!.style.transform = `translateX(${delta[0]}px)`;
        isSwipedX = true;
      }

      // swipe up or down
      if (deltaYLessThanSixth) {
        // next post
        if (!isSwipedY) {
          if(props.fetchLockRef.current) {
            return // previous fetch card still loading, prevent fetching
          }
          isSwipedY = true;
        }
      }
      
    },
    
    onRelease: ({delta}) => {
      if (scrollingRef.current) return;
      if(props.cardsRef.current && getHighestZIndex()!=Number(props.cardsRef.current!.style.zIndex)) return
      // handle end gesture using dx and dy
      console.log('release', delta, props.fetchLockRef.current)
      longPressActive.current = false;

      // launch modal on finger release
      // if (showBookmarkListRef.current){
      //   triggerModelOpenRef.current = false
      //   longPressActive.current = false;
      // } else 
      
      // toast('shoud be false: ' + triggerModelOpenRef.current)
      if(triggerModelOpenRef.current){
        triggerModelOpenRef.current = false
          setShowModal(true)
      }
          
          // Animate the card back to its original position or out of the view depending on how far the user dragged the card 👇
          const windowWidth = window.innerWidth
          const windowHeight = window.innerHeight
          // hanging card
          if(ref.current) ref.current.style.transition =
          '0.5s cubic-bezier(.6,.44,.83,.67)'
          console.log('endddd')
          // Always set default to not fetch new post
          props.setFetchPost(false)


          // next post
          if (isSwipedY || delta[1] < -windowHeight / 6) {
            if(props.fetchLockRef.current) return // previous fetch card still loading, prevent fetching
            isSwipedY = false
            props.cardsRef.current!.style.opacity = '1'
            props.setCurrentIdx(props.idx > 0 ? props.idx-1 : 8)
            props.setIsPrev(false)
            registerPostView()
          }
          // previous post
          else if (delta[1] > windowHeight / 6) {
            if (props.photos[cIdx].slice(-4) == '.mp4'){
                myVideoRef.current && myVideoRef.current.pause()       
              setVidPlay(false)
            } 
            if(ref.current) ref.current.style.transform = ''
            props.cardsRef.current!.style.opacity = '1'
            console.log("should enter once", props.idx, delta[1], windowHeight)
            props.setCurrentIdx(props.idx < 8 ? props.idx+1 : 0)
            props.setIsPrev(true)
            registerPostView()
          }
          // dismiss post
          else if (isSwipedX && delta[0] < -windowWidth / 4 ) {
            const windowWidth = window.innerWidth
            props.cardsRef.current!.style.transition =
              '0.3s cubic-bezier(.6,.44,.83,.67)'
            props.cardsRef.current!.style.transform = `translateX(-${windowWidth * 1.2}px)`
            if (isSwipedX) isSwipedX = false
            if (props.map != null){
              props.map.dragging.enable();
              props.map.touchZoom.enable();
              props.map.doubleClickZoom.enable();
              props.map.scrollWheelZoom.enable();
              props.map.boxZoom.enable();
              props.map.keyboard.enable();
              if (props.map.tap) props.map.tap.enable();
            }
            setTimeout(function(){
              props.setShowContent(false)
            }, 300);
            registerPostView()
          }
          // dismiss post
          else if (isSwipedX && delta[0] > windowWidth / 4) {
            const windowWidth = window.innerWidth
            props.cardsRef.current!.style.transition =
              '0.3s cubic-bezier(.6,.44,.83,.67)'
            props.cardsRef.current!.style.transform = `translateX(${windowWidth * 1.2}px)`
            if (isSwipedX) isSwipedX = false
            if (props.map != null){
              props.map.dragging.enable();
              props.map.touchZoom.enable();
              props.map.doubleClickZoom.enable();
              props.map.scrollWheelZoom.enable();
              props.map.boxZoom.enable();
              props.map.keyboard.enable();
              if (props.map.tap) props.map.tap.enable();
            }
            setTimeout(function(){
              props.setShowContent(false)
            }, 300);
            registerPostView()
          }
          // else {
          //   if(ref.current) ref.current.style.transform = ''
          //   props.cardsRef.current!.style.opacity = '1'
          //   props.cardsRef.current!.style.transform = ''
          //   if(ref.current) ref.current.style.opacity = '1'
          // }

    },
  });


  
  const serverBookmarkPost = async () => {
    try {
      const response = await fetch('https://outvirt.com:8080/bookmarkpost', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, token: state.token, tablename: props.tablename, post:  true})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  const serverUnbookmarkPost = async () => {
    try {
      const response = await fetch('https://outvirt.com:8080/unbookmarkpost', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, token: state.token, tablename: props.tablename, post:  true})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
    }
  }



  const serverLikePost = async () => {
    try {
      const response = await fetch('https://outvirt.com:8080/likepost', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, token: state.token, tablename: props.tablename, post:  true})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const serverUnlikePost = async () => {
    try {
      const response = await fetch('https://outvirt.com:8080/unlikepost', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, token: state.token, tablename: props.tablename, post:  true})
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      }
    } catch (err) {
      console.log(err);
    }
  }

  var longPressActive = useRef(false);
  var longPressActiveLock = useRef(false);
  var isClickedRef = useRef(false);
  var clickTimeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (props.photos.length === 1) return;
    isClickedRef.current = true;
    // Clear the previous timeout if any
    if (clickTimeoutHandler.current) {
      clearTimeout(clickTimeoutHandler.current);
    }

    clickTimeoutHandler.current = setTimeout(() => {
      isClickedRef.current = false;
    }, 1000);

    const x = event.clientX;
  
    // Get div's dimensions and position
    const divRect = ref.current!.getBoundingClientRect();
  
    if (x < divRect.left + divRect.width / 4) {
      if (cIdx - 1 >= 0) {
        setcIdx(cIdx - 1);
        idxRef.current = cIdx - 1;
        lastOnStart.current = 0;
        longPressActive.current = false;
      }
    // } else {
    } else if (x > divRect.left + divRect.width * 3 / 4) {
      if (cIdx + 1 < props.photos.length) {
        setcIdx(cIdx + 1);
        idxRef.current = cIdx + 1;
        lastOnStart.current = 0;
        longPressActive.current = false;
      }
    }
  };
 
  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [animationTriggerLike, setAnimationTriggerLike] = useState(false);
  const [animationTriggerBookmark, setAnimationTriggerBookmark] = useState(false);

  const pulseCenterKeyframes = [
    { transform: "scale(1)", color: "rgba(255, 255, 255, 0.5)" },
    { transform: "scale(1.1)", color: "rgba(255, 255, 255, 0.6)" },
    { transform: "scale(1)", color: "rgba(255, 255, 255, 0.7)" },
    { transform: "scale(1.2)", color: "rgba(255, 255, 255, 0.8)" },
    { transform: "scale(1)", color: "rgba(255, 119, 153, 0.9)" },
    { transform: "scale(1)", color: "rgba(255, 119, 153, 0.7)" },
    { transform: "scale(1)", color: "rgba(255, 119, 153, 0.3)" },
    { transform: "scale(1)", color: "rgba(255, 119, 153, 0.0)" },
  ];
  
  const pulseLikeKeyframes = [
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.5)" },
    { transform: 'scale(1.1)', color: "rgba(255, 255, 255, 0.6)" },
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.7)" },
    { transform: 'scale(1.2)', color: "rgba(255, 255, 255, 0.8)" },
    { transform: 'scale(1)', color: "rgba(255, 119, 153, 0.9)" },
  ]
  const pulseUnlikeKeyframes = [
    { transform: 'scale(1)', color: "rgba(255, 119, 153, 0.5)" },
    { transform: 'scale(1.1)', color: "rgba(255, 119, 153, 0.6)" },
    { transform: 'scale(1)', color: "rgba(255, 119, 153, 0.7)" },
    { transform: 'scale(1.2)', color: "rgba(255, 119, 153, 0.8)" },
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.7)" },
  ];
  
  
  const pulseBookmarkKeyframes = [
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.5)" },
    { transform: 'scale(1.1)', color: "rgba(255, 255, 255, 0.6)" },
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.7)" },
    { transform: 'scale(1.2)', color: "rgba(255, 255, 255, 0.8)" },
    { transform: 'scale(1)', color: "rgba(51, 189, 120, 0.9)" },
  ]
  const pulseUnbookmarkKeyframes = [
    { transform: 'scale(1)', color: "rgba(51, 189, 120, 0.5)" },
    { transform: 'scale(1.1)', color: "rgba(51, 189, 120, 0.6)" },
    { transform: 'scale(1)', color: "rgba(51, 189, 120, 0.7)" },
    { transform: 'scale(1.2)', color: "rgba(51, 189, 120, 0.8)" },
    { transform: 'scale(1)', color: "rgba(255, 255, 255, 0.7)" },
  ];
  

  const centerLikeTrail = useTrail(pulseCenterKeyframes.length, {
    from: { transform: "scale(1)", color: "rgba(255, 255, 255, 0.5)" },
    to: animationTrigger ? pulseCenterKeyframes : { transform: "scale(1)", color: "rgba(255, 255, 255, 0.5)" },
    config: { duration: 1000/8 },
    // onRest: () => setAnimationTrigger(false),
  });

  
  const likeAnimation = useSpring({
    to:  animationTriggerLike ?
        (likePost ? pulseUnlikeKeyframes : pulseLikeKeyframes) :
        (likePost ? { color: "rgba(255, 119, 153, 0.9)", transform: "scale(1)" } : { color: "rgba(255, 255, 255, 0.7)", transform: "scale(1)" }),
    config: { duration: likePost ? 500/5 : 1000/5 },
  });

  
  const bookmarkAnimation = useSpring({
    to:  animationTriggerBookmark ?
        (bookmarkPost ? pulseUnbookmarkKeyframes : pulseBookmarkKeyframes) :
        (bookmarkPost ? { color: "rgba(51, 189, 120, 0.9)", transform: "scale(1)" } : { color: "rgba(255, 255, 255, 0.7)", transform: "scale(1)" }),
    config: { duration: bookmarkPost ? 500/5 : 1000/5 },
  });


  const playCenterLikeAnimation = () => {
    console.log('played')
    setAnimationTrigger(true);
    setTimeout(() => {
      setAnimationTrigger(false);
    }, 1000);
  };

  const playLikeButtonAnimation = () => {
    setAnimationTriggerLike(true);
  
    setTimeout(() => {
      setAnimationTriggerLike(false);
    }, likePost ? 500 : 1000);
  };

  const playBookmarkButtonAnimation = () => {
    setAnimationTriggerBookmark(true);
  
    setTimeout(() => {
      setAnimationTriggerBookmark(false);
    }, bookmarkPost ? 500 : 1000);
  };

  const modal = useRef<Modal>(null);

  const registerPostView = async () => {
    
    // End measuring time
    const endTime = performance.now();
    // Calculate elapsed time
    const elapsedTime = endTime - startTime.current;
    try {
      const response = await fetch('https://outvirt.com:8080/registerview', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: props.id,
            view_time: elapsedTime,
            user_id: state.id
          })
      });
      if (!response.ok) {
          throw new Error(response.statusText);
      }
    } catch (err) {
        console.log(err);
    }
  }

  // sub card
  const subcardRef = React.useRef<HTMLInputElement>(null)
  // const [postProps, setPostProps] = useState<PostProps>({ post_id: 0, tablename: '' });
  // const [showPostModal, setShowPostModal] = useState(false);
  const [currentIdx, setCurrentIdx] = useState<number>(4);
  const [isPrev, setIsPrev] = useState<boolean>(false);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [fetchPost, setFetchPost] = useState<boolean>(false);
  const arrowClickRef = useRef(false)
  const fetchLockRef = useRef<any>(false);
  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [post, setPost] = useState<PostCard>();
  const [showCardTransition, setShowCardTransition] = useState<boolean>(false);
  
  // fetch a single post and display as card
  const fetchSinglePost = async (id: number, tablename: string) => {
    try {
      const response = await fetch('https://outvirt.com:8080/fetchsinglepostbyid', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: id, user_id: state.id, tablename: tablename })
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        // console.log(res_data);

        setFetchBuffer([res_data])
        res_data.idx = 0

        setPost(res_data)

        setCurrentIdx(0)
        setIsPrev(false)
        setShowContent(true)

      })
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (showContent){
      setTimeout(() => {
        setShowCardTransition(true)
      }
      , 100)
    } else {
      setShowCardTransition(false)
    }
  }, [showContent])


  useEffect(() => {
    if (post && post.idx != currentIdx){
      setTimeout(() => {
        setShowContent(false)
        setFetchBuffer([])
      }, 500)
    }
  }, [currentIdx, fetchPost]);

  // to handle bookmark long press
  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout | null>(null);

const handleMouseDown = () => {
  const timerId = setTimeout(() => {
    setShowBookmarkList(true);
    window.navigator.vibrate(50);
    triggerModelOpenRef.current = false
  }, 1000);  // long press is usually recognized after 1 second

  setPressTimer(timerId);
};

const handleMouseUp = () => {
  if (pressTimer) {
    clearTimeout(pressTimer);
  }
  setPressTimer(null);
};

const generateGoogleMapsUrl = (response: Location[]): string => {
  const waypoints = response
    .map((location: Location) => {
      if (location.place_id) {
        return `place_id:${location.place_id}`;
      }
      return `${location.lat},${location.lon}`;
    })
    .join('|');
    const waypoints_place_ids = response
      .map((location: Location) => {
        if (location.place_id) {
          return `${location.place_id}`;
        }
        return `${location.lat},${location.lon}`;
      })
      .join('|');

  return `https://www.google.com/maps/dir/?api=1&waypoints=${waypoints}&waypoint_place_ids=${waypoints_place_ids}`;
};
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

  return (
    <>
    {
      showContent &&
      <div ref={subcardRef}
              style={{
                // height: `${viewportHeight}px`,
      // height: `calc(${viewportHeight}px - 1rem)`,
                zIndex: cardZIndex + 3,
              }}
              className={`cards-stack-container-sub ${showCardTransition ? 'final' : 'initial'}`}>
          <ContentCard {...{ ...post!, card_name: generateRandomString(5), map: props.map, cardsRef: subcardRef, setPostProps: props.setPostProps, setShowPostModal: props.setShowPostModal, showPostModal: props.showPostModal, currentIdx: currentIdx, setCurrentIdx: setCurrentIdx, isPrev: isPrev, setIsPrev: setIsPrev, setShowContent: setShowContent, setFetchPost: setFetchPost, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef }} />
              </div>
    }
    
    <div id={'c'+props.card_name+props.idx} {...bind } ref={ref} 
    style={{
      // height: '100vh',
      height: `calc(${viewportHeight}px - 1rem)`,
      maxHeight: '1280px',
        // height: `${viewportHeight}px`,
        // overflowY: 'hidden'
        // boxSizing: 'border-box', // Include padding and border in the total height calculation

      }}
>
  {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={props.user_id} map={props.map}/>}
  {showLikeList && <LikeList showLikeList={showLikeList} setShowLikeList={setShowLikeList} post_id={props.id} />}
  {showBookmarkList && <BookmarkList showBookmarkList={showBookmarkList} setShowBookmarkList={setShowBookmarkList} post_id={props.id} tablename={props.tablename} />}
    <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="Modal"
        overlayClassName="modal-overlay"
        ref={modal}
        style={{
          overlay: {
            zIndex: cardZIndex + 2,
            // add other styles for the overlay here
          },
          content: {
            zIndex: cardZIndex + 2,
            // add styles for the content here
          },
        }}
      >
        <div className="modal-content">
            {
                (props.user_id == state.id) && (
                    <div className="list-item" onClick={() => {
                      setDeleteDialog({show: true, id: props.id, image: props.photos[0]})
                      setShowModal(false)
                    }}>
                        <div className="list-icon"> <DeleteOutline/> </div>
                        <div className="list-text unselectable">
                            <h5>{t("options.deletepost")}</h5>
                            <p>{t("options.removepost")}</p>
                        </div>
                    </div>
                )
            }
            {
                (props.user_id == state.id) && (
                    <div className="list-item" onClick={() => {
                      console.log('click edit post')
                      dispatch({type: 'setEditPost', editPost: true, editPostId: props.id})
                      setShowModal(false)
                    }}>
                    <div className="list-icon"> <Edit/> </div>
                    <div className="list-text unselectable">
                        <h5>{t("options.editpost")}</h5>
                        <p>{t("options.modifypost")}</p>
                    </div>
                    </div>
                )
            }
            <div className="list-item" onClick={async() => {
                setShowModal(false)
                const response = await fetch('https://outvirt.com:8080/filterpost', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({post_id: props.id, user_id: props.user_id})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    toast(t('options.lessshown'))
                }
            }}>
                <div className="list-icon"> <FilterList/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.filterpost")}</h5>
                <p>{t('options.lesscontents')}</p>
                </div>
            </div>
            <div className="list-item" onClick={async() => {
                setShowModal(false)
                const response = await fetch('https://outvirt.com:8080/filterpost', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({post_id: props.id, user_id: props.user_id})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    toast(t('options.postblocked'))
                }
            }}>
                <div className="list-icon"> <Block/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.blockpost")}</h5>
                <p>{t('options.blockthispost')}</p>
                </div>
            </div>
            <div className="list-item" onClick={async() => {
                setShowModal(false)
                const response = await fetch('https://outvirt.com:8080/filterpost', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({post_id: props.id, user_id: props.user_id})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    toast(t('options.postreported'))
                }
            }}>
                <div className="list-icon"> <Report/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.reportpost")}</h5>
                <p>{t('options.objpost')}</p>
                </div>
            </div>
            <div className="list-item" onClick={async() => {
                setShowModal(false)
                const response = await fetch('https://outvirt.com:8080/filterpost', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({post_id: props.id, user_id: props.user_id})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    toast(t('options.hidepost'))
                }
            }}>
                <div className="list-icon"> <Block/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.blockuser")}</h5>
                <p>{t('options.contentsnotshown')}</p>
                </div>
            </div>
            <div className="list-item" onClick={async() => {
                setShowModal(false)
                const response = await fetch('https://outvirt.com:8080/filterpost', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({post_id: props.id, user_id: props.user_id})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    toast(t('options.userreported'))
                }
            }}>
                <div className="list-icon"> <Report/> </div>
                <div className="list-text unselectable">
                <h5>{t("options.reportuser")}</h5>
                <p>{t('options.userabuse')}</p>
                </div>
            </div>


        </div>
        <div className="fade-in-anim">
            <button onClick={() => setShowModal(false)}>
                <div className='close-text unselectable'>
                    <p>
                    Close
                    </p>

                    </div>
                
            </button>
        </div>
      </Modal>

      {/* delete post */}
      <Dialog style={{ zIndex: cardZIndex + 2 }} open={deleteDialog.show} onClose={() => setDeleteDialog({show: false, id: 0, image: ''})}>
        <DialogTitle className="dark-dialog-title">Delete</DialogTitle>

        <DialogContent className={`dark-dialog-inputbox-content`}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
            <div
              className="delete-icon"
            >
              {
                (deleteDialog.image.slice(-4) == '.mp4') ? (
                  <div
                    className="map-high-level-nav-vid"
                  >
                    <video
                      loop={true} muted={true} autoPlay={true} playsInline={true}
                      preload="auto" src={`https://outvirt.com/assets/img/${deleteDialog.image}`} />
                  </div>
                ) : (
                  <div
                    className="map-high-level-nav"
                    style={{ "backgroundImage": `url(https://outvirt.com/assets/img/${deleteDialog.image})` }}
                  />
                )
              }
              </div>
              {/* <Avatar
                src={`https://outvirt.com/assets/img/${deleteDialog.image}`}
                style={{ borderRadius: "50%" }}
              /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={`dark-dialog-inputbox-content`}>
          <Button onClick={() => setDeleteDialog({show: false, id: 0, image: ''})} color="primary">
            Cancel
          </Button>
          <Button onClick={async() => {
            try {
              const response = await fetch('https://outvirt.com:8080/deletepost', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({token: state.token, postId: deleteDialog.id})
              });
              if (!response.ok) {
              throw new Error(response.statusText);
              } else {
                  if (response!.status == 201){
                      toast(t('options.postdeleted'))
                      
                      props.cardsRef.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
                      props.cardsRef.current!.style.transform = 'scale(0)';
                      props.cardsRef.current!.style.opacity = '0';
                      if (props.map != null){
                          props.map.dragging.enable();
                          props.map.touchZoom.enable();
                          props.map.doubleClickZoom.enable();
                          props.map.scrollWheelZoom.enable();
                          props.map.boxZoom.enable();
                          props.map.keyboard.enable();
                          if (props.map.tap) props.map.tap.enable();
                        }
                        setTimeout(function(){
                          props.setShowContent(false)
                        }, 200);
                  }
                  else if (response!.status == 400){
                      toast('Token expired.')
                  }
                  else {
                      toast('Internal error.')
                  }
              }
            } catch (err) {
                console.log(err);
            }

            setDeleteDialog({show: false, id: 0, image: ''})

          }} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>


      {/* <div className="buttons-container">
      <div className="updown-button-container">
        <div className="page-button" style={{zIndex: props.cardsRef.current?.style.zIndex}}>
          <IconButton onClick={(event)=>{
            if(props.fetchLockRef.current) return // previous fetch card still loading, prevent fetching
            // event.stopPropagation()
            console.log("UP", props.body, props.idx, props.currentIdx)
            props.arrowClickRef.current = true
            setTimeout(() => {
              props.arrowClickRef.current = false
            }, 500);
            props.setCurrentIdx(props.currentIdx > 0 ? props.currentIdx-1 : 8) // 0~8
            props.setIsPrev(false)
            registerPostView()
            

          }}>
            <div style={{ transform: 'scale(1)' }} className="no-padding">
                <ArrowUpwardIcon />
            </div>
          </IconButton>
        </div>
        <div className="page-button" style={{zIndex: props.cardsRef.current?.style.zIndex}}>
          <IconButton onClick={(event)=>{
            console.log("DOWN", props.body, props.idx, props.currentIdx)
            props.arrowClickRef.current = true
            setTimeout(() => {
              props.arrowClickRef.current = false
            }, 500);

            props.setCurrentIdx(props.currentIdx < 8 ? props.currentIdx+1 : 0) // 0~8
            props.setIsPrev(true)

            
            // End measuring time
            const endTime = performance.now();
            // Calculate elapsed time
            const elapsedTime = endTime - startTime.current;
            registerPostView()

          }}>
            <div style={{ transform: 'scale(1)' }} className="no-padding">
              <ArrowDownwardIcon />
            </div>
          </IconButton>
        </div>
      </div>
    </div> */}

      
      <div className="card-container">
        <div className="image-container">
        {
              props.photos[cIdx] && 
              (props.photos[cIdx].slice(-4) == '.mp4') ? (
                <video onContextMenu={handleContextMenu} id={props.card_name+props.idx} loop={true} muted={false} autoPlay={false} playsInline={true} width={"100%"} height={"100%"}
                preload="auto" onClick={handleClick}
                  src={"https://outvirt.com/assets/img/" + props.photos[cIdx]}
                >
                </video>
              ) : (
                <img onContextMenu={handleContextMenu} alt="card contents" className="card-img" src={"https://outvirt.com/assets/img/" + props.photos[cIdx]} onClick={handleClick} />
              )
              }
        </div>
      </div>
      
      <div className="location-container">
        <div className="location-card-container">
          <div className="card-location" onClick={async () => {
            if (props.zoom!=0) {
              if (referencePosts.length == 0) await fetchSubPosts()
              setShowSubPostsList(!showSubPostsList)
            } else {
              console.log('loc', props.loc_name, props.lat, props.lon)
              try {
                const response = await fetch('https://outvirt.com:8080/fetchplaceid', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      id: props.id,
                      tablename: props.tablename
                    })
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                  response.json().then((res)=>{
                    if (res.place_id == '') {
                      window.open(`https://www.google.com/maps/search/?api=1&query=${res.lat},${res.lon}`, '_blank');
                    } else {
                      window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${res.place_id}`, '_blank');
                    }
                    // props.cardsRef.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
                    // props.cardsRef.current!.style.transform = 'scale(0)';
                    // props.cardsRef.current!.style.opacity = '0';
                    // if (props.map != null){
                    //     props.map.dragging.enable();
                    //     props.map.touchZoom.enable();
                    //     props.map.doubleClickZoom.enable();
                    //     props.map.scrollWheelZoom.enable();
                    //     props.map.boxZoom.enable();
                    //     props.map.keyboard.enable();
                    //     if (props.map.tap) props.map.tap.enable();
                    //   }
                    //   setTimeout(function(){
                    //     props.setShowContent(false)
                    //   }, 200);
                    // console.log(props.id, props.lat, props.lon)
                    // // show specified content on map
                    // dispatch({ type: "setNavigate",
                    //   navigate: true,
                    //   navigate_img: props.photos[0],
                    //   navigate_lat: props.lat,
                    //   navigate_lon: props.lon,
                    //   navigate_place_id: res.place_id
                    // });
                  })
                }
              } catch (err) {
                  console.log(err);
              }

            }
            }}>
            <IconButton color="primary">
              <div className="card-location-icon">
                {props.zoom == 0 ? 
                <Navigation style={{color: "rgb(255, 255, 255)"}}/> : 
                <ListAltIcon style={{color: "rgb(255, 255, 255)"}}/>}
              </div>
            </IconButton>
            <Typography variant="caption"  className='details-font card-location-text' onClick={async () => {
              // Your original location name click logic here...
            }}>
              {props.loc_name}
            </Typography>
          </div>
          {
            (props.zoom!=0) && (showSubPostsList) ? (
              <div    onClick={async () => {
              }}>
                <Box className='subposts-parent-container'>
                  <Box className='subposts-container'>
                  {props.photos.slice(1).map((photo, idx) => {
                    return (
                      <div
                        key={idx}
                        onClick={() => {
                          setcIdx(idx+1)
                        }}
                      >
                        <Grid container direction="column" alignItems="center" className="bookmark-cat" spacing={1}>
                          <Grid item>
                          <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
                            <div
                              className="subposts-icon"
                            >
                              {
                                (photo.slice(-4) == '.mp4') ? (
                                  <div
                                    className="map-high-level-nav-vid"
                                  >
                                    <video
                                      loop={true} muted={true} autoPlay={true} playsInline={true}
                                      preload="auto" src={`https://outvirt.com/assets/imgmap/${photo}`} />
                                  </div>
                                ) : (
                                  <div
                                    className="map-high-level-nav"
                                    style={{ "backgroundImage": `url(https://outvirt.com/assets/imgmap/${photo}` }}
                                  />
                                )
                              }
                            </div>
                          </div>
                          </Grid>
                          <Grid item>
                            <Typography style={{fontSize: "12px", color: "white"}}>{referencePosts[idx].loc_name}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </Box>
              </Box>
              
            </div>
            ) : (
              (cIdx!=0 && referencePosts[cIdx-1]) &&
              <div className="card-location subcard-location"   onClick={async () => {
                if (referencePosts[cIdx-1].place_id == '') {
                  window.open(`https://www.google.com/maps/search/?api=1&query=${referencePosts[cIdx-1].lat},${referencePosts[cIdx-1].lon}`, '_blank');
                } else {
                  window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${referencePosts[cIdx-1].place_id}`, '_blank');
                }
              }}>
              <IconButton color="primary">
                <div className="card-location-icon card-location-icon-nopadding">
                  <Navigation className="small-font" style={{color: "rgb(255, 255, 255)"}}/>
                </div>
              </IconButton>
              <Typography variant="caption"  className='details-font small-font' onClick={async () => {
                // Your original location name click logic here...
              }}>
                {referencePosts[cIdx-1].loc_name}
              </Typography>
            </div>
            )
          }
        </div>
        {
          showSubPostsList ? (
            <div> 
            <IconButton color="primary" onClick={()=>{
            }}>
              <div className="card-location-icon">
                <MapIcon style={{color:"#fff"}} />
              </div>
            </IconButton>
            <IconButton color="primary" onClick={async()=>{
              try {
                const response = await fetch('https://outvirt.com:8080/fetchlistloc', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ids: referencePosts.map((post)=>{return post.id}), tablenames: referencePosts.map((post)=>{return post.tablename})})
              });
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                response.json().then(res_data => {
                  var url
                  const isMobile = isMobileDevice()
                  console.log(isMobile, navigator.userAgent);
                  if (isMobile) {url = generateGoogleMapsUrl(res_data.slice(0,3))}
                  else url = generateGoogleMapsUrl(res_data)

                  console.log(url)
                  const hasProtocol = /^https?:\/\//i.test(url);
                  const absoluteUrl = hasProtocol ? url : "http://" + url;
                  window.open(absoluteUrl, "_blank");
                })
              } catch (err) {
                console.log(err);
              }
            }}>
              <div className="card-location-icon">
                <GoogleIcon style={{color:"#fff"}} />
              </div>
            </IconButton>
            
            </div>
          ):
            (props.url != '') && <div onClick={()=>{
              const url = props.url;
              const hasProtocol = /^https?:\/\//i.test(url);
              const absoluteUrl = hasProtocol ? url : "http://" + url;
              window.open(absoluteUrl, "_blank");
          }}> 
          <IconButton color="primary">
            <div className="card-location-icon">
              <WebIcon style={{color:"#fff"}} />
            </div>
          </IconButton>
          
          </div>
        }
      </div>
      <div className="details-container">
        <div className="card-user">
          <div className="profilepic-wrapper" onClick={async () => {
            setShowStandardProfile(true)
            }}>
            <div
                className="profilepic-container"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/imgmap/${props.profilepic})`,
                }}
              />
            </div>
            <Typography variant="caption" className="details-font" onClick={async () => {
              setShowStandardProfile(true)
            }}>
              &nbsp;&nbsp;{props.name}
            </Typography>
            {
                // (props.account_type === 1) ? <div> <PublicIcon onClick={()=>{
                (props.user_url != '' && props.user_url != undefined) ? <div> <PublicIcon onClick={()=>{
                    const url = props.user_url;
                    const hasProtocol = /^https?:\/\//i.test(url);
                    const absoluteUrl = hasProtocol ? url : "http://" + url;
                    window.open(absoluteUrl, "_blank");
                }} style={{fontSize:'16px', color:"#999", margin: "5px", display:"flex" }} /> </div> : null
            }
          <Typography variant="caption"  className="subdetails-font">
            &nbsp;&nbsp;· {ParseDate(props.created_at, t)}&nbsp;&nbsp;
          </Typography>
        </div>
        <Typography variant="caption"  className="details-font">
        {
          (props.zoom!=0 && cIdx!=0 && referencePosts[cIdx-1]) ? (
            <>
            {
              referencePosts[cIdx-1].subbody ? (
                (referencePosts[cIdx-1].subbody.length > 30 && hideText) ? (
                  <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={()=>{setHideText(false)}}>{referencePosts[cIdx-1].subbody.slice(0,30)+'...'}
                  <Typography variant="caption" className="subdetails-font">
                    {' ' + t('readmore')}
                  </Typography>
                  </div>
                ) : (
                    (referencePosts[cIdx-1].subbody.length > 300) ? (
                      <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={()=>{
                        setHideText(true)
                        props.setPostProps({post_id: props.id, tablename: props.tablename})
                        props.setShowPostModal(true)
                      }}>{referencePosts[cIdx-1].subbody.slice(0,300)+'...'}
                        <Typography variant="caption" className="subdetails-font">
                          {' ' + t('readmore')}
                        </Typography>
                      </div>
                    ):(
                      <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={() =>{
                        setHideText(true)
                      }}>
                          {referencePosts[cIdx-1].subbody}
                      </div>
                    )
                )
  
              ) : null

            }
            <div className="card-user-sub" onClick={()=>{
              // fetchSinglePost(referencePosts[cIdx-1].id)
              fetchSinglePost(referencePosts[cIdx-1].id, referencePosts[cIdx-1].tablename)
            }}>
              <div className="profilepic-wrapper-sub">
                <div
                    className="profilepic-container"
                    style={{
                      backgroundImage: `url(https://outvirt.com/assets/imgmap/${referencePosts[cIdx-1].profilepic})`,
                    }}
                  />
              </div>
              <Typography variant="caption"  className="typography-no-margin typo-align-top">
                &nbsp;&nbsp;{
                  referencePosts[cIdx-1].body ? (
                    (referencePosts[cIdx-1].body.length > 30) ? (
                      <div className="caption" style={{"whiteSpace":"pre-wrap"}}>{referencePosts[cIdx-1].body.slice(0,30)+'...'}</div>
                    ) : (
                      <div className="caption" style={{"whiteSpace":"pre-wrap"}}>{referencePosts[cIdx-1].body}</div>
                    )
      
                  ) : null

                }
                {/* <div  className="subdetails-font">
                  {ParseDate(referencePosts[cIdx-1].created_at, t)}&nbsp;&nbsp;
                </div> */}
              </Typography>
        </div>
            

            </>
          ) : (
            props.body ? (
              (props.body.length > 30 && hideText) ? (
                <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={()=>{setHideText(false)}}>{props.body.slice(0,30)+'...'}
                <Typography variant="caption" className="subdetails-font">
                  {' ' + t('readmore')}
                </Typography>
                </div>
              ) : (
                  (props.body.length > 300) ? (
                    <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={()=>{
                      setHideText(true)
                      props.setPostProps({post_id: props.id, tablename: props.tablename})
                      props.setShowPostModal(true)
                    }}>{props.body.slice(0,300)+'...'}
                      <Typography variant="caption" className="subdetails-font">
                        {' ' + t('readmore')}
                      </Typography>
                    </div>
                  ):(
                    <div className="caption" style={{"whiteSpace":"pre-wrap"}} onClick={() =>{
                      setHideText(true)
                    }}>
                        {props.body}
                    </div>
                  )
              )

            ) : null

          )
              }
        </Typography>
        {
          props.topic_name.map((topic, idx) =>
            <Chip key={idx + topic} label={`#${topic}`} size="small"
  className={classes.customChip}
  classes={{ label: classes.customChipLabel }}
            onClick={() => { console.log(props.currentIdx) }} />
          )
        }
      </div>

      <div className="card-button" style={{ bottom: '30%' }}>
      <IconButton
        color="primary"
        onClick={() => {
          setShowIcons(!showIcons);
        }}
      >
        <div style={{ color: 'rgba(255, 255, 255, 0.7)', transform: 'scale(1)' }} className="no-padding">
          <ShareIcon />
        </div>
      </IconButton>
      <div className="spacer"></div>

      <div className={`icons-container ${showIcons ? 'show' : ''}`}>
        <div>
          <IconButton color="primary" onClick={async() => {
            setShowUserSelection(true)
            setShowIcons(false)
          }}>
            <SendIcon className="popout-button"/>
          </IconButton>
          <IconButton color="primary"  onClick={async() => {
            try {
              if (navigator.share) {
                await navigator.share({
                  title: 'Outvirt',
                  text: props.body,
                  url: `https://outvirt.com/map?post=${props.id}`,
                });
                console.log('Content shared');
              } else {
                await navigator.clipboard.writeText(`https://outvirt.com/map?post=${props.id}`);
                toast('Copied to clipboard', {type: 'success', autoClose: 2000})
                console.log('Share not supported, copied to clipboard instead');
              }
            } catch (err) {
              console.log('Failed to share or copy: ', err);
            }
            setShowIcons(false)
          }}>
            <LinkIcon className="popout-button"/>
          </IconButton>

        </div>
      </div>
    </div>

    <div className={`usershow-container ${showUserSelection ? 'show' : ''}`}>
      <SearchPeopleSelect setSendList={setSendList} sendList={sendList}/>
      <Button
            variant="contained"
            className="userselection-icon"
            onClick={async () => {
              setShowUserSelection(false)
              try {
                const response = await fetch('https://outvirt.com:8080/sendpost', {
                    method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({sender: state.id, receipients: sendList, text: `/post=${props.id}`})
                });
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else{
                  toast.success(t('sent'))
                }
              } catch (err) {
                console.log(err);
              }
              setSendList([])
            }}
            // startIcon={<CloseIcon />}
          >
            Send
          </Button>

    </div>

    

      
      <div className="card-button" style={{ "bottom": "20%" }}>
        
        <IconButton color="primary"
        
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onClick={async() => {
            if (state.id == 0){
              toast.error(t('acc_req'))
              return
            }

            // setShowBookmarkList(true)

          const nBookmarkPost = !bookmarkPost;
            console.log(nBookmarkPost, props.bookmarked, props.id)
          if (!nBookmarkPost && props.bookmarked) {
              serverUnbookmarkPost()
              playBookmarkButtonAnimation();
          } else if ((!nBookmarkPost && !props.bookmarked) || (nBookmarkPost && props.bookmarked)) {
              if (props.bookmarked) serverBookmarkPost()
              else serverUnbookmarkPost()
              playBookmarkButtonAnimation();
          } else if (nBookmarkPost && !props.bookmarked) {
              playBookmarkButtonAnimation();
              serverBookmarkPost()
          }
          await sleep(bookmarkPost ? 500 : 1000);
          setBookmarkPost(nBookmarkPost);
        }}>
          <animated.div style={bookmarkAnimation} className="no-padding">
            <Bookmark />
          </animated.div>
        </IconButton>
        {/* <IconButton color="primary" onClick={async () => {
          // Your original bookmark button click logic here...
        }}>
            <Bookmark style={(bookmarkPost) ? {"color":"#33bd78", "opacity":"0.9"}:{"color":"white", "opacity":"0.7"}} />
        </IconButton> */}
        <div className="spacer"></div>
      </div>
      <div className="card-button" style={{"bottom":"10%"}}>
        <IconButton color="primary" onClick={async() => {
          if (state.id == 0){
            toast.error(t('acc_req'))
            return
          }
        //   playLikeButtonAnimation()
        const nLikePost = !likePost;

        if (!nLikePost && props.liked) {
            setAddLike(-1);
            serverUnlikePost()
            // playUnlikeButtonAnimation()
            playLikeButtonAnimation();
        } else if ((!nLikePost && !props.liked) || (nLikePost && props.liked)) {
            setAddLike(0);
            // playUnlikeButtonAnimation()
            if (props.liked) serverLikePost()
            else serverUnlikePost()
            playLikeButtonAnimation();
        } else if (nLikePost && !props.liked) {
            setAddLike(1);
            playLikeButtonAnimation();
            playCenterLikeAnimation();
            // startLikeAnimation()
            serverLikePost()
        }
        await sleep(likePost ? 500 : 1000);
        setLikePost(nLikePost);
        }}>
        <animated.div style={likeAnimation} className="no-padding">
                <Favorite />
            </animated.div>
        </IconButton>
        <Typography variant="caption" className='details-font' onClick={()=>setShowLikeList(true)}>{props.n_likes + addLike}</Typography>
      </div>

      <div className="card-button" style={{"bottom":"0%"}}>
        <IconButton color="primary" onClick={() => {
          props.setPostProps({post_id: props.id, tablename: props.tablename});
          props.setShowPostModal(true);
        //   console.log('comment');
        //   toast('Download app to view')
        }}>
            <div>
                <ChatBubble style={{color: "rgba(255, 255, 255, 0.7)"}}/>
            </div>
        </IconButton>
        <Typography variant="caption" className='details-font'>{props.n_comments}</Typography>
      </div>
      {
  animationTrigger &&
    centerLikeTrail.map((props, index) => (
      <animated.div
        key={index}
        style={{
          ...props,
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
          top: "35%",
        }}
      >
        <FavoriteIcon style={{ fontSize: "100px" }} />
      </animated.div>
    ))
}
{
    props.photos.length > 1 && (
        <div className="count-container">
        <div style={{ display: "flex", paddingBottom: "0px", marginBottom: "0px" }}>
            {props.photos.map((photo, idx) => {
            return (
                <div
                key={idx}
                style={{
                    paddingBottom: "0px",
                    marginBottom: "0px",
                    justifyContent: "center",
                    display: "flex",
                    flexBasis: `${100 / props.photos.length}%`,
                }}
                >
                <div
                    style={{
                    paddingBottom: "0px",
                    marginBottom: "1px",
                    marginLeft: "0px",
                    marginRight: "0px",
                    height: "2px",
                    width: `95%`,
                    backgroundColor: `${idx === cIdx ? "#ffffff" : "#999999"}`,
                    opacity: "0.5",
                    }}
                ></div>
                </div>
            );
            })}
        </div>
        </div>

    )
}
    </div>
    </>
  );
}

export default React.memo(ContentCard);

