import React from 'react';
import maplibregl from 'maplibre-gl';
import { LatLngBounds, LatLng } from 'leaflet';
import 'maplibre-gl/dist/maplibre-gl.css';
type MlgMarkerProps = {
  mapRef: React.RefObject<maplibregl.Map>
  MarkerIcon: maplibregl.MarkerOptions;
  offset: [number, number];
  location: LatLng;
  handleMouseOver?: () => void;
  handleMouseOut?: () => void;
  handleClick?: () => void;
};

const MlgMarker: React.FC<MlgMarkerProps> = ({
  mapRef,
  MarkerIcon,
  offset,
  location,
  handleMouseOver,
  handleMouseOut,
  handleClick,
}) => {
  React.useEffect(() => {
    // console.log(mapRef.current);
    // Create a new maplibregl marker
    if (mapRef.current !== undefined && mapRef.current !== null) {
      // console.log('IN',MarkerIcon,offset,location)
      const marker = new maplibregl.Marker(MarkerIcon)
        .setLngLat(location)
        .addTo(mapRef.current);
        
        // Add event listeners

        handleMouseOver && marker.getElement().addEventListener('mouseover', handleMouseOver);
        handleMouseOut && marker.getElement().addEventListener('mouseout', handleMouseOut);
        handleClick && marker.getElement().addEventListener('click', handleClick);
        
        // Cleanup function
        return () => {
          // Remove the marker from the map
          marker.remove();
        };
    }
      }, [mapRef, location]); // Empty dependency array to run the effect only once

  return null;
};

export default MlgMarker;