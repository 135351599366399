import React, { useState, FormEvent, useContext, ChangeEvent, useRef } from "react";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
// Import any other required dependencies and styles
import {AppContext} from '../../state'



interface GoogleCredential{
    email: string;
    name: string;
    picture: string;
    sub: string;
  }
  

interface AgarUserInfo {
    team: number;
    name: string;
    score: number;
  }

const LoginComponent = () => {
  const [signUp, setSignUp] = useState(false);
  const team = useRef(false)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const [profilepic, setProfilepic] = useState('');
  const [gid, setGid] = useState('')
  const [leaderBoard, setLeaderBoard] = useState<AgarUserInfo[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [play, setPlay] = useState<boolean>(false);
  const nycu_final = 2960
  const nthu_final = 1311


  function setMultipleLocalStorage(data: Record<string, string>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        localStorage.setItem(key, data[key]);
      }
    }
  }

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    if (selectedOption=='nycu'){
      team.current = true
    } else if (selectedOption=='nthu'){
      team.current = false
    }
    console.log(event.target.value)
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    console.log("Submitted!");
    console.log(email)
    console.log(password)
    login(email, password)
  };
  const handleSignup = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    
    const email = formData.get('email') as string;
    const name = formData.get('name') as string;
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;
    
    console.log("Signup");
    console.log(email, name, username, password)
    signUpUser(email, name, username, password)
  };

  
  const signUpUser = async(email: string, name: string, username: string, password: string) => {
    try {
      const response = await fetch('https://outvirt.com:8080/gsignup', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email, name: name, username: username, password: password, profilepic: profilepic, gid: gid})
      });
      console.log(response)
      if (!response.ok) {
        throw new Error(response.statusText);
        
      } else{
        response.json().then(async(res_data) => {
          if (res_data.email == "taken"){
            
            toast.error('Email is taken', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (res_data.username == "taken"){
            
            toast.error('Username is taken', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if(res_data.email == 'pass' && res_data.username == 'pass'){
            console.log(res_data)
            dispatch({ type: "setUser",
              id: res_data.user.id,
              email: res_data.user.email,
              username: res_data.user.username,
              name: res_data.user.name,
              token: res_data.user.token,
              profilepic: res_data.user.profilepic,
              coins: res_data.user.coins,
            });  
            
            setMultipleLocalStorage({
              id: res_data.id,
              email: res_data.email,
              username: res_data.username,
              name: res_data.name,
              token: res_data.token,
              profilepic: res_data.profilepic,
              coins: res_data.coins,
            })
            setSignUp(false)
            
            
          }
        }
        )
        // console.log(response.json().then(data => data.email))
      }
      console.log(response);
    } catch (err) {
      console.log(err);
    }

  }

  
  const login = async(email: string, password: string) => {

    try {
      const response = await fetch('https://outvirt.com:8080/signin', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email, password: password, authorized: true})
      });
      console.log(response)
      if (!response.ok) {
        throw new Error(response.statusText);
        
      } else{
        response.json().then(async(res_data) => {
          console.log(res_data)
          
          if(res_data.auth == 'pass'){
            toast.success('Logged in successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            dispatch({ type: "setUser",
              id: res_data.id,
              email: res_data.email,
              username: res_data.username,
              name: res_data.name,
              token: res_data.token,
              profilepic: res_data.profilepic,
              coins: res_data.coins,
            });  
            setMultipleLocalStorage({
              id: res_data.id,
              email: res_data.email,
              username: res_data.username,
              name: res_data.name,
              token: res_data.token,
              profilepic: res_data.profilepic,
              coins: res_data.coins,
            })

          } else {
            toast.error('Incorrect email or password', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      }
    } catch (err) {
      console.log(err);
    }
  }
  // Declare state variables and functions
  // ...

  return (
    <>
      {
      // map && map.getZoom() == 18 ? (
      //   state.id == 0 ? (
            signUp ? (
              <div className="login-container">
              <form className="login-form" onSubmit={handleSignup}>
                <h2>Sign Up</h2>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={true}
                    name="email"
                  />
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    // type="email"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    name="name"
                  />
                </div>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    // type="email"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    name="username"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    name="password"
                  />
                </div>
                <button type="submit">Sign Up</button>
                <div className="google-login">
        
        </div>
        
              </form>
            </div>    
            ) : (
            <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
              <h2>Discover reality</h2>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  name="email"
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  name="password"
                />
              </div>
              <button type="submit">Login</button>
              <div className="google-login">
      
              <GoogleLogin
                  onSuccess={async(credentialResponse) => {
                    console.log(credentialResponse);
                    const data: GoogleCredential = jwt_decode(credentialResponse.credential!)
                    console.log(data)
                    try {
                      const response = await fetch('https://outvirt.com:8080/gsignin', {
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({email: data.email, displayName: data.name, imageUrl: data.picture, gid: data.sub, authorized: 'authorized'})
                      });
                      console.log(response)
                      if (!response.ok) {
                        throw new Error(response.statusText);
                      } else{
                        response.json().then(async(res_data) => {
                          console.log('resss: ' + res_data )
                          console.log('resssauth: ' + res_data.auth )
                          if (res_data.auth == "notfound"){
                            setSignUp(true)
                            setName(data.name)
                            setEmail(data.email)
                            setProfilepic(data.picture)
                            setGid(data.sub)
                            // setLoading(false);
                            // history.push({
                            //   pathname: '/tabs/gsignup',
                            //   state: {name: data.displayName, email: data.email, profilepic: data.imageUrl, gid: data.id}
                            // })
                          }
                          else if(res_data.auth == 'pass'){
                            dispatch({ type: "setUser",
                              id: res_data.id,
                              email: res_data.email,
                              username: res_data.username,
                              name: res_data.name,
                              token: res_data.token,
                              profilepic: res_data.profilepic,
                              coins: res_data.coins,
                            });  
                            setMultipleLocalStorage({
                              id: res_data.id,
                              email: res_data.email,
                              username: res_data.username,
                              name: res_data.name,
                              token: res_data.token,
                              profilepic: res_data.profilepic,
                              coins: res_data.coins,
                            })
                  
                          } else {
                            toast.error('Login Failed', {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        }
                        )
                      }
                      console.log(response);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
      />
      </div>
            </form>
            
          </div>    
      
            )
          // ) 
      //     : (
      //       play ? (
      //         <div className="play-container">
      //         <div className="play-form">
                
      //         <div className="score-container">
      //       <div className="score-column">
      //         <div className="avatar">
      //         {/* <img src={`https://outvirt.com/assets/imgmap/${state.profilepic}`} alt={'profile picture'} /> */}
      //           <img src={`https://outvirt.com/assets/img/nycu_logo.png`} alt={'profile picture'} />
      //           <label>
      //           <span>{"總比分"}</span>
      //           {/* <span>{state.name}</span> */}
      //           <div className="coin-text">
      //           {/* <img style={{width: "15px", height: "15px"}} src={`https://outvirt.com/assets/img/${selectedOption=="nycu"?"nycu_logo.png":"nthu_logo.png"}`} alt={'school'} /> */}
      //           <img style={{width: "15px", height: "15px"}} src={`https://outvirt.com/assets/img/agar.jpg`} alt={'coins'} />
      //           {/* {userScore} */}
      //           {nycu_final + nthu_final}
      //           </div>
        
      //           </label>
      //         </div>
      
      //       </div>
            
      //       <div className="score-column">
      //         {
      //           leaderBoard.map((user, index) => {
      //             return (
      //               <div className='user-container'>
      //                 <div className="users">
      //                   <img src={`https://outvirt.com/assets/img/${user.team?"nycu_logo.png":"nthu_logo.png"}`} alt={'profile picture'} />
      //                   <label>
      //                   <span style={{color: user.team!=1?'#e1a1f9':'#83d3ce'}}>{user.name}</span>
      //                   </label>
      //                 </div>
      //                 <div className="user-score">
      //                 {user.score}
      //                 </div>
      //               </div>
      //             )
      //           }
      //           )
      //         }
      //       </div>
      //       </div>
      //         <div className="score-container">
      //       <div className="score-column">
              
      //         <div className="image-container">
      //         <img className="score-image" src={`https://outvirt.com/assets/img/nycu.png`} alt={'nycu'} />
      
      //         </div>
      //         </div>
      //       <div className="score-column">
              
      //         <div className="image-container">
      //         <img className="score-image" src={`https://outvirt.com/assets/img/nthu.png`} alt={'nthu'} />
      //           </div>
      //       </div>
      //     </div>
      //     <div className="bar-container">
          
      //   <div className="bar" style={{
      //   background: `linear-gradient(to right, #83d3ce ${nycu_final / (nycu_final+nthu_final) * 100}%,  #e1a1f9 ${nthu_final / (nycu_final+nthu_final) * 100}%)`,
      //   // background: `linear-gradient(to right, #83d3ce ${leaderScore.nycu / (leaderScore.nycu+leaderScore.nthu) * 100}%,  #e1a1f9 ${leaderScore.nthu / (leaderScore.nycu+leaderScore.nthu) * 100}%)`,
      //   // transform: `translateX(calc(${centerPoint} * (100% - 1px)))`,
      // }}>
      // <span className="left-score">{nycu_final}</span>
      // <span className="right-score">{nthu_final}</span>
      //       </div>
      //     </div>
      //         </div>
            
      //     </div>    
      //       ):(
      
      //         <div className="login-container">
      //         <div className="login-form">
      //         <div className="avatar">
      //           <img src={`https://outvirt.com/assets/imgmap/${state.profilepic}`} alt={'profile picture'} />
      //           <label>
      //           <span>{state.name}</span>
      //           <div className="coin-text">
      //           <img style={{width: "15px", height: "15px"}} src={`https://outvirt.com/assets/img/coin.png`} alt={'coins'} />
      //           {state.coins}
      //           </div>
        
      //           </label>
      //         </div>
      //         <div className="radio-buttons">
      //           <label>
      //             <input type="radio" name="option" value="nycu" 
      //               checked={selectedOption === "nycu"}
      //               onChange={handleOptionChange}/>
      //             <img src={`https://outvirt.com/assets/img/nycu.png`} alt={'nycu'} />
      //           </label>
      //           <label>
      //             <input type="radio" name="option" value="nthu" 
      //               checked={selectedOption === "nthu"}
      //               onChange={handleOptionChange}/>
      //             <img src={`https://outvirt.com/assets/img/nthu.png`} alt={'nthu'} />
      //           </label>
      //         </div>
                
            
      //         <button className={selectedOption===""?"disabled-button":"play-button"}
      //           onClick={()=>{
      //             if (selectedOption != ""){
      //               // start playing!
      //               if (state.id == 0 || state.id == null || state.id == undefined) {
      //                 toast.error("請更新頁面")
      //                 return
      //               } else {
      //                 setPlay(true)
      //                 // startGame()
      //               }
      //             } else {
      //               toast.error("Please select a team!")
      
      //             }
      //           }}
      //          >Play</button>
        
      //         </div>
            
      //     </div>    
      //       )
      //     )
      // ) : null
      }
    </>
  );
};

export default LoginComponent;
