import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );
  const { t } = useTranslation();

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
        <a href = "mailto: outvirt-team@outvirt.com">{t('contact')}</a>
        {/* <Link to="#0">Contact</Link> */}
        </li>
        <li>
          <Link to="/about">{t('aboutus')}</Link>
        </li>
        {/* <li>
          <Link to="#0">FAQ's</Link>
        </li>
        <li>
          <Link to="#0">Support</Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default FooterNav;