import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Avatar, ListItemAvatar, ListItemText, IconButton, TextField, Grid, Typography } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import Modal from 'react-modal';
// import { CloseCircleOutline } from "@material-ui/icons";

import PostItem from './PostItem';
import CommentItem from './CommentItem';
import SubCommentItem from './SubCommentItem';
import './PostContent.css';
import ReplyItem from './ReplyItem';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import StandardProfile from '../user/StandardProfile';

import { useTranslation } from 'react-i18next';
interface ContainerProps {
    tablename: string
    post_id: number
    showPostModal: boolean
    setShowPostModal: React.Dispatch<React.SetStateAction<boolean>>
    webSocket?: WebSocket
    is_object?: boolean
    map: L.Map | undefined;
}

interface UserPhoto {
    srcwebviewPath: string;
    filepath: string;
}

interface Tag {
  id: number;
  username: string;
}

interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
  }
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
    url: string;
  }
  interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[];
    url: string;
  }
  const WS_URL = 'wss://outvirt.com:8080';


const PostContent: React.FC<ContainerProps> = (props) => {
  // ... useState, useRef, and other custom hooks
  const { t } = useTranslation();
  const [showReplyTo, setShowReplyTo] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const [tagList, setTagList] = useState<Tag[]>([])
  const [parentId, setParentId] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [showStandardProfile, setShowStandardProfile] = useState(false);
  const [standardProfileId, setStandardProfileId] = useState<number>(0);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
  const [post, setPost] = useState<Post>(
    {
      id: 0,
      created_at: '1',
      user_id: 0,
      body: 'loading...',
      photos: [],
      name: '',
      username: '',
      profilepic: 'picinit',
      n_comments: 0,
      n_likes: 0,
      n_shares: 0,
      shared: false,
      liked: false,
      tablename: "",
      account_type: 0,
      post_id: 0,
      precise: false,
      url: ''
    }
  );

  const ws = useRef<WebSocket>();

  useEffect(() => {
    console.log('should enter', props.showPostModal)
    setTimeout(() => {
      listRef.current?.scrollTo({
        top: listRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }, 500)
    if (props.post_id != 0 && props.showPostModal){
    //   if (location.pathname === '/tabs/conversation') {
    //     ws.current = props.webSocket
        
    //     ws.current!.send(
    //       JSON.stringify({
    //           type: 'post',
    //           user_id: state.id,
    //           post_id: props.post_id
    //       })
    //     );
    //     initPage(props.post_id, props.tablename)
    //   } else {
    //     ws.current = new WebSocket(WS_URL);
    //   }

      ws.current = new WebSocket(WS_URL);
  
        // Opening the ws connection
        ws.current!.onopen = () => {
            console.log("Connection opened");
            ws.current!.send(
                JSON.stringify({
                    type: 'post',
                    user_id: state.id,
                })
            );
            initPage(props.post_id, props.tablename)
            // setConnectionOpen(true);
        };
  
        // Listening on ws new added messages
        ws.current!.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            // update chat if sender is the same as the chat user
            if (data.post_id==props.post_id && data.type == 'cmt'){
              console.log('refresh')
              // initPage(props.post_id, props.tablename)
              refreshPage()
            }
        };
    
    } else {
        console.log('testtt', ws.current?.readyState)
        if (ws.current != null && ws.current != undefined && ws.current.readyState === WebSocket.OPEN){
            console.log("Cleaning up...");
            // if (location.pathname === '/tabs/conversation'){
            //     ws.current!.send(
            //         JSON.stringify({
            //             type: 'idle',
            //             user_id: state.id,
            //         })
            //     );
            // } else {
            //   ws.current!.close();
            // }
            
            ws.current!.close();
        }
    }
  }, [props.showPostModal]);

//   const [userInfo, setUserInfo] = useState<UserInfo>(
//     {
//       id: 0,
//       email: 'fetching...',
//       username: 'fetching...',
//       name: 'fetching...',
//       token: 'fetching...',
//       profilepic: '1185f8c893242a4df10f02f25bbab5bf',
//     }
//   );

// send message on enter
const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevents adding a newline to the TextField
      handleSubmit(); // Triggers your handleSubmit function
    }
  };

  function setMultipleLocalStorage(data: Record<string, string>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        localStorage.setItem(key, data[key]);
      }
    }
  }
  const initPage = async(post_id: number, tablename: string) => {
    let [lat, lon] = [0,0] // await getLocation();
    try {
    //   const ret = await Preferences.get({ key: 'user' });
    //   const user = JSON.parse(ret.value!);
    //   if (user != null){
    //     setUserInfo(user);
    //   }
      const response = await fetch('https://outvirt.com:8080/fetchpostmodal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({post_id: post_id, tablename: tablename, token: (state.id==0) ? '' : localStorage.getItem('token')})});
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        setPost({...res_data[0], tablename: props.tablename});
        setParentId(res_data[0].id) // post id
        setComments(res_data[1]);
        // if (!iIsPost) {
        //   setOP({...res_data[2], tablename: iPostTable})
        // }
        console.log(res_data)
        // post = res_data[0];
        // setPosts(res_data)
      })
      // response.json().then(res_data => {
      //   console.log(res_data);
      // })
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  const refreshPage = async() => {
    console.log('refresh', props.tablename, props.post_id)
    try {
    //   const ret = await Preferences.get({ key: 'user' });
    //   const user = JSON.parse(ret.value!);
    //   if (user != null){
    //     setUserInfo(user);
    //   }

      const response = await fetch('https://outvirt.com:8080/fetchpostmodal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({post_id: props.post_id, tablename: props.tablename, token: (state.id==0) ? '' : localStorage.getItem('token')})});
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res_data => {
        setPost({...res_data[0], tablename: props.tablename});
        setParentId(res_data[0].id) // post id
        setComments(res_data[1]);
        console.log(res_data)
      })
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  const buildTagList = async (text: string) => {
    var tagBucket : number[] = [];
    const textSplit = text.split(' ');
    for (var i = 0; i < textSplit.length; i++) {
      if(textSplit[i][0] == '@'){
        for (var j = 0; j < tagList.length; j++){
          if (textSplit[i].substring(1) == tagList[j].username){
            if (tagBucket.includes(tagList[j].id)){
              tagList.splice(j, 1)
            } else {
              tagBucket.push(tagList[j].id)
              tagList.splice(j, 1)
            }
          }
        }
      }
    }
    // console.log(tagBucket)
    return tagBucket;
  }
  const handleSubmit = async() => {
    setLoading(true);
    if ( (text!=undefined && text!="") || photos.length!=0){
      await Promise.all( photos.map(async (photo) => {
        let formData = new FormData();
        let img = await fetch(photo.srcwebviewPath!).then(res => res.blob());
        formData.append('photo', img  , photo.filepath);
        try {
            const response = await fetch('https://outvirt.com:8080/upload', {
              method: 'POST',
              body: formData,
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        } catch (err) {
          console.log(err);
        }
      }));
  
      
  
      const photos_string: string[] = [];
      if (photos.length>0){
        photos.map((photo) => {
          photos_string.push(photo.filepath);
        })
      }
      
    //   let [lat, lon] = await getLocation();
      let [lat, lon] = [0, 0];
      const tagBucket = await buildTagList(text!)
      console.log(lat,lon)
      try {
            const response = await fetch('https://outvirt.com:8080/comment', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: state.token, postId: post.id, parentId: parentId, photos: photos_string, text: text, lat: lat, lon: lon, precise: false, postTable: post.tablename, tagList: tagBucket})
          });
        if (!response.ok) {
          // return response;
        } else {
          
          ws.current!.send(
            JSON.stringify({
                type: 'cmt',
                post_id: post.id,
                sender: state.id,
                name: state.name,
                text: text,
            })
          );
          setText("");
          setPhotos([]);
          setParentId(post.id)
          refreshPage();
          // return response;
        }
      } catch (err) {
        console.log(err);
      }
    } else{
      toast('Comment must include at least text or image.')
    }
    setLoading(false);
  }

  const getComment = (comments: Comment[], parentId: number) => {
    for (var i=0; i < comments.length; i++){
      if (comments[i].id == parentId){
        return comments[i];
      }
      for (var j=0; j<comments[i].comments.length; j++){
        if (comments[i].comments[j].id == parentId){
          return comments[i].comments[j];
        }
      }
    }
    return undefined;
  }

  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};

    

  return (
    <Dialog  
      className="dark-dialog"
      open={props.showPostModal}
      onClose={() => props.setShowPostModal(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      style={{ zIndex: getHighestZIndex() + 1 }}
    >
    {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={standardProfileId} map={props.map}/> }

      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        {/* <span>{props.is_object ? 'Discussion' : 'Comments'}</span> */}
        <span>{t('comments')}</span>
        <IconButton color="inherit" onClick={() => props.setShowPostModal(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent ref={listRef} className="dark-dialog-content">
      {/* <div ref={listRef} className="dark-dialog-content" style={{ backgroundColor: 'transparent' }}> */}
        <PostItem
          key={post.id}
          {...{
            idx: 0,
            post: post,
            setPost: setPost,
            top: true,
            setModalInfo: setModalInfo,
            setShowPostModal: props.setShowPostModal,
            setShowStandardProfile: setShowStandardProfile,
            setStandardProfileId: setStandardProfileId,
          }}
        />
      {comments.map((comment, idx) => (
        <div key={comment.id}>
          <CommentItem
          // key={comment.id}
            {...{
              idx: idx,
              post: post,
            //   userInfo: userInfo,
              comment: comment,
              comments: comments,
              setComments: setComments,
              setModalInfo: setModalInfo,
              setParentId: setParentId,
              setShowReplyTo: setShowReplyTo,
              postId: 0,
              setShowPostModal: props.setShowPostModal,
              setShowStandardProfile: setShowStandardProfile,
              setStandardProfileId: setStandardProfileId,
            }}
          />
          {comment.comments.length > 0 &&
            comment.comments.map((subcomment, sidx) => (
              <SubCommentItem
                // key={subcomment.id}
                {...{
                  idx: idx,
                  sidx: sidx,
                  post: post,
                  comment: subcomment,
                  comments: comments,
                  setComments: setComments,
                  setModalInfo: setModalInfo,
                  setParentId: setParentId,
                  setShowReplyTo: setShowReplyTo,
                  postId: comment.id,
                  setShowPostModal: props.setShowPostModal,
                  setShowStandardProfile: setShowStandardProfile,
                  setStandardProfileId: setStandardProfileId,
                }}
              />
            ))}
        </div>
      ))}
      
      <div/>
      <Modal
        isOpen={modalInfo.show}
        onRequestClose={() => setModalInfo({ slides: [], show: false, index: 0 })}
      >
        {modalInfo.slides.length > 0 ? (
          <Swiper
            className="testSwiper"
            initialSlide={modalInfo.index}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {modalInfo.slides.map((image, index) => (
              <SwiperSlide key={index}>
                <div>
                  <img
                    src={'https://outvirt.com/assets/img/' + image}
                    width="15"
                    onClick={() => {
                      setModalInfo({ ...modalInfo, show: false });
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </Modal>
    {/* </div> */}
        
        
      </DialogContent>
      {
  parentId != post.id && parentId != 0 && showReplyTo ? (
    <div className="reply-to-box">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: '15px' }}>
        <span style={{ color: "#666" }}>Replying to...</span>
        <Button
          onClick={() => {
            setShowReplyTo(false);
            setParentId(post.id);
          }}
        >
          <CloseIcon style={{ color: "#666" }}/>
        </Button>
      </div>
      <ReplyItem comment={getComment(comments, parentId!)} />
    </div>
  ) : null
}



      {props.showPostModal && (
        <DialogActions className="dark-dialog-actions">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={8}>
              <TextField
                className="dark-text-field"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter your comment..."
                multiline
                rows={1}
                variant="outlined"
                onKeyDown={handleKeyDown}
              />
            </Grid>
            {/* <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleTakePhoto} color="primary">
                <PhotoCameraIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleChoosePhoto} color="primary">
                <ImageIcon />
              </IconButton>
            </Grid> */}
            <Grid item xs={1} container justifyContent="center">
              <IconButton onClick={handleSubmit} style={{ color: ( (text!=undefined && text!="") || photos.length!=0) ? "#3f51b5" : "#666"} }>
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* {photos.length > 0 && (
            <Grid container spacing={1} alignItems="center">
              {photos.map((photo, index) => (
                <Grid item key={index}>
                  <IconButton onClick={() => handleRemovePhoto(index)} color="secondary">
                    <CloseIcon />
                  </IconButton>
                  <Avatar variant="square" src={photo.srcwebviewPath} />
                </Grid>
              ))}
            </Grid>
          )} */}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default PostContent;
