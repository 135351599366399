import React, { useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import ParseDate from '../date/Date';
import {AppContext} from '../../state'
import './PostContent.css'
import { MdBusiness, MdFavorite, MdFavoriteBorder, MdChatBubble, MdChatBubbleOutline, MdPlace } from 'react-icons/md'; // Assuming you are using 'react-icons' for icons
import PublicIcon from '@mui/icons-material/Public';

interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
    url: string;
  }
interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
    url: string;
  }
interface UserInfo {
    id: number;
    email: string;
    username: string;
    name: string;
    token: string;
    profilepic: string;
}
interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
}

interface ContainerProps {
    idx: number
    post : Post
    comment : Comment
    comments : Comment[]
    setComments: React.Dispatch<React.SetStateAction<Comment[]>>
    setParentId: React.Dispatch<React.SetStateAction<number>>
    setShowReplyTo: React.Dispatch<React.SetStateAction<boolean>>
    setModalInfo: React.Dispatch<React.SetStateAction<ModalInfo>>
    setShowPostModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowStandardProfile: React.Dispatch<React.SetStateAction<boolean>>
    setStandardProfileId: React.Dispatch<React.SetStateAction<number>>
    postId: number
}

const CommentItem: React.FC<ContainerProps> = (props) => {
    
  const { state, dispatch } = useContext(AppContext);
    const ref = React.useRef<HTMLInputElement>(null)
    // const history = useHistory();
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return (
        <div style={{ position: 'relative' }}>
            <div ref={ref} style={{ width: '100%' }}>
                <div style={{ margin: '0 auto', maxWidth: '1140px', padding: '0 15px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div onClick={()=>{
                        // props.setStandardProfileId(props.comment.user_id)
                        // props.setShowStandardProfile(true)
                    }}>
                        <div
                            className="profile-icon"
                            style={{
                                backgroundImage: `url(https://outvirt.com/assets/img/${props.comment.profilepic})`,
                            }}
                            onClick={()=>{
                                props.setStandardProfileId(props.comment.user_id)
                                props.setShowStandardProfile(true)
                            }}
                        />
                    </div>
                    <div style={{ flexBasis: '100%', maxWidth: '100%', paddingRight: '15px', paddingLeft: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <div onClick={()=>{
                            props.setStandardProfileId(props.comment.user_id)
                            props.setShowStandardProfile(true)
                        }}>
                            {props.comment.name}
                        </div>
                        {
                            (props.comment.account_type === 1) ? <PublicIcon onClick={()=>{
                                const url = props.comment.url;
                                const hasProtocol = /^https?:\/\//i.test(url);
                                const absoluteUrl = hasProtocol ? url : "http://" + url;
                                window.open(absoluteUrl, "_blank");
                            }} style={{fontSize:'16px', color:"#999", margin: "5px"}} /> : null
                        }
                        <span style={{ color: 'gray' }}>
                            &nbsp;&nbsp;· {ParseDate(props.comment.created_at)}
                        </span>
                    </div>
                    <div>
                        <div
                        style={{ whiteSpace: 'pre-wrap' }}
                        onClick={()=>{}}
                        >
                        {props.comment.body.split(urlRegex).map((part, i) => {
                            if (part.match(urlRegex)) {
                            return (
                                <Link to={part} key={i}>
                                {part}
                                </Link>
                            );
                            } else {
                            return <span key={i}>{part}</span>;
                            }
                        })}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {props.comment.photos != null
                            ? props.comment.photos.map((photo, index) => {
                                return (
                                <div
                                    className="ion-no-padding"
                                    key={index}
                                    style={{
                                    flexBasis: props.comment.photos.length === 1 ? '100%' : '50%',
                                    maxWidth: props.comment.photos.length === 1 ? '100%' : '50%',
                                    paddingRight: '15px',
                                    paddingLeft: '15px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    }}
                                >
                                    <div
                                    onClick={() => {
                                        props.setModalInfo({
                                        slides: props.comment.photos,
                                        show: true,
                                        index: index,
                                        });
                                    }}
                                    >
                                    <div
                                        className="div-crop-comment"
                                        style={{
                                        width: props.comment.photos.length === 1 ? '60vw' : '25vw',
                                        height: props.comment.photos.length === 1 ? '60vw' : '25vw',
                                        }}
                                    >
                                        <img
                                        alt="post"
                                        className="img-crop-comment"
                                        src={`https://outvirt.com/assets/img/${photo}`}
                                        />
                                    </div>
                                    </div>
                                </div>
                                );
                            })
                            : null}
                        </div>
                    </div>
                    
                <div className="like-comment">
                <div
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1',
                    }}
                    onClick={async () => {
                        
                        if (state.id == 0) return;
                        if (!props.comment.liked){
                            try {
                                const response = await fetch('https://outvirt.com:8080/likepost', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({id: props.comment.id, token: state.token, tablename: props.post.tablename, post: false})
                            });
                            if (!response.ok) {
                                throw new Error(response.statusText);
                            }
                            props.comment.liked = true;
                            props.comment.n_likes += 1;
                            const newComments = [...props.comments.slice(0,props.idx), props.comment, ...props.comments.slice(props.idx+1)]
                            props.setComments(newComments);
                            // console.log(response);
                            } catch (err) {
                            console.log(err);
                            }
                        }
                        else {
                            console.log('unlike')
                            try {
                            const response = await fetch('https://outvirt.com:8080/unlikepost', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({id: props.comment.id, token: state.token, tablename: props.post.tablename, post: false})
                            });
                            if (!response.ok) {
                                throw new Error(response.statusText);
                            }
                            props.comment.liked = false;
                            props.comment.n_likes -= 1;
                            const newComments = [...props.comments.slice(0,props.idx), props.comment, ...props.comments.slice(props.idx+1)]
                            props.setComments(newComments);
                            } catch (err) {
                            console.log(err);
                            }
                        }
                    }}
                >
                    <span className="icon-like">
                    {props.comment.liked ? <MdFavorite color="ff7799" /> : <MdFavoriteBorder color="#92949c" />}
                    </span>
                    <span className="text">
                    {' '}
                    <span style={{ color: props.comment.liked ? 'ff7799' : '#92949c' }}>&nbsp;{props.comment.n_likes}</span>{' '}
                    </span>
                </div>
                <div
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1',
                    }}
                    onClick={() => {
                        if (state.id == 0) return;
                        props.setParentId(props.comment.id)
                        props.setShowReplyTo(true)
                    }}
                >
                    <span className="icon-chat">
                    {props.comment.n_comments ? (
                        <MdChatBubble color="77b7ff" />
                    ) : (
                        <MdChatBubbleOutline color="#92949c" />
                    )}
                    </span>
                    <span className="text">
                    {' '}
                    <span style={{ color: props.comment.n_comments ? '77b7ff' : '#92949c' }}>&nbsp;{props.comment.n_comments}</span>{' '}
                    </span>
                </div>
                </div>
                    </div>
                </div>
                </div>
            </div>
</div>

);
}
export default CommentItem