import { useRef, useEffect, useState } from 'react';

interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

  
interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    selected: boolean;
  }

type UseDraggableProps = {
  id: string;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  onDragStart?: (event: TouchEvent | MouseEvent) => void;
  onDragEnd?: (event: TouchEvent | MouseEvent) => void;
  handleDrop: (droppedId: string | null, event: MouseEvent | TouchEvent) => void;
  bookmarks: Bookmark[];
  bookmarkCats: BookmarkCatProp[];
};

export const useDraggable = ({ id, isDragging, setIsDragging, onDragStart, onDragEnd, handleDrop, bookmarks, bookmarkCats }: UseDraggableProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [initialPosition, setInitialPosition] = useState<{ x: number; y: number } | null>(null);
  const initialPositionRef = useRef<{ x: number; y: number } | null>(null);

  useEffect(() => {
    const elem = ref.current;
    if (elem) {
      elem.addEventListener('mousedown', handleMouseDown);
      elem.addEventListener('touchstart', handleTouchStart);
      elem.addEventListener('touchmove', handleTouchMove, { passive: false });
      elem.addEventListener('touchend', handleTouchEnd);
    }
  
    return () => {
      if (elem) {
        elem.removeEventListener('mousedown', handleMouseDown);
        elem.removeEventListener('touchstart', handleTouchStart);
        elem.removeEventListener('touchmove', handleTouchMove);
        elem.removeEventListener('touchend', handleTouchEnd);
      }
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ref, bookmarks, bookmarkCats]);

//   let dragStartTimeout: ReturnType<typeof setTimeout> | null = null;
  let dragStartTimeout: number | null = null;

const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();

    dragStartTimeout = window.setTimeout(() => {
        initialPositionRef.current = { x: event.clientX, y: event.clientY };
        onDragStart?.(event);
        setIsDragging(true);
        document.addEventListener('mousemove', handleMouseMove);
    }, 500); // Adjust the delay (in milliseconds) as needed
    document.addEventListener('mouseup', handleMouseUp);
};

const handleMouseUp = (event: MouseEvent) => {
    if (dragStartTimeout !== null) {
        clearTimeout(dragStartTimeout as number);
        dragStartTimeout = null;
    } 

    if (initialPositionRef.current != null){
        handleDrop(id, event)
    } 

    initialPositionRef.current = null;
    onDragEnd?.(event);

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    // Reset the transform property
    const elem = ref.current;
    if (elem) {
        elem.style.transform = '';
    }
};
  
  const handleMouseMove = (event: MouseEvent) => {
    if (initialPositionRef.current) {
      const dx = event.clientX - initialPositionRef.current.x;
      const dy = event.clientY - initialPositionRef.current.y;
      const elem = ref.current;
      if (elem) {
        elem.style.transform = `translate(${dx}px, ${dy}px)`;
      }
    }
  };
  
  let touchStartTimeout: number | null = null;

const handleTouchStart = (event: TouchEvent) => {
  const touch = event.targetTouches[0];
  touchStartTimeout = window.setTimeout(() => {
    initialPositionRef.current = { x: touch.clientX, y: touch.clientY };
    onDragStart?.(event);
    setIsDragging(true);

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
  }, 500); // Adjust the delay (in milliseconds) as needed
  document.addEventListener('touchend', handleTouchEnd);
};

const handleTouchEnd = (event: TouchEvent) => {
  if (touchStartTimeout !== null) {
    clearTimeout(touchStartTimeout as number);
    touchStartTimeout = null;
  }
  
  if (initialPositionRef.current != null){
    handleDrop(id, event)
} 

  initialPositionRef.current = null;
  onDragEnd?.(event);

  document.removeEventListener('touchmove', handleTouchMove);
  document.removeEventListener('touchend', handleTouchEnd);

  // Reset the transform property
  const elem = ref.current;
  if (elem) {
    elem.style.transform = '';
  }
};

const handleTouchMove = (event: TouchEvent) => {
  event.preventDefault();
  if (initialPositionRef.current) {
    const touch = event.targetTouches[0];
    const dx = touch.clientX - initialPositionRef.current.x;
    const dy = touch.clientY - initialPositionRef.current.y;
    const elem = ref.current;
    if (elem) {
      elem.style.transform = `translate(${dx}px, ${dy}px)`;
    }
  }
};


  return { ref };
};
