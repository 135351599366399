import React, {useState, useContext} from 'react';
import { useMap } from 'react-leaflet'
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  TextField,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Business as BusinessIcon, Check as CheckIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import UserAddItem from './components/UserAddItem';

const useStyles = makeStyles((theme) => ({
    // Add your custom styles here
    input: {
      color: 'white',
    },
    inputFocused: {
      color: 'white',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    },
  }));



interface User {
    id: number;
    account_type: number;
    name: string;
    username: string;
    profilepic: string;
    follow: boolean;
  }
  
interface UserListProps {
    users: User[];
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
}



const UserList: React.FC<UserListProps> = ({users, setUsers}) => {
  // Your component logic and state management here
  const [searchText, setSearchText] = useState('');

  const classes = useStyles();
  const { state, dispatch } = useContext(AppContext);
  const map = useMap();


  const userSearch = async() => {
    if (searchText != ""){
        try {
        const response = await fetch('https://outvirt.com:8080/searchuser', {
            method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: state.token, text: searchText})
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        response.json().then(res_data => {
            console.log(res_data);
            if (res_data.length != 0){
                setUsers(res_data)
            }
            //  else{
            //     present('No matching users.', 2000)
            // }
        })
        } catch (err) {
        console.log(err);
        }
    }
  }

  return (
    <Box>
      {/* <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: <SearchIcon />,
          className: classes.input,
        }}
        onKeyPress={async (e) => {
          if (e.key === 'Enter') {
            await userSearch();
            setSearchText('');
          }
        }}
        variant="outlined"
        className={classes.inputFocused}
        style={{ marginTop: '16px', marginBottom: '16px', paddingLeft: '4px', paddingRight: '4px', width: '90%' }} // Adjust the padding here
        /> */}
      <List>
        {users.map((user, idx) => (
          <UserAddItem key={idx} idx={idx} token={state.token} user_id={state.id} user={user} users={users} setUsers={setUsers} map={map}/>
        ))}
      </List>
    </Box>
  );
};


export default UserList;