import React from "react";
import {
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemText,
} from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import ParseDate from '../date/Date';
import {AppContext} from '../../state'

import { useContext } from "react";
import './Notifications.css'


interface NotifyItemProps {
    notification: {
      created_at: string;
      message: string;
      url: string;
    };
    isNew?: boolean;
    accountType: number;
    setPostCards: (postCards: any[]) => void;
    setShowStandardProfile: (showStandardProfile: boolean) => void;
    setShowUserProfile: (showUserProfile: boolean) => void;
    setStandardProfileId: (standardProfileId: number) => void;
    setShowContentNotif: (showContentNotif: boolean) => void;
    setCurrentIdxNotif: (currentIdxNotif: number) => void;
    setIsPrevNotif: (isPrevNotif: boolean) => void;
  }



const NotificationItem: React.FC<NotifyItemProps> = ({
    notification,
    isNew = false,
    accountType,
    setPostCards,
    setShowStandardProfile,
    setShowUserProfile,
    setStandardProfileId,
    setShowContentNotif,
    setCurrentIdxNotif,
    setIsPrevNotif
}) => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <ListItem
      className="chat-item"
      style={{
          padding: "0px",
          margin: "1px",
      }}
      onClick={async () => {
        if(notification.url!=undefined){
            if (notification.url.substring(0, notification.url.lastIndexOf('/')) == '/tabs/social/conversation'){
                // setPostId(notification.url.substring(notification.url.lastIndexOf('/')+1).split(/[\-]/)[1])
                try {
                    const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({id: [notification.url.substring(notification.url.lastIndexOf('/')+1).split(/[\-]/)[1]], user_id: state.id})
                    });
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    response.json().then(res_data => {
                        if (res_data.length > 0){
                            for (var i=0; i<res_data.length; i++){
                                res_data[res_data.length-i-1].idx = i
                            }
                            console.log('post', res_data);
                            setPostCards(res_data)
                            setCurrentIdxNotif(0)
                            setIsPrevNotif(false)
                            setShowContentNotif(true)
                            // dispatch({ type: "setNotifCurrentIdx",
                            //     notif_current_idx: 0, // initial val
                            //     notif_is_prev: false
                            // });
                            // dispatch({ type: "setNotifShowContent",
                            //     notif_show_content: true
                            //     });
                            }
                        }
                        )
                } catch (err) {
                    console.log(err);
                }
                console.log(notification.url.substring(notification.url.lastIndexOf('/')+1).split(/[\-]/)[1] )
            } else if (notification.url == '/tabs/profile'){
                console.log('profile', notification.url)
                setShowUserProfile(true)
                // if (accountType == 0){
                //     history.push({
                //             pathname: notification.url
                //     })
                // } else{
                //     if (notification.url == "/profile"){
                //         history.goBack();
                //     } else{
                //         history.push({
                //                 pathname: notification.url
                //         })
                //     }
                // }
                
            } else if (notification.url.substring(0, notification.url.lastIndexOf('/')) == '/standardprofile'){
                console.log('id', notification.url, notification.url.split('/'), notification.url.split('/')[2])
                setStandardProfileId(Number(notification.url.split('/')[2]))
                setShowStandardProfile(true)
                // dispatch({ type: "setShowStandardProfile",
                //   showStandardProfile: true,
                //   standardProfileId: Number(notification.url.split('/')[2])
                // });
                // setStandardProfileId(Number(notification.url.split('/')[2]))
                // setShowStandardProfile(true)
            } else {
                console.log('other', notification.url, notification.url.substring(0, notification.url.lastIndexOf('/')))
            }
        }
      }}
    >
      <Card
        className={`chat-card${isNew ? " new" : ""}`}
      >
        <CardContent
            style={{
                padding: "5px"
            }}
        >
          <Typography variant="subtitle2" color="textSecondary" style={{color: "#999", fontSize: "14px"}}>
            {ParseDate(notification.created_at)}
          </Typography>
          <ListItemText>
            <Typography variant="body1" color="textPrimary" style={{color: "white", fontSize: "14px"}}>
              {notification.message}
            </Typography>
          </ListItemText>
        </CardContent>
      </Card>
    </ListItem>
  );
};


export default NotificationItem