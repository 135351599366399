import React, {useRef} from "react";
import {
  Grid,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useGestureResponder } from "react-gesture-responder";

import './Conversation.css'

interface CatModal {
    show: boolean;
    idx: number;
    user_id: number;
  }

interface ContainerProps {
    image: string;
    name: string;
    selected: boolean;
    idx: number;
    id: number;
    setCatModal: React.Dispatch<React.SetStateAction<CatModal>>;
    user_id: number;
}

const BookmarkCat: React.FC<ContainerProps> = (props) => {
  var longPressActive = useRef(false);
  var longPressActiveLock = useRef(false);

  const { bind } = useGestureResponder({
    
    onStartShouldSet: () => true,
    onGrant: async(event) => {
   
      const now = Date.now();
          console.log('lock', !longPressActiveLock.current)
          if (!longPressActiveLock.current){
            longPressActiveLock.current = true;
            longPressActive.current = true;
          }
            setTimeout(() => {
              console.log('timeout', longPressActive.current)
                if (longPressActive.current) {
                  window.navigator.vibrate(50);
                  props.setCatModal({show: true, idx: props.id, user_id: props.user_id})
                  console.log('yoooo unlock')
                }
                longPressActiveLock.current = false;
            }, 500);

    },
    onMove: ({delta}) => {
      // handle move gesture using dx and dy
      if (Math.abs(delta[0])>5 || Math.abs(delta[1])>5){
        longPressActive.current = false;
      }
    },
    onRelease: () => {
      longPressActive.current = false;
          

    },
  });

  
    return (
        <Grid container direction="column" alignItems="center" className="bookmark-cat" spacing={1}>
        <Grid item>
          <Avatar
            {...bind }
            id={"cat/" + props.id}
            className="bookmark-profile-icon"
            style={{
              border: props.selected
                ? "2px solid #6a64ff"
                : "2px solid #666",
            }}
          >
            {props.image.slice(-4) === ".mp4" ? (
              <div
                className="video-container"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/img/${props.image})`,
                }}
              >
                <video preload="metadata" src={`https://outvirt.com/assets/img/${props.image}#t=0.1`}></video>
              </div>
            ) : (
              <div
                className="image-container"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/img/${props.image})`,
                }}
              ></div>
            )}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography style={{fontSize: "12px"}}>{props.name}</Typography>
        </Grid>
      </Grid>
      
    );
  }

  export default BookmarkCat;
  