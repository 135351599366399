import React, { useEffect, useRef, useState } from 'react';
import maplibregl, { ResourceType } from 'maplibre-gl';
import { LatLngBounds, LatLng } from 'leaflet';
import { LngLatBoundsLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

type MlgTileLayerProps = {
  styleUrl: string;
  center: [number, number];
  zoom: number;
  onAction?: (action: string, zoom: number, bounds: [LatLng,LatLng] ) => void;
  mapRef: React.MutableRefObject<maplibregl.Map | null > 
};

const MlgTileLayer: React.FC<MlgTileLayerProps> = ({ styleUrl, center, zoom, onAction, mapRef}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
//   const map = useRef<maplibregl.Map | null>(null);
  function convertBounds(mapBounds: maplibregl.LngLatBounds): [LatLng,LatLng] {
    const nw = new LatLng(mapBounds.getNorth(), mapBounds.getWest());
    const se = new LatLng(mapBounds.getSouth(), mapBounds.getEast());
    return [nw, se];
  }

  useEffect(() => {
    if (mapRef.current) return
    // if (mapContainer.current && mapRef.current!) {
        // if (mapContainer.current) {
        console.log('inside MlgTileLayer', styleUrl, center, zoom);
        const map = new maplibregl.Map({
          container: mapContainer.current!,
          style: styleUrl,
          center: center,
          zoom: zoom,
          dragRotate: false,
          attributionControl: false,
        //   touchZoomRotate: false,
        transformRequest: (url, resourceType) => {
          if (url.startsWith('http://')) {
            return {
              url: url.replace('http', 'https'),
              headers: { 'my-custom-header': true },
              // credentials: 'include'  // Include cookies for cross-origin requests
            };
          } else {
            return {
              url: url,
              headers: { },  // or whatever headers should be in this case
              // credentials: 'include'  // if applicable
            };
          }
        }
          // transformRequest: (url, resourceType) => {
          //   // console.log('???', url, resourceType)
          //   if (url.startsWith('http://')) {
          //     return {
          //       url: url.replace('http', 'https'),
          //       headers: { 'my-custom-header': true },
          //       // credentials: 'include'  // Include cookies for cross-origin requests
          //     };
          //   }
          // }
        });
        
        mapRef.current = map;
        map.on('zoomend', () => {
            if (onAction) {
                const newZoom = map.getZoom() ?? 0;
                const bounds = map.getBounds();
                const newBounds = convertBounds(bounds);
                onAction('zoomend', Math.round(newZoom), newBounds);
            }
        });

        map.on('moveend', () => {
            if (onAction) {
                const newZoom = map.getZoom() ?? 0;
                const bounds = map.getBounds();
                const newBounds = convertBounds(bounds);
                onAction('moveend', Math.round(newZoom), newBounds);
            }
        });

        map.on('click', () => {
            console.log('click')
            if (onAction) {
                const newZoom = map.getZoom() ?? 0;
                const bounds = map.getBounds();
                const newBounds = convertBounds(bounds);
                onAction('click', Math.round(newZoom), newBounds);
            }
        });

        // map.addControl(
        //   new maplibregl.AttributionControl({
        //     customAttribution:
        //       '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
        //     compact: false,
        //   }) 
        // );

        // for (let i = 0; i < map._controls.length; i++) {
        //   const control = map._controls[i];
        //   // If this control is the attribution control, remove it
        //   if (control instanceof maplibregl.AttributionControl) {
        //     map.removeControl(control);
        //     break;
        //   }
        // }
        // map.on('load', () => {
        //   // Iterate over the map's controls
        //   for (let i = 0; i < map._controls.length; i++) {
        //     const control = map._controls[i];
        //     // If this control is the attribution control, remove it
        //     if (control instanceof maplibregl.AttributionControl) {
        //       map.removeControl(control);
        //       break;
        //     }
        //   }
        // });

        const defaultMarkerElement = document.createElement('div');
        // defaultMarkerElement.className = 'custom-marker';
        defaultMarkerElement.style.backgroundImage = 'url(assets/arrow.svg)';
        defaultMarkerElement.style.width = '24px';
        defaultMarkerElement.style.height = '24px';

        
        return () => {
            if (mapRef.current && map) {
                console.log('remove');
                map.remove();
                mapRef.current = null;
            }
        };
    // }
  }, []);
  return <div ref={mapContainer} className="map-div" />; // this styling is required for the contentcard styling to work
  // return <div className="map-wrap">
  //   <div ref={mapContainer} className="map-div"/>;
  // </div>
};

export default MlgTileLayer;