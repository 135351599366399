import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import LazyLoadVideo from '../video/LazyLoadVideo';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: t("mainpage.featuressplit.title"),
    paragraph: t("mainpage.featuressplit.paragraph")
    // paragraph: 'Tired of spending hours on socia media and still feeling empty? Outvirt is here supercharge your social media experience.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {t("mainpage.featuressplit.tile1.title")}
                  </div>
                <h3 className="mt-0 mb-12">
                  {t("mainpage.featuressplit.tile1.subtitle")}
                  </h3>
                <p className="m-0">
                  {t("mainpage.featuressplit.tile1.content")}
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <LazyLoadVideo
                    src={require('./../../assets/videos/authentic.mp4')}
                    // src={require('./../../assets/videos/sec1.mp4')}
                    alt="Features split 02"
                    height={96}
                    className="lazyload-video"
                  />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {t("mainpage.featuressplit.tile2.title")}
                  </div>
                <h3 className="mt-0 mb-12">
                  {t("mainpage.featuressplit.tile2.subtitle")}
                  </h3>
                <p className="m-0">
                  {t("mainpage.featuressplit.tile2.content")}
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <LazyLoadVideo
                    src={require('./../../assets/videos/curated.mp4')}
                    // src={require('./../../assets/videos/sec2.mp4')}
                    alt="Features split 02"
                    height={96}
                    className="lazyload-video"
                  />
                {/* <Image
                  src={require('./../../assets/images/p3.jpg')}
                  alt="Features split 02"
                  height={96} /> */}
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {t("mainpage.featuressplit.tile3.title")}
                  </div>
                <h3 className="mt-0 mb-12">
                  {t("mainpage.featuressplit.tile3.subtitle")}
                  </h3>
                <p className="m-0">
                  {t("mainpage.featuressplit.tile3.content")}
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                  <LazyLoadVideo
                    src={require('./../../assets/videos/planning.mp4')}
                    // src={require('./../../assets/videos/sec3.mp4')}
                    alt="Features split 02"
                    height={96}
                    className="lazyload-video"
                  />
                {/* <Image
                  src={require('./../../assets/images/p2.jpg')}
                  alt="Features split 03"
                  height={96} /> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
