import React, {Component, useState, useEffect, useContext, useRef, createContext, ChangeEvent, FormEvent } from 'react';
import { Add, Notifications, QuestionAnswer, Settings as SettingsIcon, Bookmark as BookmarkIcon } from '@material-ui/icons';
import CollectionsIcon from '@mui/icons-material/Collections';
import LogoutIcon from '@mui/icons-material/Logout';
import {AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';
import LoginComponent from '../user/Login';
import CreatePost from './CreatePost';
import './Profile.css';
import EXIF from "exif-js";
import ExifReader from 'exifreader';
import {getNearestHexIdFromLatLon, reqNameId} from '../map/Utils'
import PersonIcon from '@mui/icons-material/Person';
import UserProfile from './UserProfile';
import Conversation from './Conversation';
import Bookmark from './Bookmark';
import NotificationsComponent from './Notifications';
import EditPost from './EditPost'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DiscoverPeople from './Discover';
import Settings from './Settings'
import { useTranslation } from 'react-i18next';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

import { set } from 'lodash';
interface ProfileComponentProps {
  map: L.Map | undefined;
}

interface Tag {
  id: number;
  name: string;
  username: string;
  profilepic: string;
}
const WS_URL = 'wss://outvirt.com:8080';

const ProfileComponent: React.FC<ProfileComponentProps> = ({ map }) => {
    const ws = useRef<WebSocket>();

    const { state, dispatch } = useContext(AppContext);

    function setMultipleLocalStorage(data: Record<string, string>) {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            localStorage.setItem(key, data[key]);
          }
        }
      }
    // auto-login with contents from local storage



const fetchMsgAndNot = async() => {
  console.log('token', localStorage.getItem('token'))
  
    try {
      const response = await fetch('https://outvirt.com:8080/fetchmsgandnot', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: localStorage.getItem('token')})
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        response.json().then(async(res) => {
          dispatch({ type: "setMsg", msg_cnt: res.msg_cnt });
          dispatch({ type: "setNotif", notification_incoming: res.notification_incoming });
        })
      }
    } catch (err) {
      console.log(err);
    }
  
}

  
const { t, i18n } = useTranslation();
const videoElement = useRef<HTMLVideoElement>(null);
const canvasElement = useRef<HTMLCanvasElement>(null);
const chunks = useRef<Blob[]>([]);
// const [isRecording, setIsRecording] = useState(false);
const [isCameraVisible, setIsCameraVisible] = useState(false);
const [showUserProfile, setShowUserProfile] = useState(false);
const [showConversation, setShowConversation] = useState(false);
const [showBookmark, setShowBookmark] = useState(false);
const [showDiscover, setShowDiscover] = useState(false);
const [showNotifications, setShowNotifications] = useState(false);
const [showSettings, setShowSettings] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [textSpinner, setTextSpinner] = useState<{show: boolean, label: string}>({show: false, label: ''});
const [timeoutExecuted, setTimeoutExecuted] = useState(false);
const [isRearCamera, setIsRearCamera] = useState(true);
const [cameraZIndex, setCameraZIndex] = useState(0);
const [recordingState, setRecordingState] = useState("stopped"); // "stopped", "starting", "recording", "stopping"

// let mediaRecorder: MediaRecorder | undefined;
const mediaRecorder = useRef<any>(null);

// const [mode, setMode] = useState('photo');
// const [captureEnabled, setCaptureEnabled] = useState(false);
// const [captureDisabled, setCaptureDisabled] = useState(false);
// const [dispBlob, setDispBlob] = useState<Blob|null>()
const [blobs, setBlobs] = useState<Blob[]>([])
const [urls, setUrls] = useState<string[]>([])
const [isVideos, setIsVideos] = useState<boolean[]>([])
const [isNew, setIsNew] = useState<boolean[]>([])
const msgCntRef = useRef(state.msg_cnt);
useEffect(() => {
  msgCntRef.current = state.msg_cnt;
}, [state.msg_cnt]);


// setup profile for logged in user
useEffect(() => {

  setupProfile()

  // Cleanup function (replaces useIonViewDidLeave)
  return () => {
    console.log("left conv");
    if (ws.current !== null && ws.current !== undefined && ws.current.readyState === WebSocket.OPEN) {
        console.log("Cleaning up...");
        ws.current.close();
    }
};

}, []);

useEffect(() => {
  console.log('iscamera: ', isCameraVisible)
  if (isCameraVisible){
    setCameraZIndex(getHighestZIndex() + 1)
  } else {
    setCameraZIndex(0)
  }
}, [isCameraVisible]);

// setup profile on first login
useEffect(() => {
  console.log(state)
  if (state.id != 0){
    setupProfile()
  }
}, [state.id]);


const setupProfile = async() => {
  const storedToken = localStorage.getItem('token')!
  if (storedToken != ''){
    try {
      const response = await fetch('https://outvirt.com:8080/fetchlogininfowithtoken', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({token: storedToken})
      });
      if (!response.ok) {
        dispatch({ type: "setUser",
          id: 0,
          email:  '',
          username:  '',
          name:  '',
          token:  '',
          profilepic: 'signup',
          coins: 0,
        });  
        setMultipleLocalStorage({
          id: '0',
          email: '',
          username: '',
          name: '',
          token: '',
          profilepic: 'signup',
          coins: '0',
        })
        throw new Error(response.statusText);
      } else {
        response.json().then(async(res) => {
          dispatch({ type: "setUser",
            id: res.id,
            email: res.email,
            username: res.username,
            name: res.name,
            token: res.token,
            profilepic: res.profilepic,
            coins: res.coins,
          });
          setMultipleLocalStorage({
            id: res.id.toString(),
            email: res.email,
            username: res.username,
            name: res.name,
            token: res.token,
            profilepic: res.profilepic,
            coins: res.coins.toString(),
          })
          fetchMsgAndNot()
          ws.current = new WebSocket(WS_URL);

    // Opening the ws connection
    ws.current.onopen = () => {
    console.log("Connection opened", parseInt(localStorage.getItem('id')!));
    ws.current!.send(
        JSON.stringify({
            type: 'idle',
            user_id: parseInt(localStorage.getItem('id')!),
        })
    );
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if ((data.type == 'msg' || data.type == 'cmt')){
        dispatch({
          type: "setMsg",
          msg_cnt: msgCntRef.current + 1,
      });
      }
    }
      ws.current.onclose = () => {
        console.log('ws closed');
      }
        })
      }
    } catch (err) {
      console.log(err);
    }
  }


  // dispatch({ type: "setUser",
  //   id: localStorage.getItem('id') ? parseInt(localStorage.getItem('id')!) : 0,
  //   email: localStorage.getItem('email') ? localStorage.getItem('email')! : '',
  //   username: localStorage.getItem('username') ? localStorage.getItem('username')! : '',
  //   name: localStorage.getItem('name') ? localStorage.getItem('name')! : '',
  //   token: localStorage.getItem('token') ? localStorage.getItem('token')! : '',
  //   profilepic: localStorage.getItem('profilepic') ? localStorage.getItem('profilepic')! : 'signup',
  //   coins: localStorage.getItem('coins') ? parseInt(localStorage.getItem('coins')!) : 0,
  // });  
  // if (localStorage.getItem('token')){
  //   fetchMsgAndNot()
  //   ws.current = new WebSocket(WS_URL);

  //   // Opening the ws connection
  //   ws.current.onopen = () => {
  //       console.log("Connection opened", parseInt(localStorage.getItem('id')!));
  //       ws.current!.send(
  //           JSON.stringify({
  //               type: 'idle',
  //               user_id: parseInt(localStorage.getItem('id')!),
  //           })
  //       );
  //   };

  //   ws.current.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     if ((data.type == 'msg' || data.type == 'cmt')){
  //       dispatch({
  //         type: "setMsg",
  //         msg_cnt: msgCntRef.current + 1,
  //     });
  //     }
  //   }

  // }
}


useEffect(() => {
  if(!state.showConversation && ws.current){
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if ((data.type == 'msg' || data.type == 'cmt')){
        dispatch({
          type: "setMsg",
          msg_cnt: msgCntRef.current + 1,
      });
      }
    }
  }

},[state.showConversation])

const createPost = async (inBlobs: Blob[], inUrls: string[], text: string|undefined, lat: number|undefined, lon: number|undefined, precise: boolean|undefined, locName: string|undefined, websiteText: string|undefined, labels: string[]|undefined, placeId: string|undefined, tagList: Tag[]|undefined) => {
  setTextSpinner({show: true, label: t('uploading')})
  var photos_string : string[] = []
  var obj_types : boolean[] = []
  for (var i=0; inBlobs && i<inBlobs.length; i++){
    let formData = new FormData();
    const parts = inUrls[i].split("/");
    formData.append('photo', inBlobs[i]  , parts[parts.length-1]);
    console.log('path',inUrls[i],typeof parts[parts.length-1],typeof inBlobs[i]);
    console.log('path',inUrls[i], parts[parts.length-1], inBlobs[i].type)
    if (inBlobs[i].type.startsWith("image/")) {
      console.log("This is an image");
      obj_types.push(false)
    } else if (inBlobs[i].type.startsWith("video/")) {
      console.log("This is a video");
      obj_types.push(true)
    } else {
      console.log("This is neither an image nor a video");
      continue
    }
    photos_string.push(parts[parts.length-1])
    
    try {
        const response = await fetch('https://outvirt.com:8080/upload', {
          method: 'POST',
          body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  const [idx, idy, hLat, hLon] = getNearestHexIdFromLatLon(lat!,lon!)
  const tableName = reqNameId(idx, idy)
  var tagBucket : number[] = []
  if (text != undefined) {
    tagBucket = await buildTagList(text!, tagList!)
  }
  console.log(text, tagBucket)

  try {
    const response = await fetch('https://outvirt.com:8080/post', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: state.token, photos: photos_string, text: text, lat: lat, lon: lon, precise: precise, tagList: tagBucket, tableName: tableName, locName: locName, url: websiteText, labels: labels, types: obj_types, placeId: placeId})
  });
    // if (!response.ok) {
    //   return response;
    // } else {
    //   return response;
    // }
    if (response.ok){
      toast.success('Post created successfully!')
    }
  } catch (err) {
    console.log(err);
  }

  setTextSpinner({show: false, label: ''})
}


// tag list of friends
const buildTagList = async (text: string, tagList: Tag[]) => {
  var tagBucket : number[] = [];
  const textSplit = text.split(' ');
  for (var i = 0; i < textSplit.length; i++) {
    if(textSplit[i][0] == '@'){
      for (var j = 0; j < tagList.length; j++){
        if (textSplit[i].substring(1) == tagList[j].username){
          if (tagBucket.includes(tagList[j].id)){
            tagList.splice(j, 1)
          } else {
            tagBucket.push(tagList[j].id)
            tagList.splice(j, 1)
          }
        }
      }
    }
  }
  // console.log(tagBucket)
  return tagBucket;
}
// let touchTimer: ReturnType<typeof setTimeout> | undefined;
// let videoTimer: ReturnType<typeof setTimeout> | undefined;
// const touchTimer = useRef<ReturnType<typeof setTimeout> | undefined>(null);
// const videoTimer = useRef<ReturnType<typeof setTimeout> | undefined>(null);
const touchTimer = useRef<NodeJS.Timeout | null>(null);
const videoTimer = useRef<NodeJS.Timeout | null>(null);

const longPressDuration = 1000; // Duration in milliseconds for a long press
const handleTouchStartCapture = (e: React.TouchEvent<HTMLDivElement>) => {
    // e.preventDefault();
      // toast('touchstartcapture')
    touchTimer.current = setTimeout(() => {
      setRecordingState('starting')
      videoTimer.current = setTimeout(() => {
        setRecordingState('stopping')
    }, 30000);
  }, longPressDuration);
  };

  
  const handleMouseStartCapture = (e: React.MouseEvent<HTMLDivElement>) => {
    // e.preventDefault();
    touchTimer.current = setTimeout(() => {
      setRecordingState('starting')
    }, longPressDuration);
  };
  
  const handleTouchEndCapture = (e: React.TouchEvent<HTMLDivElement>) => {
    // e.preventDefault();
    if (videoTimer.current) clearTimeout(videoTimer.current);
    if (touchTimer.current) clearTimeout(touchTimer.current);
    if (timeoutExecuted){
      setTimeoutExecuted(false);
      return
    }
    if (recordingState !== "recording") {
      handleCapture("photo");
    } else{
      setRecordingState('stopping')
    }
  };
  
  const handleMouseEndCapture = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // e.preventDefault();
    if (videoTimer.current) clearTimeout(videoTimer.current);
    if (touchTimer.current) clearTimeout(touchTimer.current);
    if (recordingState !== "recording") {
      handleCapture("photo");
    } else {
      setRecordingState('stopping')
      // setIsRecording(false);
    }
  };

useEffect(() => {
  if (recordingState === "starting" || recordingState === "stopping") {
    handleCapture("video");
  }
  
}, [recordingState]);

  useEffect(() => {
    if (isCameraVisible) {
      startCamera(isRearCamera);
    } else {
      const video = videoElement.current;
      if (video && video.srcObject) {
        const stream = video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        video.srcObject = null;
      }
    }
  }, [isCameraVisible]);

  const feedBlob = async (blob: Blob) => {
    const createdUrl = URL.createObjectURL(blob);
    console.log('-----------------------------');
    console.log('createdUrl',createdUrl);
    console.log('blob',blob);
    console.log('-----------------------------');
    const isVideo = blob.type.startsWith('video/');
    return {blob, url: createdUrl, isVideo, isNew: state.editPost};
  }
  

const handleCapture = async (mode: string) => {
    if (mode === 'photo') {
      const canvas = canvasElement.current;
      const video = videoElement.current;
      if (canvas && video) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d')?.drawImage(video, 0, 0);
        canvas.toBlob(async (blob: Blob | null) => {
          if (blob) {
            // feedBlob(blob)
            const {blob: newBlob, url: newUrl, isVideo: newIsVideo, isNew: newIsNewVal} = await feedBlob(blob);
            setBlobs(prevBlobs => [...prevBlobs, newBlob]);
            setUrls(prevUrls => [...prevUrls, newUrl]);
            setIsVideos(prevIsVideos => [...prevIsVideos, newIsVideo]);
            setIsNew(prevIsNew => [...prevIsNew, newIsNewVal]);
          }
        });
      }
      
    setIsCameraVisible(!isCameraVisible);
    setRecordingState('stopped')
    } else {
        if (recordingState == 'starting') {
          // setRecordingState("starting");
          // toast('isrec')
            // Start video recording
            const videoStream = videoElement.current?.srcObject as MediaStream;
            if (videoStream) {
              // mediaRecorder = new (window as any).MediaRecorder(videoStream);
              mediaRecorder.current = new (window as any).MediaRecorder(videoStream, {
                mimeType: 'video/webm;codecs=vp8,opus'
              });
              // console.log(mediaRecorder.current)
              // mediaRecorder.current = new (window as any).MediaRecorder(videoStream, {
              //   mimeType: 'video/mp4;codecs=avc1.42E01E,mp4a.40.2'
              // });
              // console.log(mediaRecorder.current)
              // mediaRecorder.current = new (window as any).MediaRecorder(videoStream, {
              //   mimeType: 'video/mp4;codecs=h264'
              // });
              // mediaRecorder.current = new (window as any).MediaRecorder(videoStream, {
              //   mimeType: 'video/webm;codecs=av01'
              // });
              
              
              
              mediaRecorder.current.start();
      
              mediaRecorder.current.ondataavailable = (e: BlobEvent) => {
                if (e.data) {
                  chunks.current.push(e.data);
                }
              };
      
              // toast('start')
              mediaRecorder.current.onstop = async() => {
                const blob = new Blob(chunks.current, { type: 'video/webm' });
                chunks.current = [];
                
                if (blob){
                  // toast('done')
                    // setMode('photo') // reset to photo
                    // feedBlob(blob)
                    const {blob: newBlob, url: newUrl, isVideo: newIsVideo, isNew: newIsNewVal} = await feedBlob(blob);
                    setBlobs(prevBlobs => [...prevBlobs, newBlob]);
                    setUrls(prevUrls => [...prevUrls, newUrl]);
                    setIsVideos(prevIsVideos => [...prevIsVideos, newIsVideo]);
                    setIsNew(prevIsNew => [...prevIsNew, newIsNewVal]);
                }
                
                setRecordingState("stopped");
              };

              mediaRecorder.current.onerror = (e: Error) => {
                console.error("MediaRecorder error: ", e);
              };

              setRecordingState("recording");
      
            }
          } else if (recordingState == 'stopping'){
            // Stop video recording
            console.log('test', mediaRecorder.current)
            if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
              mediaRecorder.current.stop();
            }
            
            // mediaRecorder?.stop();
            setIsCameraVisible(!isCameraVisible);
          }
          
    }
  };
  const startCamera = async (isRearCamera: boolean) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        // Stop any active streams
        if (videoElement.current && videoElement.current.srcObject) {
          const stream = videoElement.current.srcObject as MediaStream;
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        // Set the back camera as the default
        const constraints = {
          video: {
            facingMode: { exact: isRearCamera ? 'environment' : 'user' },
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            frameRate: { ideal: 30 },
          },
          audio: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoElement.current) {
          videoElement.current.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing the camera:', err);
        // toast('Error accessing the camera:');
        // setIsCameraVisible(false)
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
      }
    } else {
      console.error('Your browser does not support camera access.');
    //   toast('Your browser does not support camera access.');
      // setIsCameraVisible(false)
      if (fileInputRef.current) {
        fileInputRef.current.click();
    }
    }
  };
//   useEffect(() => {
//     startCamera();
//   }, []);
  


const fileInputRef = useRef<HTMLInputElement>(null);
const handleFileInputChange = async(e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.files && e.target.files.length > 0) {
    const newBlobs: Blob[] = [];
    const newUrls: string[] = [];
    const newIsVideos: boolean[] = [];
    const newIsNew: boolean[] = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const blob = new Blob([file], { type: file.type });

      if (blob){
        const {blob: newBlob, url: newUrl, isVideo: newIsVideo, isNew: newIsNewVal} = await feedBlob(blob);
        newBlobs.push(newBlob);
        newUrls.push(newUrl);
        newIsVideos.push(newIsVideo);
        newIsNew.push(newIsNewVal);
      }
    }

    setBlobs(prevBlobs => [...prevBlobs, ...newBlobs]);
    setUrls(prevUrls => [...prevUrls, ...newUrls]);
    setIsVideos(prevIsVideos => [...prevIsVideos, ...newIsVideos]);
    setIsNew(prevIsNew => [...prevIsNew, ...newIsNew]);

    setIsCameraVisible(prevIsCameraVisible => !prevIsCameraVisible);
  }
};
// const handleFileInputChange = async(e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const file = e.target.files[0];
//       const blob = new Blob([file], { type: file.type });

//       if (blob){
//         feedBlob(blob)
//         setIsCameraVisible(!isCameraVisible);
//       }
//     }
//   };
  
  return (
    <div>
      {showUserProfile && <UserProfile showUserProfile={showUserProfile} setShowUserProfile={setShowUserProfile} id={state.id} map={map}/>}
      {showConversation && <Conversation ws={ws} showConversation={showConversation} setShowConversation={setShowConversation} map={map}/>}
      {showBookmark && <Bookmark ws={ws} showBookmark={showBookmark} setShowBookmark={setShowBookmark} map={map}/>}
      {showDiscover && <DiscoverPeople showDiscover={showDiscover} setShowDiscover={setShowDiscover}/>}
      {showNotifications && <NotificationsComponent showNotifications={showNotifications} setShowNotifications={setShowNotifications} ws={ws} map={map}/>}
      {showSettings && <Settings showSettings={showSettings} setShowSettings={setShowSettings}/>}

      {isLoading && <div className="spinner-container" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>}
      {
        textSpinner.show &&
        <div className="spinner-container-post" style={{ zIndex: getHighestZIndex() + 2 }}>
          <img src="assets/icon.svg" alt="loading" className="spinner-post" />
          <span className="spinner-text">{textSpinner.label}</span>
        </div>
      }

        {blobs.length>0 && !isCameraVisible && <CreatePost
            blobs={blobs}
            urls={urls}
            isVideos={isVideos}
            onComplete={async(upload: boolean, text: string|undefined, lat: number|undefined, lon: number|undefined, precise: boolean|undefined, locName: string|undefined, websiteText: string|undefined, labels: string[]|undefined, placeId: string|undefined, tagList: Tag[]|undefined) => {
              if (upload) {
                createPost(blobs, urls, text, lat, lon, precise, locName, websiteText, labels, placeId, tagList);

                // close post creation
                setBlobs([]);
                setUrls([]);
                setIsVideos([]);
                setIsCameraVisible(false);

                setIsLoading(false)
              } else {
                // discard post
                setBlobs([]);
                setUrls([]);
                setIsVideos([]);
                setIsCameraVisible(false);
              }
            }}
            onBlobFeed={() => {
                // setDispBlob(null);
                setIsCameraVisible(true);
            }}
            onDeleteBlob={(index) => {
                const newBlobs = blobs.filter((_, i) => i !== index);
                const newUrls = urls.filter((_, i) => i !== index);
                const newIsVideos = isVideos.filter((_, i) => i !== index);
                setBlobs(newBlobs);
                setUrls(newUrls);
                setIsVideos(newIsVideos);
            }}
        />}


        {state.editPost && <EditPost
            blobs={blobs}
            urls={urls}
            isVideos={isVideos}
            isNew={isNew}
            setBlobs={setBlobs}
            setUrls={setUrls}
            setIsVideos={setIsVideos}
            setIsNew={setIsNew}
            onComplete={async(save: boolean, text: string|undefined, lat: number|undefined, lon: number|undefined, precise: boolean|undefined, locName: string|undefined, websiteText: string|undefined, labels: string[]|undefined, placeId: string|undefined, tagList: Tag[]|undefined, oriTable: string|undefined) => {
              if (save) {
                setIsLoading(true)
                var photos_string : string[] = []
                var obj_types : boolean[] = []
                for (var i=0; blobs && i<blobs.length; i++){
                  const parts = urls[i].split("/");

                  // upload new photos/videos
                  if (isNew[i]){
                    let formData = new FormData();
                    formData.append('photo', blobs[i]  , parts[parts.length-1]);
  
                    try {
                        const response = await fetch('https://outvirt.com:8080/upload', {
                          method: 'POST',
                          body: formData,
                      });
                      if (!response.ok) {
                        throw new Error(response.statusText);
                      }
                      console.log(response);
                    } catch (err) {
                      console.log(err);
                    }
                    if (blobs[i].type.startsWith("image/")) {
                      console.log("This is an image");
                      obj_types.push(false)
                    } else if (blobs[i].type.startsWith("video/")) {
                      console.log("This is a video");
                      obj_types.push(true)
                    } else {
                      console.log("This is neither an image nor a video");
                      continue
                    }
                    photos_string.push(parts[parts.length-1])
                  } else {
                    obj_types.push(isVideos[i])
                    photos_string.push(parts[parts.length-1])
                  }

                  
                }

            
                const [idx, idy, hLat, hLon] = getNearestHexIdFromLatLon(lat!,lon!)
                const tableName = reqNameId(idx, idy)
                var tagBucket : number[] = []
                if (text != undefined) {
                  tagBucket = await buildTagList(text!, tagList!)
                }
                console.log(text, tagBucket)
                try {
                  const response = await fetch('https://outvirt.com:8080/updatepost', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({id: state.editPostId, photos: photos_string, text: text, lat: lat, lon: lon, precise: precise, tagList: tagList, tableName: tableName, locName: locName, url: websiteText, labels: labels, types: obj_types, oriTable: oriTable, placeId: placeId})
                });
                console.log('res', response)
                  if (!response.ok) {
                    return response;
                  } else {
                    toast(t('options.postupdated'))
                  }
                } catch (err) {
                  console.log(err);
                }

                // complete and close post creation
                setBlobs([]);
                setUrls([]);
                setIsVideos([]);
                setIsNew([])
                setIsCameraVisible(false);
                dispatch({type: 'setEditPost', editPost: false, editPostId: 0})
                setIsLoading(false)
              } else {
                // discard post
                setBlobs([]);
                setUrls([]);
                setIsVideos([]);
                setIsNew([])
                setIsCameraVisible(false);
                dispatch({type: 'setEditPost', editPost: false, editPostId: 0})
              }
            }}
            onBlobFeed={() => {
                // setDispBlob(null);
                setIsCameraVisible(true);
            }}
            onDeleteBlob={(index) => {
                const newBlobs = blobs.filter((_, i) => i !== index);
                const newUrls = urls.filter((_, i) => i !== index);
                const newIsVideos = isVideos.filter((_, i) => i !== index);
                const newIsNew = isNew.filter((_, i) => i !== index);
                setBlobs(newBlobs);
                setUrls(newUrls);
                setIsVideos(newIsVideos);
                setIsNew(newIsNew);
            }}
        />}


        {/* video components */}
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            accept="image/*,video/*"
            multiple
        />
        {
          isCameraVisible &&
            <div className={`camera-container ${!isRearCamera ? 'flip-horizontal' : ''}`} style={{zIndex: `${cameraZIndex + 1}`}} >
                <video
                    ref={videoElement}
                    className={`camera-stream ${isCameraVisible ? 'visible' : ''}`}
                    autoPlay
                    playsInline
                    muted
                ></video>
            </div>

        }
        <canvas ref={canvasElement} className="camera-canvas"></canvas>

        {/* Add the round capture button and the gallery button */}
        <div className={`camera-controls ${isCameraVisible ? 'visible' : ''}`} style={{zIndex: `${cameraZIndex+2}`}}>
        <div
            className="gallery-button"
            onClick={() => {
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
            }}
            style={{zIndex: `${cameraZIndex+2}`}}
            >
          <CollectionsIcon/>
        </div>
        <div
          className="toggle-camera-button"
          onClick={() => {
            const newIsRearCamera = !isRearCamera;
            setIsRearCamera(newIsRearCamera);
            startCamera(newIsRearCamera);
          }}
          style={{zIndex: `${cameraZIndex+2}`}}
        >
          <CameraswitchIcon/>
        </div>

        <div
            className={`capture-button ${recordingState=='recording' ? 'recording' : ''}`}
            // className={`capture-button ${isRecording ? 'recording' : ''}`}
            onTouchStart={handleTouchStartCapture}
            onTouchEnd={handleTouchEndCapture}
            onMouseDown={handleMouseStartCapture} // For non-touch devices
            onMouseUp={handleMouseEndCapture} // For non-touch devices
            // onClick={handleCapture}
        >
          {recordingState=='recording' ? (
            <svg className="countdown-svg" viewBox="0 0 80 80">
              <circle className="countdown-circle-bg" cx="40" cy="40" r="35" />
              <circle className="countdown-circle" cx="40" cy="40" r="35" />
            </svg>
          ):(
            <svg className="countdown-svg" viewBox="0 0 80 80">
              <circle className="countdown-circle-bg-placeholder" cx="40" cy="40" r="35" />
              <circle className="countdown-circle-placeholder" cx="40" cy="40" r="35" />
            </svg>
          )}
        </div>
      </div>

  {/* user profile */}
    <div className="user-container-profile">
  <div className="parent">
    <div className="hover-bridge"></div>
    {
        state.id!=0 ?
        <div className="popup">
            {/* logout */}
            <div className="rounded-box" onClick={()=>{
                dispatch({ type: "setUser",
                    id: 0,
                    email: '',
                    username: '',
                    name: '',
                    token: '',
                    profilepic: 'signup',
                    coins: 0,
                });
                
                setMultipleLocalStorage({
                    id: '0',
                    email: '',
                    username: '',
                    name: '',
                    token: '',
                    profilepic: 'signup',
                    coins: '0',
                })  
                toast.success('Logged out successfully', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
            }}><LogoutIcon/> {t('social.logout')}</div>
            <div className="rounded-box"
              onClick={()=>{
                setShowSettings(true);
              }}>
                <SettingsIcon/> {t('social.settings')}
            </div>
            <div className="rounded-box"
              onClick={()=>{
                setShowDiscover(true);
              }}>
              <div className="popup-wrapper">
                <PersonAddIcon/> 
                  {/* {state.msg_cnt > 0 && (
                    <div className="box-bubble">{state.msg_cnt}</div>
                    )} */}
              </div>
              {t('social.discpeople')}
            </div>
            <div className="rounded-box"
              onClick={()=>{
                setShowConversation(true)
                // dispatch({type: 'setShowConversation', showConversation: true})
              }}>
              <div className="popup-wrapper">
                <QuestionAnswer/> 
                  {state.msg_cnt > 0 && (
                    <div className="box-bubble">{state.msg_cnt}</div>
                    )}
              </div>
              {t('conversation.title')}
            </div>
            <div className="rounded-box"
              onClick={()=>{
                setShowBookmark(true)
              }}>
              <div className="popup-wrapper">
                <BookmarkIcon/> 
              </div>
              {t('bookmark.title')}
            </div>
            <div className="rounded-box"
              onClick={()=>{
                setShowNotifications(true);
              }}>
              <div className="popup-wrapper">
                <Notifications/>
                  {state.notification_incoming.length > 0 && (
                    <div className="box-bubble">{state.notification_incoming.length}</div>
                    )}
              </div>
              {t('notification')}
            </div>
            <div className="rounded-box"
                onClick={()=>{
                  setShowUserProfile(true);
                }}
            ><PersonIcon/>
              {t('social.profile')}
            </div>
            {/* post */}
            <div className="rounded-box" 
                id="capture-btn"
                onClick={()=>{
                    setIsCameraVisible(!isCameraVisible);
                }}
            ><Add/>{t('createpost.post')}</div>
            </div>
        :
        <div className="popin">
            <LoginComponent/>
        </div>

    }
        <div className={`avatar ${state.id==0 && 'popout'}`} onClick={()=>{
            // if(state.id==0){
            //     toast('📣 Zoom-in to connect');
            // }
        }}>
          <div className="avatar-img-wrapper">
            <img className="avatar-img-wrapper" src={`https://outvirt.com/assets/imgmap/${state.profilepic}`} alt={'profile picture'} />
            {state.msg_cnt>0 && <div className="bubble">{state.msg_cnt}</div>}
          </div>
        
        <div className="popright">
        {
            state.id!=0 &&
        // <label>
          <div className="avatar-label">
            <span>{state.name}</span>
            <div className="coin-text">
            <img style={{width: "15px", height: "15px"}} src={`https://outvirt.com/assets/img/coin.png`} alt={'coins'} />
            &nbsp;{state.coins}
            </div>

          </div>
        // </label>
        }
        </div>
        </div>
    </div>
</div>
    </div>

  );
};

export default ProfileComponent;