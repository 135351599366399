import React, { useState, useEffect, useContext } from 'react';
import {AppContext} from '../../../state'
import { Avatar } from '@mui/material';
import { MdFavorite, MdChatBubble } from 'react-icons/md'; // Assuming you are using 'react-icons' for icons
import ParseDate from '../../date/DateT';
import { useTranslation } from 'react-i18next';

interface PostProps {
  id: number;
  setShowChatModal: (showChatModal: boolean) => void;
}


interface PostData {
    post: {
        id: number;
        image: string;
        profilepic: string;
        body: string;
        n_likes: number;
        n_comments: number;
        created_at: string;
    },
    following_pics: string[];
  }
  

const ChatPost: React.FC<PostProps> = ({ id, setShowChatModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [postData, setPostData] = useState<PostData | null>(null);
  const { state, dispatch } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchPostData = async () => {
      setIsLoading(true);
      try {
        
        const response = await fetch('https://outvirt.com:8080/fetchpostforchat', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: id, user_id: state.id})
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data: PostData = await response.json();
        setPostData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!postData) {
    return <div>Post not found</div>;
  }

  return (
    <div className="chat-post-parent-container" onClick={async()=>{
        // setShowChatModal(false)
        // state.showConversation && dispatch({type: 'setShowConversation', showConversation: false})
        dispatch({ type: "setShowSinglePost",
            showSinglePost: true,
            singlePostId: postData.post.id,
        });

    }}>
        <p className="chat-post-text">Check out this post</p>
        <div
            className="chat-post-image"
            onClick={() => {
            }}
        >
            {
            (postData.post.image.slice(-4) == '.mp4') ? (
                <div
                className="map-high-level-nav-vid"
                >
                <video
                    loop={true} muted={true} autoPlay={true} playsInline={true} src={`https://outvirt.com/assets/imgmap/${postData.post.image}`} />
                </div>
            ) : (
                <div
                className="map-high-level-nav"
                style={{ "backgroundImage": `url(https://outvirt.com/assets/imgmap/${postData.post.image})` }}
                />
            )
            }
        </div>
        
        <div className="chatpost-content-container">
            <div className="chatpost-content-background">
            <div>
                <div className="profilepic-wrapper">
                <div
                    className="profilepic-container"
                    style={{
                    backgroundImage: `url(https://outvirt.com/assets/imgmap/${postData.post.profilepic})`,
                    }}
                />
                </div>
                <div className="likes-comments">
                <div><MdFavorite color="#ff7799" />&nbsp;{postData.post.n_likes}</div>
                <div><MdChatBubble color="#77b7ff" /> &nbsp;{postData.post.n_comments}</div>
                    <div className="followingpics-row">
                    {
                    postData.following_pics.map((followingPic, fIdx) => {
                    return (
                            <div key={fIdx} className="followingpic-wrapper">
                            <div
                                className="followingpic-container"
                                style={{
                                backgroundImage: `url(https://outvirt.com/assets/imgmap/${followingPic})`,
                                }}
                            />
                            </div>
                        );
                    })
                }
                </div>
                </div>
            </div>
            <div className="post-body">
                <p className="post-date">{ParseDate(postData.post.created_at, t)}</p>
                <p className="post-text" dangerouslySetInnerHTML={{ __html: postData.post.body }}></p>
            </div>
            </div>
        </div>
    </div>
  );
};

export default ChatPost;