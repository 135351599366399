import React, { createContext, useEffect, useReducer, Dispatch } from "react";
import {
  TFunction
} from 'i18next';



interface Notification {
  id: number;
  message: string;
  url: string;
  created_at: string;
}

type StateType = {
  id: number;
  email: string;
  username: string;
  name: string;
  token: string;
  profilepic: string;
  coins: number;
  ingame: boolean;
  loader: boolean; // Added loader property
  current_idx: number;
  t?: TFunction;
  show_content: boolean;
  is_prev: boolean;
  fetch_post: boolean;
  follow_current_idx: number;
  follow_show_content: boolean;
  follow_is_prev: boolean;
  follow_fetch_post: boolean;
  bookmark_current_idx: number;
  bookmark_show_content: boolean;
  bookmark_is_prev: boolean;
  bookmark_fetch_post: boolean;
  profile_current_idx: number;
  profile_show_content: boolean;
  profile_is_prev: boolean;
  profile_fetch_post: boolean;
  notif_current_idx: number;
  notif_show_content: boolean;
  notif_is_prev: boolean;
  notif_fetch_post: boolean;
  msg_cnt: number;
  notification_incoming: Notification[];
  visible: boolean;
  loc: number[];
  showStandardProfile: boolean;
  standardProfileId: number;
  editPost: boolean;
  editPostId: number;
  navigate: boolean;
  navigate_img: string;
  navigate_lat: number;
  navigate_lon: number;
  navigate_place_id: string;
  showUserPostsOnMap: boolean;
  userPostsOnMapId: number;
  showConversation: boolean;
  showSinglePost: boolean;
  singlePostId: number;
};

type ActionType = {
  type: "setUser" | "setIngame" | "setLoader"| "setShowContent" | "setCurrentIdx" |
  "setFetchPost" | "setFollowCurrentIdx" | "setFollowShowContent" | "setFollowFetchPost" | 
  "setBookmarkCurrentIdx" | "setBookmarkShowContent" | "setBookmarkFetchPost" |
  "setProfileCurrentIdx" | "setProfileShowContent" | "setProfileFetchPost" |
  "setNotifCurrentIdx" | "setNotifShowContent" | "setNotifFetchPost" | "setMsg" | "setNotif" |
  "setVisible" | "setShowStandardProfile" | "setStandardProfileId" | "setEditPost" | "setNavigate" |
  "setShowUserPostsOnMap" | "setShowConversation" | "setShowSinglePost"; // Added setLoader action type
  id?: number;
  email?: string;
  username?: string;
  name?: string;
  token?: string;
  profilepic?: string;
  coins?: number;
  ingame?: boolean;
  current_idx?: number;
  t?: TFunction;
  show_content?: boolean;
  is_prev?: boolean;
  fetch_post?: boolean;
  follow_current_idx?: number;
  follow_show_content?: boolean;
  follow_is_prev?: boolean;
  follow_fetch_post?: boolean;
  bookmark_current_idx?: number;
  bookmark_show_content?: boolean;
  bookmark_is_prev?: boolean;
  bookmark_fetch_post?: boolean;
  profile_current_idx?: number;
  profile_show_content?: boolean;
  profile_is_prev?: boolean;
  profile_fetch_post?: boolean;
  notif_current_idx?: number;
  notif_show_content?: boolean;
  notif_is_prev?: boolean;
  notif_fetch_post?: boolean;
  msg_cnt?: number;
  notification_incoming?: Notification[];
  visible?: boolean;
  loc?: number[];
  showStandardProfile?: boolean;
  standardProfileId?: number;
  editPost?: boolean;
  editPostId?: number;
  navigate?: boolean;
  navigate_img?: string;
  navigate_lat?: number;
  navigate_lon?: number;
  navigate_place_id?: string;
  showUserPostsOnMap?: boolean;
  userPostsOnMapId?: number;
  showConversation?: boolean;
  showSinglePost?: boolean;
  singlePostId?: number;
};

export const initialState: StateType = {
  id: 0,
  email: "fetching...",
  username: "fetching...",
  name: "fetching...",
  token: "",
  profilepic: "signup",
  coins: 0,
  ingame: false,
  loader: false, // Added loader with initial value
  current_idx: 4,
  show_content: false,
  is_prev: false,
  fetch_post: false,
  follow_current_idx: 4,
  follow_show_content: false,
  follow_is_prev: false,
  follow_fetch_post: false,
  bookmark_current_idx: 4,
  bookmark_show_content: false,
  bookmark_is_prev: false,
  bookmark_fetch_post: false,
  profile_current_idx: 4,
  profile_show_content: false,
  profile_is_prev: false,
  profile_fetch_post: false,
  notif_current_idx: 4,
  notif_show_content: false,
  notif_is_prev: false,
  notif_fetch_post: false,
  msg_cnt: 0,
  notification_incoming: [],
  visible: false,
  loc: [0, 0],
  showStandardProfile: false,
  standardProfileId: 0,
  editPost: false,
  editPostId: 0,
  navigate: false,
  navigate_img: '',
  navigate_lat: 0,
  navigate_lon: 0,
  navigate_place_id: '',
  showUserPostsOnMap: false,
  userPostsOnMapId: 0,
  showConversation: false,
  showSinglePost: false,
  singlePostId: 0,
};

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case "setUser": {
      return {
        ...state,
        id: action.id!,
        email: action.email!,
        username: action.username!,
        name: action.name!,
        token: action.token!,
        profilepic: action.profilepic!,
        coins: action.coins!,
      };
    }
    case "setIngame": {
      return {
        ...state,
        ingame: action.ingame!,
      };
    }
    case "setShowContent": {
      return { ...state,
          show_content: action.show_content!,
      };
    }
    case "setCurrentIdx": {
      return { ...state,
          current_idx: action.current_idx!,
          is_prev: action.is_prev!,
      };
    }
    case "setFetchPost": {
      return { ...state,
          fetch_post: action.fetch_post!,
      };
    }
    case "setFollowCurrentIdx": {
      return { ...state,
        follow_current_idx: action.follow_current_idx!,
        follow_is_prev: action.follow_is_prev!,
      };
    }
    case "setFollowShowContent": {
      return { ...state,
        follow_show_content: action.follow_show_content!,
      };
    }
    case "setFollowFetchPost": {
      return { ...state,
          follow_fetch_post: action.follow_fetch_post!,
      };
    }
    case "setBookmarkCurrentIdx": {
      return { ...state,
          bookmark_current_idx: action.bookmark_current_idx!,
          bookmark_is_prev: action.bookmark_is_prev!,
      };
    }
    case "setBookmarkShowContent": {
      return { ...state,
        bookmark_show_content: action.bookmark_show_content!,
      };
    }
    case "setBookmarkFetchPost": {
      return { ...state,
        bookmark_fetch_post: action.bookmark_fetch_post!,
      };
    }
    case "setProfileCurrentIdx": {
      return { ...state,
          profile_current_idx: action.profile_current_idx!,
          profile_is_prev: action.profile_is_prev!,
      };
    }
    case "setProfileShowContent": {
      return { ...state,
        profile_show_content: action.profile_show_content!,
      };
    }
    case "setProfileFetchPost": {
      return { ...state,
        profile_fetch_post: action.profile_fetch_post!,
      };
    }
    case "setNotifCurrentIdx": {
      return { ...state,
          notif_current_idx: action.notif_current_idx!,
          notif_is_prev: action.notif_is_prev!,
      };
    }
    case "setNotifShowContent": {
      return { ...state,
        notif_show_content: action.notif_show_content!,
      };
    }
    case "setNotifFetchPost": {
      return { ...state,
        notif_fetch_post: action.notif_fetch_post!,
      };
    }
    case "setMsg": {
      return { ...state,
        msg_cnt: action.msg_cnt!,
      };
    }
    case "setNotif": {
      return { ...state,
          notification_incoming: action.notification_incoming!,
      };
    }
    case "setVisible": {
      return { ...state,
          visible: action.visible!,
      };
    }
    case "setShowStandardProfile": {
      return { ...state,
        showStandardProfile: action.showStandardProfile!,
        standardProfileId: action.standardProfileId!,
      };
    }
    case "setEditPost": {
      return { ...state,
        editPost: action.editPost!,
        editPostId: action.editPostId!,
      };
    }
    case "setNavigate": {
      return { ...state,
        navigate: action.navigate!,
        navigate_img: action.navigate_img!,
        navigate_lat: action.navigate_lat!,
        navigate_lon: action.navigate_lon!,
        navigate_place_id: action.navigate_place_id!,
      };
    }
    case "setShowUserPostsOnMap": {
      return { ...state,
        showUserPostsOnMap: action.showUserPostsOnMap!,
        userPostsOnMapId: action.userPostsOnMapId!,
      };
    }
    case "setShowConversation": {
      return { ...state,
        showConversation: action.showConversation!,
      };
    }
    case "setShowSinglePost": {
      return { ...state,
        showSinglePost: action.showSinglePost!,
        singlePostId: action.singlePostId!,
      };
    }
    default:
      return state;
  }
};

type AppContextType = {
  state: StateType;
  dispatch: Dispatch<ActionType>;
};

let AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider(props: React.PropsWithChildren<{}>) {
  const fullInitialState = {
    ...initialState,
  };

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value: AppContextType = { state, dispatch };

  useEffect(() => {
    window.localStorage["persistedState"] = JSON.stringify({
      loader: state.loader
    });
  }, [state]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;
// export const wsContext = createContext();

export { AppContext, AppContextProvider, AppContextConsumer };
