import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Map from '../map/MapEmbed'
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const { t } = useTranslation();

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const containerStyle = {
    display: "inline-block",
    width: "400px",
    height: "400px",
    backgroundColor: "gray",
    border: "1px solid black",
    overflow: "hidden",
  };
  
  // const containerStyle = {
  //   display: "inline-block",
  //   width: "100%",
  //   height: "0",
  //   paddingBottom: "100%",
  //   backgroundColor: "gray",
  //   border: "1px solid black",
  //   overflow: "hidden",
  // };
  
  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              {t("mainpage.hero.title")} <span className="text-color-primary">Outvirt</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              {t("mainpage.hero.subtitle")}
                </p>
              {/* <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                <Button tag="a" color="primary" href="https://apps.apple.com/app/outvirt/id6443451816?l=en">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-apple" viewBox="0 0 16 16" >
                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                    </svg>   
                    &nbsp;App Store
                    </Button>
                  <Button tag="a" color="dark" href="https://play.google.com/store/apps/details?id=com.outvirt.app">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-google-play" viewBox="0 0 16 16">
                    <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96 2.694-1.586Zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055l7.294-4.295ZM1 13.396V2.603L6.846 8 1 13.396ZM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27Z"/>
                    </svg>
                    &nbsp;Google Play
                    </Button>
                </ButtonGroup>
              </div> */}
            </div>
          </div>
        </div>
      </div>
{/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
  <div style={containerStyle}>
    <Map/>
  </div> */}
 {/* <a
   data-video="https://player.vimeo.com/video/174002812"
   href="#0"
   aria-controls="video-modal"
   onClick={openModal}
 >
   <Image
     className="has-shadow"
     src={require('./../../assets/images/video-placeholder.jpg')}
     alt="Hero"
     width={896}
     height={504} />
 </a> */}
{/* </div> */}
{/* <Modal
 id="video-modal"
 show={videoModalActive}
 handleClose={closeModal}
 video="https://player.vimeo.com/video/174002812"
 videoTag="iframe" /> */}
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;



          //<div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
          //  <a
          //    data-video="https://player.vimeo.com/video/174002812"
          //    href="#0"
          //    aria-controls="video-modal"
          //    onClick={openModal}
          //  >
          //    <Image
          //      className="has-shadow"
          //      src={require('./../../assets/images/video-placeholder.jpg')}
          //      alt="Hero"
          //      width={896}
          //      height={504} />
          //  </a>
          //</div>
          //<Modal
          //  id="video-modal"
          //  show={videoModalActive}
          //  handleClose={closeModal}
          //  video="https://player.vimeo.com/video/174002812"
          //  videoTag="iframe" />
export default Hero;
