import React from 'react';
import './ChatItem.css';
import ChatPost  from './components/ChatPost';

interface ChatItemProps {
  chat: {
    own: boolean;
    text: string;
    photos: string[] | null;
  };
  profilepic: string;
  setModalInfo: (info: { slides: string[]|null; show: boolean; index: number }) => void;
  setShowChatModal: (show: boolean) => void;
}


const ChatItem: React.FC<ChatItemProps> = (props) => {
  const postId = props.chat.text && props.chat.text.startsWith('/post=') ? parseInt(props.chat.text.substring(6)) : null;

  return (
    <div className="chat-item" style={{ position: 'relative' }}>
      <div style={{ width: '100%' }}>
        <div className="comment-font">
          {props.chat.own ? (
            <div className="chat-row">
              <div className="chat-col-right">
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    backgroundColor: '#7b3fe5',
                    maxWidth: '70%',
                    padding: '5px 10px',
                    // padding: '10px',
                    borderRadius: '10px',
                    lineHeight: '1.4',
                    wordBreak: 'break-word',
                  }}
                >
                  {postId? (<ChatPost id={postId} setShowChatModal={props.setShowChatModal}/> ) : props.chat.text}
                  {
                    props.chat.photos != null &&
                    <div className="chat-photo-row">
                        {
                        props.chat.photos.map((photo, index) => (
                            <div
                                key={index}
                                className="chat-col-photo"
                                onClick={() => {
                                props.setModalInfo({ slides: props.chat.photos, show: true, index: index });
                                }}
                            >
                                <div className="div-crop-comment">
                                <img
                                    alt="post"
                                    className="img-crop-comment"
                                    src={`https://outvirt.com/assets/img/${photo}`}
                                />
                                </div>
                            </div>
                            ))
                        }
                    </div>
                  }
                </div>
              </div>
              <div className="profile-avatar">
                <img src={`https://outvirt.com/assets/img/${props.profilepic}`} width="15" alt="profile" />
              </div>
            </div>
          ) : (
            <div className="chat-row">
              <div className="profile-avatar">
                <img src={`https://outvirt.com/assets/img/${props.profilepic}`} width="15" alt="profile" />
              </div>
              <div className="chat-col-left">
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    backgroundColor: '#7D7C7A',
                    maxWidth: '70%',
                    padding: '5px 10px',
                    borderRadius: '10px',
                    lineHeight: '1.4',
                    wordBreak: 'break-word',
                  }}
                >
                  {props.chat.text}
                  {
                    props.chat.photos != null &&
                    <div className="chat-photo-row">
                        {props.chat.photos.map((photo, index) => (
                            <div
                                key={index}
                                className="chat-col-photo"
                                onClick={() => {
                                props.setModalInfo({ slides: props.chat.photos, show: true, index: index });
                                }}
                            >
                                <div className="div-crop-comment">
                                <img
                                    alt="post"
                                    className="img-crop-comment"
                                    src={`https://outvirt.com/assets/img/${photo}`}
                                />
                                </div>
                            </div>
                            ))}
                    </div>

                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
