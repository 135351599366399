import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import LazyLoadVideo from '../video/LazyLoadVideo';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const AboutDetails = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const { t } = useTranslation();

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'row-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: t("mainpage.featuressplit.title"),
    paragraph: t("mainpage.featuressplit.paragraph")
    // paragraph: 'Tired of spending hours on socia media and still feeling empty? Outvirt is here supercharge your social media experience.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            
          <div className="row-item">
                <div className="row-item-content center-content-mobile reveal-from-left" data-reveal-container=".row-item">
                  <h3 className="mt-0 mb-12">
                      {t("aboutpage.contenttiles.tile1.title")}
                    </h3>
                  <p className="m-0">
                    {t("aboutpage.contenttiles.tile1.content")}
                    </p>
                </div>
              </div>
          <div className="row-item">
                <div className="row-item-content center-content-mobile reveal-from-left" data-reveal-container=".row-item" data-reveal-delay="500">
                  <h3 className="mt-0 mb-12">
                      {t("aboutpage.contenttiles.tile2.title")}
                    </h3>
                  <p className="m-0">
                    {t("aboutpage.contenttiles.tile2.content")}
                    </p>
                </div>
              </div>
          <div className="row-item">
                <div className="row-item-content center-content-mobile reveal-from-left" data-reveal-container=".row-item" data-reveal-delay="1000">
                  <h3 className="mt-0 mb-12">
                      {t("aboutpage.contenttiles.tile3.title")}
                    </h3>
                  <p className="m-0">
                    {t("aboutpage.contenttiles.tile3.content")}
                    </p>
                </div>
              </div>
          <div className="row-item">
                <div className="row-item-content center-content-mobile reveal-from-left" data-reveal-container=".row-item" data-reveal-delay="1500">
                  <h3 className="mt-0 mb-12">
                      {t("aboutpage.contenttiles.tile4.title")}
                    </h3>
                  <p className="m-0">
                    {t("aboutpage.contenttiles.tile4.content")}
                    </p>
                </div>
              </div>
          <div className="row-item">
                <div className="row-item-content center-content-mobile reveal-from-left" data-reveal-container=".row-item" data-reveal-delay="2000">
                  <h3 className="mt-0 mb-12">
                      {t("aboutpage.contenttiles.tile5.title")}
                    </h3>
                  <p className="m-0">
                    {t("aboutpage.contenttiles.tile5.content")}
                    </p>
                </div>
              </div>


  

          </div>
        </div>
      </div>
    </section>
  );
}

AboutDetails.propTypes = propTypes;
AboutDetails.defaultProps = defaultProps;

export default AboutDetails;
