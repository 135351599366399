import React, {useContext, useState} from 'react';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Business as BusinessIcon, Check as CheckIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import { AppContext } from '../../../state'
import StandardProfile from '../StandardProfile';

interface UserItemProps {
    idx: number;
    token: string;
    user_id: number;
    user: any; // Replace 'any' with a proper user type
    users: any[]; // Replace 'any' with a proper user type
    setUsers: (users: any[]) => void; // Replace 'any' with a proper user type
    map: L.Map | undefined;
  }
  
  const UserAddItem: React.FC<UserItemProps> = ({ idx, token, user_id, user, users, setUsers, map }) => {
    const itemStyle = 'rgb(30, 35, 40)';
    const { state, dispatch } = useContext(AppContext);
    const [showStandardProfile, setShowStandardProfile] = useState(false);

    return (
      <>
      {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={user.id} map={map} />}
      <ListItem>
  <Grid container alignItems="center">
    <Grid item xs={2} onClick={() => {
      dispatch({ type: "setShowStandardProfile",
        showStandardProfile: true,
        standardProfileId: user_id
      });
    }}>
      <ListItemAvatar>
        <Avatar src={`https://outvirt.com/assets/imgmap/${user.profilepic}`} />
      </ListItemAvatar>
    </Grid>
    <Grid item xs={8} onClick={() => {
      setShowStandardProfile(true)
    }}>
      <ListItemText primary={user.name} secondary={<><span style={{color:"#999"}}>@{user.username}&nbsp;</span></>} />
    </Grid>
    <Grid item xs={2}>
      {
        user_id!=0 && user_id!=user.id &&
        <ListItemSecondaryAction>
          <Button
            color="primary"
            variant={user.follow ? 'contained' : 'outlined'}
            onClick={async () => {
              if (!user.follow){
                  try {
                      const response = await fetch('https://outvirt.com:8080/followuser', {
                          method: 'POST',
                          headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({id: user.id, token: token})
                          // body: JSON.stringify({id: post.id, token: userInfo.token, lat: latLon?.lat, lon: latLon?.lon, post: true})
                      });
                      if (!response.ok) {
                          throw new Error(response.statusText);
                      }
                      user.follow = true;
                      const newUser = [...users.slice(0,idx), user, ...users.slice(idx+1)] // TODO: there might be a bug here
                      setUsers(newUser);
                  } catch (err) {
                  console.log(err);
                  }
              }
              else {
                  try {
                      const response = await fetch('https://outvirt.com:8080/unfollowuser', {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({id: user.id, token: token})
                  });
                  if (!response.ok) {
                      throw new Error(response.statusText);
                  }
                  user.follow = false;
                  const newUser = [...users.slice(0,idx), user, ...users.slice(idx+1)]
                  setUsers(newUser);
                  } catch (err) {
                  console.log(err);
                  }
              }
            }}
          >
            {user.follow ? <CheckIcon /> : <PersonAddOutlinedIcon />}
          </Button>
        </ListItemSecondaryAction>
      }
    </Grid>
  </Grid>
</ListItem>
</>
    );
  };
  
  export default UserAddItem;