import React, {useContext, useState} from "react";
import {
  Grid,
  Avatar,
  Typography,
  Chip,
} from "@material-ui/core";
import ParseDate from '../../date/DateT';
import {AppContext} from '../../../state'
import '../Conversation.css'
import '../Profile.css'
import { useTranslation } from 'react-i18next';
import { useDraggable } from "./Drag";
import { Bookmarks } from "@material-ui/icons";
import SmsIcon from '@mui/icons-material/Sms';
interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    selected: boolean;
    user_id: number;
  }

  
interface ChatUser {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }
  
interface PostProps {
    tablename: string
    post_id: number
  }

interface PostCard {
    idx: number;
    id: number;
    tablename: string;
    photos: string[]
    profilepic: string
    user_id: number
    name: string
    type: boolean
    body: string
    created_at: string
    n_likes: number
    n_comments: number
    bookmarked: boolean
    liked: boolean
    precise: boolean
    topic_name: string[]
    loc_name: string
    lat?: number
    lon?: number
    account_type: number;
    url: string;
    user_url: string
  }

interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

interface ContainerProps {
    idx: number
    bookmarks: Bookmark[]
    subbody: string[]
    setSubbody: React.Dispatch<React.SetStateAction<string[]>>
}

const BookmarkItemCompose: React.FC<ContainerProps> = (props) => {
  // Your existing logic and functions
  const { state, dispatch } = useContext(AppContext);
  // const ref = React.useRef<HTMLInputElement>(null)
  const { t } = useTranslation();
  // const draggableRef = useDraggable({ id: props.idx.toString() });
  const [showComment, setShowComment] = useState(false);

  return (
    <>
    <Grid container style={{marginBottom: "16px"}}>
      <Grid item xs={2}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
        <div className="div-crop-conversation">
            {props.bookmarks[props.idx].photos[0].slice(-4) === ".mp4" ? (
              <div className="post-icon-wrapper">
                <div className="vid-container-bookmark"  style={{ border: "1px solid #666" }}>
                  <video preload="metadata" src={`https://outvirt.com/assets/imgmap/${props.bookmarks[props.idx].photos[0]}#t=0.1`}></video>
                </div>
              </div>
            ) : (
              <div className="post-icon-wrapper">
                <div
                  className="img-container-bookmark"
                  style={{
                    backgroundImage: `url(https://outvirt.com/assets/imgmap/${props.bookmarks[props.idx].photos[0]})`,
                    border: "1px solid #666"
                  }}
                />
              </div>
            )}
        </div>
      </Grid>
      <Grid item xs={9}>
        <Grid container style={{color: props.bookmarks[props.idx].last_id == state.id || props.bookmarks[props.idx].n_comments == 0 || props.bookmarks[props.idx].tablename != 'chat' ? "#9E9E9E" : "#FFFFFF" }}>
          <Grid item xs={12}>
            <Typography className="limit-text">
              {props.bookmarks[props.idx].name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center"}}>
            <Typography
              className="limit-text"
              style={{ maxWidth: "70vw" }}
            >
              {props.bookmarks[props.idx].last_id === state.id && "You: "}
              {props.bookmarks[props.idx].body}
            </Typography>
          </Grid>
          
        </Grid>
      </Grid>
      
      <Grid item xs={1}>
      <button className={`compile-label-button input-end ${props.subbody[props.idx]=='' ? 'not-active' : ''}`} onClick={async () => {
            setShowComment(!showComment);
            }}>
            <SmsIcon className="icon-button"/>
        </button>


      </Grid>
    </Grid>
    {showComment && (
    
    <Grid container style={{marginBottom: "16px"}}>
    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
        <div className="compile-avatar-comment">
            <img src={"https://outvirt.com/assets/imgmap/" + state.profilepic} width="35" alt="Profile"></img>
        </div>
    </Grid>
    <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <textarea className="textarea-comment" rows={1} placeholder={t('createpost.comment')!} value={props.subbody[props.idx]} 
            onChange={async(e) => {
                let newSubbody = [...props.subbody];
                newSubbody[props.idx] = e.target.value;
                props.setSubbody(newSubbody);
            }}/>
    </Grid>
</Grid>
    )}

    </>
  );
}


export default BookmarkItemCompose;
