import { useState, useEffect } from "react";
import "./PostButtons.css";
import React from 'react';
import { ArrowUpward, ArrowDownward, SpaceBar, ArrowLeft, ArrowRight, TouchApp } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import MouseIcon from '@mui/icons-material/Mouse';

const PostButtons: React.FC<{ showTutorials: boolean; onTimeoutEnd: () => void }> = ({
    showTutorials,
    onTimeoutEnd,
  }) => {
    const [visible, setVisible] = useState(true);

    const [isMobile, setIsMobile] = useState(false);

    const [tutorialButtons, setTutorialButtons] = useState(
        [
            {
              icon: <div className='letter-icon-outline'>Esc</div>,
              caption: "Dismiss",
            },
            {
              icon: <div className="icon-group"><ArrowDownward className="icon-outline"/><MouseIcon className="icon-outline"/></div>,
              // icon: <ArrowDownward className="icon-outline"/>,
              caption: "Next",
            },
            {
              icon: <div className="icon-group"><ArrowUpward className="icon-outline"/><MouseIcon className="icon-outline"/></div>,
              // icon: <ArrowUpward className="icon-outline"/>,
                caption: "Previous",
            },
            {
              icon: <div className="icon-group"><ArrowLeft className="icon-outline"/><ArrowRight className="icon-outline"/></div>,
              caption: "Browse",
            },
            {
                icon: <SpaceBar className="icon-outline"/>,
                caption: "Like",
            },
            {
                icon: <div className='letter-icon-outline'>c</div>,
                caption: "Comments",
            },
          ]
    )

    useEffect(() => {
        const detectDevice = () => {
        const userAgent = navigator.userAgent || navigator.vendor;
        const isMobileDevice = /Mobi|Android/i.test(userAgent);
        const isSmallScreen = window.innerWidth <= 768;
        setIsMobile(isMobileDevice || isSmallScreen|| 'ontouchstart' in window || navigator.maxTouchPoints > 0);
        };
        detectDevice();
    }, []);
  
    useEffect(() => {
      if (showTutorials) {
        setVisible(true);
        setTimeout(() => {
          setVisible(false);
          onTimeoutEnd();
        }, 1500);
      } else {
        setVisible(false);
      }
    }, [showTutorials]);
  
    useEffect(() => {
        if (isMobile) {
            setTutorialButtons(
                [
                    {
                      icon: <div className="icon-group"><SwipeLeftIcon className="icon-outline"/><SwipeRightIcon className="icon-outline"/></div>,
                      caption: "Dismiss",
                    },
                    {
                        icon: <SwipeUpIcon className="icon-outline"/>,
                        caption: "Next",
                    },
                    {
                        icon: <SwipeDownIcon className="icon-outline"/>,
                        caption: "Previous",
                    },
                    {
                        icon: <TouchApp className="icon-outline"/>,
                        caption: "Like",
                    },
                  ]
            )
            }
        }, [isMobile])

    

  return (
    <>
      {showTutorials && (
        <div className={`tutorial-buttons-container ${visible ? 'show' : ''}`} style={{zIndex: getHighestZIndex()+1}}>
          {tutorialButtons.map((button, index) => (
            <div className="tutorial-button" key={index}>
              <div className="tutorial-button-icon">{button.icon}</div>
              <div className="tutorial-button-caption">{button.caption}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default PostButtons;
