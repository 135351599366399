import React, { useRef, useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import L, { LatLng, Icon } from 'leaflet';
// import 'leaflet/dist/leaflet.css';
import './MapLocate.css';
// import markerIconPng from "leaflet/dist/images/marker-icon.png"

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Button, Card, CardHeader, CardContent, Fab } from '@material-ui/core';
import { Check, Search, Star, LocationOn } from '@material-ui/icons';
import { AppContext } from '../../state'
import { useTranslation } from 'react-i18next';
import GoogleIcon from '@mui/icons-material/Google';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { getHighestZIndex } from '../utils/GetHighestZIndex';

// Import your custom styles here
const customMarkerIcon = new Icon({
  iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon.png`,
  iconRetinaUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x.png`,
  shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});



interface PlaceSuggestions {
  lat: number;
  lon: number;
  ne_lat: number;
  ne_lon: number;
  sw_lat: number;
  sw_lon: number;
  name: string;
  vicinity: string;
  open_now: boolean;
  price_level: number;
  rating: number;
  user_ratings_total: number;
  place_id: string;
}


interface ContainerProps {
  onSelectLoc: (lat: number, lon: number, locName: string, placeId: string) => void;
}



const MapLocate: React.FC<ContainerProps> = (props) => {
  // Your states and functions here
  const [marker, setMarker] = useState<LatLng>(new LatLng(24.790601503977307, 120.99565885890476));
  const [searchText, setSearchText] = useState<string>('')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showInput, setShowInput] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(true);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [map, setMap] = useState<L.Map>();
  // const [placeSuggestions, setPlaceSuggestions] = useState<PlaceSuggestions[]>([])
  const [placeSuggestions, setPlaceSuggestions] = useState<PlaceSuggestions[]>([])
  const { t, i18n } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [swiperRef, setSwiperRef] = useState<any | null>(null);
  const [precise, setPrecise] = useState(false);

  const [mapLocate, setMapLocate] = useState<boolean>(false);
  const [locName, setLocName] = useState<string>("");
  const [placeId, setPlaceId] = useState<string>("");
  const [lat, setLat] = useState<number>();
  const [lon, setLon] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const MapController = () => {
    const map = useMap();
    setMap(map)



    return <></>;
  };

  // initialize initial location
  useEffect(() => {
    // locate use using GPS
    if (map) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            setMarker(new LatLng(latitude, longitude));
            setLat(latitude)
            setLon(longitude)
            map!.flyTo([latitude, longitude], 17, { duration: 0.7 });
          },
          (error) => {
            console.error('Error fetching location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }

    }
  }, [map])
  // Modify content display to fit the screen
  useEffect(() => {

    setTimeout(() => {
    }, 1000);
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  const GetZoomLevel = () => {

    const mapEvents = useMapEvents({

      click(e: any) {
        if (!showSuggestions) {
          setMarker(e.latlng)
          setLocName('')
          setPlaceId('')
          // setLat(e.latlng.lat)
          // setLon(e.latlng.lng)
          // setPlaceId("")
          // if (isMarkerInsidePolygon(e.latlng, regionCoords)){
          //   setConfirmAllow(true)
          // }
          // else{
          //   setConfirmAllow(false)
          // }
          console.log(e.latlng)

        }
      },
      zoomend: () => {
        // setZoomLevel(mapEvents.getZoom());
      },
      moveend: async () => {
      }
    });

    return null
  }
  return (
    <div onClick={(e) => e.stopPropagation()} style={{ zIndex: '3000', height: `${viewportHeight}px`, position: 'relative' }}>
      {isLoading && <div className="spinner-container" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>}      <MapContainer
        center={[marker.lat, marker.lng]}
        zoom={18}
        scrollWheelZoom={true}
        zoomControl={false}
        attributionControl={false}
        style={{ height: '100vh', width: '100%', backgroundColor: 'black' }}
      >
        <GetZoomLevel />
        <MapController />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://outvirt.com/osm/{z}/{x}/{y}.png"
        />
        <Marker position={marker} icon={customMarkerIcon}></Marker>
      </MapContainer>

      {
        !showSuggestions &&
          <Fab
            style={{ zIndex: '3000', position: 'absolute', bottom: '70px', right: '15px' }}
            // color="secondary"
            className="navigate-icon-locate"
            onClick={async () => {
              // locate use using GPS
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;

                    setMarker(new LatLng(latitude, longitude));
                    setLat(latitude)
                    setLon(longitude)
                    map!.flyTo([latitude, longitude], 17, { duration: 0.7 });
                  },
                  (error) => {
                    console.error('Error fetching location:', error);
                  }
                );
              } else {
                console.error('Geolocation is not supported by this browser.');
              }
            }}
          >
            <LocationSearchingIcon />
            {/* <LocationOn /> */}
          </Fab>
      }


      {
        !showSuggestions &&
          <Fab
            style={{ zIndex: '3000', position: 'absolute', bottom: '15px', right: '15px' }}
            color="primary"
            variant="extended"
            onClick={async (ev) => {
              ev.stopPropagation()
              ev.preventDefault()
              props.onSelectLoc(marker.lat, marker.lng, locName, placeId)
            }}
          >
            <Check />
          </Fab>
      }

      <Fab
        style={{
          zIndex: '3000',
          position: 'absolute',
          top: '15px',
          right: '15px',
          border: '1px solid #333',
          backgroundColor: '#333',
          color: '#fff',
          display: showSearchButton ? 'flex' : 'none',
          animation: showSearchButton ? 'slideIn 0.5s ease' : 'slideOut 0.9s ease',
        }}
        color="default"
        onClick={() => {
          setShowInput(true);
          setShowSearchButton(false);
        }}
      >
        <Search />
      </Fab>

      {
        showSuggestions &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

          <Swiper style={{ 'zIndex': "54000", "position": "absolute", "display": "flex", "width": "90%", "bottom": "5px", justifyContent: "center" }}
            modules={[Pagination]}
            className="dark-swiper"
            // autoplay={true}
            // keyboard={true}
            onSwiper={setSwiperRef}
            // pagination={{ clickable: true, el: '.swiper-pagination-custom' }}
            pagination={true}
            // scrollbar={true}
            // zoom={true}
            onTouchStart={() => {
              map && map!.dragging.disable();
              map && map!.touchZoom.disable();
              map && map!.doubleClickZoom.disable();
              map && map!.scrollWheelZoom.disable();
              map && map!.boxZoom.disable();
              map && map!.keyboard.disable();
              if (map && map!.tap) map!.tap.disable();
            }}
            onTouchEnd={() => {
              map && map!.dragging.enable();
              map && map!.touchZoom.enable();
              map && map!.doubleClickZoom.enable();
              map && map!.scrollWheelZoom.enable();
              map && map!.boxZoom.enable();
              map && map!.keyboard.enable();
              if (map && map!.tap) map!.tap.enable();
            }}
            onSlideChange={() => {
              setMarker(new LatLng(placeSuggestions[swiperRef.activeIndex].lat, placeSuggestions[swiperRef.activeIndex].lon));
              setLat(placeSuggestions[swiperRef.activeIndex].lat)
              setLon(placeSuggestions[swiperRef.activeIndex].lon)
              map!.flyTo([placeSuggestions[swiperRef.activeIndex].lat, placeSuggestions[swiperRef.activeIndex].lon], 16, { duration: 0.7 });
            }}
          >
            {
              placeSuggestions.map((placeSuggestion, idx) => {
                return (
                  <SwiperSlide>
                    <div className="dark-card">
                      {/* <div className="dark-card-header">
                                      </div> */}
                      <div className="dark-card">
                        <div className="place-name">
                          {placeSuggestion.name}
                        </div>
                        <div className="vicinity">
                          {placeSuggestion.vicinity}
                        </div>
                        <div>
                          {placeSuggestion.open_now ? <span style={{ color: 'green' }}>{t('createpost.open')}</span> : <span style={{ color: 'red' }}>{t('createpost.closed')}</span>}
                          &nbsp;·&nbsp;
                          {
                            Array(placeSuggestion.price_level).fill(null).map((_, i) => { return '$' })
                          }

                        </div>
                        <div>
                          <div className="rating-buttons">
                            {
                              placeSuggestion.rating &&
                              <div className="rating">
                                <span style={{ verticalAlign: 'middle' }}>{placeSuggestion.rating}&nbsp;</span>
                                {Array(Math.floor(placeSuggestion.rating))
                                  .fill(null)
                                  .map((_, i) => {
                                    return <StarIcon key={i} className="star-positive" />
                                    // return <i style={{ verticalAlign: 'middle' }} className="text-warning fas fa-star"></i>;
                                  })}
                                {Array(5 - Math.floor(placeSuggestion.rating))
                                  .fill(null)
                                  .map((_, i) => {
                                    return <StarIcon key={i} className="star-negative" />
                                    // return <i style={{ verticalAlign: 'middle' }} className="text-secondary fas fa-star"></i>;
                                  })}
                                <span style={{ verticalAlign: 'middle' }}>
                                  &nbsp;({placeSuggestion.user_ratings_total})
                                </span>

                              </div>
                            }
                            <div className="buttons">
                              <button className="google-button" onClick={() => {

                                window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeSuggestion.place_id}`, '_blank');
                                // Browser.open({ url: `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeSuggestion.place_id}`})
                              }}>
                                <GoogleIcon />
                              </button>
                              <button
                                className="round-button-locate"
                                onTouchStart={() => {
                                  // map!.click.disable();
                                  map!.dragging.disable();
                                  map!.touchZoom.disable();
                                  map!.doubleClickZoom.disable();
                                  map!.scrollWheelZoom.disable();
                                  map!.boxZoom.disable();
                                  map!.keyboard.disable();
                                  if (map!.tap) map!.tap.disable();
                                }}
                                onTouchEnd={() => {
                                  map!.dragging.enable();
                                  map!.dragging.enable();
                                  map!.touchZoom.enable();
                                  map!.doubleClickZoom.enable();
                                  map!.scrollWheelZoom.enable();
                                  map!.boxZoom.enable();
                                  map!.keyboard.enable();
                                  if (map!.tap) map!.tap.enable();
                                }}
                                onClick={() => {
                                  setLocName(placeSuggestion.name)
                                  setPlaceId(placeSuggestion.place_id)
                                  setShowSuggestions(false)
                                  setPrecise(true)
                                  setMapLocate(false)
                                }}>
                                <CheckIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                )
              })
            }
          </Swiper>
        </div>
      }


      {showInput && (
        <input
          type="text"
          placeholder="Enter location"
          className="map-searchbar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === 'Enter') {
              setIsLoading(true);
              // Your logic here
              setShowInput(false);
              setShowSearchButton(true);
              console.log(map)
              try {
                const response = await fetch('https://outvirt.com:8080/placesearch', {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    user_id: state.id,
                    lat: map!.getCenter().lat,
                    lon: map!.getCenter().lng,
                    text: searchText,
                    lang: i18n.language
                  })
                });
                if (!response.ok) {
                  throw new Error(response.statusText);
                } else {
                  response.json().then((res) => {
                    console.log(res)
                    if (res.length > 0) {
                      console.log('res', res)
                      setPlaceSuggestions(res)
                      setShowSuggestions(true)

                      setMarker(new LatLng(res[0].lat, res[0].lon));
                      setLat(res[0].lat)
                      setLon(res[0].lon)
                      map!.flyTo([res[0].lat, res[0].lon], 16, { duration: 0.7 });
                    }

                  })
                }
              } catch (err) {
                console.log(err);
              }
            }

            setIsLoading(false);
          }}
          style={{
            zIndex: '3000',
            position: 'absolute',
            top: '15px',
            right: '15px',
            padding: '5px',
            borderRadius: '4px',
            border: '1px solid #333',
            backgroundColor: '#333',
            color: '#fff',
            width: '300px',
            display: showInput ? 'block' : 'none',
            opacity: showInput ? 1 : 0,
            animation: showInput ? 'slideIn 0.5s ease' : 'slideOut 0.5s ease',
          }}
          autoFocus
        />
      )}
    </div>
  );
};

export default MapLocate;