import React, {useContext, useState} from 'react';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core';
import { Search as SearchIcon, Business as BusinessIcon, Check as CheckIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@material-ui/icons';
import { AppContext } from '../../../state'
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';  

interface UserItemProps {
    idx: number;
    post_id: number;
    tablename: string;
    token: string;
    bookmarkCat: any; // Replace 'any' with a proper user type
    bookmarkCats: any[]; // Replace 'any' with a proper user type
    setBookmarkCats: (bookmarkCats: any[]) => void; // Replace 'any' with a proper user type
  }
  const PostBookmarkItem: React.FC<UserItemProps> = ({ idx, post_id, tablename, token, bookmarkCat, bookmarkCats, setBookmarkCats }) => {
    const itemStyle = 'rgb(30, 35, 40)';
    const { state, dispatch } = useContext(AppContext);
    const [showStandardProfile, setShowStandardProfile] = useState(false);
    const { t } = useTranslation();
    return (
      <>
      <ListItem>
  <Grid container alignItems="center">
    <Grid item xs={2}>
      <ListItemAvatar>
        <Avatar src={`https://outvirt.com/assets/imgmap/${bookmarkCat.image}`} />
      </ListItemAvatar>
    </Grid>
    <Grid item xs={8} onClick={() => {
      setShowStandardProfile(true)
    }}>
      <ListItemText primary={bookmarkCat.name=='recent'? t("conversation.recent"): bookmarkCat.name}/>
    </Grid>
    <Grid item xs={2}>
        <ListItemSecondaryAction>
          <Button
            color="primary"
            variant={bookmarkCat.added ? 'contained' : 'outlined'}
            onClick={async () => {
              if (!bookmarkCat.added){
                  try {
                      const response = await fetch('https://outvirt.com:8080/addbookmarkcat', {
                          method: 'POST',
                          headers: {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({post_id: post_id, tablename: tablename, cat_id: bookmarkCat.id, token: token})
                          // body: JSON.stringify({id: post.id, token: userInfo.token, lat: latLon?.lat, lon: latLon?.lon, post: true})
                      });
                      if (!response.ok) {
                          throw new Error(response.statusText);
                      }
                      bookmarkCat.added = true;
                      const newBookmarkCats = [...bookmarkCats.slice(0,idx), bookmarkCat, ...bookmarkCats.slice(idx+1)] // TODO: there might be a bug here
                      setBookmarkCats(newBookmarkCats);
                  } catch (err) {
                  console.log(err);
                  }
              }
              else {
                  try {
                      const response = await fetch('https://outvirt.com:8080/removebookmarkcat', {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({post_id: post_id, tablename: tablename, cat_id: bookmarkCat.id, token: token})
                  });
                  if (!response.ok) {
                      throw new Error(response.statusText);
                  }
                  bookmarkCat.added = false;
                  const newBookmarkCats = [...bookmarkCats.slice(0,idx), bookmarkCat, ...bookmarkCats.slice(idx+1)]
                  setBookmarkCats(newBookmarkCats);
                  } catch (err) {
                  console.log(err);
                  }
              }
            }}
          >
            {bookmarkCat.added ? <CheckIcon /> : <AddIcon />}
          </Button>
        </ListItemSecondaryAction>
    </Grid>
  </Grid>
</ListItem>
</>
    );
  };
  
  export default PostBookmarkItem;