import ParseDate from '../date/Date';
import { Button, Grid, Avatar } from "@material-ui/core";
import { useState, useRef} from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
  }
  interface ReplyItemProps {
    comment?: Comment;
  }
  
  const ReplyItem: React.FC<ReplyItemProps> = ({ comment }) => {
    const itemStyle = 'rgb(30, 35, 40)';
    // const history = useHistory();
    console.log(comment)
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const ref = React.useRef<HTMLInputElement>(null)

	return (
        <div style={{ position: 'relative', fontSize: '15px' }}>
            <div ref={ref} style={{ width: '100%' }}>
                <div style={{ margin: '0 auto', maxWidth: '1140px', padding: '0 15px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                    onClick={()=>{}}
                    >
                    <div
                        className="profile-icon"
                        style={{
                            backgroundImage: `url(https://outvirt.com/assets/img/${comment!.profilepic})`,
                        }}
                    />

                    {/* <div className="profile-icon">
                        <img src={`https://outvirt.com/assets/img/${props.comment.profilepic}`} alt="profile" />
                    </div> */}
                    </div>
                    <div style={{ flexBasis: '100%', maxWidth: '100%', paddingRight: '15px', paddingLeft: '15px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        <div onClick={()=>{}}>
                        {comment!.name}
                        </div>
                        <span style={{ color: 'gray' }}>
                        &nbsp;&nbsp;· {ParseDate(comment!.created_at)}
                        </span>
                    </div>
                    <div>
                        <div
                        style={{ whiteSpace: 'pre-wrap' }}
                        onClick={()=>{}}
                        >
                        {comment!.body.split(urlRegex).map((part, i) => {
                            if (part.match(urlRegex)) {
                            return (
                                <Link to={part} key={i}>
                                {part}
                                </Link>
                            );
                            } else {
                            return <span key={i}>{part}</span>;
                            }
                        })}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {comment!.photos != null
                            ? comment!.photos.map((photo, index) => {
                                return (
                                <div
                                    className="ion-no-padding"
                                    key={index}
                                    style={{
                                    flexBasis: comment!.photos.length === 1 ? '100%' : '50%',
                                    maxWidth: comment!.photos.length === 1 ? '100%' : '50%',
                                    paddingRight: '15px',
                                    paddingLeft: '15px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    }}
                                >
                                    <div>
                                    <div
                                        className="div-crop-comment"
                                        style={{
                                        width: comment!.photos.length === 1 ? '60vw' : '25vw',
                                        height: comment!.photos.length === 1 ? '60vw' : '25vw',
                                        }}
                                    >
                                        <img
                                        alt="post"
                                        className="img-crop-comment"
                                        src={`https://outvirt.com/assets/img/${photo}`}
                                        />
                                    </div>
                                    </div>
                                </div>
                                );
                            })
                            : null}
                        </div>
                    </div>
                    
                    </div>
                </div>
                </div>
            </div>
</div>



	);
}

export default ReplyItem;