import React, { useRef, useState, useContext, useEffect, useLayoutEffect, ReactElement } from 'react';
import {AppContext} from '../../../state'
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import { Add as AddIcon, Search as SearchIcon } from '@material-ui/icons';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Collapse, ClickAwayListener, MenuList, Popover , Select, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import 'swiper/swiper.min.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Swiper as SwiperType } from 'swiper';
import UserList from '../UserList';
import { getHighestZIndex } from '../../utils/GetHighestZIndex';

interface ContainerProps {
    showLikeList: boolean
    setShowLikeList: React.Dispatch<React.SetStateAction<boolean>>
    post_id: number
}

interface User {
    id: number;
    account_type: number;
    name: string;
    username: string;
    profilepic: string;
    follow: boolean;
  }



const LikeList: React.FC<ContainerProps> = (props) => {
  const { t, i18n } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);

  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', showError: false });
  const [searchText, setSearchText] = useState('');
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);
  const [language, setLanguage] = useState<string>('english');
  const [users, setUsers] = useState<User[]>([])

  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
  };

  
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    fetchPostLikes(props.post_id)

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const fetchPostLikes = async (post_id: number) => {
    setLoading(true);
    try {
        const response = await fetch('https://outvirt.com:8080/fetchpostlikes', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                post_id: props.post_id,
                user_id: state.id,
            })
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        } else {
          response.json().then((res)=>{
            setUsers(res)
          })
        }
      } catch (err) {
          console.log(err);
      }
}



  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};



  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
    }
  };

  return (
    <div>
    <Dialog  
    //   className="like-dialog dark-dialog"
      className="dark-dialog"
      open={props.showLikeList}
      onClose={() => props.setShowLikeList(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: getHighestZIndex() + 1 }}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <FavoriteIcon/>
            &nbsp;&nbsp;Likes
        </div>
        <IconButton color="inherit" onClick={() => props.setShowLikeList(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content">
        <UserList users={users} setUsers={setUsers}/>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default LikeList;
