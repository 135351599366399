
import React, {createRef, RefObject,createContext, useContext, useRef } from 'react';
import './Agar.css';
import Blob from "./components/Blob";
import {BlobData, getMagnitude, normalize, getRandomNumber} from "./util";
// import {tsx2jsGive, tsx2jsGet, js2tsxGive, js2tsxGet, GetID, GiveEat, GiveScore} from "../state";
import e from 'express';
// import {wsContext, AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';
import { FaBold } from 'react-icons/fa';


// leaderscore: id=-1 x:nycu y:nthu
// leaderboard: id=-5 score name team
// userscore: id=eat_id x: score
interface AppState {
    mainBlob: BlobData, //BlobData define in the ./util.ts
    otherBlobs: BlobData[] //food & other user
}

interface LeaderScore {
    nycu: number,
    nthu: number,
}

interface LeaderBoard {
    score: number,
    name: string,
    team: boolean, // 0: nthu 1: nycu
}


interface DisplayProps {
    updateUser: (newValue: number) => void;
    updateLeaderScore: (newValue: LeaderScore) => void;
    updateLeaderBoard: (newValue: LeaderBoard[]) => void;
    updatePlay: (newValue: boolean) => void;
  }

// const width = window.innerWidth;
// const height = window.innerHeight;
const width = document.documentElement.clientWidth;
const height = document.documentElement.clientHeight;

var intervalId: any = null; //setInterval
var score = 0; //record the score
// var myID = GetID(); // get the information of the user
// var myID = GetID(); // get the information of the user
var last = -1; // global cnt used for add new balls
const color = [ //define the color of balls
    "LightCoral",
    "PaleGreen",
    "Wheat",
    "RosyBrown",
    "Pink",
    "MediumAquamarine",
    "LightSalmon",
    "ightYellow",
    "PaleTurquoise",
    "Lavender",
    "Moccasin"
];

var wsSetup = false;
// class Agar extends React.Component<{}, AppState> {
class Agar extends React.Component<DisplayProps, AppState> {
    // static contextType = wsContext;
    // context!: React.ContextType<typeof wsContext>;
    // sendMessage(ws: React.MutableRefObject<WebSocket>, id: number, name: string) {
    //     if (this.state.mainBlob.x==undefined || this.state.mainBlob.y==undefined || this.state.mainBlob.r==undefined){
    //         return
    //     }
    //     const main_x = this.state.mainBlob.x; //the user's ball position
    //     const main_y = this.state.mainBlob.y;
    //     const main_r = this.state.mainBlob.r
    //     // const pt = DOMPoint.fromPoint({ x: main_x, y: main_y, z: main_r}); 
    //     ws && ws.current.send(JSON.stringify({x: main_x, y: main_y, r: main_r, id: id, alive: true, name: name})); //send to server
    //     // tsx2jsGive(pt); //send to map.js (to update the server data by socket)
    // }

    // receiveMessage(msg: BlobData[], id: number, ws: React.MutableRefObject<WebSocket>){
    //     msg && msg.map(blob => {
    //         // if (blob.id!=0)
    //     // own message
    //     if (blob.id === id) {
    //       // console.log(blob.id === state.id, blob.id, state.id, blob.alive)
    //       if (!blob.alive){
    //         // gameover
    //         toast.error('Game Over', {
    //           position: "top-center",
    //           autoClose: 5000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //         });
    //         this.props.updatePlay(false);
    //         ws.current.close()
    //         ws.current.onmessage = null;
    //         if(intervalId)  clearInterval(intervalId);
    //         wsSetup = false
    //       } else {
    //         // update own blob (only update radius as x,y is updated by client)
    //         this.setState(prevState => ({
    //             mainBlob: {
    //                 ...prevState.mainBlob,
    //                     // x: blob.x,
    //                     // y: blob.y,
    //                     r: blob.r
    //             }
    //         }));
    //       }
    //     } 
    //     else if (blob.id == -1) {
    //         // update leader score
    //         this.props.updateLeaderScore({nycu: blob.x, nthu: blob.y});
    //     } 
    //     else if (blob.id == -5) {
    //         // update leader score
    //         blob.board && this.props.updateLeaderBoard(blob.board);
    //     }
    //     else {
    //         // if (blob.id < 10000){
    //         //     console.log(blob)
    //         // }
    //     // console.log(blob.alive, blob.id, blob.x, blob.y)

    //     //   console.log('food', blob)
    //     // console.log('b', blob)
    //       if (blob.alive){
    //         const blobExist = this.state.otherBlobs.some(otherBlob => otherBlob.id === blob.id)
    //         // const blobExist = foodBlobsRef.current && foodBlobsRef.current.some(element => element.id === blob.id);
    //         // const blobExist = foodBlobs.some(element => element.id === blob[ball_id]);
    //         if (!blobExist) {
    //           // add new blob
    //           this.setState(prevState => ({
    //             otherBlobs: [...prevState.otherBlobs, {
    //                 x: blob.x,
    //                 y: blob.y,
    //                 id: blob.id,
    //                 r: blob.r,
    //                 alive: true,
    //                 name: blob.name,
    //                 color: color[Math.floor(Math.random()*11)]
    //             }]
    //             }));
    //         } else {
    //           // update blob loc
    //           this.setState(prevState => {
    //             const updatedBlobs = prevState.otherBlobs.map(oblob => {
    //               if (oblob.id === blob.id) {
    //                 return { ...oblob, x: blob.x, y: blob.y, r: blob.r };
    //               }
    //               return oblob;
    //             });
    //             return { otherBlobs: updatedBlobs };
    //           });
              
    //         //   this.setState(prevState => {
    //         //     const blobToModify = prevState.otherBlobs.find(oblob => oblob.id === blob.id); // find the id from array is as same as input
    //         //     if (blobToModify) { //update
    //         //       blobToModify.x = blob.x;
    //         //       blobToModify.y = blob.y;
    //         //       blobToModify.r = blob.r;
    //         //       const filteredBlobs = prevState.otherBlobs.filter(item => item.id !== blob.id);

    //         //       return { otherBlobs: [...filteredBlobs, blobToModify] };
    //         //     } else {
    //         //         return { otherBlobs: [...prevState.otherBlobs] };
    //         //     }
    //         //   });
    //         }
    //       } else {
    //         // blob dead, remove from list
    //         this.setState(prevState => {
    //             const updatedBlobs = prevState.otherBlobs.filter(element => element.id !== blob.id) // find the id from array is as same as input
    //             return { otherBlobs: updatedBlobs }
    //           });
    //         this.props.updateUser(blob.x);
    //       }
    //     }
    //     // return element;
    //   })
    // }

    // // Agar_js2tsx() {
    // //     var get_var = js2tsxGet(); // get data from map.js(server)
    // //     if(last < get_var.id && get_var.id != myID.id){ // check if the id already be initiated
    // //         this.addBlobPosition(get_var.x,get_var.y,get_var.id,get_var.r,get_var.alive); // add new ball
    // //         last = get_var.id
    // //     }
    // //     else if(get_var.id != myID.id){ // update old ball
    // //         this.updateBlobPosition(get_var.x,get_var.y,get_var.id,get_var.r,get_var.alive);
    // //     }
    // // }

    // svg: RefObject<SVGSVGElement>;
    
    // constructor(props: any) {
    //     super(props);
    //     this.state = {
    //         mainBlob: { // initiate the date
    //             x: 0,
    //             y: 0,
    //             r: 20,
    //             // id: 0,
    //             id: Math.random(),
    //             color: "Moccasin",
    //             name: '',
    //             alive: true
    //         },
    //         otherBlobs: [] // in the begining, there is no other balls
    //     };
    //     this.svg = createRef();
    // }

    // updateBlobPosition = (xx:number, yy:number, idd:number, rr:number, ll:boolean) => {
    //     if(ll){ // if alive, update the date
    //         this.setState(prevState => {
    //             const blobToModify = prevState.otherBlobs.find(blob => blob.id === idd); // find the id from array is as same as input
    //             if (blobToModify) { //update
    //               blobToModify.x = xx;
    //               blobToModify.y = yy;
    //               blobToModify.r = rr;
    //             }
    //             return { otherBlobs: prevState.otherBlobs };
    //           });
    //     }
    //     else{ // dead, remove the balls
    //         this.setState(prevState => {
    //             const newotherBlobs = prevState.otherBlobs.filter((blob) => {
    //               return blob.id !== idd; // 排除id属性为idd的元素
    //             });
    //             return { otherBlobs: newotherBlobs };
    //         });
    //     }
    // }

    // // addBlobPosition = (xx:number, yy:number, idd:number, rr:number, ll:boolean) => { // add new ball
    // //     const newBlobPosition = {
    // //         x: xx,
    // //         y: yy,
    // //         r: rr,
    // //         id: idd, 
    // //         color: color[Math.floor(Math.random()*11)]
    // //     };

    // //     this.setState(prevState => ({
    // //         otherBlobs: [...prevState.otherBlobs, newBlobPosition] 
    // //     }));

    // // };

    // componentDidMount() { // 我不知道它存在的意義
    //     this.setPositionUpdater();
        
    //     // const { ws, id, name } = this.context;
    //     // console.log(ws,id, name)
        
    
    //     // ws.addEventListener('message', (event) => {
    //     //   const message = JSON.parse(event.data);
    //     //   if (message.id === id) {
    //     //     this.receiveMessage(message);
    //     //   }
    //     // });
    // }


    // componentDidUpdate() { // clean the balls that was killed
    //     // this.state.otherBlobs.forEach((pos: BlobData, index: number) => {
    //     //     if (this.eats(pos)) {
    //     //         const blobs = this.state.otherBlobs;
    //     //         blobs.splice(index, 1);
    //     //         this.setState({otherBlobs: blobs});
    //     //     }
    //     // });
    //     if (!wsSetup){
    //         console.log('wsSetup', wsSetup)
    //         const value = this.context;
    //         if (value){
    //             // const msg = value.msg;
    //             const ws = value.ws;
    //             const id = value.id;
    //             const name = value.name;
    //             const init_x = value.init_x;
    //             const init_y = value.init_y;

    //             this.setState(
    //                 {
    //                     mainBlob: { // initiate the date
    //                         x: init_x,
    //                         y: init_y,
    //                         r: 20,
    //                         // id: 0,
    //                         id: id,
    //                         color: "Moccasin",
    //                         name: name,
    //                         alive: true
    //                     },
    //                     otherBlobs: [] // in the begining, there is no other balls
    //                 }
    //             )
                
    //             ws.current.onmessage =(e: MessageEvent)=> {     
    //                 var blobs = JSON.parse(e.data);
    //                 this.receiveMessage(blobs, id, ws)
    //                 // this.sendMessage(ws, id, name)
    //             }
    //             intervalId = setInterval(() => this.sendMessage(ws, id, name), 50);

    //             console.log('msg', ws, id)
    //             // console.log(this.state.otherBlobs)
    //             wsSetup = true;
    //         }

    //     }
    // }

    // updatePosition(pt: DOMPoint): void { //update the value of the user's ball
    //     const {mainBlob} = this.state;
    //     const svgElement = this.svg.current;
    //     if (svgElement) {
    //         const screenCTM = svgElement.getScreenCTM();
    //         if (screenCTM) {
    //             const loc = pt.matrixTransform(screenCTM.inverse());
    //             const normalized = normalize(loc.x - width / 2, loc.y - height / 2); // this influence the speed of moving
    //             // console.log(mainBlob.x + normalized.x, mainBlob.y + normalized.y, normalize(width/2, height/2))
    //             this.setState(prevState => ({
    //                 mainBlob: {
    //                     ...prevState.mainBlob,
    //                         x: (Math.abs(mainBlob.x + normalized.x) > 2000) ? mainBlob.x : mainBlob.x + normalized.x,
    //                         y: (Math.abs(mainBlob.y + normalized.y) > 2000) ? mainBlob.y : mainBlob.y + normalized.y
    //                 }
    //             }));
    //         }
    //     }
    // }

    // setPositionUpdater() { // get the position of mouse and send it to the update function 
    //     if (this.svg.current) {
    //         let point = this.svg.current.createSVGPoint();
    //         document.onmousemove = (e) => {
    //             point.x = e.clientX;
    //             point.y = e.clientY;
    //         };
    //         document.ontouchmove = (e) => {
    //             point.x = e.touches[0].clientX;
    //             point.y = e.touches[0].clientY;
    //         };
    //         setInterval(() => this.updatePosition(point), 20); // update the main ball's position
    //         // setInterval(() => this.Agar_js2tsx(), 10); // get data from map.js(server) to update the balls
    //     }
    // }

    // // eats(other: BlobData): boolean { // return be eaten ? 1 : 0
    // //     const {mainBlob} = this.state;
    // //     const distance = getMagnitude(mainBlob.x - other.x, mainBlob.y - other.y);
    // //     if (distance < mainBlob.r + other.r) { //hit another ball
    // //         if(mainBlob.r < other.r){ //hit another user and which ball is bigger than main ball
    // //             GiveEat(mainBlob.id); //game over
    // //             return false;
    // //         }
    // //         else if(mainBlob.r == other.r){ //hit another user and which ball is equal to main ball
    // //             return false; // just pass by
    // //         }
    // //         else{
    // //             score = score + 1; // eat the food and score added by 1 
    // //             GiveScore(score); // send the score to map.js(server)
    // //             GiveEat(other.id); // eat the food and send the id to map.js(server)
    // //             this.setState(prevState => ({ // make the main ball bigger 
    // //                 mainBlob: {
    // //                     ...prevState.mainBlob,
    // //                     r: getMagnitude(mainBlob.r, other.r)
    // //                 }
    // //             }));
    // //             return true;
    // //         }
    // //     } else {
    // //         return false;
    // //     }
    // // }
    // // componentDidMount() {
    // //     const { ws, id } = this.context;
    
    // //     ws.addEventListener('message', (event) => {
    // //       const message = JSON.parse(event.data);
    // //       if (message.id === id) {
    // //         this.receiveMessage(message);
    // //       }
    // //     });
    // //   }
    // render() {
    //     // const { msg, ws, id, name } = this.context;
    //     // const value = this.context;
    //     // if (value){
    //     //     const msg = value.msg;
    //     //     const ws = value.ws;
    //     //     const id = value.id;
    //     //     const name = value.name;
    //     //     this.sendMessage(ws, id, name)
    //     //     this.receiveMessage(msg, id)
    //     //     // console.log('msg', msg, ws, id)
    //     //     // console.log(this.state.otherBlobs)

    //     // }
    //     // this.Agar_tsx2js(); //send msg to map.js(server)

    //     const fullScreen = { // CSS
    //         position: "fixed",
    //         top: 0,
    //         bottom: 0,
    //         left: 0,
    //         right: 0,
    //         backgroundColor: 'transparent'
    //     } as React.CSSProperties;


    //     const {mainBlob, otherBlobs} = this.state;

    //     return (
            
    //         <svg style={fullScreen} ref={this.svg} width={width} height={height}>
    //             <g 
    //                transform={`translate(${width / 2}, ${height / 2})`}>
    //                 <g transform={`translate(${-mainBlob.x}, ${-mainBlob.y})`}>
    //                     <Blob id={mainBlob.id} x={mainBlob.x}  y={mainBlob.y}
    //                           r={mainBlob.r} color={mainBlob.color} alive={true} name={mainBlob.name}/>
    //                     {otherBlobs.map((blob: BlobData) =>
    //                         <Blob id={blob.id} 
    //                                 x={blob.x}
    //                                 y={blob.y} 
    //                                 r={blob.r} key={blob.id} color={blob.color} alive={true} name={blob.name}/>)}
    //                 </g>
    //             </g>
    //         </svg>
    //     );
    // }
}

export default Agar;