import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, Route  } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import Map from './components/map/map'
import Metric from './components/metric/metric'

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import Uploader from './views/Uploader';


// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);
// ReactGA.initialize([
//   {
//     trackingId: process.env.REACT_APP_GA_CODE,
//   },
// ]);
const trackPage = page => {
  ReactGA.send({ hitType: "pageview", page: page });

  // ReactGA.set({ page });
  // ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // console.log(location.pathname)
    const page = location.pathname;
    trackPage(page);
    if (page == '/'){
      document.body.classList.add('is-loaded')
      childRef.current.init();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    // <>
    //   <Helmet>
    //     <meta property="og:image" content="https://outvirt.com/assets/img/logo" />
    //   </Helmet>
    <Routes >
          <Route
            path="/" 
            element={
              <ScrollReveal
                ref={childRef}
                children={() => (
              <LayoutDefault>
                <Home/>
              </LayoutDefault>
            )} />
            }
            />
            <Route path="/map" element={<Map/>} />
            <Route path="/metric" element={<Metric/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/uploader" element={<Uploader/>} />
          </Routes >
  );
}

export default App;