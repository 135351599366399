export function dismissElementsAboveZIndex(zIndexThreshold: number): void {
    // Get all elements on the page
    const allElements = document.getElementsByTagName("*");
  
    // Iterate over all elements using a for loop
    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i] as HTMLElement;
  
      // Get the computed style of the element
      const style = window.getComputedStyle(element);
  
      // Get the z-index value of the element
      const zIndex = parseInt(style.getPropertyValue("z-index"), 10);
  
      // Check if the z-index is above the specified threshold
      if (!isNaN(zIndex) && zIndex > zIndexThreshold) {
        // Dismiss the element by setting its display to none
        element.style.display = "none";
      }
    }
  }
  