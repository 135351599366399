import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import {AppContext} from '../../state'
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, Grid, Box, ListItemText, IconButton, TextField, makeStyles  } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon, Notifications as NotificationsIcon } from '@material-ui/icons';
import { QuestionAnswer } from '@material-ui/icons';
import ContentCard from '../post/ContentCard'
import PostContent from '../post/PostContent'
import '../map/map.css'
import AddIcon from "@material-ui/icons/Add";
import ConversationItem from './ConversationItem';
import BookmarkCat from './BookmarkCat';
import './Conversation.css'
import StandardProfile from './StandardProfile';
import UserProfile from './UserProfile';
import ChatRoom from './ChatRoom'
import { toast } from 'react-toastify';
import NotificationItem from './NotificationItem';
import { getHighestZIndex } from '../utils/GetHighestZIndex';
import { generateRandomString } from '../utils/GenerateRandomString';
import L from "leaflet";

interface ContainerProps {
    showNotifications: boolean
    setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>
    ws: React.MutableRefObject<WebSocket | undefined>
    map: L.Map | undefined;
    // webSocket?: WebSocket
}




interface Notification {
    id: number;
    message: string;
    url: string;
    created_at: string;
  }
interface Tag {
  id: number;
  username: string;
}


interface PostProps {
  tablename: string
  post_id: number
  is_object?: boolean
}


interface ChatUser {
    id: number;
    profilepic: string;
    name: string;
    username: string;
  }
  

interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
  }
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
  }
  interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
  }


interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number
  url: string
  user_url: string
  zoom: number;
  }
  


  interface FollowingPost {
    id: number;
    profilepic: string;
    tablename: string;
  }
  interface Bookmark {
    last_id: number;
    user_id: number;
    id: number;
    photos: string[];
    tablename: string;
    type: boolean; // false: img, true: vid
    cat: number;
    body: string;
    updated_at: string;
    n_comments: number;
    name: string;
    username?: string;
  }

  interface BookmarkCatProp {
    id: number;
    image: string;
    name: string;
    selected: boolean;
  }

  
interface CatModal {
    show: boolean;
    idx: number;
  }
  


  const WS_URL = 'wss://outvirt.com:8080';


const Notifications: React.FC<ContainerProps> = (props) => {
  const { t, i18n } = useTranslation();
  
  const { state, dispatch } = useContext(AppContext);
  
  const listRef = useRef<any>(null);

  const ref = React.useRef<HTMLInputElement>(null)
  const [showPostModal, setShowPostModal] = useState(false);
  const [followingPosts, setFollowingPosts] = useState<FollowingPost[]>([]);
  const [bookmarkCats, setBookmarkCats] = useState<BookmarkCatProp[]>([{
    id: 0,
    name: "Recent",
    image: "recent",
    selected: true,
  }])
  const [bookmarksContainer, setBookmarksContainer] = useState<Bookmark[]>([]);
  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>(-1);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [bookmarkSelectIdx, setBookmarkSelectIdx] = useState<number>(-1);
  const [bookmarkFetchBuffer, setBookmarkFetchBuffer] = useState<PostCard[]>([]);
  const [contentScroll, setContentScroll] = useState<boolean>(true)
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatUser, setChatUser] = useState<ChatUser>({id: 0, name: '', username: '', profilepic: 'picinit'});
  const [bookmarkPostCards, setBookmarkPostCards] = useState<PostCard[]>([]);
  const [catModal, setCatModal] = useState<CatModal>({show: false, idx: 0})

  const [catId, setCatId] = useState<number>(0);
  const catIdRef = useRef<number>(catId);
  const [followingPostCards, setFollowingPostCards] = useState<PostCard[]>([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [accountType, setAccountType] = useState<number>(0); // 0: standard 1: business

  
const [posts, setPosts] = useState<Post[]>([]);
const [postProps, setPostProps] = useState<PostProps>({post_id: 0, tablename: ''});

const [notifications, setNotifications] = useState<Notification[]>([]);
const [notificationsBin, setNotificationsBin] = useState<Notification[]>([]);
const [infNotificationsBin, setInfNotificationsBin] = useState<Notification[]>([]);
// const [infPosts, setInfPosts] = useState<Post[]>([]);
const [postCards, setPostCards] = useState<PostCard[]>([]);

const [pageNumber, setPageNumber] = useState(1);
  // const loader = useRef(null);
  const [isHeightFilled, setIsHeightFilled] = useState(false);
  const bookmarksRef = useRef<Bookmark[]>(bookmarks);
  const [showStandardProfile, setShowStandardProfile] = useState(false);
  const [standardProfileId, setStandardProfileId] = useState<number>(0);

  const [showUserProfile, setShowUserProfile] = useState<boolean>(false)
  const dialogRef = useRef<HTMLDivElement>(null);
  const [dialogZIndex, setDialogZIndex] = useState<number>(0);

  const [cardZIndex, setCardZIndex] = useState<number>(0);
  const [cardName, setCardName] = useState<string>('');
  const [showContent, setShowContent] = useState<boolean>(false);
  const [isPrev, setIsPrev] = useState<boolean>(false);
  const [currentIdx, setCurrentIdx] = useState<number>(4);
  const [fetchPost, setFetchPost] = useState<boolean>(false);
  const arrowClickRef = useRef(false)
  const fetchLockRef = useRef<any>(false);


  // initialize zIndex when content shown
  useEffect(() => {
    if (showContent) {
      setCardZIndex(getHighestZIndex());
      setCardName(generateRandomString(5))
    } 
  }, [showContent])


  // set card's height
  useEffect(() => {
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };
    setDialogZIndex(getHighestZIndex());

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);


   
//   useEffect(() => {
//         initPage(state.id)
//         dispatch({ type: "setNotif",
//           notification_incoming: []
//         });
//         console.log('ci', state.current_idx)
//         if (state.show_content){
//           // show cards on this page
//           const myVideo = document.getElementById("video"+state.current_idx) as HTMLVideoElement; 
//           myVideo && myVideo.pause();    
//         }
//         if (state.notif_show_content){
//           // pause video on other pages if played
//           const myVideo = document.getElementById("notif"+state.current_idx) as HTMLVideoElement; 
//           myVideo && myVideo.play();    
//         }
//         if (state.profile_show_content){
//           // pause video on other pages if played
//           const myVideo = document.getElementById("profile"+state.current_idx) as HTMLVideoElement; 
//           myVideo && myVideo.pause();    
//         }
//         if (state.follow_show_content){
//           // show cards on this page
//           const myVideo = document.getElementById("follow"+state.follow_current_idx) as HTMLVideoElement; 
//           myVideo && myVideo.pause();    
//         }
//         if (state.bookmark_show_content){
//           // show cards on this page
//           const myVideo = document.getElementById("bookmark"+state.follow_current_idx) as HTMLVideoElement; 
//           myVideo && myVideo.pause();    
//         }
// }, []);


useEffect(() => {
  if (!postCards.map(post => post.idx).includes(state.notif_current_idx) ){
      dispatch({ type: "setNotifShowContent",
          notif_show_content: false
      });
  }
}, [state.notif_current_idx, state.notif_fetch_post]);



const initPage = async(userId: number) => {
    // const ret = await Preferences.get({ key: 'lang' });
    // setLanguage(ret.value!)
    console.log('lang', i18n.language)
    try {
      const response = await fetch('https://outvirt.com:8080/fetchnotification', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: userId, lang: i18n.language})
    });
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      
      response.json().then(res_data => {
        console.log(res_data.notification_incoming, res_data.notification_bin)
        setNotifications(res_data.notification_incoming)
        setNotificationsBin(res_data.notification_bin)
        pushInititalData(res_data.notification_bin)
        setAccountType(res_data.account_type)
      })
    } catch (err) {
      console.log(err);
    }
};


const pushData = (curData: Notification[]) => {
    const max = curData.length - infNotificationsBin.length;
    const min = (max - 15 > 0) ? max - 15 : 0;
    const newData : Notification[] = [];
    for (let i = min; i < max; i++) {
      newData.push(curData[i]);
    }
    setInfNotificationsBin([
      ...newData,
      ...infNotificationsBin,
    ]);
  }


const pushInititalData = (initialData: Notification[]) => {
    const max = initialData.length;
    const min = (max - 15 > 0) ? max - 15 : 0;
    const newData : Notification[] = [];
    for (let i = min; i < max && i < initialData.length; i++) {
      newData.push(initialData[i]);
    }
    
    setInfNotificationsBin([
      ...newData
    ]);
  }

  const loadData = () => {
    setTimeout(async() => {
        if (notificationsBin.length > 0){
            if (infNotificationsBin.length + 15 > notificationsBin.length) {
              try {
                const response = await fetch('https://outvirt.com:8080/fetchmorenotification', {
                    method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: state.id, firstId: notificationsBin[0].id, lang: i18n.language})
                });
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                response.json().then(res_data => {
                  if (res_data.notification_bin.length > 0) {
                      setNotificationsBin([...res_data.notification_bin, ...notificationsBin])
                      pushData([...res_data.notification_bin, ...notificationsBin]);
                  }
                })
              } catch (err) {
                console.log(err);
              }
            } 
            else {
              pushData(notificationsBin);
            }
        }
    }, 500);
  }  


//   useEffect(() => {
//     if (standardProfileId !== 0){
//       setShowStandardProfile(true)
//     }

//   },[standardProfileId])

//   useEffect(() => {
//     if (!showStandardProfile){
//       setStandardProfileId(0)
//     }

//   },[showStandardProfile])



  useEffect(() => {
    if (props.showNotifications){
        initPage(state.id)
        dispatch({ type: "setNotif",
          notification_incoming: []
        });
    }
}, [props.showNotifications]);


useEffect(() => {
    if (!isHeightFilled && props.showNotifications) {
      console.log('height')
      loadData();
    }
  }, [isHeightFilled, props.showNotifications]);
// }, [isHeightFilled, props.showNotifications, bookmarks, bookmarksContainer]);

  
  // Fetch more posts when pageNumber changes
  useEffect(() => {
    if (pageNumber > 1 && props.showNotifications){
      console.log('page')
      loadData();
    } 
  }, [pageNumber, props.showNotifications]);


  useEffect(() => {
    if (infNotificationsBin.length > 0 && notificationsBin.length > 0) {
      checkIfHeightFilled();
    }
  }, [infNotificationsBin, notificationsBin]);




  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};




  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };


  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    const tolerance = 5;
  if (scrollHeight - scrollTop <= clientHeight + tolerance) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      // if (state.notif_show_content){
      //   // close content card
      //     ref.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
      //     ref.current!.style.transform = 'scale(0)';
      //     ref.current!.style.opacity = '0';
      //       setTimeout(function(){
      //           dispatch({ type: "setNotifShowContent",
      //           notif_show_content: false
      //           });
      //       }, 200);
      // } else{
      //   props.setShowNotifications(false)
      // }
    // return highestZIndex;
      console.log('NOTIF IDX', getHighestZIndex(), dialogZIndex)
      if (getHighestZIndex()==dialogZIndex){
        props.setShowNotifications(false)
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    bookmarkFriends: {
      display: "flex",
      overflowX: "scroll",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      marginBottom: "12px"
    },
    noScrollbar: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  }));
  const classes = useStyles();


  return (
    <div>
        {/* standard profile */}
     {/* {state.showStandardProfile && <StandardProfile/> } */}
     {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={standardProfileId} map={props.map}/> }
     {showUserProfile && <UserProfile showUserProfile={showUserProfile} setShowUserProfile={setShowUserProfile} id={state.id} map={props.map}/> }
      {/* {
        (state.follow_show_content) && (
          
            
          <div ref={ref}
            style={{
              height: `${viewportHeight}px`,
            }} 
            className="cards-stack-container">
              {
                followingPostCards.map((post, idx) =>{
                  return <ContentCard  key={post.id} {...{...post, card_name: "follow", cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                }
                )
              }
            </div>
        )
      } */}

      {
        (showContent) && (
          
            <div ref={ref}
              style={{
                height: `${viewportHeight}px`,
                zIndex: dialogZIndex + 2
              }} 
             className="cards-stack-container">
              {
                postCards.map((post, idx) =>{
                  return <ContentCard key={post.id} {...{ ...post, card_name: cardName, cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdx, setCurrentIdx: setCurrentIdx, isPrev: isPrev, setIsPrev: setIsPrev, setShowContent: setShowContent, setFetchPost: setFetchPost, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef }} />
                  // return <ContentCard  key={post.id} {...{...post, card_name: "notif", cardsRef: bookmarkRef, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                }
                )
              }
            </div>
        )
      }
      <PostContent {...{map: props.map, showPostModal: showPostModal, setShowPostModal: setShowPostModal, post_id: postProps?.post_id, tablename: postProps?.tablename, webSocket: props.ws.current}} key={postProps?.post_id}/>
      {/* <ChatRoom {...{chatUser: chatUser, chatModal: chatModal, showChatModal: showChatModal, setShowChatModal: setShowChatModal, webSocket: ws.current}} /> */}
        {/* {showStandardProfile && <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={chatUser.id}/> }
        {showChatModal && <ChatRoom showChatModal={showChatModal} setShowChatModal={setShowChatModal} chatUser={chatUser} webSocket={props.ws.current}/> } */}
      {/* <StandardProfile showStandardProfile={showStandardProfile} setShowStandardProfile={setShowStandardProfile} id={chatUser.id}/> */}
    <Dialog  
      ref={dialogRef}
      className="profile-dialog dark-dialog"
      open={props.showNotifications}
      onClose={() => props.setShowNotifications(false)}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      style={{ zIndex: dialogZIndex + 1 }}
    >


      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
        <div className="modal-header">
            <NotificationsIcon/>
            &nbsp;&nbsp;{t('notification')}
        </div>
        <IconButton color="inherit" onClick={() => props.setShowNotifications(false)} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent  style={{ padding: "10px"}} ref={listRef} className="dark-dialog-content" onScroll={handleScroll}>
      <div>
        <Grid container>
          <Grid item xs={12}>
            
          {notifications.slice(0).reverse().map((notification, idx) => {
            return (
            <NotificationItem key={idx} notification={notification} isNew={true} accountType={accountType} setPostCards={setPostCards} setShowStandardProfile={setShowStandardProfile} setStandardProfileId={setStandardProfileId} setShowUserProfile={setShowUserProfile} setCurrentIdxNotif={setCurrentIdx} setIsPrevNotif={setIsPrev} setShowContentNotif={setShowContent}/>
            )
            })}
            {infNotificationsBin.slice(0).reverse().map((notification, idx) => {

            return (
              <NotificationItem key={idx} notification={notification} isNew={true} accountType={accountType} setPostCards={setPostCards} setShowStandardProfile={setShowStandardProfile} setStandardProfileId={setStandardProfileId} setShowUserProfile={setShowUserProfile} setCurrentIdxNotif={setCurrentIdx} setIsPrevNotif={setIsPrev} setShowContentNotif={setShowContent}/>
            )
            })}
          </Grid>
        </Grid>
      </div>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default Notifications;
