import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Modal as ModalCore, Dialog, DialogTitle, DialogContent, Grid as GridCore, Button, List, ListItem, Avatar, Box, ListItemText, IconButton, Chip, Typography } from '@material-ui/core';
import { Close as CloseIcon, Send as SendIcon, PhotoCamera as PhotoCameraIcon, Image as ImageIcon } from '@material-ui/icons';
import {AppContext} from '../../state'
import { toast, ToastContainer } from 'react-toastify';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import Modal from 'react-modal';
import { Grid, Row, Col } from 'react-flexbox-grid';
import CollectionsIcon from '@mui/icons-material/Collections';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import { CloseCircleOutline } from "@material-ui/icons";
import ContentCard from '../post/ContentCard'
import PostContent from '../post/PostContent'
import '../map/map.css'
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import './Profile.css'
import {
    MapOutlined,
    ChatBubbleOutlined,
    Check,
    PersonAddOutlined,
  } from "@material-ui/icons";
  import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
  import Connections from './Connections';
  import { getHighestZIndex } from '../utils/GetHighestZIndex';
  import { generateRandomString } from '../utils/GenerateRandomString';
import { dismissElementsAboveZIndex } from '../utils/DismissElementsAboveZIndex';
import PublicIcon from '@mui/icons-material/Public';

interface ContainerProps {
    showStandardProfile: boolean
    setShowStandardProfile: React.Dispatch<React.SetStateAction<boolean>>
    id: number
    map: L.Map | undefined;
    // webSocket?: WebSocket
}

interface UserPhoto {
    srcwebviewPath: string;
    filepath: string;
}

interface Tag {
  id: number;
  username: string;
}


interface PostProps {
  tablename: string
  post_id: number
  is_object?: boolean
}



interface ModalInfo {
    slides: string[];
    show: boolean;
    index: number;
  }
interface Post {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    post_id: number;
    precise: boolean;
  }
  interface Comment {
    id: number
    created_at: string;
    user_id: number;
    body: string
    photos: string[]
    name: string
    username: string;
    profilepic: string
    n_comments: number
    n_likes: number
    n_shares: number
    liked: boolean
    shared: boolean
    tablename: string;
    account_type: number;
    comments: Comment[]
  }


interface PostCard {
  idx: number;
  id: number;
  tablename: string;
  photos: string[]
  profilepic: string
  user_id: number
  name: string
  type: boolean
  body: string
  created_at: string
  n_likes: number
  n_comments: number
  bookmarked: boolean
  liked: boolean
  precise: boolean
  topic_name: string[]
  loc_name: string
  lat?: number
  lon?: number
  account_type: number
  url: string
  user_url: string
  zoom: number;
  }

  
interface UserInfo {
    // basic
    id: number;
    username: string;
    name: string;
    profilepic: string;
    account_type: number;
    follow: boolean;
    token: string;
    // remaining
    email: string;
    followers: number[];
    following: number[];
    posts: number[];
    shares: number[];
    comments: number[];
  
    level: number;
    experience: number;
    experienceRequired: number;
    url: string;
}


interface Asset {
    id: number;
    photo: string
    coins: number;
  }
  

  

  const WS_URL = 'wss://outvirt.com:8080';


const StandardProfile: React.FC<ContainerProps> = (props) => {
  // ... useState, useRef, and other custom hooks
  const { t } = useTranslation();
  const [showReplyTo, setShowReplyTo] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [photos, setPhotos] = useState<UserPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { state, dispatch } = useContext(AppContext);
  const [tagList, setTagList] = useState<Tag[]>([])
  const [parentId, setParentId] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>([]);
  // const listRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<any>(null);
  // const contentRef = useRef<HTMLDivElement | null>(null);
  const [postCards, setPostCards] = useState<PostCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.useRef<HTMLInputElement>(null)
  const [showPostModal, setShowPostModal] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [fetchBuffer, setFetchBuffer] = useState<PostCard[]>([]);
  const [selectIdx, setSelectIdx] = useState<number>(-1);
  const [modalInfo, setModalInfo] = useState<ModalInfo>({
    slides: [],
    show: false,
    index: 0
  })
  // const [post, setPost] = useState<Post>(
  //   {
  //     id: 0,
  //     created_at: '1',
  //     user_id: 0,
  //     body: 'loading...',
  //     photos: [],
  //     name: '',
  //     username: '',
  //     profilepic: 'picinit',
  //     n_comments: 0,
  //     n_likes: 0,
  //     n_shares: 0,
  //     shared: false,
  //     liked: false,
  //     tablename: "",
  //     account_type: 0,
  //     post_id: 0,
  //     precise: false,
  //   }
  // );

const [posts, setPosts] = useState<Post[]>([]);
const [postProps, setPostProps] = useState<PostProps>({post_id: 0, tablename: ''});
const [userInfo, setUserInfo] = useState<UserInfo>({
    id: 0,
    email: 'fetching...',
    username: 'fetching...',
    name: 'fetching...',
    profilepic: '1185f8c893242a4df10f02f25bbab5bf',
    token: '',
    account_type: 1,
    followers: [],
    following: [],
    follow: false,
    posts: [],
    shares: [],
    comments: [],
    level: 1,
    experience: 0,
    experienceRequired: 999,
    url: ''
})
const [assets, setAssets] = useState<Asset[]>([]);

const [showConnections, setShowConnections] = useState(false);

const [profileZIndex, setProfileZIndex] = useState<number>(0);
const [cardZIndex, setCardZIndex] = useState<number>(0);
const [cardName, setCardName] = useState<string>('');
const [showContent, setShowContent] = useState<boolean>(false);
const [isPrev, setIsPrev] = useState<boolean>(false);
const [currentIdx, setCurrentIdx] = useState<number>(4);
const [fetchPost, setFetchPost] = useState<boolean>(false);
const arrowClickRef = useRef(false)
const dialogRef = useRef<HTMLDialogElement | null>(null);
const fetchLockRef = useRef<any>(false);

// initialize zIndex when content shown
useEffect(() => {
  if (showContent) {
    console.log('SHOW', profileZIndex, getHighestZIndex())
    setCardZIndex(getHighestZIndex());
    setCardName(generateRandomString(5))
  }
}, [showContent])
useEffect(() => {
  if (props.showStandardProfile) {
    setProfileZIndex(getHighestZIndex());
  } 
}, [props.showStandardProfile])

  const ws = useRef<WebSocket>();


  useEffect(() => {
    console.log('ENTER PROFULE')
    setProfileZIndex(getHighestZIndex());
    // set card's height
    const updateHeight = () => {
    setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const setProfile = async() => {
      
    // const ret = await Preferences.get({ key: 'user' });
    // const user = JSON.parse(ret.value!);
    // setSelfId(user.id)

    // console.log('checkid', props.id)
    
    try {
      const response = await fetch('https://outvirt.com:8080/fetchstandardprofile', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, token: state.token})
          // body: JSON.stringify({id: props.showStandardProfile ? props.id : state.standardProfileId, token: state.token})
          // body: JSON.stringify({id: props.id, token: state.token})
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(async(userData) => {
        console.log('userdata', userData)
        setUserInfo({...userData, token: state.token})
        // fetch posts, shares, comments
        try {
          const response = await fetch('https://outvirt.com:8080/fetchuserposts', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: state.token, posts: userData.posts})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          response.json().then(res_data => {
            setPosts(res_data.posts)
            pushInitialDataPosts(res_data.posts)
          })
        } catch (err) {
          console.log(err);
        }
      })
    } catch (err) {
      console.log(err);
    }

    // fetch asset
    try {
      const response = await fetch('https://outvirt.com:8080/fetchassetsale', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id: props.id})
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      response.json().then(res => {
        setAssets(res.assets)
      })
    } catch (err) {
      console.log(err);
    }
  }
  

// Fetch more post on server every 9 posts; store in buffer
const fetchPostById = async() => {
  fetchLockRef.current = true
  const fetchIdx = selectIdx + fetchBuffer.length + postCards.length
  const deltaId = (infPosts.length - fetchIdx > 9) ? 9 : infPosts.length - fetchIdx
  try {
    const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: infPosts.slice(fetchIdx, fetchIdx+deltaId).map(post => post.id), user_id: props.id})
    // body: JSON.stringify({id: infPosts.slice(fetchIdx, fetchIdx+deltaId).map(post => post.id), user_id: props.showStandardProfile ? props.id : state.standardProfileId})
  });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    response.json().then(res_data => {
      setFetchBuffer([...fetchBuffer, ...res_data])
    })
  } catch (err) {
    console.log(err);
  }
  setSelectIdx(fetchIdx)
  fetchLockRef.current = false
}



  useEffect(() => {
    if (currentIdx == -1){
      console.log('???')
      setProfile()
    }
    if (postCards.length>0 && fetchPost){
      // fetch more post from server every 9 post
      if (currentIdx == 1 && fetchBuffer.length < 9){
        console.log('fetch more post')
        fetchPostById()
      }
      if (postCards.length == 9){
        // funnel contents from buffer to display and pop last element of display
        let idx = (currentIdx + 4) % 9 // store previous 3 posts
        console.log('NOW', currentIdx, idx)
        let data = fetchBuffer.shift();
        
        postCards.pop()
        if (data){ // false if end of post reached
          data!.idx = idx
          setPostCards(oldCards =>
            [data!, ...oldCards]
          )
        }
      } else {
        // insert directly from buffer to display
        let data = fetchBuffer.shift();
        if (data){ // false if end of post reached
          data!.idx = (postCards[0].idx == 0) ? 8 : postCards[0].idx - 1
          setPostCards(oldCards =>
            [data!, ...oldCards]
          )
        } else if (!postCards.map(post => post.idx).includes(currentIdx) ){
          // dismiss content cards if no more post (for scroll)
          setTimeout(() => {
            setShowContent(false)
            
            props.map && props.map.dragging.enable();
            props.map && props.map.touchZoom.enable();
            props.map && props.map.doubleClickZoom.enable();
            props.map && props.map.scrollWheelZoom.enable();
            props.map && props.map.boxZoom.enable();
            props.map && props.map.keyboard.enable();
            if (props.map) {
              if (props.map!.tap) props.map!.tap.enable();
            }
            setPostCards([])
            setFetchBuffer([])
          }, 500);
        }
      }
  
    } else if (!postCards.map(post => post.idx).includes(currentIdx) ){
      console.log('shouldenter')
      setTimeout(() => {
        setShowContent(false)
        
        props.map && props.map.dragging.enable();
        props.map && props.map.touchZoom.enable();
        props.map && props.map.doubleClickZoom.enable();
        props.map && props.map.scrollWheelZoom.enable();
        props.map && props.map.boxZoom.enable();
        props.map && props.map.keyboard.enable();
        if (props.map) {
          if (props.map!.tap) props.map!.tap.enable();
        }
        setPostCards([])
        setFetchBuffer([])
      }, 500);
    }
  }, [currentIdx, fetchPost]);


  useEffect(() => {
    if (props.showStandardProfile){
      // if (state.showStandardProfile || props.showStandardProfile){
      setProfile()
      // if (state.show_content){
      //   // pause video on other pages if played
      //   const myVideo = document.getElementById("video"+state.current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.follow_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("follow"+state.follow_current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.bookmark_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("bookmark"+state.bookmark_current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.pause();    
      // }
      // if (state.profile_show_content){
      //   // show cards on this page
      //   const myVideo = document.getElementById("profile"+state.profile_current_idx) as HTMLVideoElement; 
      //   myVideo && myVideo.play();    
      // }
    }
}, [props.showStandardProfile]);
// }, [state.showStandardProfile, props.showStandardProfile]);


  // posts
  const [infPosts, setInfPosts] = useState<Post[]>([]);
  const pushDataPosts = (curData: Post[]) => {
    const max = infPosts.length + 12;
    const min = max - 12;
    const newData : Post[] = [];
    for (let i = min; i < max && i < curData.length; i++) {
      newData.push(curData[i]);
    }
    setInfPosts([
      ...infPosts,
      ...newData
    ]);
  }
  
  const pushInitialDataPosts = (initialData: Post[]) => {
    const max = 12;
    const min = max - 12;
    const newData : Post[] = [];
    for (let i = min; i < max && i < initialData.length; i++) {
      newData.push(initialData[i]);
    }
    console.log(newData);
    
    setInfPosts([
      ...newData
    ]);
  }

  let startY: number | null = null;

const handleTouchStart = (e: TouchEvent) => {
  startY = e.touches[0].clientY;
};

const handleTouchMove = (e: TouchEvent) => {
  if (startY !== null && listRef.current !== null) {
    const deltaY = startY - e.touches[0].clientY;
    listRef.current.scrollTop += deltaY;
    startY = e.touches[0].clientY;
  }
};

const handleDialogEntered = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Adding touchmove listener to content element");
    contentElement.addEventListener("touchstart", handleTouchStart);
    contentElement.addEventListener("touchmove", handleTouchMove);
  } else {
    console.log("Content element not found");
  }
};

const handleDialogExited = () => {
  const contentElement = listRef.current;

  if (contentElement) {
    console.log("Removing touchmove listener from content element");
    contentElement.removeEventListener("touchstart", handleTouchStart);
    contentElement.removeEventListener("touchmove", handleTouchMove);
  }
};

const [pageNumber, setPageNumber] = useState(1);
  // const loader = useRef(null);
  const [isHeightFilled, setIsHeightFilled] = useState(false);

  useEffect(() => {
    if (!isHeightFilled && (props.showStandardProfile)) {
      // if (!isHeightFilled && (state.showStandardProfile || props.showStandardProfile)) {
      fetchMorePosts();
    }
  }, [isHeightFilled, props.showStandardProfile]);
// }, [isHeightFilled, state.showStandardProfile, props.showStandardProfile]);

  const checkIfHeightFilled = () => {
    if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
      setIsHeightFilled(false);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setIsHeightFilled(true);
    }
  };

  useEffect(() => {
    if (infPosts.length > 0) {
        console.log('checckkkk')
      checkIfHeightFilled();
    }
  }, [infPosts]);
  

  // Function to load more posts
  const fetchMorePosts = async () => {
    console.log('fetching more posts')
    setTimeout(async() => {
      if (infPosts.length + 12 > posts.length) {
        try {
          const response = await fetch('https://outvirt.com:8080/fetchmoreuserposts', {
              method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({id: props.id, lastId: (posts.length==0 ? -1 : posts[posts.length - 1].id)})
          // body: JSON.stringify({id: props.showStandardProfile ? props.id : state.standardProfileId, lastId: (posts.length==0 ? -1 : posts[posts.length - 1].id)})
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          }
        response.json().then(res_data => {
            if (res_data.posts.length!=0){
                setPosts([...posts, ...res_data.posts])
                pushDataPosts([...posts, ...res_data.posts]);
            }
          })
        } catch (err) {
          console.log(err);
        }
      } else {
        pushDataPosts(posts);
      }
    }, 500);
  };

  // Fetch more posts when pageNumber changes
  useEffect(() => {
    // if (pageNumber > 1 && (state.showStandardProfile || props.showStandardProfile)){
    if (pageNumber > 1 && (props.showStandardProfile)) fetchMorePosts();
  }, [pageNumber, props.showStandardProfile]);
// }, [pageNumber, state.showStandardProfile, props.showStandardProfile]);

  // Intersection Observer to detect when the loader div is visible
  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const { scrollTop, clientHeight, scrollHeight } = target;
    if (scrollHeight - scrollTop === clientHeight) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };
  
  
  const handleDialogKeyDown = (event:  React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (getHighestZIndex()==Number(dialogRef.current!.style.zIndex)){
        setTimeout(function(){
          props.setShowStandardProfile(false)
        }, 200);
          
      }
      // console.log('dialogquit', dialogRef.current ? dialogRef.current.style.zIndex : null);
      // if (showContent){
      //   setShowContent(false)
      // } else {
      //     props.setShowStandardProfile(false)
      // }


      // if (state.profile_show_content){
      //   // close content card
      //     ref.current!.style.transition = 'transform 0.5s cubic-bezier(.6,.44,.83,.67), opacity 0.5s linear';
      //     ref.current!.style.transform = 'scale(0)';
      //     ref.current!.style.opacity = '0';
      //       setTimeout(function(){
      //           dispatch({ type: "setProfileShowContent",
      //             profile_show_content: false
      //           });
      //       }, 200);
      // } else{
      //   if (state.showStandardProfile){
      //     if (!state.show_content){
      //       // dismiss handled within ContentCard
      //       dispatch({ type: "setShowStandardProfile",
      //         showStandardProfile: false,
      //         standardProfileId: 0
      //       });
      //     }
      //     // props.setShowStandardProfile(false)
      //   } else if (props.showStandardProfile){
      //     if (!state.show_content){
      //       // dismiss handled within ContentCard
      //       props.setShowStandardProfile && props.setShowStandardProfile(false)
      //     }

      //   }
      // }
    }
  };
  

  return (
    <div>
      <PostContent {...{map: props.map, showPostModal: showPostModal, setShowPostModal: setShowPostModal, post_id: postProps?.post_id, tablename: postProps?.tablename}} key={postProps?.post_id}/>

      {showConnections && <Connections showConnections={showConnections} setShowConnections={setShowConnections} user_id={props.id} />}
    <Dialog  
    ref={dialogRef}
      className="dark-dialog"
      // open={state.showStandardProfile || props.showStandardProfile!}
      open={props.showStandardProfile}
      onClose={() => {props.setShowStandardProfile(false)}}
      // onClose={() => {props.setShowStandardProfile ? props.setShowStandardProfile(false) : dispatch({ type: "setShowStandardProfile", showStandardProfile: false, standardProfileId: 0 })}}
      fullWidth
      maxWidth="md"
      onEntered={handleDialogEntered}
      onExited={handleDialogExited}
      onKeyDown={handleDialogKeyDown}
      disableEnforceFocus 
      // style={state.show_content?{zIndex: 3003}:{zIndex: 2001}}
      style={{ zIndex: profileZIndex + 1 }}
      // style={{ zIndex: getHighestZIndex() + 1 }}
      // style={{ zIndex: profileZIndex + 1 }}
    >
      {isLoading && <div className="spinner-container" style={{ zIndex: getHighestZIndex() + 2 }}><img src="assets/icon.svg" alt="loading" className="spinner" /></div>}

    {
            (showContent) && (
                <div ref={ref}
                  style={{
                    height: `${viewportHeight}px`,
                    zIndex: cardZIndex + 2,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                  }} 
                className="cards-stack-container">
                  {
                    postCards.map((post, idx) =>{
                      return <ContentCard key={post.id} {...{ ...post, card_name: cardName, cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal, currentIdx: currentIdx, setCurrentIdx: setCurrentIdx, isPrev: isPrev, setIsPrev: setIsPrev, setShowContent: setShowContent, setFetchPost: setFetchPost, arrowClickRef: arrowClickRef, fetchLockRef: fetchLockRef, map: props.map }} />
                      // return <ContentCard  key={post.id} {...{...post, card_name: "profile", cardsRef: ref, setPostProps: setPostProps, setShowPostModal: setShowPostModal, showPostModal: showPostModal}}/>
                    }
                    )
                  }
                </div>
            )
          }

      <DialogTitle className="dark-dialog-title">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: "20px" }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`https://outvirt.com/assets/imgmap/${userInfo.profilepic}`} // Replace this URL with the actual profile picture URL
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            marginRight: '10px',
          }}
        />
        <div>
          <Box display="flex" alignItems="center">
              {userInfo.name}&nbsp;
              <Chip
                    label={userInfo.level}
                    style={{ height: "14px", marginLeft:"6px", padding: "1px", paddingTop: '2px', fontSize: "12px", color: "#fff", backgroundColor: "#7a3fe4" }}
                  />
            </Box>
          <div
            style={{
              color: 'gray',
              fontSize: '14px',
            }}
          >
            @{userInfo.username} {/* Replace 'Username' with the actual username */}
          </div>
        </div>
      </div>
      {/* <IconButton color="inherit" onClick={() => {props.setShowStandardProfile ? props.setShowStandardProfile(false) : dispatch({ type: "setShowStandardProfile", showStandardProfile: false, standardProfileId: 0 })}} edge="end"> */}
      <IconButton color="inherit" onClick={() => {props.setShowStandardProfile(false)}} edge="end">
            <CloseIcon />
        </IconButton>
    </div>
      </DialogTitle>
      <DialogContent ref={listRef} style={{padding: '10px'}} className="dark-dialog-content" onScroll={handleScroll}>
        <GridCore container>
      <GridCore item xs={12} container 
      className="profile-stats"
      >
        <GridCore item xs={4} 
        className="profile-stat"
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.posts.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("social.posts")}</Typography>
        </GridCore>
        <GridCore item xs={4} 
          className="profile-stat"
          onClick={() => setShowConnections(true)}
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.following.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("following")}</Typography>
        </GridCore>
        <GridCore item xs={4} 
          className="profile-stat"
          onClick={() => setShowConnections(true)}
        >
          <Typography variant="h5" style={{ fontSize: '16px' }}>{userInfo.followers.length}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: '12px' }}>{t("followers")}</Typography>
        </GridCore>
      </GridCore>
      <GridCore item xs={12} container 
        className="profile-stats"
        justifyContent="space-between"
      >
        {
          userInfo.url != '' &&
            <GridCore
              item
              xs={3}
              className="profile-stat"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Button
                className="mail-button"
                // color="default"
                style={{color: "#7a3fe4", borderColor: "#7a3fe4"}}
                variant="outlined"
                size="small"
                onClick={() => {
                  const url = userInfo.url;
                  const hasProtocol = /^https?:\/\//i.test(url);
                  const absoluteUrl = hasProtocol ? url : "http://" + url;
                  window.open(absoluteUrl, "_blank");
                }}
              >
                <PublicIcon   style={{ fontSize: "24px" }}/>
              </Button>
            </GridCore>
        }
        <GridCore
          item
          xs={3}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            className="mail-button"
            // color="default"
            style={{color: "#7a3fe4", borderColor: "#7a3fe4"}}
            variant="outlined"
            size="small"
            onClick={() => {
              dispatch({
                type: 'setShowUserPostsOnMap',
                showUserPostsOnMap: true,
                userPostsOnMapId: props.id!,
                // userPostsOnMapId: props.showStandardProfile ? props.id! : state.standardProfileId,
              })
              // dismissElementsAboveZIndex(2000)
              // if (props.showStandardProfile){
              //   props.setShowStandardProfile && props.setShowStandardProfile(false)
              // } else {
              //   dispatch({ type: "setShowStandardProfile",
              //     showStandardProfile: false,
              //     standardProfileId: 0
              //   })
              // }
              // setShowContent(false)

            }}
          >
            <MapOutlined   style={{ fontSize: "24px" }}/>
          </Button>
        </GridCore>
        <GridCore
          item
          xs={3}
          className="profile-stat"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            className="mail-button"
            variant="outlined"
            style={{color: "#7a3fe4", borderColor: "#7a3fe4"}}
            size="small"
            onClick={() => {
              // your onClick logic
            }}
          >
            <ChatBubbleOutlineIcon  style={{ fontSize: "24px" }}/>
          </Button>
        </GridCore>

        {
                  (state.id == userInfo.id) ? null : (
                    
                    <GridCore
                        item
                        xs={3}
                        className="profile-stat"
                        style={{ justifyContent: "center", display: "flex" }}
                    >
                        <Button 
                        // className="mail-button"
                        className={`follow-button${userInfo.follow ? " followed" : ""}`}
                        variant={"outlined" }
                        // variant={(userInfo.follow) ? "contained": "outlined" }
                        size="small"
                        style={{
                          color: userInfo.follow ? "#fff" : "#7a3fe4",
                          borderColor: userInfo.follow ? "fff" : "#7a3fe4",
                          backgroundColor: userInfo.follow ? "#7a3fe4" : "transparent"
                        }}
                        onClick={async() => {
                          if (!userInfo.follow){
                            try {
                                const response = await fetch('https://outvirt.com:8080/followuser', {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({id: userInfo.id, token: userInfo.token})
                                    // body: JSON.stringify({id: post.id, token: userInfo.token, lat: latLon?.lat, lon: latLon?.lon, post: true})
                                });
                                if (!response.ok) {
                                    throw new Error(response.statusText);
                                }
                                const newFollowers = userInfo.followers.concat(state.id);
                                setUserInfo({...userInfo, follow: true, followers: newFollowers});
                            } catch (err) {
                            console.log(err);
                            }
                          }
                          else {
                              try {
                                  const response = await fetch('https://outvirt.com:8080/unfollowuser', {
                                  method: 'POST',
                                  headers: {
                                      'Accept': 'application/json',
                                      'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify({id: userInfo.id, token: userInfo.token})
                              });
                              if (!response.ok) {
                                  throw new Error(response.statusText);
                              }
                              
                              const newFollowers = userInfo.followers.splice(userInfo.followers.indexOf(state.id, 1))
                              setUserInfo({...userInfo, follow: false});
                              } catch (err) {
                              console.log(err);
                              }
                          }
                        }}>
                            {
                                userInfo.follow ? <CheckIcon  style={{ fontSize: "24px" }}/> : <PersonAddIcon  style={{ fontSize: "24px" }}/>
                            }
                        </Button>
                    </GridCore>

                  )
                }


        {/* Your conditional rendering for the last column */}
      </GridCore>
    </GridCore>
      <Grid fluid>
  <Row>
    {infPosts &&
      infPosts.map((infPost, idx) => {
        return (
          <Col
            key={idx}
            className="div-crop-profile"
            xs={4}
            onClick={async () => {
              
              setIsLoading(true)
              setSelectIdx(idx)
              const deltaId = (infPosts.length - (idx) > 9) ? 9 : infPosts.length - (idx)
              try {
                const response = await fetch('https://outvirt.com:8080/fetchfollowingpostsbyid', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: infPosts.slice(idx, idx+deltaId).map(post => post.id), user_id: props.id})
                // body: JSON.stringify({id: infPosts.slice(idx, idx+deltaId).map(post => post.id), user_id: props.showStandardProfile ? props.id : state.standardProfileId})
              });
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                response.json().then(res_data => {
                  console.log(res_data);
                  const bufferStart = res_data.length > 5 ? 5 : res_data.length;

                  setFetchBuffer(res_data.splice(bufferStart, res_data.length - bufferStart))
                  
                  for (var j=0; j<res_data.length; j++){
                    res_data[res_data.length-j-1].idx = j
                  }
                  console.log('post', res_data);
                  setPostCards(res_data.reverse())
                  setCurrentIdx(bufferStart-1)
                  setIsPrev(false)
                  setShowContent(true)
                  
                  props.map!.dragging.disable();
                  props.map!.touchZoom.disable();
                  props.map!.doubleClickZoom.disable();
                  props.map!.scrollWheelZoom.disable();
                  props.map!.boxZoom.disable();
                  props.map!.keyboard.disable();
                  if (props.map!.tap) props.map!.tap.disable();
                })
              } catch (err) {
                console.log(err);
              }
              setIsLoading(false)
            }}
          >
            {infPosts[idx].photos[0].slice(-4) === ".mp4" ? (
              <div className="vid-container-bookmark">
              {infPosts[idx].photos.length > 1 && <div className="albums-icon"><CollectionsIcon /></div> }
                <div className="video-play"><PlayArrowIcon /></div>
                <video
                  preload="metadata"
                  src={`https://outvirt.com/assets/img/${infPosts[idx].photos[0]}#t=0.1`}
                ></video>
              </div>
            ) : (
              <div
                className="img-container-bookmark"
                style={{
                  backgroundImage: `url(https://outvirt.com/assets/img/${infPosts[idx].photos[0]})`,
                }}
              >
                {infPosts[idx].photos.length > 1 && <div className="albums-icon"><CollectionsIcon /></div> }
              </div>
            )}
          </Col>
        );
      })}
  </Row>
</Grid>
      <div/>
      <Modal
        isOpen={modalInfo.show}
        onRequestClose={() => setModalInfo({ slides: [], show: false, index: 0 })}
      >
        {modalInfo.slides.length > 0 ? (
          <Swiper
            className="testSwiper"
            initialSlide={modalInfo.index}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {modalInfo.slides.map((image, index) => (
              <SwiperSlide key={index}>
                <div>
                  <img
                    src={'https://outvirt.com/assets/img/' + image}
                    width="15"
                    onClick={() => {
                      setModalInfo({ ...modalInfo, show: false });
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </Modal>
      </DialogContent>
    </Dialog>
    </div>
  );
}

export default StandardProfile;
